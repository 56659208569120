import Marquee from 'components/partials/Marquee'
import React from 'react'

const dspList1 = [
  {
    content: 'Joox',
    img: '/assets/dsps/05JOOX_icon_vertical_black.png',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/Amazon.png',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/ang_wide_color.png',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/applemusic0.png',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/boomplay-logo2019.jpeg',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/Colored_Full_Black@2x-small.png',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/gn_wide_color.png',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/gp_wide_color.png',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/JioSaavn-Lockup-Navy-Transparent.png',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/mn_wide_color.png',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/napster-logo-bw.jpeg',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/NetEase-Cloud-Music-Digital-Stores-Page.png',
  },
]
const dspList2 = [
  {
    content: 'Joox',
    img: '/assets/dsps/Pandora_Wordmark_Black-1.png',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/Peloton-Standard-Lockup-RGB.png',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/resso_logo_stores.png',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/SnapLogo.jpeg',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/Spotify_logo_black-2000x601.png',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/STORE-PAGEMT-ListenOn-Badge-3.png',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/sz_wide_color.png',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/tencent-logo.png',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/Tiktok-logo1-1.png',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/tp_wide_color.png',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/wi_wide_color.png',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/YouTube.png',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/浅色背景.png',
  },
]

const LogoCloud = () => {
  return (
    <div>
      {/* <h1 className='max-w-5xl mx-auto mb-10 text-3xl font-bold tracking-tight text-center text-white md:text-5xl lg:text-7xl lg:leading-tight font-title'>
        Unlimited <span className='text-primary-purple'>100% Free</span> Distribution
      </h1> */}
      <div className=''>
        <Marquee items={dspList1} />
        <Marquee items={dspList2} direction='right' />
      </div>
      {/* <p className='relative font-medium text-center mx-auto mt-10 mb-4 md:mb-8 max-w-lg px-0.5 md:px-0 text-sm md:text-xl leading-5 md:leading-7 text-gray-700'>
        Release your music on Spotify, Apple Music, TikTok, Instagram, Soundcloud,
        Tidal, and all other major stores and streaming platforms.
      </p> */}
    </div>
  )
}

export default LogoCloud
