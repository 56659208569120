import { Upload } from '@aws-sdk/lib-storage'
import { useUI } from 'ctx/UIContext'
import { s3Client } from 'lib/aws-config'
import { lsget } from 'lib/misc'
import { removeSpecialCharacters } from 'lib/utils'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useMastering, useUser } from 'store/RootStore'

export default function useAudioMixing(): {
  mixAudio: (data: File[], mixId: string) => Promise<void>
  masterAudio: (
    data: File[],
    masterId: string,
    low_gain?: number,
    mid_gain?: number,
    high_gain?: number,
    width?: number
  ) => Promise<void>
  generateAccessToken: () => Promise<void>
  accessToken: string
} {
  const navigate = useNavigate()
  const { setLoading } = useUI()
  const { user } = useUser()
  const { getAccessToken, uploadFiles, mixFiles, masterFiles } = useMastering()
  const { t } = useTranslation()

  const accessToken = lsget('mastering_access_token')

  const generateAccessToken = async (): Promise<void> => {
    setLoading(true)

    try {
      const _token: any = await getAccessToken()

      if (!('access_token' in _token)) {
        throw new Error("Couldn't login")
      }

      localStorage.setItem('mastering_access_token', _token.access_token)
    } catch (error: any) {
      if ('message' in error) {
        console.error(error)
        toast.error(t('audioMixing.unavailable'))
        navigate('/')
      }
    } finally {
      setLoading(false)
    }
  }

  const uploadToS3 = async (file: File, userId: string, mixId: string) => {
    const fileName = file.name
    const key = `audioMixingUploads/${userId}/${mixId}/${fileName}`
    const params: any = {
      Bucket: 'musicdash',
      Key: key,
      Body: file,
      ACL: 'public-read',
    }
    const response = new Upload({
      client: s3Client,
      params: params,
    })

    return await response
      .done()
      .then((response) => response)
      .catch((err) => err)
  }

  const mixAudio = async (data: File[], mixId: string) => {
    setLoading(true)

    try {
      toast('Uploading audio files', {
        icon: '📤',
      })
      const audioURLs = await Promise.all(
        data.map(async (file) => {
          const audioFile = new File([file], removeSpecialCharacters(file.name), {
            type: file.type,
          })

          const uploadResponse = await uploadToS3(audioFile, user.username, mixId)
          return uploadResponse.Location
        })
      )
      toast.success('Successfully uploaded audio files')

      const uploadFilesResponse = await uploadFiles(audioURLs)

      if (uploadFilesResponse.message) {
        toast('Mixing audio files', {
          icon: '📤',
        })
        const mixResponse = await mixFiles(audioURLs)
        if (mixResponse.s3_url) {
          toast.success(t('audioMixing.success'))
          return mixResponse
        } else {
          toast.error(t('audioMixing.error'))
        }
      } else {
        toast.error(t('audioMixing.error'))
      }
    } catch (error) {
      console.error('Error during audio mixing process:', error)
      toast.error(t('audioMixing.error2'))
    } finally {
      setLoading(false)
    }
  }

  const masterAudio = async (
    data: File[],
    masterId: string,
    low_gain?: number,
    mid_gain?: number,
    high_gain?: number,
    width?: number
  ) => {
    setLoading(true)

    try {
      toast('Uploading audio files', {
        icon: '📤',
      })
      const audioURLs = await Promise.all(
        data.map(async (file) => {
          const audioFile = new File([file], removeSpecialCharacters(file.name), {
            type: file.type,
          })
          const uploadResponse = await uploadToS3(audioFile, user.username, masterId)
          return uploadResponse.Location
        })
      )
      toast.success('Successfully uploaded audio files')
      const uploadFilesResponse = await uploadFiles(audioURLs)

      if (uploadFilesResponse.message) {
        toast('Mastering audio files', {
          icon: '📤',
        })
        const masterFilesResponse = await masterFiles(
          audioURLs,
          low_gain,
          mid_gain,
          high_gain,
          width
        )

        if (masterFilesResponse.s3_url) {
          toast.success(t('audioMastering.success'))
          return masterFilesResponse
        } else {
          toast.error(t('audioMastering.error'))
        }
      } else {
        toast.error(t('audioMastering.error'))
      }
    } catch (error) {
      console.error('Error during audio mastering process:', error)
      toast.error(t('audioMastering.error2'))
    } finally {
      setLoading(false)
    }
  }

  return {
    generateAccessToken,
    mixAudio,
    masterAudio,
    accessToken,
  }
}

export {}
