import {
  createContext,
  useState,
  useContext,
  FC,
  Dispatch,
  SetStateAction,
  ReactNode,
  useEffect,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import AWS from 'aws-sdk'
import { lsget } from 'lib/misc'
import { useUI } from './UIContext'
import { getHeaders } from 'lib/apiHelpers'
import { API_URL } from 'lib/constants'
import { useUser } from 'store/RootStore'
import { listUserEmails } from 'lib/aws-config'

AWS.config.region = process.env.REACT_APP_REGION as string // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID as string,
})

const ddb = new AWS.DynamoDB.DocumentClient()

interface AuthContextProps {
  login: (username: string, password: string) => Promise<void>
  signUp: (
    username: string,
    password: string,
    firstName: string,
    lastName: string,
    email: string,
    refferal: string | null
  ) => Promise<void>
  confirmSignup: (username: string, code: string | number) => Promise<void>
  logout: () => Promise<void>
  forgotPassword: (username: string) => Promise<void>
  resendConfirmation: (username: string) => Promise<void>
  resetPassword: (code: string, username: string, password: string) => Promise<void>
  changePassword: (oldPass: string, newPass: string) => Promise<void>
  user: string
  setUser: Dispatch<SetStateAction<string>>
}
interface ContextProviderProps {
  children: ReactNode
}

const AuthContext = createContext<AuthContextProps | null>(null)

const AuthContextProvider: FC<ContextProviderProps> = ({ children }) => {
  const navigate = useNavigate()
  const ui = useUI()

  const [user, setUser] = useState(() => lsget('user'))
  const { setUser: setCurrentUser } = useUser()

  const signUp = async (
    username: string,
    password: string,
    firstName: string,
    lastName: string,
    email: string,
    refferal: string | null
  ) => {
    ui?.setLoading(true)

    const coupon = localStorage.getItem('affiliate')

    try {
      await listUserEmails().then(async (res) => {
        if (
          (res && res.map((res) => res.email).includes(email)) ||
          res.map((res) => res.username).includes(username)
        ) {
          toast.error('User already exists')
          ui.setLoading(false)
        } else {
          const _userData = {
            username,
            password,
            firstname: firstName,
            lastname: lastName,
            email,
            refferal,
          }

          const requestOptions: RequestInit = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(_userData),
            redirect: 'follow',
            cache: 'no-cache',
          }

          const _user = await fetch(`${API_URL}signup`, requestOptions)
            .then((res) => res.json())
            .catch((err) => {
              console.error(err)
              // navigate('/my-submissions')
            })
          if ('detail' in _user) {
            toast.error("Couldn't signup, Please retry")
          } else if ('error' in _user) {
            toast.error(_user.error)
          } else {
            toast.success(
              'Your account is registered. Please check your email for activation instructions.',
              {
                icon: '👏',
              }
            )
            localStorage.removeItem('affiliate')
            navigate(`/confirm-signup?user=${username}`)
          }

          ui.setLoading(false)
        }
      })
    } catch (err) {
      toast.error("An error occured. Couldn't sign up. Please retry")
      ui.setLoading(false)
    }
  }

  const confirmSignup = async (username: string, code: string | number) => {
    ui.setLoading(true)

    try {
      toast.success('Your account is Confirmed now. Please Log in', {
        icon: '👏',
      })
      navigate('/login')
    } catch (error: any) {
      console.log(error)
    } finally {
      ui.setLoading(false)
    }
  }

  const getUserID = async () => {
    try {
      const headers = getHeaders()

      const requestOptions: RequestInit = {
        method: 'GET',
        headers,
        // body: raw,
        redirect: 'follow',
        cache: 'no-cache',
      }

      return await fetch(`${API_URL}get_userid`, requestOptions)
        .then((res) => res.json())
        .catch((err) => {
          console.error(err)
          // navigate('/my-submissions')
        })
    } catch (error) {
      console.log(error)
    }
  }

  const login = async (username: string, password: string): Promise<void> => {
    ui?.setLoading(true)

    try {
      const raw = new FormData()
      raw.append('username', username)
      raw.append('password', password)

      const requestOptions: RequestInit = {
        method: 'POST',
        // headers,
        body: raw,
        redirect: 'follow',
        cache: 'no-cache',
      }

      const _user = await fetch(`${API_URL}login`, requestOptions)
        .then((res) => res.json())
        .catch((err) => {
          console.error(err)
          // navigate('/my-submissions')
        })

      if (!('access_token' in _user)) {
        throw new Error("Couldn't login")
        return
      }

      localStorage.setItem('access_token', _user.access_token)
      localStorage.setItem('user_id', _user.user_id)
      setUser(_user)
      setCurrentUser(_user)
      setLoginTimeout()
      clearLocalStorageAfterInterval()
      toast.success('Logged in successfully', {
        icon: '👏',
      })
      navigate('/my-submissions')
    } catch (error: any) {
      if ('message' in error) {
        console.error(error)
        toast.error('Unable to log in.')
      }
    } finally {
      ui?.setLoading(false)
    }
  }

  const logout = async (): Promise<void> => {
    ui?.setLoading(true)
    return await new Promise((resolve, reject) => {
      try {
        localStorage.clear()
        setUser(null)
        resolve()
        navigate('/login')

        // if (window) {
        //   window.location.reload()
        // }
        toast.success(`Logged out successfully!`)
      } catch (error) {
        reject(error)
        console.log(error)
        console.error('Logout failed!! Please try again')
      } finally {
        ui?.setLoading(false)
      }
    })
  }

  const forgotPassword = async (username: string): Promise<void> => {
    ui.setLoading(true)

    try {
      await fetch('https://api.musicdash.com/forgot_password', {
        body: JSON.stringify({ username }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
      }).then((res) => {
        if (res.status === 200) {
          toast.success('Check your email for the verification code.')
          navigate(`/reset-password?user=${username}`)
        } else {
          toast.error('An error happened')
        }
      })
    } catch (error: any) {
      console.log(error)
      if ('message' in error) {
        toast.error(error.message)
      }
    } finally {
      ui.setLoading(false)
    }
  }
  const resendConfirmation = async (username: string): Promise<void> => {
    ui.setLoading(true)

    try {
      await fetch('https://api.musicdash.com/resend', {
        body: JSON.stringify({ username }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
      }).then((res) => {
        if (res.status === 200) {
          toast.success('Confirmation Email resent. Check your inbox.')
          navigate(`/reset-password?user=${username}`)
        } else {
          toast.error('An error happened')
        }
      })
    } catch (error: any) {
      console.log(error)
      if ('message' in error) {
        toast.error(error.message)
      }
    } finally {
      ui.setLoading(false)
    }
  }

  const resetPassword = async (code: string, username: string, password: string) => {
    ui?.setLoading(true)
    try {
      await fetch(`${API_URL}confirm_forgot_password`, {
        body: JSON.stringify({
          username,
          confirmation_code: code,
          password,
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
      }).then((res) => {
        if (res.status === 200) {
          toast.success('Successfully updated password. Please Re-Login')
          navigate('/login')
        } else {
          toast.error('An error happened')
        }
      })
    } catch (error: any) {
      console.log(error)
      if ('message' in error) {
        toast.error(error.message)
      }
    } finally {
      ui?.setLoading(false)
    }
  }

  const changePassword = async (oldPass: string, newPass: string) => {
    ui.setLoading(true)
    const token = lsget('access_token')
    if (!token) {
      ui?.setLoading(false)
      logout()
      return
    }
    try {
      fetch(`${API_URL}change_password`, {
        body: JSON.stringify({
          previous_password: oldPass,
          new_password: newPass,
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        method: 'POST',
      }).then((res) => {
        if (res.status === 200) {
          toast.success(
            'Successfully changed password. Use your new password when logging in'
          )
          // navigate('/login')
        } else {
          toast.error('An error happened')
        }
      })
    } catch (error: any) {
      console.log(error)
      if ('message' in error) {
        toast.error(error.message)
      }
    } finally {
      ui.setLoading(false)
    }
  }

  function setLoginTimeout() {
    // Get the current timestamp in milliseconds
    const currentTime = new Date().getTime()

    // const timeout = 60 * 1000
    const timeout = 5 * 60 * 60 * 1000

    // Calculate the timestamp when the data should be cleared
    const clearTime = currentTime + timeout

    // Save the clear time in local storage
    localStorage.setItem('loggedInAt', currentTime.toString())
    localStorage.setItem('shouldLogOut', clearTime.toString())
    // localStorage.setItem('timeout', timeout.toString())
  }

  useEffect(() => {
    clearLocalStorageAfterInterval()
  }, [])

  function clearLocalStorageAfterInterval() {
    const interval = setInterval(() => {
      // Check if it's time to clear the data
      const shouldLogOut = localStorage.getItem('shouldLogOut')
      if (shouldLogOut) {
        if (Number(shouldLogOut) <= new Date().getTime()) {
          // Clear the data and remove the clear time from local storage
          logout()
          clearInterval(interval)
        }
      } else {
        // clearInterval(interval)
      }
    }, 1000)
  }

  return (
    <AuthContext.Provider
      value={{
        login,
        signUp,
        confirmSignup,

        logout,
        forgotPassword,
        resendConfirmation,
        resetPassword,
        changePassword,
        user,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const useAuth = () => useContext(AuthContext)

export { AuthContextProvider, useAuth }
