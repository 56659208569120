import { FC, JSXElementConstructor, ReactElement } from 'react'
import Tippy from '@tippyjs/react'

import 'tippy.js/dist/tippy.css'

type TooltipProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: ReactElement<any, string | JSXElementConstructor<any>> | undefined
  content: any
}

const Tooltip: FC<TooltipProps> = ({ children, content }) => {
  return (
    <Tippy content={content} theme='fd' offset={[0, 7]}>
      {children}
    </Tippy>
  )
}

export default Tooltip
