import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import ImageCropper from 'components/shared/ImageCropper'
import { Controller, useForm } from 'react-hook-form'
import { useState, useEffect, FC } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSubmissions, useUser } from 'store/RootStore'
import { toast } from 'react-hot-toast'
import { useUI } from 'ctx/UIContext'

import { useCallbackPrompt } from 'hooks/useCallbackPrompt'
import CustomModal from 'components/shared/CustomModal'

import { genresList } from 'data/mock-data'

import FileInput from 'components/shared/FileInput'
import { FieldWrapper } from 'components/shared/FormUtils'
import PageHeader from 'components/shared/PageHeader'
import { GetObjectCommand } from '@aws-sdk/client-s3'
import { s3Client } from 'lib/aws-config'
import { urltoFile } from 'lib/imageUtils'
import AsyncSelect from 'react-select/async'
import { OptionProps, components } from 'react-select'
import { Button } from 'components/partials/Button'
import { Input, Label, SelectInput } from 'components/partials/Inputs'
import { cn, debounce, extractFilename, removeSpecialCharacters } from 'lib/utils'
import { deleteFromS3, toBase64, uploadToS3 } from 'lib/aws-utils'
import { useTranslation } from 'react-i18next'
import { InfoIcon } from 'lucide-react'

type FormType = {
  releaseTitle: string
  genre: string
  label: string
  selected_type: string
  artistName: { value: string; identifier: string }
  sub_genre: string
  status: string
  artwork: File | null
  additional_file: File | null
}

const EditSubmissionData: FC<{ submission: any; getSub: () => void }> = ({
  submission,
  getSub,
}) => {
  const { t } = useTranslation()

  const schema = yup.object({
    label: yup.string().required(t('errors.labelRequired')),
    releaseTitle: yup.string().required(t('errors.releaseTitleRequired')),
    artistName: yup.object().shape({
      value: yup.string().required(t('errors.artistNameRequired')),
    }),
    genre: yup.string().required(t('errors.genreRequired')),
    sub_genre: yup.string().required(t('errors.subGenreRequired')),
    selected_type: yup.string().required(t('errors.typeRequired')),
  })
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    control,
    setError,
    clearErrors,
    reset,
    getValues,
    watch,
  } = useForm<FormType>({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  })

  const navigate = useNavigate()
  const { setLoading } = useUI()
  const { updateSubmission, updateMeta, getSubmission, getArtists } =
    useSubmissions()
  const { user } = useUser()

  const [isBlocking, setBlocking] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)
  const [isSubmitted, setSubmitted] = useState(false)

  const [fileValue, setFileValue] = useState<File | null>(null)
  const [audioLoading, setAudioLoading] = useState(false)

  const [progress, setProgress] = useState(0)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [selectedType, setSelectedType] = useState<string>('')

  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
    isBlocking && !isSubmitted
  )

  const uploadFiles = async (
    submissionId: string,
    userId: string,
    audioFile?: File,
    artworkFile?: File
  ) => {
    setLoading(true)
    try {
      if (artworkFile) {
        toast('Uploading artwork file', {
          icon: '📤',
        })
        await uploadToS3(artworkFile, userId, submissionId)
        toast.success('Successfully uploaded artwork file')
      }
      if (audioFile) {
        toast('Uploading audio file', {
          icon: '📤',
        })
        await uploadToS3(audioFile, userId, submissionId)
        toast.success('Successfully uploaded audio file')
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const deleteFiles = async (
    submissionId: string,
    userId: string,
    audioFileName?: string | null,
    artworkFileName?: string | null
  ) => {
    try {
      if (audioFileName) {
        await deleteFromS3(audioFileName, userId, submissionId)
      }
      if (artworkFileName) {
        await deleteFromS3(artworkFileName, userId, submissionId)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getObjectFromS3 = async (key: string, type: string, fileName: string) => {
    const _key = `submissions/${key}`
    const command = new GetObjectCommand({
      Bucket: 'musicdash',
      Key: _key,
    })

    try {
      if (type === 'artwork') {
        const response = await s3Client.send(command)
        const str = await response.Body?.transformToByteArray()
        urltoFile(
          'data:image/png;base64,' + toBase64(str),
          fileName,
          'image/jpeg'
        ).then((res) => {
          setFileValue(res)
        })
      }
      if (type === 'audio') {
        setAudioLoading(true)
        const response = await s3Client.send(command)
        const str = await response.Body?.transformToByteArray()
        urltoFile('data:audio/wav;base64,' + toBase64(str), fileName, 'audio/wav')
          .then((res) => {
            setSelectedFile(res)
          })
          .finally(() => {
            setAudioLoading(false)
          })
      }
    } catch (err) {
      console.error(err)
    }
  }

  const onSubmit = async (data: any) => {
    if (!data.artistName) {
      toast.error(t('toasts.selectArtist'))
      return
    }

    const oldArtwork = extractFilename(submission.artWork)
    const oldAudio = extractFilename(submission.file)

    const artworkFile = fileValue
      ? new File([fileValue], removeSpecialCharacters(fileValue.name), {
          type: fileValue.type,
        })
      : null
    const audioFile = selectedFile
      ? new File([selectedFile], removeSpecialCharacters(selectedFile.name), {
          type: selectedFile.type,
        })
      : null

    const newArtworkFileURL = artworkFile
      ? decodeURIComponent(
          `https://musicdash.s3.amazonaws.com/submissions/${user.user_id}/${submission.submission_id}/${artworkFile.name}`
        )
      : null

    const newFileURL = audioFile
      ? decodeURIComponent(
          `https://musicdash.s3.amazonaws.com/submissions/${user.user_id}/${submission.submission_id}/${audioFile.name}`
        )
      : null

    const submissionData = {
      submission_id: submission.submission_id,
      releaseTitle: data.releaseTitle,
      artistName: data.artistName?.label,
      identifier: data.artistName?.identifier,
      label: data.label,
      genre: data.genre,
      sub_genre: data.sub_genre,
      selected_type: data.selected_type,
      status: 'draft',
      artwork: newArtworkFileURL === null ? submission.artWork : newArtworkFileURL,
      additional_file: newFileURL === null ? submission.file : newFileURL,
    }

    const isNewArtwork =
      oldArtwork.toLowerCase() !== artworkFile?.name?.toLowerCase()
    const isNewAudio = oldAudio.toLowerCase() !== audioFile?.name?.toLowerCase()

    try {
      setLoading(true)
      setSubmitting(true)
      const subID = submission.submission_id
      const userID = user.user_id

      // upload new files
      await uploadFiles(
        subID,
        userID,
        isNewAudio && audioFile ? audioFile : undefined,
        isNewArtwork && artworkFile ? artworkFile : undefined
      )

      // delete existing files
      const deleteFilesResponse = await deleteFiles(
        subID,
        userID,
        isNewAudio ? oldAudio : null,
        isNewArtwork ? oldArtwork : null
      )

      const updateSubResponse = await updateSubmission(submissionData)
      if (!updateSubResponse) {
        return
      }

      const updateMetadataResponse = await updateMeta({
        submission_id: subID,
        additional_file: newFileURL,
        artwork: newArtworkFileURL,
        genre: submission.genre,
        sub_genre: submission.sub_genre,
      })
    } catch (error) {
      console.log(error)
    } finally {
      setSubmitting(false)
      setLoading(false)
      getSub()
    }
  }

  const cancelSubmission = (e: any) => {
    e.preventDefault()
    navigate('/my-submissions')
  }

  useEffect(() => {
    if (isDirty) {
      if (isSubmitted) {
        setBlocking(false)
      } else {
        setBlocking(true)
      }
    }
    // if ((isDirty || isDirty_2 || isDirty3) && !isSubmitted) {
    //   setBlocking(true)
    // }
  }, [isDirty, isSubmitted])

  useEffect(() => {
    const defaultValue = {
      label: submission.artistName,
      value: submission.artistName,
      identifier: submission.identifier,
    }

    reset({ ...submission, artistName: defaultValue })

    if (submission.artWork) {
      const artworkKey = submission.artWork.split('submissions/')[1]
      const fileName = artworkKey?.split('/')[2]

      fileName && getObjectFromS3(artworkKey, 'artwork', fileName)
    }
    if (submission.file) {
      const fileKey = submission.file?.split('submissions/')[1]
      const fileName = fileKey?.split('/')[2]

      fileName && getObjectFromS3(fileKey, 'audio', fileName)
    }

    if (submission?.selected_type?.toLowerCase() === 'music video') {
      setSelectedType('music video')
    } else if (submission?.selected_type?.toLowerCase() === 'single') {
      setSelectedType('single')
    } else {
      setSelectedType('album')
    }
  }, [JSON.stringify(submission)])

  const loadArtists = async (inputValue: string) => {
    if (inputValue === '') return []
    return await new Promise<any[]>((resolve) => {
      getArtists(inputValue).then((res) => {
        if (res && 'artists' in res) {
          const _options = res.artists.items?.map((item: any) => ({
            label: item.name,
            value: item.uri,
            profile_pic: item.images?.length ? item.images[0].url : '',
            identifier: item.uri,
          }))
          resolve(appendSpecialOption(_options, inputValue))
        }
      })
    })
  }

  const Option = (props: OptionProps<any>) => {
    return (
      <components.Option className='gap-2' {...props}>
        <div className='w-8 h-8 overflow-hidden rounded-full'>
          <img src={props.data.profile_pic} alt='' className='' />
        </div>
        {props.children}
      </components.Option>
    )
  }

  const appendSpecialOption = (filteredOptions: any[], value: string) => {
    const label = `Create "${value}"`
    return [
      { label, value, profile_pic: '/assets/plus.svg', identifier: '' },
      ...filteredOptions,
    ]
  }

  const type = watch('selected_type')

  const renderFileInputs = () => {
    switch (type?.toLowerCase()) {
      case 'single':
      case 'album':
        return (
          <>
            <ImageCropper
              register={register}
              label={t('labels.artwork')}
              name='artwork'
              aspectW={1}
              aspectH={1}
              setError={setError}
              setValue={setFileValue}
              errors={errors}
              reset={reset}
              cls='relative w-full md:w-2/3'
              validationRules='1:1 - Minimum 3000 x 3000 px'
              required
              value={fileValue}
            />
            <div className='w-full md:w-1/2'>
              <FileInput
                register={register}
                setError={setError}
                label={t('labels.uploadAudio')}
                name='additional_file'
                helperText='MP3 / WAV Files only'
                errors={errors}
                clearErrors={clearErrors}
                accept={['.mp3', '.wav']}
                setValue={setSelectedFile}
                fileValue={selectedFile}
                isLoading={false}
                progress={progress}
              />
            </div>
          </>
        )
      case 'music video':
        return (
          <>
            <ImageCropper
              register={register}
              label={t('labels.videoThumbnail')}
              name='artwork'
              aspectW={16}
              aspectH={9}
              setError={setError}
              setValue={setFileValue}
              errors={errors}
              reset={reset}
              cls='relative w-full md:w-2/3'
              validationRules='16:9 - Minimum 1920 x 1080 px'
              required
              value={fileValue}
            />
            <div className='w-full md:w-1/2'>
              <FileInput
                register={register}
                setError={setError}
                label={t('labels.uploadVideo')}
                name='additional_file'
                helperText='16:9 - mp4 / mov files only'
                errors={errors}
                clearErrors={clearErrors}
                accept={['.mp4', '.mov']}
                setValue={setSelectedFile}
                fileValue={selectedFile}
                isLoading={false}
                progress={progress}
              />
            </div>
          </>
        )

      default:
        return (
          <>
            <ImageCropper
              register={register}
              label={t('labels.artwork')}
              name='artwork'
              aspectW={1}
              aspectH={1}
              setError={setError}
              setValue={setFileValue}
              errors={errors}
              reset={reset}
              cls='relative w-full md:w-2/3'
              validationRules='1:1 - Minimum 2100 x 2100 px'
              required
              value={fileValue}
            />
            <div className='w-full md:w-1/2'>
              <FileInput
                register={register}
                setError={setError}
                label={t('labels.uploadAudio')}
                name='additional_file'
                helperText='MP3 / WAV Files only'
                errors={errors}
                clearErrors={clearErrors}
                accept={['.mp3', '.wav']}
                setValue={setSelectedFile}
                fileValue={selectedFile}
                isLoading={false}
                progress={progress}
              />
            </div>
          </>
        )
    }
  }

  return (
    <>
      <CustomModal
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        isOpen={showPrompt}
        close={cancelNavigation}
        action={confirmNavigation}
        title='Leave page'
      >
        <p className='text-sm text-center text-gray-500'>
          {t('messages.deleteConfirm')}
        </p>
      </CustomModal>

      <form
        className='flex flex-col items-center justify-center bg-white rounded-lg shadow-sm'
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <PageHeader
          title={submission?.releaseTitle + ' - ' + submission?.selected_type}
          hasCreateSubmissionTrigger={false}
          additionalElements={
            <div className='flex items-center justify-end gap-1'>
              <Button type='button' onClick={cancelSubmission} variant='destructive'>
                {t('buttons.cancel')}
              </Button>
              <Button
                type='submit'
                variant='outline'
                disabled={isSubmitting}
                isLoading={isSubmitting}
              >
                {isSubmitting ? t('buttons.saving') : t('buttons.saveChanges')}
              </Button>
            </div>
          }
          className='bg-white'
        />

        <div className='w-full mt-5'>
          <div className='grid items-start justify-between w-full grid-cols-1 gap-4 p-5 mb-4 rounded-lg md:grid-cols-2 lg:grid-cols-3'>
            <Input
              label={t('labels.releaseTitle')}
              placeholder={t('labels.releaseTitle')}
              name='releaseTitle'
              register={register}
              error={errors?.releaseTitle?.message as string}
            />

            <SelectInput
              control={control}
              options={[
                { label: 'Single', value: 'Single' },
                { label: 'Album', value: 'Album' },
                { label: 'Music Video', value: 'Music Video' },
              ]}
              label={t('labels.type')}
              placeholder={t('labels.selectType')}
              name='selected_type'
              error={errors?.selected_type?.message as string}
              isDisabled
            />

            <FieldWrapper>
              <Label htmlFor='artistName'>
                {t('labels.artistName')}{' '}
                {errors['artistName'] ? (
                  <span className={cn('text-xs text-red-500 ml-1')}>
                    * {t('errors.artistNameRequired')}
                  </span>
                ) : null}
              </Label>
              <Controller
                control={control}
                name='artistName'
                rules={{ required: true }}
                render={({ field: { onChange, value, ref } }) => {
                  return (
                    <AsyncSelect
                      className='input !p-0'
                      classNamePrefix='select'
                      value={value}
                      onChange={onChange}
                      ref={ref}
                      isLoading={false}
                      isClearable
                      isSearchable
                      options={[]}
                      defaultOptions
                      noOptionsMessage={() => 'Type to search artists'}
                      cacheOptions
                      loadOptions={debounce(loadArtists, 500)}
                      // menuIsOpen
                      components={{
                        Option,
                      }}
                    />
                  )
                }}
              />
            </FieldWrapper>

            <Input
              label={t('labels.label')}
              placeholder={t('labels.label')}
              name='label'
              register={register}
              error={errors?.label?.message as string}
            />

            <SelectInput
              control={control}
              options={
                genresList &&
                genresList.map((genre) => ({
                  label: genre.name,
                  value: genre.name,
                }))
              }
              label={t('labels.genre')}
              placeholder={t('labels.selectGenre')}
              name='genre'
              error={errors?.genre?.message as string}
            />
            <SelectInput
              control={control}
              options={
                genresList &&
                genresList.map((genre) => ({
                  label: genre.name,
                  value: genre.name,
                }))
              }
              label={t('labels.subGenre')}
              placeholder={t('labels.selectSubGenre')}
              name='sub_genre'
              error={errors?.sub_genre?.message as string}
            />
            <div className='flex items-center gap-2 p-4 px-6 rounded-md bg-primary-purple/5'>
              <InfoIcon className='size-5 text-primary-purple' />{' '}
              <div>
                {t('labels.checkArtworkInfo')}{' '}
                <Link
                  to={'/artwork-generator'}
                  className='font-semibold transition-all text-primary-purple hover:text-primary-purple/80'
                >
                  {t('words.here')}
                </Link>{' '}
                {t('labels.checkArtworkInfo2')}
              </div>
            </div>
            <div className='relative flex flex-col items-start justify-center gap-1 col-span-full md:flex-row md:gap-2'>
              {renderFileInputs()}
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default EditSubmissionData
