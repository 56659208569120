import AuthLayout from 'components/layout/AuthLayout'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Link } from 'react-router-dom'

import { FieldWrapper, Error } from 'components/shared/FormUtils'
import { useAuth } from 'ctx/AuthContext'
import { useTranslation } from 'react-i18next'
import { useUI } from 'ctx/UIContext'

const ForgotPassword = () => {
  const auth = useAuth()
  const { t } = useTranslation()
  const { whiteLabelData } = useUI()

  const schema = yup.object().shape({
    username: yup.string().required(t('auth.confirm.username.required')),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema),
  })

  const onSubmit = (data: any) => {
    auth?.forgotPassword(data.username)
  }

  return (
    <AuthLayout>
      <form
        className='form justify-center !h-max !p-0'
        onSubmit={handleSubmit(onSubmit)}
      >
        <FieldWrapper>
          <label htmlFor='username'>
            {t('auth.confirm.username.title')}{' '}
            {errors['username'] ? (
              <Error>
                <span className='inline-block mt-0.5'>
                  {errors?.email?.message?.toString()}
                </span>
              </Error>
            ) : null}
          </label>
          <input
            className='input'
            type='text'
            placeholder='Username'
            {...register('username')}
          />
        </FieldWrapper>
        <div className='relative flex items-center justify-end my-2'>
          <Link to='/login' className='text-sm underline'>
            {t('authLayout.login.backToLogin')}
          </Link>
        </div>
        <button
          className={`mt-2 button ${
            !whiteLabelData.theme ? 'primary' : 'hover:opacity-80'
          }`}
          style={{
            backgroundColor: whiteLabelData?.theme?.[2] ?? '',
            color: whiteLabelData?.theme?.[1] ?? '',
          }}
          type='submit'
        >
          {t('authLayout.resetPassword.title')}
        </button>
      </form>
    </AuthLayout>
  )
}

export default ForgotPassword
