import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Status from './Status'
import Tooltip from './Tooltip'
import { useSubmissions, useUser } from 'store/RootStore'
import { toast } from 'react-hot-toast'
import CustomModal from './CustomModal'
import { useUI } from 'ctx/UIContext'
import { Badge } from 'components/partials/Badge'
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from 'components/partials/Card'
import { Settings2, Trash2 } from 'lucide-react'
import { extractFilename } from 'lib/utils'
import { deleteFromS3 } from 'lib/aws-utils'
import { useTranslation } from 'react-i18next'

interface IFilmCardProps {
  submission: any
  i: number
  updateSubmissions: () => void
}

const FilmCard: FC<IFilmCardProps> = ({ submission, i, updateSubmissions }) => {
  const { deleteSubmission, getSubmission } = useSubmissions()
  const { setLoading } = useUI()
  const { user } = useUser()
  const { t } = useTranslation()

  const navigate = useNavigate()

  const [deleteWarningOpen, setDeleteWarningOpen] = useState(false)

  const beforeDeleteSubmission = (e: any) => {
    e.preventDefault()
    setDeleteWarningOpen(true)
  }

  const isEditable = submission.status?.toLowerCase() === 'draft'

  const handleClickEdit = () => {
    const type = submission.selected_type.toLowerCase().split(' ').join('-')
    const link = `/submission/${type}/${submission.submission_id}`
    // if (isEditable) {
    //   navigate(link)
    // }
    navigate(link)
  }

  const handleDeleteSubmission = async (id: string) => {
    setDeleteWarningOpen(false)
    setLoading(true)
    try {
      const getSubmissionResponse = await getSubmission(id)

      if (!getSubmissionResponse && !getSubmissionResponse[0]) return
      const res = getSubmissionResponse[0]

      const artworkFileName = extractFilename(res.artWork)
      const audioFileName = extractFilename(res.file)

      const deleteFilesResponse: any = await deleteFiles(
        audioFileName,
        artworkFileName,
        id,
        user.user_id
      )

      // if (!deleteFilesResponse) return

      const deleteSubmissionResponse = await deleteSubmission(id)
      toast.success(deleteSubmissionResponse.message)
    } catch (error) {
      toast.error('An error happened when removing submission')
    } finally {
      updateSubmissions()
      setLoading(false)
    }
  }

  const deleteFiles = async (
    audioFileName: string | null,
    artworkFileName: string | null,
    submissionId: string,
    userId: string
  ) => {
    setLoading(true)
    try {
      if (audioFileName) {
        await deleteFromS3(audioFileName, userId, submissionId)
      }
      if (artworkFileName) {
        await deleteFromS3(artworkFileName, userId, submissionId)
      }
    } catch (error) {
      toast.error('An error happened when deleting submission')
    }
  }

  return (
    <>
      <CustomModal
        isOpen={deleteWarningOpen}
        close={() => setDeleteWarningOpen(false)}
        action={() => handleDeleteSubmission(submission.submission_id)}
        title='Delete submission'
      >
        <p className='text-sm text-center text-gray-500'>
          {t('messages.deleteAlert')}
        </p>
      </CustomModal>
      <Card>
        <CardHeader className='relative'>
          <CardTitle className='flex flex-row items-end justify-between w-full gap-2 px-1'>
            <span className='text-xl font-bold leading-6 capitalize truncate'>
              {submission.releaseTitle}
            </span>
            <div className='relative flex items-center justify-end gap-1 w-max'>
              {isEditable ? (
                <Tooltip content={t('filmCard.deleteSubmissionModal.title')}>
                  <div
                    className='icon error'
                    aria-hidden
                    onClick={beforeDeleteSubmission}
                  >
                    <Trash2 size={20} />
                  </div>
                </Tooltip>
              ) : null}
              <div className='relative'>
                <Status type={submission?.status} />
              </div>
            </div>
          </CardTitle>
        </CardHeader>
        <CardContent className='relative px-2 pb-2'>
          <div className='relative w-full overflow-hidden border h-max rounded-xl aspect-square group'>
            <img
              className='object-contain object-center w-full h-full'
              src={
                submission.artWork
                  ? submission.artWork
                  : '/assets/imgs/empty-image.png'
              }
              alt={submission.releaseTitle}
            />
            <div className='absolute top-0 left-0 w-full h-full transition-opacity duration-300 bg-black opacity-0 group-hover:opacity-60' />

            <div
              className={[
                'cursor-pointer group-hover:opacity-100 absolute top-0 left-0 w-full h-full opacity-0 flex flex-col gap-2 items-center justify-center transition-opacity duration-300',
                // !isEditable
                //   ? 'cursor-not-allowed'
                //   : 'cursor-pointer group-hover:opacity-100',
              ].join(' ')}
              aria-hidden
              onClick={handleClickEdit}
            >
              <span className='text-2xl text-white'>
                <Settings2 />
              </span>
              <span className='text-xs text-white'>
                {t('filmCard.editButton.labelAlt')}
              </span>
            </div>
          </div>
        </CardContent>
        <CardFooter className='flex items-center justify-start flex-1 gap-1'>
          <Badge variant='secondary'>{submission.selected_type}</Badge>
          <Badge>{submission.genre}</Badge>
        </CardFooter>
      </Card>
    </>
  )
}

export default FilmCard
