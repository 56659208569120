import { Tab } from '@headlessui/react'
import { useUI } from 'ctx/UIContext'
import { classNames } from 'lib/misc'
import { ReactNode } from 'react'

const GuidesTabs2 = () => {
  const tabs = [
    {
      title: 'Submission Guides',
      value: 'Submission Guides',
      content: (
        <div className='relative w-full h-full p-4 overflow-hidden text-white rounded-2xl md:p-10 bg-gradient-to-br from-purple-700 to-primary-purple'>
          <FilmSubmissionGuide />
        </div>
      ),
      height: 1872,
    },
    {
      title: 'Audio Requirements',
      value: 'Audio Requirements',
      content: (
        <div className='relative w-full h-full p-4 overflow-hidden text-white rounded-2xl md:p-10 bg-gradient-to-br from-purple-700 to-primary-purple'>
          <VideoAudioRequirements />
        </div>
      ),
      height: 1740,
    },
    {
      title: 'Artwork Requirements',
      value: 'Artwork Requirements',
      content: (
        <div className='relative w-full h-full p-4 overflow-hidden text-white rounded-2xl md:p-10 bg-gradient-to-br from-purple-700 to-primary-purple'>
          <ArtworkPosterRequirements />
        </div>
      ),
      height: 892,
    },
  ]

  return (
    <div className='relative w-full h-full overflow-y-auto'>
      <Tab.Group>
        <div className='px-4 mx-auto md:container'>
          <Tab.List className='flex flex-col items-start justify-center w-full h-auto my-8 md:items-center md:flex-row md:h-10'>
            {tabs.map((tab, index) => (
              <Tab
                key={index}
                className={({ selected }) =>
                  classNames(
                    'capitalize px-2 md:px-5 py-1 md:py-1.5 flex items-center rounded-xl justify-start gap-2 text-sm whitespace-nowrap',
                    selected ? 'bg-primary-purple text-white' : ''
                  )
                }
              >
                {tab.title}
              </Tab>
            ))}
          </Tab.List>
        </div>
        <Tab.Panels className='mt-2'>
          {tabs.map((tab, index) => (
            <Tab.Panel key={index} className='p-3'>
              {tab.content}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}
export default GuidesTabs2

const GuideContainer = ({ children }: { children: ReactNode }) => {
  return (
    <div className='container relative p-2 mx-auto my-10 md:p-6'>{children}</div>
  )
}

const FilmSubmissionGuide = () => {
  const { whiteLabelData } = useUI()

  return (
    <GuideContainer>
      <h2 className='mb-4 text-2xl font-bold text-center'>
        How to Add your Submission to the{' '}
        {whiteLabelData?.app_name && whiteLabelData?.app_name !== 'default'
          ? whiteLabelData.app_name
          : 'MusicDASH'}{' '}
        Dashboard
      </h2>
      {/* <p className="mb-6 text-sm text-center">
          Submit your video in the highest quality available.
        </p> */}

      <div className='mb-4'>
        <ol className='ml-4 list-decimal'>
          <li>
            Press the Create Submission button located on the top right side of the
            page.
          </li>
          <li>
            Add all the data in the fields (title, type, genre, artist name, label,
            genre, sub genre ,artwork and audio).
            <span className='font-semibold'>
              {' '}
              Audio files must be FLAC or WAV and in one of the below specifications:
            </span>
            <ul className='ml-6'>
              <li>HD Audio 192 kHz 32 bits</li>
              <li>HD Audio 192 kHz 24 bits</li>
              <li>HD Audio 176.4 kHz 32 bits</li>
              <li>HD Audio 176.4 kHz 24 bits</li>
              <li>HD Audio 96 kHz 32 bits</li>
              <li>HD Audio 96 kHz 24 bits</li>
              <li>HD Audio 88.2 kHz 32 bits</li>
              <li>HD Audio 88.2 kHz 24 bits</li>
              <li>HD Audio 48 kHz 32 bits</li>
              <li>HD Audio 48 kHz 24 bits</li>
              <li>HD Audio 48 kHz 16 bits</li>
              <li>HD Audio 44.1 kHz 32 bits</li>
              <li>HD Audio 44.1 kHz 24 bits</li>
              <li>Audio 44.1 kHz 16 bits</li>
            </ul>
            <span className='font-semibold'>
              {' '}
              Cover art files must be JPG RGB or PNG format, a minimum of 1400 x 1400
              pixels and maximum of 6000 x 6000 pixels . We highly recommend you aim
              to supply a minimum of 3000 x 3000 pixels.
            </span>
          </li>
          <li>
            When it’s done, press the Create Submission button located on the top
            right side of the page.
          </li>
        </ol>
      </div>

      <div className='mb-4'>
        <h3 className='mb-2 text-xl font-semibold'>Metadata Screen</h3>
        <p className='mb-2'>
          On the metadata screen, you must input all the required fields (Track
          title, Genre, Sub Genre, Lyrics Language, Title Language, Parental
          Advisory, Digital Release Date, Preview Start, Lyrics Writer, Composer).
          Make sure to press the “save Changes” button on the top-right to save
          everything you updated.
        </p>
        <div className='relative max-w-lg mb-4 overflow-hidden rounded-lg shadow-lg'>
          <img src='/assets/guides/metadata.jpg' alt='' />
        </div>
      </div>

      <div className='mb-4'>
        <h3 className='mb-2 text-xl font-semibold'>Submission Screen</h3>
        <p className='mb-2'>
          On the submission screen, you can update the details of the submission. You
          can update the artworks, or audio files. Once you finish updating, you must
          press the “save changes” button.
        </p>
        <div className='relative max-w-lg mb-4 overflow-hidden rounded-lg shadow-lg'>
          <img src='/assets/guides/submission.jpg' alt='' />
        </div>
      </div>

      <div className='mb-4'>
        <h3 className='mb-2 text-xl font-semibold'>Agreement Screen</h3>
        <p className='mb-2'>
          On this screen, you can add your details and sign the agreement. Draw your
          signature in the signature field and press the “Update Agreement” button
          once you’re done.
        </p>
        <div className='relative max-w-lg mb-4 overflow-hidden rounded-lg shadow-lg'>
          <img src='/assets/guides/agreement.jpg' alt='' />
        </div>
        <p className='font-semibold'>Note:</p>
        <p>
          When uploading files, you need to wait until the upload finishes. Please
          don’t refresh or close the page as it will discontinue the uploading of
          files. It will depend on your internet connection and how fast it will be
          done.
        </p>
      </div>

      <div className='mb-4'>
        <h3 className='mb-2 text-xl font-semibold'>Submission for Distributions</h3>
        <p className='mb-2'>
          Once all completed with the submission, the “Send to Distribution” button
          on the top-right corner will be enabled. It will submit your submissions
          directly to distributions.
        </p>
        <p className='font-semibold'>Note:</p>
        <p>
          If there is still missing data, the “Send to Distribution” button will
          still be disabled.
        </p>
      </div>
    </GuideContainer>
  )
}

const VideoAudioRequirements = () => {
  return (
    <GuideContainer>
      <div className='space-y-8'>
        <section>
          <h2 className='mb-2 text-xl font-semibold'>
            Audio files must be FLAC or WAV and in one of the below specifications:
          </h2>
          <ul className='space-y-1 list-disc list-inside'>
            <li>HD Audio 192 kHz 32 bits</li>
            <li>HD Audio 192 kHz 24 bits</li>
            <li>HD Audio 176.4 kHz 32 bits</li>
            <li>HD Audio 176.4 kHz 24 bits</li>
            <li>HD Audio 96 kHz 32 bits</li>
            <li>HD Audio 96 kHz 24 bits</li>
            <li>HD Audio 88.2 kHz 32 bits</li>
            <li>HD Audio 88.2 kHz 24 bits</li>
            <li>HD Audio 48 kHz 32 bits</li>
            <li>HD Audio 48 kHz 24 bits</li>
            <li>HD Audio 48 kHz 16 bits</li>
            <li>HD Audio 44.1 kHz 32 bits</li>
            <li>HD Audio 44.1 kHz 24 bits</li>
            <li>Audio 44.1 kHz 16 bits</li>
          </ul>
        </section>
      </div>
    </GuideContainer>
  )
}

const ArtworkPosterRequirements = () => {
  return (
    <GuideContainer>
      <h1 className='mb-4 text-2xl font-bold text-center'>Artwork Requirements</h1>
      <div className='space-y-8'>
        <section>
          <ul className='space-y-1 list-disc list-inside'>
            <li>
              Artwork files must be JPG RGB or PNG format, a minimum of 1400 x 1400
              pixels and maximum of 6000 x 6000 pixels . We highly recommend you aim
              to supply a minimum of 3000 x 3000 pixels.
            </li>
            <li>
              You have the capability to upload images and utilize our built-in crop
              tool, which ensures dimension validation during the cropping process.
            </li>
            <li>
              Additionally, you can opt to create artwork for FREE using our
              &quot;Artwork Generator&quot; tool powered by AI.
            </li>
          </ul>
        </section>
      </div>
    </GuideContainer>
  )
}
