import { useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import yupPassword from 'yup-password'

import AuthLayout from 'components/layout/AuthLayout'
import { FieldWrapper, Error } from 'components/shared/FormUtils'
import { useAuth } from 'ctx/AuthContext'
import { EyeClosedIcon, EyeOpenIcon } from 'components/shared/Icons'
import { useTranslation } from 'react-i18next'
import { useUI } from 'ctx/UIContext'

yupPassword(yup)

const ResetPassword = () => {
  const auth = useAuth()
  const { t } = useTranslation()
  const { whiteLabelData } = useUI()
  const [searchParams] = useSearchParams()
  const [isPasswordVisible, setPasswordVisible] = useState(false)

  const schema = yup.object().shape({
    code: yup.string().required(t('auth.errors.codeRequired')),
    password: yup
      .string()
      .required(t('auth.errors.newPassword'))
      .minLowercase(1, t('auth.errors.passLowerCase'))
      .minUppercase(1, t('auth.errors.passUpperCase'))
      .minNumbers(1, t('auth.errors.passNumber'))
      .minSymbols(1, t('auth.errors.passSpecialChar'))
      .min(8, t('auth.errors.passLength')),
    cpassword: yup
      .string()
      .required(t('auth.errors.confirmPassRequired'))
      .min(8, t('auth.errors.passLength'))
      .oneOf([yup.ref('password')], t('auth.errors.confirmPassMatch')),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema),
  })

  const onSubmit = (data: any) => {
    if (searchParams && searchParams.get('user')) {
      const _username = searchParams.get('user')
      if (!_username) return
      auth?.resetPassword(data.code, _username, data.password)
    }
  }

  const togglePasswordVisible = () => setPasswordVisible(!isPasswordVisible)

  return (
    <AuthLayout>
      <form
        className='form justify-center !h-max !p-0'
        onSubmit={handleSubmit(onSubmit)}
      >
        <FieldWrapper cls='flex-1 w-full'>
          <label htmlFor='code'>
            {t('auth.confirm.code.verificationCode')}{' '}
            {errors['code'] ? (
              <Error>
                <span className='inline-block mt-0.5'>
                  {' '}
                  {errors.code?.message?.toString()}
                </span>
              </Error>
            ) : null}
          </label>
          <input
            className='input'
            type='number'
            placeholder='Verification Code'
            {...register('code')}
          />
        </FieldWrapper>
        <FieldWrapper>
          <label htmlFor='password'>
            {t('auth.password.newPassword')}{' '}
            {errors['password'] ? (
              <Error>
                <span className='inline-block mt-0.5'>
                  {' '}
                  {errors.password?.message?.toString()}
                </span>
              </Error>
            ) : null}
          </label>
          <div className='flex items-start justify-between w-full'>
            <input
              className='input'
              type={isPasswordVisible ? 'text' : 'password'}
              placeholder='Min 8 characters'
              {...register('password')}
            />
            <span
              className='inline-block pt-4 pl-2 text-sm text-primary-purple'
              aria-hidden
              onClick={togglePasswordVisible}
            >
              {isPasswordVisible ? <EyeOpenIcon /> : <EyeClosedIcon />}
            </span>
          </div>
        </FieldWrapper>
        <FieldWrapper>
          <label htmlFor='cpassword'>
            {t('auth.password.confirmPassword')}{' '}
            {errors['cpassword'] ? (
              <Error>
                <span className='inline-block mt-0.5'>
                  {' '}
                  {errors.cpassword?.message?.toString()}
                </span>
              </Error>
            ) : null}
          </label>
          <div className='flex items-start justify-between w-full'>
            <input
              className='input'
              type={isPasswordVisible ? 'text' : 'password'}
              placeholder='Min 8 characters'
              {...register('cpassword')}
            />
            <span
              className='inline-block pt-4 pl-2 text-sm text-primary-purple'
              aria-hidden
              onClick={togglePasswordVisible}
            >
              {isPasswordVisible ? <EyeOpenIcon /> : <EyeClosedIcon />}
            </span>
          </div>
        </FieldWrapper>
        <div className='relative flex items-center justify-end my-2'>
          <Link to='/login' className='text-sm underline'>
            {t('authLayout.login.backToLogin')}
          </Link>
        </div>
        <button
          className={`mt-2 button ${
            !whiteLabelData.theme ? 'primary' : 'hover:opacity-80'
          }`}
          style={{
            backgroundColor: whiteLabelData?.theme?.[2] ?? '',
            color: whiteLabelData?.theme?.[1] ?? '',
          }}
          type='submit'
        >
          {t('authLayout.resetPassword.title')}
        </button>
      </form>
    </AuthLayout>
  )
}

export default ResetPassword
