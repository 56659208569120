import PageHeader from 'components/shared/PageHeader'
import { useTranslation } from 'react-i18next'
import FBLogin from './FBLogin'
import { FB_REDIRECT_URL } from 'lib/constants'
import { useEffect } from 'react'
import useMarketingTool from 'hooks/useMarketingTool'

const MarketingLogin = () => {
  const { t } = useTranslation()
  const { generateAccessToken } = useMarketingTool()

  useEffect(() => {
    generateAccessToken()
  }, [])

  return (
    <div className='page-wrapper'>
      <PageHeader title={t('sidebar.marketingTool')} isLoading={false} />
      <div className='flex flex-col items-center justify-center w-full h-full my-10 space-y-4 font-medium md:pt-0'>
        <div className='mb-10 space-y-2 text-center'>
          <h1 className='text-3xl font-bold'>AI Marketing Tool</h1>
          <p>
            AI-driven tool for creating and optimizing targeted marketing campaigns
            with ease.
          </p>
        </div>
        <div className='flex flex-col gap-10 lg:flex-row'>
          <div className='w-full max-w-md '>
            <img
              src='/assets/marketing-tool.webp'
              alt=''
              className='w-full h-full'
            />
          </div>
          <div className='w-full max-w-xl'>
            <h1 className='mb-2 font-bold uppercase'>What's New?</h1>
            <p className='mb-6'>
              Facebook & Instagram Promotions: Create & Manage Ad Campaigns with AI,
              Tailored to connect with audiences on both platforms.
            </p>
            <h1 className='mb-2 font-bold uppercase'>Why use our tool?</h1>
            <p className='mb-6'>
              Launch Ad campaigns & post updates about your latest submissions. All
              through our platform.
            </p>
            <FBLogin
              clientId={process.env.REACT_APP_FB_APP_ID as string}
              redirectUri={FB_REDIRECT_URL}
              scope={[
                'email',
                'read_insights',
                'publish_video',
                'pages_show_list',
                'ads_management',
                'ads_read',
                'business_management',
                'instagram_basic',
                'instagram_manage_comments',
                'instagram_manage_insights',
                'instagram_content_publish',
                'leads_retrieval',
                'page_events',
                'pages_read_engagement',
                'pages_manage_metadata',
                'pages_read_user_content',
                'pages_manage_ads',
                'pages_manage_posts',
                'pages_manage_engagement',
                'instagram_shopping_tag_products',
                'instagram_branded_content_brand',
                'instagram_branded_content_creator',
                'instagram_branded_content_ads_brand',
                'instagram_manage_events',
                'public_profile',
              ]}
              configId={process.env.REACT_APP_FB_CONFIG_ID as string}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MarketingLogin
