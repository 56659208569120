import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import toast from 'react-hot-toast'
import { useState } from 'react'
import { FieldErrors, UseFormRegister } from 'react-hook-form'
import { handleFormSubmit } from 'lib/form'
import { useUI } from 'ctx/UIContext'

export type FormField = {
  name: string
  email: string
  message: string
}

const schema = yup
  .object({
    name: yup.string().required('Name is required'),
    email: yup.string().email('Invalid email address').required('Email is required'),
    message: yup.string().required('Message is required'),
  })
  .required()

const ContactForm = () => {
  const { whiteLabelData } = useUI()
  const [loading, setLoading] = useState(false)

  const form = useForm<FormField>({
    defaultValues: {
      name: '',
      email: '',
      message: '',
    },
    resolver: yupResolver(schema) as any,
  })

  const { register, handleSubmit, formState } = form
  const { errors } = formState

  const onSubmit = async (data: FormField) => {
    try {
      setLoading(true)
      const response = await handleFormSubmit(data, whiteLabelData)
      if (response.message === 'success') {
        toast.success('Email sent successfully!')
        form.reset()
        setLoading(false)
      } else {
        toast.error('Failed to send email!')
        setLoading(false)
      }
    } catch (error) {
      toast.error('Failed to send email!')
      setLoading(false)
    }
  }

  return (
    <div className='relative'>
      <h1
        id='contact'
        className='max-w-5xl mx-auto mb-10 text-3xl font-bold tracking-tight text-center text-black lg:text-7xl lg:leading-tight md:text-5xl font-title'
      >
        Say <span className='text-primary-purple'>Hello!</span>
      </h1>
      {/* <a href="mailto:contact@filmmakerdash.com">
      <RevealText
        content="contact@musicdash.com"
        className="max-w-2xl mx-auto mt-8 mb-8 text-lg font-normal text-center lg:text-2xl md:mb-16 text-neutral-500"
      />
    </a> */}
      <form onSubmit={handleSubmit(onSubmit)} className='max-w-6xl mx-auto'>
        <div className='grid grid-cols-1 gap-x-8 gap-y-6'>
          <div className='grid grid-cols-1 gap-6 lg:grid-cols-2'>
            <InputField
              label='Name'
              name='name'
              placeholder='John Doe'
              register={register}
              errors={errors}
            />
            <InputField
              label='Email'
              name='email'
              placeholder='johndoe@gmail.com'
              register={register}
              errors={errors}
              type='email'
            />
          </div>
          <TextAreaField
            label='Message'
            name='message'
            placeholder='Your Message...'
            register={register}
            errors={errors}
          />
        </div>
        <div className='flex justify-center w-full mt-10'>
          <button
            className={`button md:!text-xl md:!py-3 md:!px-6 ${
              !whiteLabelData.theme ? 'primary' : 'hover:opacity-80'
            }`}
            style={{
              backgroundColor: whiteLabelData?.theme?.[2] ?? '',
              color: whiteLabelData?.theme?.[1] ?? '',
            }}
          >
            {loading ? 'Sending...' : 'Send Email'}
          </button>
        </div>
      </form>
    </div>
  )
}

export default ContactForm

interface TextAreaFieldProps {
  label: string
  name: string
  placeholder: string
  register: UseFormRegister<any>
  errors: FieldErrors
  isOptional?: boolean
}

const TextAreaField = ({
  label,
  name,
  placeholder,
  register,
  errors,
  isOptional = false,
}: TextAreaFieldProps) => (
  <div>
    <label
      htmlFor={name}
      className='block text-sm font-medium leading-6 sm:text-base'
    >
      {label} {isOptional ? '(Optional)' : '*'}
    </label>
    <div className='mt-2.5'>
      <textarea
        {...register(name)}
        placeholder={placeholder}
        rows={5}
        className='block w-full border-t-0 border-x-0 border-b bg-transparent border-primary text-sm outline-none px-3.5 py-2 shadow-sm ring-0 focus:ring-0 focus:ring-transparent focus:ring-offset-0 sm:text-base sm:leading-6'
      />
      <p className='mt-2 text-sm text-red-400 errors'>
        {errors[name]?.message?.toString()}
      </p>
    </div>
  </div>
)

interface InputFieldProps {
  label: string
  name: string
  placeholder: string
  register: UseFormRegister<any>
  errors: FieldErrors
  type?: string
  isOptional?: boolean
}

const InputField = ({
  label,
  name,
  placeholder,
  register,
  errors,
  type = 'text',
  isOptional = false,
}: InputFieldProps) => (
  <div>
    <label
      htmlFor={name}
      className='block text-sm font-medium leading-6 sm:text-base'
    >
      {label} {isOptional ? '(Optional)' : '*'}
    </label>
    <div className='mt-2.5'>
      <input
        {...register(name)}
        placeholder={placeholder}
        type={type}
        className='block w-full border-t-0 border-x-0 border-b bg-transparent border-primary text-sm outline-none px-3.5 py-2 shadow-sm ring-0 focus:ring-0 focus:ring-transparent focus:ring-offset-0 sm:text-base sm:leading-6'
      />
      <p className='mt-2 text-sm text-red-400 errors'>
        {errors[name]?.message?.toString()}
      </p>
    </div>
  </div>
)
