import { useEffect, useState } from 'react'
import { motion } from 'framer-motion'

import { tabStepVariants, tabTransition } from 'lib/animations'
import FilmCard from 'components/shared/FilmCard'
import { useSubmissions, useUser } from 'store/RootStore'
import PageHeader from 'components/shared/PageHeader'
import { useTranslation } from 'react-i18next'

const MySubmissions = () => {
  const { getSubmissions } = useSubmissions()
  const { t } = useTranslation()
  const { user } = useUser()

  const [submissions, setSubmissions] = useState<any[]>([])
  const [isSubmissionsLoading, setSubmissionsLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [statusFilter, setStatusFilter] = useState('')

  const updateSubmissions = () => {
    setSubmissionsLoading(true)
    getSubmissions()
      .then((res) => setSubmissions(res))
      .finally(() => {
        setSubmissionsLoading(false)
      })
  }

  useEffect(() => {
    updateSubmissions()
  }, [user])

  const filteredSubmissions =
    submissions?.length > 0 &&
    submissions.filter((submission) => {
      const matchesStatus = statusFilter
        ? submission.status?.toLowerCase() === statusFilter.toLowerCase()
        : true
      const matchesSearchTerm = searchTerm
        ? Object.values(submission).some((value) =>
            String(value).toLowerCase().includes(searchTerm.toLowerCase())
          )
        : true
      return matchesStatus && matchesSearchTerm
    })

  return (
    <div className='page-wrapper'>
      <PageHeader
        title={t('sidebar.mySubmissions')}
        isLoading={isSubmissionsLoading}
        hasCreateSubmissionTrigger
      />
      <div className='flex flex-col justify-between w-full gap-6 my-4 md:flex-row'>
        <input
          type='text'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder='Search by title, artist name, etc.'
        />
        <select
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
          className='md:max-w-xs'
        >
          <option value=''>All Status</option>
          <option value='draft'>Draft</option>
          <option value='review'>Review</option>
          <option value='pending'>Pending</option>
          <option value='in distribution'>In Distribution</option>
          <option value='delivered'>Delivered</option>
          <option value='completed'>Completed</option>
          <option value='rejected'>Rejected</option>
        </select>
      </div>
      <div className='flex flex-col items-start justify-start w-full h-full pt-6 md:pt-0'>
        {isSubmissionsLoading ? (
          <div className='relative flex items-center justify-center w-full mt-10 col-span-full'>
            <div className='flex items-center gap-y-2 gap-x-4'>
              <h2 className='text-lg font-semibold'>
                {t('messages.gettingSubmissions')}
              </h2>
            </div>
          </div>
        ) : filteredSubmissions && filteredSubmissions?.length ? (
          <motion.div
            variants={tabStepVariants}
            initial='enter'
            animate='center'
            exit='exit'
            transition={tabTransition}
            className='grid justify-between w-full gap-4 mt-2 main-grid'
          >
            {filteredSubmissions.map((value: any, index: number) => (
              <FilmCard
                submission={value}
                key={index}
                i={index}
                updateSubmissions={updateSubmissions}
              />
            ))}
          </motion.div>
        ) : (
          <div className='relative flex items-center justify-center w-full mt-10 col-span-full'>
            <div className='flex flex-col items-center gap-y-2 gap-x-4'>
              <h2 className='text-3xl font-semibold'>{t('messages.oops')}</h2>
              <p className='max-w-sm text-center'>
                {t('messages.noSubmissionsMessage')}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default MySubmissions
