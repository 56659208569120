import { Disclosure, Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon, ChevronUpIcon, LoadingIcon } from 'components/shared/Icons'
import { classNames } from 'lib/misc'
import { FC, Fragment, useMemo, useState } from 'react'
import { MoneyBagIcon } from 'components/shared/Icons'
import { useAnalytics, useUser } from 'store/RootStore'
import { toast } from 'react-hot-toast'
import { getGrossPercentage } from 'lib/utils'
import { useTranslation } from 'react-i18next'
import { useUI } from 'ctx/UIContext'

interface IBalanceBreakdown {
  creditBalance: any
  royaltyEarnings: any
  pendingRequests: any
  updateDuePayments: () => void
}

const BalanceBreakdown: FC<IBalanceBreakdown> = ({
  creditBalance,
  royaltyEarnings,
  pendingRequests,
  updateDuePayments,
}) => {
  const { user } = useUser()
  const { requestDuePayments } = useAnalytics()
  const { t } = useTranslation()
  const { whiteLabelData } = useUI()

  const [isBalanceLoading, setBalanceLoading] = useState(false)
  const [isRequestingPayment, setRequestingPayment] = useState(false)

  const requestPayment = async () => {
    if (user && user.paypal) {
      if (Object.keys(pendingRequests).length) {
        setRequestingPayment(true)
        await requestDuePayments()
          .then((res) => {
            toast.success(t('paymentRequest.success'))
          })
          .catch((err) => {
            toast.error(t('paymentRequest.error'))
          })
          .finally(() => {
            updateDuePayments()
            setRequestingPayment(false)
          })
      }
    } else {
      toast.error(t('paymentRequest.addPaypalError'))
    }
  }

  const breakdownData = useMemo(
    () => [
      // {
      //   title: 'Balance',
      //   subtitle: 'Total balance carried forward',
      //   amount: data ? data.balance : 0,
      //   subitems: data ? data.balance_breakdown : [],
      //   icon: SavingsIcon,
      // },
      // {
      //   title: 'Digital Sales',
      //   subtitle: 'Total Digital Sales',
      //   amount: data ? data.digital_sales : 0,
      //   subitems: data ? data.digital_sales_breakdown : [],
      //   icon: EarningsIcon,
      // },
      // {
      //   title: 'Agent Rate',
      //   subtitle: '10.0% of Net',
      //   amount: data ? data.agent_rate : 0,
      //   isExpense: true,
      //   icon: PercentIcon,
      // },
      // {
      //   title: 'Royalty Rate',
      //   subtitle: '50.0 of Net',
      //   amount: data ? data.royalty_rate : 0,
      //   isExpense: true,
      //   icon: PercentIcon,
      // },
      {
        title: t('balanceMockData.totalRoyaltyEarnings'),
        subtitle: '',
        amount: royaltyEarnings ? royaltyEarnings.royality : 0,
        isTotal: true,
        subitems: [],
        isExpense: false,
        icon: MoneyBagIcon,
      },
      // {
      //   title: 'Total Net Income',
      //   subtitle: '',
      //   amount: data ? data.total_net : 0,
      //   isTotal: true,
      //   icon: MoneyBagIcon,
      // },
    ],
    [creditBalance, royaltyEarnings]
  )

  return (
    <div className='overflow-hidden card long'>
      <div className='relative flex flex-col items-center justify-between md:items-start'>
        <div
          className='relative flex flex-col items-center w-full p-4 overflow-hidden bg-secondary-purple rounded-2xl md:items-start'
          style={{
            backgroundColor: whiteLabelData.theme ? 'var(--whitelabel-neutral)' : '',
          }}
        >
          {isBalanceLoading ? (
            <div className='absolute inset-0 bg-black/75 z-[4] flex items-center justify-center'>
              <span className='inline-block text-xl text-white animate-spin'>
                <LoadingIcon />
              </span>
            </div>
          ) : null}
          <h4 className='mb-1 text-xs text-gray-200 truncate'>
            {t('creditBalance.title')}
          </h4>
          <h1 className='text-4xl font-bold text-white'>
            $
            {pendingRequests[0]
              ? Number(pendingRequests[0].payment_amount)?.toFixed(2)
              : 0}
          </h1>
          <button
            className='button outlined !w-max shadow-sm !shadow-primary-purple mt-4 flex gap-1 md:gap-2 items-center justify-center'
            onClick={requestPayment}
            disabled={pendingRequests[0] && pendingRequests[0].status !== 'pending'}
          >
            <span className=''>
              {pendingRequests[0] && pendingRequests[0].status !== 'pending'
                ? t('paymentRequest.requestedButtonText')
                : isRequestingPayment
                ? t('paymentRequest.requestingPaymentText')
                : t('paymentRequest.requestPaymentButtonText')}
            </span>
            {isRequestingPayment ? (
              <span className='inline-block animate-spin'>
                <LoadingIcon />
              </span>
            ) : null}
          </button>
        </div>
      </div>
      <div className='flex-col hidden mt-6 overflow-y-auto md:flex'>
        <p className='text-xs text-gray-300'>{t('breakdown.title')}</p>
        <div className='relative flex flex-col flex-1 w-full gap-2 mt-4'>
          <div className='relative flex flex-col flex-1 w-full'>
            {breakdownData.map((item, i) =>
              !item.isTotal ? (
                <BreakdownItem
                  title={item.title}
                  amount={item.amount}
                  subtitle={item.subtitle}
                  subItems={item.subitems ? item.subitems : []}
                  icon={item.icon()}
                  isExpense={item.isExpense}
                  key={i}
                />
              ) : null
            )}
          </div>
          <div className='w-full h-px my-2 bg-primary-purple/5' />
          <div className='relative w-full h-max'>
            {breakdownData.map((item, i) =>
              item.isTotal ? (
                <BreakdownItem
                  title={item.title}
                  amount={item.amount}
                  subtitle={item.subtitle}
                  icon={item.icon()}
                  key={i}
                />
              ) : null
            )}
          </div>
        </div>
      </div>
      <Popover className='relative flex flex-col items-center w-full'>
        <Popover.Button className='flex items-center justify-center gap-2 my-1 button outlined-alt md:hidden'>
          {t('breakdown.transactions')}
          <span className=''>
            <ChevronDownIcon />
          </span>
        </Popover.Button>
        <Transition
          as={Fragment}
          enter='transition ease-[cubic-bezier(0.19, 1, 0.22, 1)] duration-300'
          enterFrom='opacity-0 -translate-y-8'
          enterTo='opacity-100 translate-y-0'
          leave='transition ease-in duration-150'
          leaveFrom='opacity-100 translate-y-0'
          leaveTo='opacity-0 -translate-y-8'
        >
          <Popover.Panel className='flex flex-col mt-1 overflow-y-auto md:hidden'>
            <div className='relative flex flex-col flex-1 w-full gap-2 mt-2'>
              <div className='relative flex flex-col flex-1 w-full'>
                {breakdownData.map((item, i) =>
                  !item.isTotal ? (
                    <BreakdownItem
                      title={item.title}
                      amount={item.amount}
                      subtitle={item.subtitle}
                      subItems={item.subitems ? item.subitems : []}
                      icon={item.icon()}
                      isExpense={item.isExpense}
                      key={i}
                    />
                  ) : null
                )}
              </div>
              <div className='w-full h-px my-2 bg-primary-purple/5' />
              <div className='relative w-full h-max'>
                {breakdownData.map((item, i) =>
                  item.isTotal ? (
                    <BreakdownItem
                      title={item.title}
                      amount={item.amount}
                      subtitle={item.subtitle}
                      icon={item.icon()}
                      key={i}
                    />
                  ) : null
                )}
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  )
}

interface IBreakdownItem {
  title: string | JSX.Element
  subtitle?: string | JSX.Element
  amount: string
  icon?: JSX.Element
  isExpense?: boolean
  subItems?: any[]
}

const BreakdownItem: FC<IBreakdownItem> = ({
  title,
  subtitle,
  amount,
  icon,
  isExpense = false,
  subItems = [],
}) => {
  return (
    <Disclosure as='div'>
      {({ open }) => (
        <>
          <div className='relative flex items-start justify-between gap-4 p-3'>
            <div className='relative flex items-center justify-start'>
              <span className='text-xl text-blue-500'>{icon ? icon : null}</span>
            </div>
            {subItems.length ? (
              <Disclosure.Button className='flex flex-col items-start justify-center flex-1 cursor-pointer'>
                <h4 className='font-semibold text-gray-700 text-start'>{title}</h4>
                <span className='text-xs text-gray-400 text-start'>{subtitle}</span>
              </Disclosure.Button>
            ) : (
              <div className='flex flex-col items-start justify-center flex-1'>
                <h4 className='font-semibold text-gray-700 text-start'>{title}</h4>
                <span className='text-xs text-gray-400 text-start'>{subtitle}</span>
              </div>
            )}

            <div className='flex items-center justify-end gap-1'>
              {subItems.length ? (
                <span
                  className={classNames(
                    'font-bold',
                    isExpense ? 'text-red-500' : 'text-green-500'
                  )}
                >
                  {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
                </span>
              ) : null}{' '}
              <div className='flex flex-col'>
                <span
                  className={classNames(
                    'font-bold text-end',
                    isExpense ? 'text-red-500' : 'text-green-500'
                  )}
                >
                  $ {Number(amount)?.toFixed(2)}
                </span>
              </div>
            </div>
          </div>

          {subItems.length ? (
            <Disclosure.Panel className='relative p-4 m-1 bg-gray-100 rounded-lg'>
              {subItems.map((item, i) => (
                <div
                  key={i}
                  className='relative flex items-center justify-between p-1'
                >
                  <span className='text-xs text-gray-500'>{item.title}</span>
                  <span className='text-sm font-semibold text-black'>
                    ${item.amount || item.balance}
                  </span>
                </div>
              ))}
            </Disclosure.Panel>
          ) : null}
        </>
      )}
    </Disclosure>
  )
}

export default BalanceBreakdown
