import { createContext, useContext, FC, ReactNode, useState } from 'react'

interface IMainContext {
  submissionData: any
  addSubmission: (data: any) => void
}

interface IMainContextProvider {
  children: ReactNode
}

const MainContext = createContext<IMainContext>({
  submissionData: {},
  addSubmission: () => null,
})

const MainContextProvider: FC<IMainContextProvider> = ({ children }) => {
  const [submissions, setSubmissions] = useState<any[]>([
    // {
    //   title: 'first film',
    //   type: 'film',
    //   genre: 'Factual Entertainment',
    //   synopsys:
    //     'Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse, perferendis. Optio ipsam perferendis consequuntur nobis vero deserunt eius illo veritatis non culpa cupiditate vitae sint incidunt, quam dicta, placeat voluptas.',
    //   artwork: {},
    // },
  ])

  const addSubmission = (data: any) => {
    setSubmissions((v) => [...v, data])
  }

  return (
    <MainContext.Provider value={{ submissionData: submissions, addSubmission }}>
      {children}
    </MainContext.Provider>
  )
}

const useMain = () => useContext(MainContext)

export { MainContextProvider, useMain }
