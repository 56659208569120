import { Upload } from '@aws-sdk/lib-storage'
import { useUI } from 'ctx/UIContext'
import { s3Client } from 'lib/aws-config'
import { lsget } from 'lib/misc'
import { removeSpecialCharacters } from 'lib/utils'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { useMarketing, useSpotify, useUser } from 'store/RootStore'
import { formatInTimeZone } from 'date-fns-tz'
import ReactGA from 'react-ga4'
import { useEffect } from 'react'

export default function useMarketingTool(): {
  startFacebookCampaign: (data: any) => void
  startInstagramCampaign: (data: any) => void
  generateAccessToken: () => Promise<void>
  accessToken: string
} {
  const navigate = useNavigate()
  const { setLoading } = useUI()
  const { user } = useUser()
  const {
    getAccessToken,
    createAd,
    createAdcreative,
    createAdcreativePost,
    createAdset,
    createCampaign,
  } = useMarketing()
  const { handleRefresh } = useSpotify()

  const accessToken = lsget('fb_access_token')

  useEffect(() => {
    const interval = setInterval(() => {
      const spotify_access_token = lsget('spotify_access_token')
      if (spotify_access_token) {
        handleRefresh()
          .then((newToken) => {
            localStorage.setItem('spotify_access_token', newToken.access_token)
          })
          .catch(() => {
            toast.error('Spotify token refresh failed')
          })
      }
    }, 3600000)

    return () => clearInterval(interval)
  }, [])

  const generateAccessToken = async (): Promise<void> => {
    setLoading(true)

    try {
      const _token: any = await getAccessToken()

      if (!('access_token' in _token)) {
        throw new Error("Couldn't login")
      }

      localStorage.setItem('marketing_access_token', _token.access_token)
    } catch (error: any) {
      if ('message' in error) {
        console.error(error)
        toast.error(
          'Marketing Tool is currently unavailable. Please try again later'
        )
        navigate('/')
      }
    } finally {
      setLoading(false)
    }
  }

  const uploadToS3 = async (file: File, userId: string, postId: string) => {
    const fileName = file.name
    const key = `marketingUploads/${userId}/${postId}/${fileName}`
    const params: any = {
      Bucket: 'musicdash',
      Key: key,
      Body: file,
      ACL: 'public-read',
    }
    const response = new Upload({
      client: s3Client,
      params: params,
    })

    return await response
      .done()
      .then((response) => response)
      .catch((err) => err)
  }

  const startFacebookCampaign = async (data: any) => {
    setLoading(true)

    try {
      let uploadResponse = null
      if (data.postCreated) {
        const imageFile = new File(
          [data.file],
          removeSpecialCharacters(data.file.name),
          { type: data.file.type }
        )

        uploadResponse = await uploadToS3(imageFile, user.username, data.post.id)

        if (!uploadResponse) {
          setLoading(false)
          return
        }
      }

      const campaignData = {
        u_access_token: accessToken,
        ad_account_id: data.adAccount.id,
        name: data.adName,
        // objective: data.type.objective,
        objective: data.postCreated ? 'OUTCOME_ENGAGEMENT' : 'OUTCOME_AWARENESS',
        status: data.paymentOption?.payment_option?.id ? 'ACTIVE' : 'PAUSED',
        special_ad_categories: 'NONE',
      }

      const campaignResponse = await createCampaign(campaignData)
      if (campaignResponse) {
        let adCreativeResponse = null

        if (data.postCreated) {
          const adCreativeData = {
            u_access_token: accessToken,
            ad_account_id: data.adAccount.id,
            name: data.adName,
            page_id: data.facebookPage.id,
            description: data.adDescription,
            img_url: uploadResponse.Location,
            link_url:
              data.type.id === 'boost_spotify_engagement'
                ? data.selectedTrack.external_urls.spotify
                : data.linkUrl,
            message: data.message,
            headline: data.headline,
            types: data.cta.value,
            link_caption: data.linkUrl,
          }

          adCreativeResponse = await createAdcreative(adCreativeData)
        } else {
          const adCreativeData = {
            u_access_token: accessToken,
            ad_account_id: data.adAccount.id,
            name: data.adName,
            page_post_id: data.post.id,
          }
          adCreativeResponse = await createAdcreativePost(adCreativeData)
        }

        if (adCreativeResponse) {
          const adsetData = {
            u_access_token: accessToken,
            ad_account_id: data.adAccount.id,
            name: data.adName,
            campaign_id: campaignResponse.id,
            start_time: formatInTimeZone(
              data.startDate,
              'America/New_York',
              'yyyy-MM-dd HH:mm:ssXXX'
            ),
            end_time: formatInTimeZone(
              data.endDate,
              'America/New_York',
              'yyyy-MM-dd HH:mm:ssXXX'
            ),
            daily_budget: data.budget.toString(),
            bid_amount: data.bidAmount.toString(),
            billing_event: data.type.billing_event,
            optimization_goal: data.type.optimization_goal,
            age_min: data.minAge.toString(),
            age_max: data.maxAge.toString(),
            genders: [1],
            countries: data.geoTargets.map((loc: any) => loc.code),
            region: '4081',
            f_positions: data.type.f_positions,
            i_positions: data.type.i_positions,
            publisher_platforms: [
              data.type.id === 'boost_spotify_engagement'
                ? 'facebook'
                : data.type.social,
            ],
            status: data.paymentOption?.payment_option?.id ? 'ACTIVE' : 'PAUSED',
          }

          const adsetResponse = await createAdset(adsetData)
          if (adsetResponse) {
            if (!data.paymentOption?.payment_option?.id) {
              setLoading(false)
              toast.success(
                'Campaign created successfully. Ad Paused until payment option is added'
              )
              ReactGA.event({
                category: 'Facebook Campaign',
                action: 'Create',
              })
              navigate('/marketing-tool')

              return
            }

            const adData = {
              u_access_token: accessToken,
              ad_account_id: data.adAccount.id,
              name: data.adName,
              adset_id: adsetResponse.id,
              creative_id: adCreativeResponse.id,
              status: data.paymentOption?.payment_option?.id ? 'ACTIVE' : 'PAUSED',
            }
            const adResponse = await createAd(adData)
            if (adResponse) {
              setLoading(false)
              toast.success('Campaign created successfully')
              ReactGA.event({
                category: 'Facebook Campaign',
                action: 'Create',
              })
              navigate('/marketing-tool')
            }
          }
        }
      }
    } catch (error) {
      console.error('Error creating campaign:', error)
    }
  }

  const startInstagramCampaign = async (data: any) => {
    const imageFile = new File(
      [data.file],
      removeSpecialCharacters(data.file.name),
      { type: data.file.type }
    )
    setLoading(true)

    try {
      const uploadResponse = await uploadToS3(imageFile, user.username, data.post.id)
      if (!uploadResponse) {
        setLoading(false)
        return
      }

      const campaignData = {
        u_access_token: accessToken,
        ad_account_id: data.adAccount.id,
        name: data.adName,
        // objective: data.type.objective,
        objective: data.postCreated ? 'OUTCOME_ENGAGEMENT' : 'OUTCOME_AWARENESS',
        status: data.paymentOption?.payment_option?.id ? 'ACTIVE' : 'PAUSED',
        special_ad_categories: 'NONE',
      }

      const campaignResponse = await createCampaign(campaignData)
      if (campaignResponse) {
        const adCreativeData = {
          u_access_token: accessToken,
          ad_account_id: data.adAccount.id,
          name: data.adName,
          page_id: data.facebookPage.id,
          description: data.adDescription,
          img_url: uploadResponse.Location,
          link_url: data.linkUrl,
          message: data.message,
          headline: data.headline,
          types: data.cta.value,
          link_caption: data.linkUrl,
        }

        const adCreativeResponse = await createAdcreative(adCreativeData)
        if (adCreativeResponse) {
          const adsetData = {
            u_access_token: accessToken,
            ad_account_id: data.adAccount.id,
            name: data.adName,
            campaign_id: campaignResponse.id,
            start_time: formatInTimeZone(
              data.startDate,
              'America/New_York',
              'yyyy-MM-dd HH:mm:ssXXX'
            ),
            end_time: formatInTimeZone(
              data.endDate,
              'America/New_York',
              'yyyy-MM-dd HH:mm:ssXXX'
            ),
            daily_budget: data.budget.toString(),
            bid_amount: data.bidAmount.toString(),
            billing_event: data.type.billing_event,
            optimization_goal: data.type.optimization_goal,
            age_min: data.minAge.toString(),
            age_max: data.maxAge.toString(),
            genders: [1],
            countries: data.geoTargets.map((loc: any) => loc.code),
            region: '4081',
            f_positions: data.type.f_positions,
            i_positions: data.type.i_positions,
            publisher_platforms: [data.type.social],
            status: data.paymentOption?.payment_option?.id ? 'ACTIVE' : 'PAUSED',
          }

          const adsetResponse = await createAdset(adsetData)
          if (adsetResponse) {
            if (!data.paymentOption?.payment_option?.id) {
              setLoading(false)
              toast.success(
                'Campaign created successfully. Ad Paused until payment option is added'
              )
              ReactGA.event({
                category: 'Instagram Campaign',
                action: 'Create',
              })
              navigate('/marketing-tool')

              return
            }

            const adData = {
              u_access_token: accessToken,
              ad_account_id: data.adAccount.id,
              name: data.adName,
              adset_id: adsetResponse.id,
              creative_id: adCreativeResponse.id,
              status: data.paymentOption?.payment_option?.id ? 'ACTIVE' : 'PAUSED',
            }
            const adResponse = await createAd(adData)
            if (adResponse) {
              setLoading(false)
              toast.success('Campaign created successfully')
              ReactGA.event({
                category: 'Instagram Campaign',
                action: 'Create',
              })
              navigate('/marketing-tool')
            }
          }
        }
      }
    } catch (error) {
      console.error('Error creating campaign:', error)
    }
  }

  return {
    generateAccessToken,
    startFacebookCampaign,
    startInstagramCampaign,
    accessToken,
  }
}

export {}
