import * as React from 'react'
import * as LabelPrimitive from '@radix-ui/react-label'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from 'lib/utils'
import { Control, Controller, UseFormRegister } from 'react-hook-form'
import { HTMLProps } from 'react'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './Select'

type Props = {
  label: string
  name: string
  register: UseFormRegister<any>
  error?: string
  className?: string
  wrapperClassName?: string
  isRequired?: boolean
  isDisabled?: boolean
}

const labelVariants = cva(
  'text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
)

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
    VariantProps<typeof labelVariants>
>(({ className, ...props }, ref) => (
  <LabelPrimitive.Root
    ref={ref}
    className={cn(labelVariants(), className)}
    {...props}
  />
))
Label.displayName = LabelPrimitive.Root.displayName

// eslint-disable-next-line @typescript-eslint/no-empty-interface
type InputProps = HTMLProps<HTMLInputElement> & Props

const Input = ({
  label,
  name,
  register,
  error,
  className,
  wrapperClassName,
  isRequired = false,
  isDisabled = false,
  type = 'text',
  ...props
}: InputProps) => {
  return (
    <div className={cn(wrapperClassName)}>
      <Label htmlFor={name}>
        {label}{' '}
        {error ? (
          <span className={cn('text-xs text-red-500 ml-1')}>* {error}</span>
        ) : null}
      </Label>
      <input
        type={type}
        {...register(name)}
        className={cn('input', className)}
        role='presentation'
        autoComplete='off'
        disabled={isDisabled}
        {...props}
      />
    </div>
  )
}

export type Option = {
  label: string
  value: string
}
type SelectProps = HTMLProps<HTMLSelectElement> &
  Omit<Props, 'register'> & {
    control: Control<any>
    options: Option[]
    additionalComponents?: JSX.Element
  }

const SelectInput = ({
  label,
  name,
  control,
  error,
  className,
  wrapperClassName,
  isRequired = false,
  isDisabled = false,
  options,
  placeholder,
  additionalComponents,
  ...props
}: SelectProps) => {
  return (
    <div className={cn(wrapperClassName)}>
      <Label htmlFor={name}>
        {label}{' '}
        {error ? (
          <span className={cn('text-xs text-red-500 ml-1')}>* {error}</span>
        ) : null}
      </Label>
      <Controller
        name={name}
        control={control}
        rules={{
          required: isRequired,
        }}
        render={({ field }) => (
          <Select
            onValueChange={field.onChange}
            value={field.value}
            disabled={isDisabled}
          >
            <SelectTrigger>
              <SelectValue placeholder={placeholder} />
            </SelectTrigger>
            <SelectContent>
              {options.map((opt, i) => (
                <SelectItem key={i} value={opt.value}>
                  {opt.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        )}
      />
      {additionalComponents ? (
        <div className={cn('text-sm text-muted-foreground mt-2')}>
          {additionalComponents}
        </div>
      ) : null}
    </div>
  )
}

export { Input, Label, SelectInput }
