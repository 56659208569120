import { FC, ReactNode, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import MainLayout from './MainLayout'
import LogoCloud from './LogoCloud'
import { useUI } from 'ctx/UIContext'
import { authData } from 'lib/constants'
import { useTranslation } from 'react-i18next'

type AuthLayoutProps = {
  children: ReactNode
}

const AuthLayout: FC<AuthLayoutProps> = ({ children }) => {
  const location = useLocation()
  const { whiteLabelData } = useUI()
  const { t } = useTranslation()

  const pageData = useMemo(() => {
    const _pageData = authData.find(
      (page) => page.route.toLowerCase() === location.pathname.toLowerCase()
    )
    return _pageData
  }, [location])

  return (
    <MainLayout>
      <div className='relative flex flex-col min-h-screen lg:h-screen lg:flex-row'>
        <div
          className='relative w-full h-full p-5 overflow-hidden bg-primary-purple '
          style={{
            backgroundColor: whiteLabelData?.theme?.[2] ?? '',
            color: whiteLabelData?.theme?.[1] ?? '',
          }}
        >
          <div className='max-w-[1000px] mx-auto  lg:flex-col lg:flex w-full h-full'>
            <div>
              <div className='flex items-center gap-4'>
                <a
                  href={
                    whiteLabelData.app_name === 'default'
                      ? 'https://www.musicdash.com/'
                      : '/'
                  }
                  className='mr-auto'
                >
                  <img
                    src={whiteLabelData.logo ?? '/assets/logo-white.png'}
                    alt=''
                    className='h-10 md:h-14'
                  />
                </a>
                <a
                  href={
                    whiteLabelData.app_name === 'default'
                      ? 'https://www.musicdash.com/'
                      : '/'
                  }
                  className={`font-semibold transition-all button hover:opacity-90 ${
                    !whiteLabelData?.theme?.[2] ? ' bg-gray-800 text-white' : ''
                  }`}
                  style={{
                    color: whiteLabelData?.theme?.[1] ?? '',
                    backgroundColor: whiteLabelData?.theme?.[0] ?? '',
                  }}
                >
                  Home
                </a>
                <a
                  href={
                    whiteLabelData.app_name === 'default'
                      ? 'https://www.musicdash.com/guides'
                      : '/public/guides'
                  }
                  className={`font-semibold transition-all button hover:opacity-90 ${
                    !whiteLabelData?.theme?.[2] ? ' bg-gray-800 text-white' : ''
                  }`}
                  style={{
                    color: whiteLabelData?.theme?.[1] ?? '',
                    backgroundColor: whiteLabelData?.theme?.[0] ?? '',
                  }}
                >
                  View Important Guides
                </a>
              </div>
            </div>
            <div className='mt-10 md:mt-20'>
              <h1
                style={{
                  color: whiteLabelData?.theme?.[1] ?? '',
                }}
                className='block max-w-xl text-2xl font-bold text-white capitalize 2xl:max-w-4xl md:text-4xl 2xl:text-6xl'
              >
                Get Started with{' '}
                {whiteLabelData.app_name !== 'default' ? `Music` : 'MusicDASH'}{' '}
                Distribution Platform
              </h1>
              <p
                className='hidden max-w-xl mt-10 text-lg font-semibold lg:block text-white/70'
                style={{
                  color: whiteLabelData?.theme?.[1] ?? '',
                }}
              >
                A platform offering tools to help independent artists, labels, and
                distributors monetize their music and videos.
              </p>
            </div>
            <div className='hidden my-10 lg:block lg:mt-auto'>
              <LogoCloud />
            </div>
          </div>
        </div>
        <div className='relative flex items-start justify-center w-full md:max-w-[450px] md:mx-auto 2xl:max-w-[650px] h-full lg:p-5 lg:items-center'>
          <div className='relative flex flex-col h-auto items-start w-full max-w-sm xl:max-w-md justify-start top-[var(--header-height)] lg:top-auto px-3 py-4 gap-4'>
            <div className='mb-3 xl:mb-4'>
              <h2 className='mb-0 text-xl font-semibold leading-none font-title md:text-2xl md:mb-2'>
                {whiteLabelData.app_name !== 'default' &&
                location.pathname === '/login'
                  ? t('whitelabelauth.login')
                  : whiteLabelData.app_name !== 'default' &&
                    location.pathname === '/signup'
                  ? t('whitelabelauth.signup')
                  : pageData?.title}
              </h2>
              <p className='relative text-xs text-gray-400'>{pageData?.subtitle}</p>
            </div>
            <div className='w-full h-full pb-32 lg:pb-0'>{children}</div>
          </div>
        </div>
        <div
          className='lg:hidden relative p-5 overflow-hidden bg-primary-purple  max-w-[1000px] mx-auto  w-full h-full'
          style={{
            backgroundColor: whiteLabelData?.theme?.[2] ?? '',
            color: whiteLabelData?.theme?.[1] ?? '',
          }}
        >
          <div className='mt-10 md:mt-20'>
            <p
              className='block max-w-xl mt-10 text-lg font-semibold text-white/70'
              style={{
                color: whiteLabelData?.theme?.[1] ?? '',
              }}
            >
              A platform offering tools to help independent artists, labels, and
              distributors monetize their music and videos.
            </p>
          </div>
          <div className='my-10 lg:mt-auto'>
            <LogoCloud />
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default AuthLayout
