import React from 'react'
import { useCampaign } from 'ctx/CampaignContext'
import { Card } from 'components/partials/Card'
import { formatDateToYYYYMMDD, getNumberOfDays } from 'lib/utils'
import { Button } from 'components/partials/Button'
import useMarketingTool from 'hooks/useMarketingTool'

const CampaignReview: React.FC = () => {
  const { config } = useCampaign()
  const { startFacebookCampaign, startInstagramCampaign } = useMarketingTool()

  return (
    <div className='mt-6'>
      <Card className='p-4 mt-4 rounded-lg'>
        <h2 className='mb-4 font-bold'>Campaign Overview</h2>
        <ReviewItem title='Ad Name:' value={config.adName} />
        <ReviewItem title='Ad Description:' value={config.adDescription} />
        <ReviewItem title='Type:' value={config.type.title} />
        <ReviewItem title='Call-to-Action:' value={config.cta.name} />
        <ReviewItem title='Message:' value={config.message} />
        {config.postCreated ? (
          <>
            <ReviewItem title='Head Line:' value={config.headline} />
            <ReviewItem
              title='Link Url:'
              value={
                config.type.id === 'boost_spotify_engagement'
                  ? config.selectedTrack.external_urls.spotify
                  : config.linkUrl
              }
            />
          </>
        ) : (
          <div className='flex flex-col gap-1 py-4 border-b md:gap-6 md:flex-row md:justify-between'>
            <p className='font-semibold'>Selected Post:</p>
            <img
              src={config.post.full_picture || config.post.media_url}
              className='rounded-lg w-36 h-36'
            />
          </div>
        )}
        <ReviewItem title='Budget:' value={config.budget} />
        <ReviewItem title='Bid Amount:' value={config.bidAmount} />

        {/* <ReviewItem title='Targets:' value={config.options.join(', ')} /> */}
        <ReviewItem
          title='Age Range:'
          value={`${config.minAge} to ${config.maxAge}`}
        />
        <ReviewItem
          title='Locations:'
          value={config.geoTargets.map((loc: any, index: number) => (
            <span key={index}>{`${loc.name}, `}</span>
          ))}
        />
        <ReviewItem
          title='Duration'
          value={`${formatDateToYYYYMMDD(
            config.startDate
          )} to ${formatDateToYYYYMMDD(config.endDate)}`}
        />
        <ReviewItem
          title='Length'
          value={`${getNumberOfDays(config.startDate, config.endDate)} days`}
        />
      </Card>
      <Card className='flex flex-col items-center p-4 mt-4 rounded-lg'>
        <h2 className='mb-4 text-lg font-bold text-center'>
          Confirm Campaign Details
        </h2>
        <p className='max-w-xl mb-4 text-sm text-center md:text-base'>
          Please review the details above and click the button below to start the
          campaign.
        </p>
        <Button
          onClick={() =>
            config.type.social === 'instagram'
              ? startInstagramCampaign(config)
              : startFacebookCampaign(config)
          }
        >
          Start Campaign
        </Button>
      </Card>
    </div>
  )
}

export default CampaignReview

const ReviewItem = ({ title, value }: { title: string; value: string }) => {
  return (
    <div className='flex flex-col gap-1 py-4 border-b md:gap-6 md:flex-row md:justify-between'>
      <p className='font-semibold'>{title}</p>
      <p className='max-w-xl'>{value}</p>
    </div>
  )
}
