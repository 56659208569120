import { FC, lazy, ReactNode, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useUI } from 'ctx/UIContext'
import { authData } from 'lib/constants'
import { classNames } from 'lib/misc'
import Header from './Header'
import { PageLoading } from './LoadingScreen'
import SideMenu from './Sidemenu'

type MainLayoutProps = {
  children: ReactNode
}

const LoadingScreen = lazy(() => import('./LoadingScreen'))

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  const { isSidebarOpen, whiteLabelData } = useUI()
  const location = useLocation()
  const [showChildren, setShowChildren] = useState(false)
  const [isLoading, setLoading] = useState(true)

  const authRoutes = authData.map((item) => item.route)
  const isAuthRoute = useMemo(
    () => authRoutes.includes(location.pathname),
    [location]
  )

  useEffect(() => {
    if (whiteLabelData.app_name) {
      setLoading(false)
    }
  }, [whiteLabelData])

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowChildren(true)
    }, 400)

    return () => clearTimeout(timer)
  }, [])

  return (
    <main
      style={{
        backgroundColor: whiteLabelData?.theme?.[1] ?? '',
        color: whiteLabelData?.theme?.[0] ?? '',
      }}
    >
      {isSidebarOpen && <div className='fixed inset-0 z-[9998]' />}
      <div className='relative flex w-full'>
        {!isAuthRoute ? <SideMenu /> : null}
        <div
          className={classNames(
            'relative min-h-auto h-full md:h-auto w-full transition-all md:transition-none duration-300 md:ml-64',
            isAuthRoute
              ? 'overflow-x-hidden overflow-y-auto md:overflow-hidden !ml-0'
              : 'ml-0',
            isSidebarOpen
              ? 'pointer-events-none blur-lg'
              : 'pointer-events-auto blur-none'
          )}
        >
          {isAuthRoute ? null : <Header />}
          {showChildren && whiteLabelData.app_name !== undefined ? (
            children
          ) : (
            <PageLoading />
          )}
        </div>
      </div>
      {isLoading ? <LoadingScreen isLoading /> : null}
    </main>
  )
}

export default MainLayout
