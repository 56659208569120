import { Button } from 'components/partials/Button'
import { Card } from 'components/partials/Card'
import Tooltip from 'components/shared/Tooltip'
import { cn } from 'lib/utils'
import { SquareArrowOutUpRight } from 'lucide-react'

interface PostCardProps {
  onClick?: () => void
  active?: boolean
  type: 'track' | 'playlist'
  song: any
}

const SongCard = ({ onClick, active, type, song }: PostCardProps) => {
  return (
    <Tooltip content={song.name}>
      <Card
        className={cn(
          'w-[12rem] rounded-lg p-1',
          active && 'border-primary-purple/60'
        )}
      >
        <div className='w-full rounded-lg aspect-square'>
          <img
            src={song.images[0].url}
            alt={song.name}
            className='object-contain w-full h-full rounded-lg'
          />
        </div>
        <div className='flex items-center gap-1 pt-2'>
          <Button
            variant={active ? 'default' : 'secondary'}
            className='flex-1 capitalize transition-all rounded-md'
            onClick={onClick}
          >
            {active ? 'Selected' : `Select ${type}`}
          </Button>
          <Tooltip content='View Post'>
            <a href={song.external_urls.spotify} target='_blank' rel='noreferrer'>
              <Button variant='secondary' size='icon' className='rounded-md'>
                <SquareArrowOutUpRight />
              </Button>
            </a>
          </Tooltip>
        </div>
      </Card>
    </Tooltip>
  )
}

export default SongCard
