import {
  FC,
  createContext,
  useContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react'

interface IUIContext {
  isLoading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  isSidebarOpen: boolean
  setSidebarOpen: Dispatch<SetStateAction<boolean>>
  whiteLabelData:
    | {
        theme: string[]
        logo: string
        app_name: string
        coupon: string
      }
    | any
  setWhiteLabelData: Dispatch<SetStateAction<any>>
}

interface IUIContextProvider {
  children: ReactNode
}

const UIContext = createContext<IUIContext>({
  isLoading: false,
  setLoading: () => false,
  isSidebarOpen: true,
  setSidebarOpen: () => false,
  whiteLabelData: [],
  setWhiteLabelData: () => [],
})

const UIContextProvider: FC<IUIContextProvider> = ({ children }) => {
  const [isLoading, setLoading] = useState(false)
  const [isSidebarOpen, setSidebarOpen] = useState(false)
  const [whiteLabelData, setWhiteLabelData] = useState<any>([])

  useEffect(() => {
    const theme = whiteLabelData.theme

    if (theme) {
      document.documentElement.style.setProperty('--secondary-purple', theme?.[2])
      document.documentElement.style.setProperty('--primary-purple', theme?.[2])
    }
  }, [whiteLabelData])

  useEffect(() => {
    if ('caches' in window) {
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach((name) => {
          caches.delete(name)
        })
      })

      // Makes sure the page reloads. Changes are only visible after you refresh.
      // window.location.reload()
    }
  }, [])

  return (
    <UIContext.Provider
      value={{
        isLoading,
        setLoading,
        isSidebarOpen,
        setSidebarOpen,
        whiteLabelData,
        setWhiteLabelData,
      }}
    >
      {children}
    </UIContext.Provider>
  )
}

const useUI = () => useContext(UIContext)

export { UIContextProvider, useUI }
