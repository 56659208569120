import { useNavigate } from 'react-router-dom'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, ChevronUpIcon, UserIcon } from 'components/shared/Icons'
import { Fragment, useMemo } from 'react'

import { useAuth } from 'ctx/AuthContext'
import { useUser } from 'store/RootStore'
import { useTranslation } from 'react-i18next'
import { useUI } from 'ctx/UIContext'

const HeaderMenu = () => {
  const auth = useAuth()
  const navigate = useNavigate()
  const { user } = useUser()
  const { t } = useTranslation()
  const { whiteLabelData } = useUI()

  const headerMenu = [
    {
      name: t('sidebar.accountSettings'),
      path: '',
      disabled: false,
    },
    // {
    //   name: 'Documentation',
    //   path: '',
    //   disabled: false,
    // },
    // {
    //   name: 'Invite a friend (coming soon!)',
    //   path: '',
    //   disabled: false,
    // },
  ]

  const _name = useMemo(() => {
    const name = user?.firstname + ' ' + user?.lastname
    return name
  }, [user])

  return (
    <Menu as='div' className='flex items-center'>
      {({ open }) => (
        <>
          <Menu.Button className='relative py-1 pl-1 pr-2 text-lg text-white rounded-full bg-primary-purple w-max'>
            <span className='flex items-center gap-2'>
              <span className='p-1 bg-white rounded-full text-primary-purple'>
                <UserIcon />
              </span>
              <span className='text-xs'>
                {!open ? <ChevronDownIcon /> : <ChevronUpIcon />}
              </span>
            </span>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter='transition ease-out duration-300'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-200'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <Menu.Items className='absolute right-0 top-0 mt-[2.3rem] p-1 max-w-[250px] w-max flex flex-col bg-white shadow-2xl rounded-md'>
              <div className='flex flex-col items-center justify-between py-4 text-sm font-title'>
                <span className='px-2 font-semibold text-black capitalize whitespace-nowrap'>
                  {_name || 'test'}
                </span>
                <span className='px-2 text-xs font-normal text-black/70 whitespace-nowrap'>
                  {user?.user_email || 'test@email.com'}
                </span>
              </div>
              <div className='relative w-full h-px my-1 bg-gray-200' />
              {headerMenu.map((item, i) => (
                <Menu.Item key={i} disabled={item.disabled}>
                  {({ active }) => (
                    // <Link className={`${active && 'bg-blue-500'}`} to={item.path}>
                    <button
                      className={`${
                        active ? 'bg-primary-purple text-white' : 'text-gray-900'
                      } group flex w-full items-center rounded-md px-3 py-2 text-sm whitespace-nowrap`}
                      onClick={() => navigate('/account-settings')}
                    >
                      {item.name}
                    </button>
                    // </Link>
                  )}
                </Menu.Item>
              ))}
              <div className='relative w-full h-px my-1 bg-gray-200' />
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-primary-purple text-white' : 'text-gray-900'
                    } group flex w-full items-center rounded-md px-3 py-2 text-sm whitespace-nowrap`}
                    onClick={() => auth?.logout()}
                  >
                    {t('headerMenu.logout')}
                  </button>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )
}

export default HeaderMenu
