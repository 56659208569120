import { Button } from 'components/partials/Button'
import { ListMusic, Trash2 } from 'lucide-react'
import { createRef, useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import pica from 'pica'
import { useBulkSubmissions } from '../context/BulkSubmissionContext'
import FileUpload from './FileUpload.Partial'
import { useTranslation } from 'react-i18next'

const ArtworkUpload = () => {
  const { updateData, metadata } = useBulkSubmissions()
  const { t } = useTranslation()

  const [artworkErrors, setArtworkErrors] = useState<any[]>([])
  const [artworkFiles, setArtworkFiles] = useState<any[]>([])
  const [isArtworksLoading, setArtworksLoading] = useState(false)
  const [isOkToProceed, setIsOkToProceed] = useState(false)

  const validateArtworkFiles = (files: any) => {
    const _artworkFiles = [...artworkFiles, ...files]
    _artworkFiles.forEach((_file) => {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        function () {
          const image = new Image()
          image.src = this.result as string
          image.addEventListener('load', function () {
            if (this.height / this.width === 1) {
              const resizedImage = document.createElement('canvas')
              resizedImage.height = 1400
              resizedImage.width = 1400
              pica()
                .resize(image, resizedImage, {
                  unsharpAmount: 80,
                  unsharpRadius: 0.6,
                  unsharpThreshold: 2,
                })
                .then((result: any) => {
                  pica()
                    .toBlob(result, 'image/jpeg', 0.9)
                    .then((res: any) => {
                      const imagefile = new File([res], _file?.name)
                      const _files = [...artworkFiles, imagefile]
                      setArtworkFiles((f) => [...f, imagefile])
                      setIsOkToProceed(true)
                    })
                })
                .catch((err: any) => console.log(err))
            } else {
              setArtworkErrors((err) => [...err, _file?.name + ' is not valid'])
              setIsOkToProceed(false)
            }
          })
        },
        false
      )

      reader.readAsDataURL(_file)
    })
  }

  const removeArtworkFiles = (file: any) => {
    const newFiles = [...artworkFiles]
    newFiles.splice(artworkFiles.indexOf(file), 1)
    setArtworkFiles(newFiles)
    setArtworkErrors([])
    setIsOkToProceed(false)
  }

  const updateFiles = (file: File) => {
    const _audioFiles = [...artworkFiles, file]
    setArtworkFiles(_audioFiles)
  }

  useEffect(() => {
    if (artworkFiles.length === metadata.length) {
      setIsOkToProceed(true)
    }
  }, [artworkFiles, metadata])

  const ref = createRef() as any

  const openDialog = () => {
    if (ref.current) {
      ref.current.open()
    }
  }

  return (
    <div>
      <div className='w-full p-4 mt-8 border-2 border-dashed border-black/10'>
        {metadata.map((item, i) => (
          <FileUpload
            item={item}
            i={i}
            key={i}
            updateFiles={updateFiles}
            removeFile={removeArtworkFiles}
            type='image'
            allowedTypes='image/*'
          />
        ))}
      </div>
      {/* <Dropzone
        onDrop={(acceptedFiles) => validateArtworkFiles(acceptedFiles)}
        maxFiles={0}
        noClick
        noKeyboard
        ref={ref}
        accept={{
          'image/*': ['.jpeg', '.png', '.jpg'],
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <section className='flex items-center justify-center w-full p-4'>
            <div
              {...getRootProps()}
              className='relative p-8 border-2 border-dashed border-black/10 !m-0 flex items-center justify-center flex-col w-full max-w-3xl rounded-lg gap-2'
            >
              <span className='text-xs text-gray-800'>
                All files should be in same order as the metadata file.
              </span>
              <input {...getInputProps()} />
              {artworkFiles.length ? (
                <div className='relative flex flex-col items-start justify-start w-full gap-2'>
                  {artworkFiles.map((file, i) => (
                    <div
                      className='flex items-center justify-between w-full'
                      key={i}
                    >
                      <ListMusic />
                      <span className='flex flex-1 ml-4 font-semibold'>
                        <span className='max-w-sm truncate'>{file.name}</span>
                      </span>
                      <Button
                        variant='destructive'
                        size='icon'
                        className='self-end'
                        onClick={(e) => {
                          removeArtworkFiles(file)
                        }}
                      >
                        <Trash2 size={16} />
                      </Button>
                    </div>
                  ))}
                </div>
              ) : (
                <Button variant='ghost' onClick={openDialog}>
                  <Trans>{t('artworkUpload.dropFilesHere')}</Trans>
                </Button>
              )}
            </div>
          </section>
        )}
      </Dropzone> */}
      {artworkErrors.length ? (
        <div className='flex flex-col items-center justify-center w-full max-w-3xl mx-auto'>
          <p className='mb-2 font-semibold text-red-600'>
            {t('artworkUpload.invalidFiles')}
          </p>
          <div className='grid grid-cols-1 md:grid-cols-2'>
            {artworkErrors.map((error, i) => (
              <span key={i} className='text-sm'>
                {error}
              </span>
            ))}
          </div>
        </div>
      ) : null}

      <div className='flex items-center justify-center w-full max-w-3xl mx-auto mt-4 gap-x-2'>
        <Button
          onClick={() => {
            updateData('artwork', artworkFiles)
          }}
          isDisabled={!isOkToProceed}
          variant='outline'
        >
          {t('artworkUpload.nextButton')}
        </Button>
      </div>
    </div>
  )
}

export default ArtworkUpload
