import { Button } from 'components/partials/Button'
import PageHeader from 'components/shared/PageHeader'
import { useUI } from 'ctx/UIContext'
import useArtworkTool from 'hooks/useArtworkTool'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const ArtworkGenerator = () => {
  const navigate = useNavigate()
  const { setLoading } = useUI()
  const { t } = useTranslation()
  const { generateAccessToken } = useArtworkTool()

  useEffect(() => {
    try {
      setLoading(true)
      const fetchAccessToken = async () => await generateAccessToken()

      fetchAccessToken()
      setLoading(false)
    } catch (error) {
      setLoading(false)
      navigate('/')
    }
  }, [])

  return (
    <div className='page-wrapper'>
      <PageHeader title={t('sidebar.artworkGenerator')} isLoading={false} />
      <div className='flex flex-col items-center justify-center w-full h-full my-10 space-y-4 font-medium md:pt-0'>
        <div className='mb-10 space-y-2 text-center'>
          <h1 className='text-3xl font-bold'>{t('artworkGenerator.home.title')}</h1>
          <p>{t('artworkGenerator.home.subtitle')}</p>
        </div>
        <div className='flex flex-col gap-10 lg:flex-row'>
          <div className='w-full max-w-md'>
            <img
              src='/assets/artwork-generator.webp'
              alt=''
              className='w-full h-full'
            />
          </div>
          <div className='w-full max-w-xl'>
            <h1 className='mb-2 font-bold uppercase'>{t('labels.whatsNew')}</h1>
            <p className='mb-6'>{t('artworkGenerator.home.whatsNew')}</p>
            <h1 className='mb-2 font-bold uppercase'>{t('labels.whyUse')}</h1>
            <p className='mb-6'>{t('artworkGenerator.home.whyUse')}</p>
            <Button onClick={() => navigate('/artwork-generator/generate')}>
              {t('labels.getStarted')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ArtworkGenerator
