import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'
import moment from 'moment'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function getHashParams(param: string) {
  const hash = window.location.hash.substring(1)
  const params = new URLSearchParams(hash)
  return params.get(param)
}

export const debounce = <T extends unknown[], R>(
  func: (...args: T) => Promise<R>,
  delay: number
) => {
  let timeoutId: NodeJS.Timeout

  return (...args: T): Promise<R> => {
    return new Promise((resolve, reject) => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        func(...args)
          .then((result) => resolve(result))
          .catch((error) => reject(error))
      }, delay)
    })
  }
}

export function extractFilename(url: string) {
  // Split the URL by '/'
  const parts = url.split('/')
  // Get the last part which contains the filename
  const filename = parts[parts.length - 1]
  // Return the filename
  return filename
}
export function downloadBlob(file: File) {
  // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
  const blobUrl = URL.createObjectURL(file)

  // Create a link element
  const link = document.createElement('a')

  // Set link's href to point to the Blob URL
  link.href = blobUrl
  link.download = file.name

  // Append link to the body
  document.body.appendChild(link)

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  )

  // Remove link from body
  document.body.removeChild(link)
}

function excelSerialToDate(serial: number) {
  const MS_PER_DAY = 24 * 60 * 60 * 1000
  const excelEpoch = new Date(Date.UTC(1899, 11, 30))
  const utcDays = Math.floor(serial)
  const utcMilliseconds = Math.round((serial - utcDays) * MS_PER_DAY)
  const date = new Date(
    excelEpoch.getTime() + utcDays * MS_PER_DAY + utcMilliseconds
  )
  return date
}

export function formatDateToYYYYMMDD(date: Date) {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0') // Adding 1 because getMonth() returns zero-based month index
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}

export const getNumberOfDays = (startDate: Date, endDate: Date): number => {
  const start = new Date(startDate)
  const end = new Date(endDate)
  const diffTime = Math.abs(end.getTime() - start.getTime())
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  return diffDays
}

export const formatDateFromDays = (serial: number) => {
  const date = excelSerialToDate(serial)
  const formattedDate = formatDateToYYYYMMDD(date)
  return formattedDate
}

export function convertExcelTimeToReadable(excelTime: number) {
  // Assuming excelTime is a fraction of a day
  // Convert it to hours and minutes
  const totalMinutes = excelTime * 24 * 60
  const hours = Math.floor(totalMinutes / 60)
  const minutes = Math.round(totalMinutes % 60)

  // Format the time as "hh:mm"
  const formattedTime = `${hours < 10 ? '0' : ''}${hours}:${
    minutes < 10 ? '0' : ''
  }${minutes}`

  return formattedTime
}

export function getGrossPercentage(number: number, prevNumber: number): number {
  return ((number - prevNumber) / prevNumber) * 100
}

export const removeSpecialCharacters = (input: string): string => {
  // Define a regular expression pattern to match the characters you want to remove
  // eslint-disable-next-line no-useless-escape
  const pattern = /[\s\[\]#\$&%-]/g

  // Use the replace method with the pattern to remove the matched characters
  return input.replace(pattern, '-')
}

export const getQuarters = (date?: Date) => {
  const _date = date || new Date()
  return {
    currentY: moment().format('Y'),
    currentQ: moment().format('Q'),
    prevY: moment().subtract(1, 'Q').format('Y'),
    prevQ: moment().subtract(1, 'Q').format('Q'),
  }
}

export const convertToInt = (arr: string[]) => arr.map((int: string) => Number(int))
