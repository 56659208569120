import LanguageSelector from 'components/partials/LanguageSelector'
import {
  CloseIcon,
  FilmIcon,
  HomeIcon,
  PerformanceIcon,
  UploadBulk,
  SquareActivity,
  PaletteIcon,
} from 'components/shared/Icons'
import { useUI } from 'ctx/UIContext'
import { useOnClickOutside } from 'hooks/useOutsideClick'
import { classNames } from 'lib/misc'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const SideMenu = () => {
  const sidebarRef = useRef<HTMLDivElement>(null)
  const { isSidebarOpen, setSidebarOpen } = useUI()
  const { whiteLabelData } = useUI()

  const { t } = useTranslation()

  const sidebarData = [
    {
      title: t('sidebar.manage'),
      route: '',
      icon: HomeIcon,
      routes: [
        {
          route: '/my-submissions',
          name: t('sidebar.mySubmissions'),
          icon: FilmIcon,
        },

        {
          route: '/earnings',
          name: t('sidebar.earnings'),
          icon: PerformanceIcon,
        },
      ],
    },
    {
      title: t('sidebar.aiTools'),
      route: '',
      icon: HomeIcon,
      routes: [
        // {
        //   route: '/marketing-tool',
        //   name: t('sidebar.marketingTool'),
        //   icon: FilmIcon,
        // },
        {
          route: '/audio-mixing',
          name: t('sidebar.audioMixing'),
          icon: SquareActivity,
        },
        {
          route: '/artwork-generator',
          name: t('sidebar.artworkGenerator'),
          icon: PaletteIcon,
        },
      ],
    },
    {
      title: t('sidebar.recordLabels'),
      route: '',
      icon: HomeIcon,
      routes: [
        {
          route: '/bulk-Submission',
          name: t('sidebar.bulkSubmission'),
          icon: UploadBulk,
        },
      ],
    },
  ]

  const closeSidebar = () => {
    isSidebarOpen && setSidebarOpen(false)
  }

  useOnClickOutside(sidebarRef, closeSidebar)

  return (
    <div
      className={classNames(
        'fixed left-0 top-0 transition-all duration-300 group z-[999] flex flex-col h-screen overflow-hidden shadow-md bg-white',
        isSidebarOpen ? 'w-72' : 'w-0 md:w-64'
      )}
      ref={sidebarRef}
    >
      <div
        className={classNames(
          'flex items-center bg-primary-purple rounded-br-[50px]',
          'justify-start group-hover:justify-between'
        )}
      >
        <div className='flex flex-1 items-center justify-center px-6 py-4 min-h-[var(--header-height)]'>
          <div className='w-full md:flex md:justify-center md:items-center'>
            {whiteLabelData.app_name !== undefined && (
              <img
                src={whiteLabelData.logo ?? '/assets/logo.png'}
                alt=''
                className={`inline-block mr-4 ${
                  whiteLabelData.logo ? 'h-full max-h-14' : 'h-8'
                }`}
              />
            )}
          </div>
          <button
            type='button'
            className='relative block p-2 ml-auto text-lg text-white rounded-full md:hidden bg-primary-purple w-max'
            onClick={() => setSidebarOpen(false)}
          >
            <CloseIcon />
          </button>
        </div>
      </div>
      <div className='flex-1'>
        {sidebarData.map((item, i) => (
          <div className='px-2 mx-2' key={i}>
            <h4 className='m-3 text-sm font-semibold uppercase text-black/60'>
              {item.title}
            </h4>
            {item.routes.map((route, j) => {
              const isActive = location.pathname
                .toLowerCase()
                .includes(route.route.toLowerCase())
              return (
                <Link
                  to={item.route + route.route}
                  key={j}
                  className={classNames(
                    'rounded-full transition-all duration-300 cursor-pointer my-1 flex gap-3 items-center',
                    'w-full justify-start py-3 text-sm px-4 ml-0',
                    isActive
                      ? 'bg-primary-purple text-white'
                      : 'hover:bg-secondary-purple/5 text-gray-500'
                  )}
                >
                  <span>{route.icon()}</span>
                  <span className='capitalize font-body'>{route.name}</span>
                </Link>
              )
            })}
          </div>
        ))}
      </div>
      <div className='flex flex-col w-full gap-2 px-4 mt-auto mb-4'>
        <LanguageSelector />
      </div>
    </div>
  )
}

export default SideMenu
