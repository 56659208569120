import {
  Dispatch,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Dialog } from '@headlessui/react'

import Cropper from 'react-easy-crop'
import { Point, Area } from 'react-easy-crop/types'
import {
  UseFormRegister,
  FieldValues,
  UseFormSetError,
  FieldErrors,
  UseFormReset,
} from 'react-hook-form'
import { CloseIcon, InfoIcon, LoadingIcon } from './Icons'
import { getCroppedImg } from 'lib/imageCroppingUtils'
import { SetStateAction } from 'jotai'
import { readFile, urltoFile } from 'lib/imageUtils'
import Tooltip from './Tooltip'
import { Button } from 'components/partials/Button'
import { useTranslation } from 'react-i18next'
// import 'react-easy-crop/react-easy-crop.css'

interface ArtworkInputProps {
  register: UseFormRegister<FieldValues | any>
  label: string
  name: string
  setError: UseFormSetError<FieldValues | any>
  setValue: any | Dispatch<SetStateAction<any>>
  reset: UseFormReset<FieldValues | any>
  errors?: FieldErrors<FieldValues | any>
  aspectH?: number
  aspectW?: number
  validationRules?: string
  cls?: string
  required?: boolean
  value?: any
  isLoading?: boolean
}

const ImageCropper: FC<ArtworkInputProps> = ({
  register,
  label,
  name,
  reset,
  errors,
  setError,
  setValue,
  aspectH = 1,
  aspectW = 1,
  validationRules,
  cls = '',
  required = false,
  value = null,
  isLoading = false,
}) => {
  const {
    onChange,
    name: inputName,
    ref,
  } = register(name, { required: required ? `${name} is required` : false })

  const _minZoom = 1
  const _maxZoom = 10

  const input = useRef<any>(null)
  const { t } = useTranslation()

  const maxZoom = useRef(_maxZoom)
  const minZoom = useRef(_minZoom)
  const [isDialogOpen, setDialogOpen] = useState(false)
  const [fileName, setFileName] = useState('')
  const [imageSrc, setImageSrc] = useState<any>(null)
  const [selectedArtwork, setSelectedArtwork] = useState<any>(null)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null)
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(_minZoom)
  const [cropAreaSize, setCropAreaSize] = useState({ width: 0, height: 0 })
  const [zoomSpeed, setZoomSpeed] = useState(1)
  const [isImageValid, setIsImageValid] = useState(true)

  const _aspect = useMemo(() => `${aspectW}/${aspectH}`, [aspectH, aspectW])
  const accept = ['.jpg', '.jpeg', '.png']

  const onFileChange = useCallback(async (event: any) => {
    const file = event.target.files?.[0]
    if (!file) return
    const imageDataUrl = await readFile(file)
    setImageSrc(imageDataUrl)
    setFileName(file.name)
    setDialogOpen(true)
    event.target.value = ''
  }, [])

  const err = useMemo(
    () => (errors && errors[name] ? errors[name]?.message : ''),
    [errors]
  )

  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      const isSizeValid = validateDimensions(
        croppedAreaPixels.width,
        croppedAreaPixels.height,
        aspectW / aspectH
      )

      if (!isSizeValid) {
        setIsImageValid(false)
      } else {
        setIsImageValid(true)
      }

      setCroppedAreaPixels(croppedAreaPixels)
    },
    []
  )

  const getOutput = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels)
      setSelectedArtwork(croppedImage)
      if (!croppedImage) return
      urltoFile(croppedImage, fileName, 'image/jpeg')
        .then((res) => {
          setValue(res)
        })
        .then(() => {
          handleModalClose()
        })
    } catch (e) {
      console.error(e)
    }
  }, [imageSrc, croppedAreaPixels, aspectH, aspectW])

  const handleModalClose = () => {
    setDialogOpen(false)
    setCrop({ x: 0, y: 0 })
    setZoom(1)
    setCroppedAreaPixels(null)
    setImageSrc(null)
    maxZoom.current = _maxZoom
  }

  const handleCropAreaChange = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels)
      const lastZoom = zoom
      const dimensions = validateDimensions(
        croppedAreaPixels.width,
        croppedAreaPixels.height,
        aspectW / aspectH
      )
      if (!dimensions) {
        // setZoom(lastZoom)
        maxZoom.current = lastZoom
      }
    },
    [zoom]
  )

  const validateDimensions = (
    width: number,
    height: number,
    aspectRatio: number
  ) => {
    // console.log(width, height)
    return width >= 3000 && height >= 3000
    // switch (aspectRatio) {
    //   case 2 / 3:
    //     return width >= 1400 && height >= 2100
    //   case 3 / 4:
    //     return width >= 1576
    //   case 16 / 6:
    //     return width >= 1920 && height >= 720
    //   case 4 / 3:
    //     return width >= 1920 && height >= 1440
    //   case 2 / 1:
    //     return width >= 1920 && height >= 960
    //   case 16 / 9:
    //     return width >= 1925
    //   default:
    //     return true
    // }
  }

  useEffect(() => {
    if (!value) {
      setSelectedArtwork(null)
      return
    }
    const reader = new FileReader()
    reader.readAsDataURL(value)
    reader.onload = function () {
      setSelectedArtwork(reader.result)
    }
  }, [value])

  const removeFile = (e: any) => {
    e.preventDefault()
    setSelectedArtwork(null)
    setImageSrc(null)
    setValue(null)
    let fileInputValue = input.current.querySelector('input').value
    fileInputValue = ''
    reset({ name: null }, { keepValues: false })
  }

  return (
    <>
      <div className={['flex', cls].join(' ')} ref={input}>
        <label
          htmlFor={name}
          className={[
            'flex flex-col items-center justify-center w-full min-h-full border border-secondary-purple/20 overflow-hidden !rounded-lg cursor-pointer bg-gray-50 !mb-0 hover:bg-gray-100',

            errors && errors[name] ? 'error' : '',
          ].join(' ')}
          style={{
            aspectRatio: _aspect,
          }}
          // onClick={onLabelClick}
        >
          <div className='absolute flex items-center justify-center w-full h-max'>
            {selectedArtwork ? (
              <img
                src={selectedArtwork ? selectedArtwork : ''}
                alt={name}
                className='relative object-contain w-full h-full'
              />
            ) : (
              <div className='relative flex flex-col items-center justify-center w-full h-full pt-5 pb-6'>
                <svg
                  aria-hidden='true'
                  className='w-10 h-10 mb-3 text-primary-purple'
                  fill='none'
                  stroke='currentColor'
                  viewBox='0 0 24 24'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12'
                  ></path>
                </svg>
                <p className='mb-2 text-xs text-gray-500'>
                  <span className='font-semibold'>
                    {label} {required ? '*' : null}
                  </span>
                </p>
                <p className='px-2 text-xs text-gray-400'>{validationRules}</p>
                {typeof err === 'string' ? (
                  <p className='mt-2 text-xs text-red-500' id='file_input_help'>
                    {err}
                  </p>
                ) : null}
              </div>
            )}
          </div>
          <input
            type='file'
            id={name}
            className='!mb-0'
            hidden
            name={inputName}
            ref={ref}
            onChange={(e) => {
              onChange(e)
              onFileChange(e)
            }}
            // {...register(name, {
            //   required: required ? 'This is required' : false,

            //   onChange: (e) => {
            //     onFileChange(e)
            //   },
            // })}
            accept={accept?.join(',')}
          />
          {selectedArtwork ? (
            <Tooltip content='Remove file'>
              <button
                className='absolute top-0 right-0 flex items-center justify-center w-5 h-5 m-1 text-base text-white bg-red-500 border border-red-500 rounded-full'
                onClick={removeFile}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  height='1em'
                  width='1em'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M6 18L18 6M6 6l12 12'
                  />
                </svg>
              </button>
            </Tooltip>
          ) : null}
        </label>
        <Tooltip content={validationRules || 'deliverables'}>
          <div className='absolute top-0 flex items-center justify-center w-5 h-5 m-1 text-base bg-white border rounded-full border-secondary-purple text-secondary-purple'>
            <InfoIcon />
          </div>
        </Tooltip>
        {isLoading ? (
          <div className='absolute inset-0 bg-transparent z-top pointer-events-none overflow-hidden !rounded-lg flex items-center justify-center'>
            <div className='absolute inset-0 bg-white opacity-40'></div>
            <span className='text-2xl text-primary-purple animate-spin'>
              <LoadingIcon />
            </span>
          </div>
        ) : null}
      </div>
      <Dialog
        open={isDialogOpen}
        onClose={handleModalClose}
        className='fixed inset-0 w-full h-full z-top'
      >
        <div className='fixed inset-0 md:inset-[2rem] flex items-center justify-center md:ml-72'>
          <Dialog.Panel className='absolute inset-0 overflow-hidden bg-white shadow-2xl md:rounded-xl'>
            <div className='absolute top-0 left-0 w-full h-full mb-4'>
              <Cropper
                // minZoom={minZoom.current}
                // maxZoom={maxZoom.current}
                image={imageSrc}
                crop={crop}
                // zoomSpeed={zoomSpeed}
                zoom={zoom}
                aspect={aspectW / aspectH}
                onCropChange={setCrop}
                // onCropAreaChange={handleCropAreaChange}
                // onCropSizeChange={setCropAreaSize}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                showGrid={false}
                disableAutomaticStylesInjection={false}
                // cropSize={{ height: 200, width: 200 }}
                // classes={{
                //   containerClassName: 'rounded-lg w-full h-full',
                //   cropAreaClassName: ' !border-purple-500/30 w-full h-full',
                //   mediaClassName: '',
                // }}
                // restrictPosition={false}
              />
            </div>
            <div className='absolute bottom-0 left-0 flex items-center justify-between w-full px-4 py-2 bg-white'>
              {!isImageValid ? (
                <span className='text-sm text-destructive'>
                  {t('errors.imageSizeInvalid')}
                </span>
              ) : (
                <span className='text-sm text-green-500'>
                  {t('messages.imageSizeValid')}
                </span>
              )}
              <div className='flex items-center justify-end flex-1 gap-2'>
                <Button
                  onClick={(e) => {
                    e.preventDefault()
                    getOutput()
                  }}
                  isDisabled={!isImageValid}
                >
                  {t('labels.cropImage')}
                </Button>
                <Button
                  variant='destructive'
                  onClick={(e) => {
                    e.preventDefault()
                    setImageSrc(null)
                    handleModalClose()
                  }}
                >
                  {t('buttons.cancel')}
                </Button>
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  )
}

export default ImageCropper
