import { PageLoading } from 'components/layout/LoadingScreen'
import { Card } from 'components/partials/Card'
import { ArrowLeft } from 'lucide-react'
import StatusInfo from './StatusInfo'

const SubmissionInfo = ({ submission }: { submission: any }) => {
  console.log(submission)
  if (!submission.status) {
    return (
      <Card className='flex items-center justify-center p-5 bg-white min-h-96'>
        <PageLoading className='h-auto' />
      </Card>
    )
  }

  if (submission?.status?.toLowerCase() === 'draft') {
    return (
      // <Card className='p-5 bg-white min-h-96'>
      //   SubmissionInfo is enabled once your submission is delivered
      // </Card>
      null
    )
  }

  return (
    <>
      <div className='flex items-center justify-between'>
        <a
          href='/my-submissions'
          className='inline-flex items-center gap-2 transition-all text-primary-purple hover:opacity-70'
        >
          <ArrowLeft className='size-5' /> Back to Submissions
        </a>
        {/* <Button className='button error'>Request for Deletion</Button> */}
      </div>
      <StatusInfo status={submission?.status} />
      <Card className='p-5 pb-10 bg-white min-h-96'>
        <h1 className='mb-6 text-base font-black md:text-xl w-max font-title'>
          Submission Info
        </h1>
        <div className='flex flex-col gap-10 lg:flex-row'>
          <div className='w-full lg:w-1/3'>
            <img
              src={submission?.artWork}
              alt='artwork'
              className='w-full rounded-lg'
            />
            <div className='flex flex-col items-start '>
              <audio controls className='w-full mt-4'>
                <source src={submission?.file} type='audio/wav' />
                Your browser does not support the audio element.
              </audio>
              <a
                href={submission?.agreement}
                download
                className='mt-4 text-white rounded-lg button primary'
              >
                Download Agreement
              </a>
            </div>
          </div>
          <div className='grid w-full grid-cols-2 gap-6 2xl:px-10 lg:grid-cols-3 lg:w-2/3'>
            <InfoItem label='Release Title' value={submission?.releaseTitle} />
            <InfoItem label='Track Title' value={submission?.trackTitle} />
            <InfoItem label='Artist Name' value={submission?.artistName} />
            <InfoItem label='Composer' value={submission?.composer} />
            <InfoItem label='Release Date' value={submission?.digitalReleaseDate} />
            <InfoItem label='Genre' value={submission?.genre} />
            <InfoItem label='Sub Genre' value={submission?.sub_genre} />
            <InfoItem label='Label' value={submission?.label} />
            <InfoItem label='Status' value={submission?.status} />
            <InfoItem label='Selected Type' value={submission?.selected_type} />
            <InfoItem
              label='Parental Advisory'
              value={submission?.parentalAdvisory}
            />
            <InfoItem label='Lyrics Writer' value={submission?.lyrics_writer} />
            <InfoItem label='Title Language' value={submission?.titleLanguage} />
            <InfoItem label='Version' value={submission?.version} />
            <InfoItem label='DSP Count' value={submission?.dsps?.length} />
            <InfoItem label='Lyrics Language' value={submission?.lyricsLanguage} />
            <div className='col-span-2 lg:col-span-3'>
              <InfoItem label='Lyrics' value={submission?.lyrics} />
            </div>
          </div>
        </div>
      </Card>
    </>
  )
}

export default SubmissionInfo

const InfoItem = ({
  label,
  value,
  className,
}: {
  label: string
  value: string
  className?: string
}) => {
  return (
    <div className={className}>
      <div className='text-sm font-bold md:text-base'>{label}</div>
      <div className='text-sm md:text-base'>{value}</div>
    </div>
  )
}
