import React, { FC } from 'react'
import Tooltip from './Tooltip'
import { CircleCheck, CircleEllipsis, Clock3, Timer, X } from 'lucide-react'

type StatusProps = {
  type: string
}

const statusClasses = {
  draft: 'icon bg-red-500 text-white p-0.5 rounded-full',
  review: 'icon bg-orange-500 text-white p-0.5 rounded-full',
  delivered: 'icon bg-green-500 text-white p-0.5 rounded-full',
  reject: 'icon bg-red-500 text-white p-0.5 rounded-full',
}

const Status: FC<StatusProps> = ({ type }) => {
  const renderStatus = (status: string) => {
    let statIcon: string | JSX.Element = ''

    switch (status?.toLowerCase()) {
      case 'completed':
      case 'in distribution':
      case 'delivered':
        statIcon = (
          <span className={statusClasses.delivered}>
            <CircleCheck size={20} />
          </span>
        )
        break
      case 'draft':
        statIcon = (
          <span className={statusClasses.draft}>
            <CircleEllipsis size={20} />
          </span>
        )
        break

      case 'rejected':
      case '"rejected"':
        statIcon = (
          <span className={statusClasses.reject}>
            <X size={20} />
          </span>
        )
        break
      case 'pending':
      case 'in review':
      case 'review':
        statIcon = (
          <span className={statusClasses.review}>
            <Clock3 size={20} />
          </span>
        )
        break

      default:
        statIcon = (
          <span className={statusClasses.review}>
            <CircleEllipsis size={20} />
          </span>
        )
        break
    }

    return statIcon
  }
  return <Tooltip content={type.toLowerCase()}>{renderStatus(type)}</Tooltip>
}

export default Status
