import { FC } from 'react'
import { formatCash } from 'lib/misc'
import { useTranslation } from 'react-i18next'
import { useUI } from 'ctx/UIContext'

interface IEarningsChart {
  data: any
}

const EarningsChart: FC<IEarningsChart> = ({ data }) => {
  const { t } = useTranslation()
  const { whiteLabelData } = useUI()
  return (
    <div className='h-full card'>
      <div className='flex flex-col'>
        <h4 className='text-lg font-bold text-navy-700'>
          {t('breakdown.totalEarnings')}
        </h4>
        <div className='flex flex-col items-center py-10'>
          <p
            className='text-4xl font-bold text-primary-purple'
            style={{
              color: whiteLabelData.theme ? 'var(--whitelabel-neutral)' : '',
            }}
          >
            $ {formatCash(data[0]?.earning ?? 0)}
          </p>
        </div>
      </div>
    </div>
  )
}

export default EarningsChart
