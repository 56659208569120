import React, { useState } from 'react'
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react'
import { ChevronLeft, ChevronRight } from 'lucide-react'

interface Props {
  children: JSX.Element | JSX.Element[]
  dots?: boolean
  arrows?: boolean
  perView: number
  sm?: number
  md?: number
  lg?: number
  xl?: number
  xl2?: number
  spacing: number
}

const Carousel = ({
  children,
  dots,
  arrows,
  perView,
  sm,
  md,
  lg,
  xl,
  xl2,
  spacing,
}: Props) => {
  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [loaded, setLoaded] = useState(false)
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
    loop: false,
    mode: 'free-snap',
    breakpoints: {
      '(min-width: 640px)': {
        slides: { perView: sm, spacing: spacing },
      },
      '(min-width: 768px)': {
        slides: { perView: md, spacing: spacing },
      },
      '(min-width: 1024px)': {
        slides: { perView: lg, spacing: spacing },
      },
      '(min-width: 1280px)': {
        slides: { perView: xl, spacing: spacing },
      },
      '(min-width: 1536px)': {
        slides: { perView: xl2, spacing: spacing },
      },
    },
    slides: {
      perView: perView,
      spacing: spacing,
    },
  })

  return (
    <>
      <div className='relative w-full'>
        <div ref={sliderRef} className='keen-slider'>
          {Array.isArray(children) &&
            children.map((item: JSX.Element, index: number) => (
              <div
                key={index}
                className={`keen-slider__slide number-slide${index + 1}`}
              >
                {item}
              </div>
            ))}
        </div>
        <div className='flex items-center justify-between mt-10'>
          {dots && loaded && instanceRef.current && (
            <div className='flex justify-center '>
              {Array.from(
                {
                  length: instanceRef.current.track.details.slides.length,
                },
                (_, idx) => (
                  <button
                    key={idx}
                    onClick={() => {
                      instanceRef.current?.moveToIdx(idx)
                    }}
                    className={
                      'mx-1 size-2  cursor-pointer rounded-full border-0 p-1 transition-all focus:outline-none ' +
                      (currentSlide === idx
                        ? 'scale-150 bg-primary-purple'
                        : 'bg-gray-400')
                    }
                  ></button>
                )
              )}
            </div>
          )}
          {arrows && loaded && instanceRef.current && (
            <div className='flex gap-3'>
              <button
                onClick={(e: any) =>
                  e.stopPropagation() || instanceRef.current?.prev()
                }
                disabled={currentSlide === 0}
                className={` cursor-pointer rounded-full border-[1px] p-2 transition-all hover:border-primary-purple hover:text-primary-purple sm:left-1 lg:block`}
              >
                <ChevronLeft className='size-5' />
              </button>
              <button
                onClick={(e: any) =>
                  e.stopPropagation() || instanceRef.current?.next()
                }
                disabled={
                  currentSlide ===
                  instanceRef.current.track.details.slides.length - 1
                }
                className={`cursor-pointer rounded-full border-[1px] p-2 transition-all hover:border-primary-purple hover:text-primary-purple sm:-right-1 lg:block`}
              >
                <ChevronRight className='size-5' />
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Carousel
