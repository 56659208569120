import AboutContent from 'components/layout/AboutContent'
import ContactForm from 'components/layout/ContactForm'
import GuidesTabs2 from 'components/layout/GuidesTabs2'
import LoadingScreen from 'components/layout/LoadingScreen'
import { useUI } from 'ctx/UIContext'

const PublicAbout = () => {
  const { whiteLabelData } = useUI()
  return (
    <>
      {whiteLabelData.app_name && whiteLabelData !== undefined ? (
        <>
          <div className='fixed top-0 w-full bg-white shadow-md z-top'>
            <div className='container flex items-center justify-between p-2 mx-auto '>
              <a
                href='/'
                className={`h-10 p-2 md:h-14 ${
                  whiteLabelData?.wlabel === 'wintribemedia'
                    ? ''
                    : 'bg-primary-purple'
                }`}
                style={{
                  backgroundColor:
                    whiteLabelData?.wlabel === 'wintribemedia'
                      ? ''
                      : whiteLabelData?.theme?.[2] ?? '',
                }}
              >
                <img
                  src={whiteLabelData.logo ?? '/assets/logo-white.png'}
                  alt=''
                  className='object-contain h-full'
                />
              </a>
              <div className='space-x-4'>
                <a
                  href='/public/guides'
                  className={`font-semibold py-2 transition-all button hover:opacity-90 ${
                    !whiteLabelData?.theme?.[2] ? ' bg-gray-800 text-white' : ''
                  }`}
                  style={{
                    color: whiteLabelData?.theme?.[1] ?? '',
                    backgroundColor: whiteLabelData?.theme?.[2] ?? '',
                  }}
                >
                  View Guides
                </a>
                <a
                  href='/login'
                  className={`font-semibold py-2 transition-all button hover:opacity-90 ${
                    !whiteLabelData?.theme?.[2] ? ' bg-gray-800 text-white' : ''
                  }`}
                  style={{
                    color: whiteLabelData?.theme?.[1] ?? '',
                    backgroundColor: whiteLabelData?.theme?.[2] ?? '',
                  }}
                >
                  Get Started
                </a>
              </div>
            </div>
          </div>
          <div className='container py-32 mx-auto'>
            <div
              id='title'
              className='text-4xl font-extrabold tracking-tighter text-center md:text-7xl font-title'
            >
              <h1>Your DIY</h1>
              <h1 className='text-primary-purple'>Music Distribution</h1>
              <h1>Platform</h1>
            </div>
            <AboutContent />
            <div className='mt-20 md:mt-32'>
              <ContactForm />
            </div>
          </div>
        </>
      ) : (
        <LoadingScreen isLoading={true} />
      )}
    </>
  )
}

export default PublicAbout
