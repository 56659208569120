import { FC, ReactNode } from 'react'

import MainLayout from './MainLayout'

type PageLayoutProps = {
  children: ReactNode
}

const PageLayout: FC<PageLayoutProps> = ({ children }) => {
  return (
    <MainLayout>
      <div
        className={[
          'relative p-2 w-full bg-gray-50 pt-[var(--header-height)] md:pt-0 h-full md:h-[var(--content-height)] overflow-x-hidden',
        ].join(' ')}
      >
        {children}
      </div>
    </MainLayout>
  )
}

export default PageLayout
