import { Card } from 'components/partials/Card'
import Tooltip from 'components/shared/Tooltip'

interface CampaignTypeCardProps {
  onClick: () => void
  type: any
  config: any
}

const CampaignTypeCard = ({ onClick, type, config }: CampaignTypeCardProps) => {
  const isDisabled =
    (type.social === 'spotify' && !config.spotifyUser) ||
    (type.social === 'instagram' && !config.instagramUser) ||
    (type.social === 'facebook' && !config.facebookPage)

  const getTooltipContent = () => {
    if (type.social === 'instagram' && !config.instagramUser) {
      return 'You need to connect an Instagram Business account to access this feature.'
    } else if (type.social === 'facebook' && !config.facebookPage) {
      return 'You need to connect a Facebook Page to access this feature.'
    } else if (type.social === 'spotify' && !config.spotifyUser) {
      return 'You need to connect a Spotify Account to access this feature.'
    }
    return ''
  }

  const card = (
    <Card
      onClick={!isDisabled ? onClick : undefined}
      className={`cursor-pointer hover:scale-[102%] transition-all w-full max-w-[18rem] rounded-lg ${
        isDisabled ? 'opacity-50 cursor-not-allowed' : ''
      }`}
    >
      <div className='flex items-center justify-center w-full rounded-t-lg h-28 bg-primary-purple/10'>
        <img src={type.image} alt={type.title} className='w-12 h-12' />
      </div>
      <div className='p-5'>
        <h4 className='mb-4 text-lg font-semibold'>{type.title}</h4>
        <p className='text-sm'>{type.description}</p>
      </div>
    </Card>
  )

  return isDisabled ? <Tooltip content={getTooltipContent()}>{card}</Tooltip> : card
}

export default CampaignTypeCard
