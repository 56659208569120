import { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Country, State, City } from 'country-state-city'

import { FieldWrapper, Error } from 'components/shared/FormUtils'
import { useSubmissions, useUser } from 'store/RootStore'
import { EyeClosedIcon, EyeOpenIcon } from 'components/shared/Icons'
import CustomModal from 'components/shared/CustomModal'
import { useAuth } from 'ctx/AuthContext'
import { Input, Label, SelectInput } from 'components/partials/Inputs'
import { Button } from 'components/partials/Button'
import Signature from 'components/shared/Signature'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'components/partials/Dialog'
import PDFRenderer from 'components/shared/PDFRenderer.tsx'
import Tooltip from 'components/shared/Tooltip'
import { Check, Loader } from 'lucide-react'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import htmltopdf from 'html2pdf.js'
import { uploadToS3 } from 'lib/aws-utils'
import { useUI } from 'ctx/UIContext'
import PageHeader from 'components/shared/PageHeader'
import { useTranslation } from 'react-i18next'

const emailString = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i

type AccountDetailsFormType = {
  firstname: string
  lastname: string
  user_email: string
  paypal: string
  line1: string
  line2: string
  state: string
  country: string
  tel: string
}

const defaultValues: AccountDetailsFormType = {
  firstname: '',
  lastname: '',
  user_email: '',
  paypal: '',
  line1: '',
  line2: '',
  state: '',
  country: '',
  tel: '',
}

const AgreementForm = ({
  submission,
  getSub,
}: {
  submission: any
  getSub: () => void
}) => {
  const { t } = useTranslation()
  const schema = yup.object().shape({
    email: yup.string().matches(emailString, { message: t('errors.invalidEmail') }),
    paypal: yup.string().matches(emailString, { message: t('errors.invalidEmail') }),
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    getValues,
    watch,
  } = useForm<AccountDetailsFormType>({
    mode: 'all',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues,
  })

  const country_watcher = watch('country')

  const [selectedCountry, setSelectedCountry] = useState<any>({})
  const [countries, setCountries] = useState<any[]>([])
  const [states, setStates] = useState<any[]>([])
  const [cities, setCities] = useState<any[]>([])

  const [isAgreementGenerated, setAgreementGenerated] = useState(false)

  const [sign, setSign] = useState('')

  const { user } = useUser()
  const { updateSubmission, updateMeta, getSubmission, getArtists } =
    useSubmissions()
  const { setLoading, isLoading } = useUI()

  const pdfRef = useRef<HTMLDivElement>(null)

  const uploadFiles = async (
    agreementFile: File | null,
    submissionId: string,
    userId: string
  ) => {
    setLoading(true)
    try {
      if (agreementFile) {
        await uploadToS3(agreementFile, userId, submissionId)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const renderPDF = async () => {
    const fileName = `${user.firstname + '_' + user.lastname}_agreement.pdf`
    const opt = {
      margin: 1,
      fileName,
      image: { type: 'jpeg', quality: 0.98 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    }

    await htmltopdf()
      .from(pdfRef.current)
      .set(opt)
      .toPdf()
      .output('blob')
      .then((data: Blob) => {
        updateSub(data, fileName)
        // updateData('agreement', [file])
        // setTimeout(() => {
        // }, 100)
      })
    //   .save()
  }

  const updateSub = async (data: Blob, fileName: string) => {
    setLoading(true)
    const file = new File([data], fileName)
    const newFileURL = file
      ? decodeURIComponent(
          `https://musicdash.s3.amazonaws.com/submissions/${user.user_id}/${submission.submission_id}/${fileName}`
        )
      : null
    const subID = submission.submission_id
    const userID = user.user_id

    const submissionData = {
      submission_id: submission.submission_id,
      agreement: newFileURL,
    }

    try {
      await uploadFiles(file, subID, userID)

      await updateSubmission(submissionData)

      const sub = await getSub()
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    reset(user)
  }, [JSON.stringify(user)])

  const onSubmit = (data: AccountDetailsFormType) => {
    setAgreementGenerated(true)
    renderPDF()
  }

  const updateSignature = (sign: string) => {
    setSign(sign)
  }

  const getAllCountries = () => Country.getAllCountries()

  const handleCountryChange = useCallback(
    (_selectedCountryName: any) => {
      const _selectedCountry = getAllCountries().find(
        (c) => c.name?.toLowerCase() === _selectedCountryName?.toLowerCase()
      )
      if (!_selectedCountry) return
      setSelectedCountry(_selectedCountry)
      const statesOfCountry = State.getStatesOfCountry(_selectedCountry.isoCode)
      setStates(statesOfCountry)

      if (!statesOfCountry.length) {
        const citiesOfcountry = City.getCitiesOfCountry(selectedCountry.isoCode)
        if (citiesOfcountry) {
          setCities(citiesOfcountry)
        }
      } else {
        const citiesOfState = City.getCitiesOfState(
          selectedCountry.isoCode,
          statesOfCountry[0].isoCode
        )

        setCities(citiesOfState)
      }
    },
    [selectedCountry]
  )

  useEffect(() => {
    setCountries(getAllCountries())
  }, [])

  useEffect(() => {
    handleCountryChange(country_watcher)
  }, [country_watcher])

  const isValidToSubmit = useMemo(() => sign !== '', [sign])

  return (
    <div>
      <form
        className='items-center justify-center bg-white rounded-lg form'
        onSubmit={handleSubmit(onSubmit)}
      >
        <PageHeader
          title={submission?.releaseTitle + ' - ' + submission?.selected_type}
          hasCreateSubmissionTrigger={false}
          className='bg-white'
        />

        {!submission.agreement ? (
          <>
            <div className='grid justify-between w-full grid-cols-1 gap-4 mb-10 md:grid-cols-2 lg:grid-cols-3'>
              <Input
                label={t('authLayout.info.firstName')}
                placeholder={t('authLayout.info.firstName')}
                name='firstname'
                register={register}
                wrapperClassName='flex-1'
                error={errors?.firstname?.message as string}
                isDisabled={isLoading}
              />
              <Input
                label={t('authLayout.info.lastName')}
                placeholder={t('authLayout.info.lastName')}
                name='lastname'
                register={register}
                wrapperClassName='flex-1'
                error={errors?.lastname?.message as string}
                isDisabled={isLoading}
              />

              <Input
                label={t('authLayout.info.email')}
                placeholder={t('authLayout.info.email')}
                name='user_email'
                type='email'
                register={register}
                error={errors?.user_email?.message as string}
                isDisabled={isLoading}
              />
              <Input
                label={t('authLayout.info.paypalEmail')}
                placeholder={t('authLayout.info.paypalEmail')}
                name='paypal'
                type='email'
                register={register}
                error={errors?.paypal?.message as string}
                isDisabled={isLoading}
              />
              <Input
                label={t('authLayout.info.addrLine1')}
                placeholder={t('authLayout.info.addrLine1')}
                name='line1'
                register={register}
                error={errors?.line1?.message as string}
                isDisabled={isLoading}
              />
              <Input
                label={t('authLayout.info.addrLine2')}
                placeholder={t('authLayout.info.addrLine2')}
                name='line2'
                register={register}
                error={errors?.line2?.message as string}
                isDisabled={isLoading}
              />
              <SelectInput
                control={control}
                options={countries.map((country) => ({
                  label: country.name,
                  value: country.name,
                }))}
                label={t('authLayout.info.country')}
                placeholder={t('authLayout.info.country')}
                name='country'
                error={errors?.country?.message as string}
                isDisabled={isLoading}
              />
              <SelectInput
                control={control}
                options={states.map((state) => ({
                  label: state.name,
                  value: state.name,
                }))}
                label={t('authLayout.info.state')}
                placeholder={t('authLayout.info.state')}
                name='state'
                error={errors?.state?.message as string}
                isDisabled={isLoading}
              />
            </div>
            <div className='flex flex-col items-start justify-start w-full'>
              <Label>{t('words.signature')}</Label>
              <Signature
                className='w-full'
                updateSignature={updateSignature}
                clearSignature={() => {
                  setSign('')
                  setAgreementGenerated(false)
                }}
              />
            </div>
            <div className='flex items-center justify-between flex-1 w-full'>
              <Button
                type='submit'
                className='mt-4'
                isLoading={isLoading}
                isDisabled={!isValidToSubmit}
              >
                {isLoading
                  ? t('labels.updatingAgreement')
                  : t('labels.updateAgreement')}
              </Button>

              {isAgreementGenerated ? (
                <Dialog>
                  <DialogTrigger>
                    <Tooltip content='view agreement'>
                      <div
                        aria-hidden
                        className='inline-flex font-semibold text-black underline cursor-pointer whitespace-nowrap'
                      >
                        {isLoading ? (
                          <Loader />
                        ) : (
                          <Check className='text-green-500' />
                        )}
                        {t('labels.agreementUpdated')}
                      </div>
                    </Tooltip>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogDescription>
                        <div className='h-full pdf-wrapper'>
                          <PDFRenderer
                            data={[{ 'Release Title': 'submission name' }]}
                            signature={sign}
                            userData={getValues()}
                          />
                        </div>
                      </DialogDescription>
                    </DialogHeader>
                  </DialogContent>
                </Dialog>
              ) : null}
            </div>
          </>
        ) : (
          <>
            <div className='my-4'>
              <span className='text-base font-semibold'>
                {t('messages.submitAgreementSuccess')}{' '}
                <a className='underline' href={submission.agreement}>
                  {t('words.here')}
                </a>{' '}
                {t('messages.submitAgreementSuccess2')}
              </span>
            </div>
          </>
        )}
      </form>
      <div className=''>
        <div className='pdf-wrapper' ref={pdfRef}>
          <PDFRenderer data={[submission]} signature={sign} userData={getValues()} />
        </div>
      </div>
    </div>
  )
}

export default AgreementForm
