import { useCampaign } from 'ctx/CampaignContext'
import CampaignTypeCard from './components/CampaignTypeCard'
import React from 'react'
import { Card } from 'components/partials/Card'

const campaignTypes = [
  {
    social: 'instagram',
    id: 'boost_instagram_engagement',
    title: 'Boost Instagram Engagement',
    image: '/assets/instagram-logo.png',
    description:
      'Boost your Instagram profile engagement by putting your content in front of new followers using automated highly-targeted, Instagram ads.',
    billing_event: 'IMPRESSIONS',
    optimization_goal: 'POST_ENGAGEMENT',
    objective: 'OUTCOME_ENGAGEMENT',
    f_positions: ['feed', 'marketplace', 'story'],
    i_positions: ['stream', 'explore', 'explore_home'],
  },
  {
    social: 'facebook',
    id: 'boost_facebook_engagement',
    title: 'Boost Facebook Engagement',
    image: '/assets/facebook-logo.png',
    description:
      'Boost your Facebook post by putting your content in front of new followers using automated highly-targeted, Instagram ads.',
    billing_event: 'IMPRESSIONS',
    optimization_goal: 'POST_ENGAGEMENT',
    objective: 'OUTCOME_AWARENESS',
    f_positions: ['feed', 'marketplace', 'story'],
    i_positions: ['stream', 'explore', 'explore_home'],
  },
  {
    social: 'facebook',
    id: 'create_facebook_campaign',
    title: 'Custom Facebook Campaign',
    image: '/assets/facebook-logo.png',
    description:
      'Create your Facebook post by creating one using AI and putting your content in front of new followers using automated highly-targeted, Instagram ads.',
    billing_event: 'IMPRESSIONS',
    optimization_goal: 'POST_ENGAGEMENT',
    objective: 'OUTCOME_ENGAGEMENT',
    f_positions: ['feed', 'marketplace', 'story'],
    i_positions: ['stream', 'explore', 'explore_home'],
  },
  // {
  //   social: 'spotify',
  //   id: 'boost_spotify_engagement',
  //   title: 'Boost Spotify Engagement',
  //   image: '/assets/spotify-logo.png',
  //   description:
  //     'Boost your Spotify post by putting your content in front of new followers using automated highly-targeted, Instagram ads.',
  //   billing_event: 'IMPRESSIONS',
  //   optimization_goal: 'POST_ENGAGEMENT',
  //   objective: 'OUTCOME_ENGAGEMENT',
  //   f_positions: ['feed', 'marketplace', 'story'],
  //   i_positions: ['stream', 'explore', 'explore_home'],
  // },
]

const CampaignType: React.FC<{ nextStep: () => void }> = ({ nextStep }) => {
  const { setConfig, config } = useCampaign()

  const handleNext = (type: (typeof campaignTypes)[0]) => {
    setConfig({ ...config, type })
    nextStep()
  }

  return (
    <div className='mt-6'>
      <Card className='h-full p-4 rounded-lg'>
        <h2 className='mb-4 font-bold'>Select Campaign Type</h2>
        <div className='flex flex-wrap justify-center gap-4 md:justify-normal'>
          {campaignTypes.map((type, index) => (
            <CampaignTypeCard
              key={index}
              type={type}
              config={config}
              onClick={() => handleNext(type)}
            />
          ))}
        </div>
      </Card>
    </div>
  )
}

export default CampaignType
