import React, { createContext, useContext, useState } from 'react'

interface CampaignContextProps {
  config: any
  setConfig: (config: any) => void
}

const CampaignContext = createContext<CampaignContextProps>({
  config: {},
  setConfig: () => {
    throw new Error('setConfig function must be overridden')
  },
})

export const useCampaign = () => useContext(CampaignContext)

export const CampaignProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [config, setConfig] = useState<any>({})

  return (
    <CampaignContext.Provider value={{ config, setConfig }}>
      {children}
    </CampaignContext.Provider>
  )
}
