import { Badge } from 'components/partials/Badge'
import { Button } from 'components/partials/Button'
import { Card } from 'components/partials/Card'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useMarketing } from 'store/RootStore'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/partials/Select'
import { PageLoading } from 'components/layout/LoadingScreen'
import { cn } from 'lib/utils'
import { CirclePause, CircleCheck, CircleX } from 'lucide-react'
import { lsget } from 'lib/misc'
import { useNavigate } from 'react-router-dom'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
} from 'components/partials/Dialog'

const CampaignInfo = () => {
  const navigate = useNavigate()
  const { getAdAccountId, getCampaignInfo, deleteCampaign } = useMarketing()
  const [adAccounts, setAdAccounts] = useState<any[] | null>(null)
  const [adAccount, setAdAccount] = useState<any | null>(null)
  const [campaigns, setCampaigns] = useState<any[] | null>(null)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [selectedCampaignId, setSelectedCampaignId] = useState<string | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const adRes = await getAdAccountId()
        if ('error' in adRes || 'detail' in adRes) {
          localStorage.removeItem('fb_access_token')
          throw new Error(adRes.detail)
        } else {
          setAdAccounts(adRes.data)
        }
      } catch (error: any) {
        toast.error(`Error fetching ad accounts data. Please try again.`)
        navigate('/marketing-tool/login')
      }
    }

    const accessToken = lsget('fb_access_token')
    if (!accessToken) {
      navigate('/marketing-tool/login')
    } else {
      fetchData()
    }
  }, [])

  const fetchData = async () => {
    try {
      const campaigns = await getCampaignInfo('campaigns', adAccount?.id)
      if ('error' in campaigns || 'detail' in campaigns) {
        localStorage.removeItem('fb_access_token')
        throw new Error(campaigns.detail)
      } else {
        setCampaigns(campaigns)
      }
    } catch (error: any) {
      toast.error(`Error fetching campaign data. Please try again.`)
      navigate('/marketing-tool/login')
    }
  }

  useEffect(() => {
    setCampaigns(null)
    if (adAccount) {
      fetchData()
    }
  }, [adAccount])

  const handleDeleteCampaign = async () => {
    if (selectedCampaignId) {
      try {
        const res = await deleteCampaign(selectedCampaignId)
        if ('error' in res || 'detail' in res || 'error_message' in res) {
          throw new Error(res.detail)
        } else {
          toast.success('Campaign deleted successfully')
          if (adAccount) {
            fetchData()
          }
        }
      } catch (error: any) {
        toast.error(`Error deleting campaign. Please try again`)
      } finally {
        setIsDialogOpen(false)
        setSelectedCampaignId(null)
      }
    }
  }

  const openDeleteDialog = (campaignId: string) => {
    setSelectedCampaignId(campaignId)
    setIsDialogOpen(true)
  }

  return (
    <>
      <Card className='w-full p-5 mt-4 rounded-md'>
        <h1 className='text-lg font-bold'>Current Campaigns</h1>
        <div className='flex flex-col gap-4 md:flex-row md:gap-4 md:justify-end md:items-center'>
          <div>
            <h2 className='mb-1 font-bold'>Advertising Account: </h2>
          </div>
          <div className='w-full md:max-w-xs'>
            {adAccounts && adAccounts.length > 0 ? (
              <Select
                onValueChange={(value) =>
                  setAdAccount(adAccounts?.find((acc) => acc.id === value) || null)
                }
                value={adAccount?.id || ''}
              >
                <SelectTrigger className='border rounded-lg focus:outline'>
                  <SelectValue placeholder={'Select Ad Account'} />
                </SelectTrigger>
                <SelectContent>
                  {adAccounts.map((opt, i) => (
                    <SelectItem key={i} value={opt.id}>
                      {opt.account_id}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            ) : (
              <PageLoading className='flex items-center justify-center h-0' />
            )}
          </div>
        </div>
        <div>
          {adAccount ? (
            <>
              {campaigns && campaigns.length > 0 ? (
                <>
                  {campaigns.map((campaign) => (
                    <Card
                      key={campaign.id}
                      className={cn('w-full p-5 mt-4 rounded-md')}
                    >
                      <div className='flex items-center justify-between mb-4'>
                        <p className='text-lg font-bold'>{campaign.name}</p>
                        <Button
                          variant='destructive'
                          className='bg-red-500'
                          onClick={() => openDeleteDialog(campaign.id)}
                        >
                          Delete Campaign
                        </Button>
                      </div>
                      <p className='flex items-center gap-1'>
                        <span className='w-32 font-bold'>Objective:</span>
                        {campaign.objective}
                      </p>
                      <p className='flex items-center gap-1'>
                        <span className='w-32 font-bold'>Created Time:</span>
                        {campaign.created_time}
                      </p>
                      <p className='flex items-center gap-1'>
                        <span className='w-32 font-bold'>Status:</span>
                        {campaign?.status === 'PAUSED' ? (
                          <CirclePause className='text-yellow-500 size-4' />
                        ) : campaign?.status === 'ACTIVE' ? (
                          <CircleCheck className='text-green-500 size-4' />
                        ) : (
                          <CircleX className='text-red-500 size-4' />
                        )}
                        {campaign.status}
                      </p>
                      <p className='flex items-center gap-1'>
                        <span className='w-32 font-bold'>Effective Status:</span>
                        {campaign?.effective_status === 'PAUSED' ? (
                          <CirclePause className='text-yellow-500 size-4' />
                        ) : campaign?.effective_status === 'ACTIVE' ? (
                          <CircleCheck className='text-green-500 size-4' />
                        ) : (
                          <CircleX className='text-red-500 size-4' />
                        )}
                        {campaign.effective_status}
                      </p>
                    </Card>
                  ))}
                </>
              ) : campaigns ? (
                <p>No Campaigns</p>
              ) : (
                <PageLoading className='flex items-center justify-center h-full py-20' />
              )}
            </>
          ) : (
            <p className='w-full py-20 text-center'>
              Select an Ad Account to view Campaigns
            </p>
          )}
        </div>
      </Card>
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className='h-auto'>
          <h2 className='text-xl font-bold'>Delete Campaign</h2>
          <p>
            Are you sure you want to delete this campaign? This action cannot be
            undone.
          </p>
          <div className='flex items-center justify-end gap-2'>
            <Button variant='outline' onClick={() => setIsDialogOpen(false)}>
              Cancel
            </Button>
            <Button variant='destructive' onClick={handleDeleteCampaign}>
              Delete
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default CampaignInfo
