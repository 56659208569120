import { FC, ReactNode } from 'react'
import { Dialog } from '@headlessui/react'
import { useTranslation } from 'react-i18next'

interface ICustomModal {
  isOpen: boolean
  close: () => void
  action: () => void
  title: string
  children: ReactNode
  subtitle?: string
}

const CustomModal: FC<ICustomModal> = ({
  isOpen,
  close,
  action,
  title,
  children,
  subtitle = '',
}) => {
  const { t } = useTranslation()
  return (
    <Dialog
      open={isOpen}
      onClose={() => close()}
      as='div'
      className='relative z-top'
    >
      <div className='fixed inset-0 bg-black/40' aria-hidden='true' />
      <div className='fixed inset-0 flex items-center justify-center'>
        <Dialog.Panel className='w-full max-w-md p-6 mx-2 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl'>
          <Dialog.Title
            as='h3'
            className='text-lg font-semibold leading-6 text-center text-gray-900 capitalize'
          >
            {title}
          </Dialog.Title>
          <Dialog.Description>{subtitle}</Dialog.Description>
          <div className='mt-2'>{children}</div>
          <div className='flex items-center justify-center gap-2 mt-4'>
            <button onClick={() => action()} className='button primary'>
              {t('words.gotit')}
            </button>
            <button onClick={() => close()} className='button muted'>
              {t('words.no')}
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}

export default CustomModal
