import { useUI } from 'ctx/UIContext'
import { useEffect, useState } from 'react'
import { useAnalytics, useSubmissions } from 'store/RootStore'
import ReactGA from 'react-ga4'

export default function useEarnings(userID: string): {
  updateDuePayments: () => void
  userEarnings: any
  creditBalance: any
  royaltyEarnings: any
  submissionEarnings: any
  pendingRequests: any
} {
  const {
    getCreditBalance,
    getRoyaltyEarnings,
    getUserEarnings,
    getSubmissionEarnings,
    getPaymentRequests,
  } = useAnalytics()
  const { setLoading } = useUI()
  const { getSubmissions } = useSubmissions()
  const [userEarnings, setUserEarnings] = useState<any>('')
  const [submissionEarnings, setSubmissionEarnings] = useState<any>([])
  const [creditBalance, setCreditBalance] = useState<any>('')
  const [royaltyEarnings, setRoyaltyEarnings] = useState<any>('')
  const [pendingRequests, setPendingRequests] = useState<any>([])

  const updateDuePayments = async () => {
    await getPaymentRequests('pending').then((res) => {
      if (res) {
        setPendingRequests(res)
        ReactGA.event({
          category: 'Payment Requests',
          action: 'Request',
        })
      }
    })
  }

  const getData = async () => {
    if (!userID) return

    setLoading(true)

    await updateDuePayments()

    await getUserEarnings().then((res) => {
      if (res) {
        setUserEarnings(res)
      }
    })

    await getSubmissionEarnings().then(async (res) => {
      if (!res) return
      const submissions: any = await getSubmissions()

      const submissionEarnings: any[] = res
        .filter((item: any) =>
          submissions.some(
            (submission: any) => submission.submission_id === item.submission
          )
        )
        .map((item: any) => item)

      setSubmissionEarnings(submissionEarnings)
    })

    await getCreditBalance().then((res) => {
      if (res) {
        setCreditBalance(res)
      }
    })

    await updateDuePayments()

    await getRoyaltyEarnings().then((res) => {
      if (res) {
        setRoyaltyEarnings(res)
      }
    })

    setLoading(false)
  }

  useEffect(() => {
    getData()
  }, [userID])

  return {
    updateDuePayments,
    creditBalance,
    royaltyEarnings,
    userEarnings,
    submissionEarnings,
    pendingRequests,
  }
}

export {}
