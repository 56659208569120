import { Button } from 'components/partials/Button'
import { createRef, useEffect, useState } from 'react'
import { useBulkSubmissions } from '../context/BulkSubmissionContext'
import FileUpload from './FileUpload.Partial'
import { useTranslation } from 'react-i18next'

const alloweAudioExts = ['.wav', '.flac', '.mp3']

const AudioUpload = () => {
  const { updateData, metadata, goBack } = useBulkSubmissions()
  const { t } = useTranslation()

  const [audioErrors, setAudioErrors] = useState<any[]>([])
  const [audioFiles, setAudioFiles] = useState<any[]>([])
  const [isaudioLoading, setaudioLoading] = useState(false)
  const [isOkToProceed, setIsOkToProceed] = useState(false)

  const validateaudioFile = (files: any) => {
    setaudioLoading(true)
    const re = /(?:\.([^.]+))?$/
    const _audioFiles = [...audioFiles, ...files]
    _audioFiles.forEach((_file) => {
      const reader = new FileReader()

      const ext = re.exec(_file.name)

      if (ext && ext[0] && alloweAudioExts.includes(ext[0])) {
        reader.addEventListener(
          'load',
          function () {
            const audio = new Audio()
            audio.src = this.result as string
            audio.addEventListener('loadedmetadata', function () {
              const duration = Math.abs(this.duration)
              if (duration > 0.75) {
                setAudioFiles((f) => [...f, _file])
                setIsOkToProceed(true)
              } else {
                setAudioErrors((err) => [...err, _file?.name + ' is not valid'])
                setIsOkToProceed(false)
              }
            })
          },
          false
        )

        reader.readAsDataURL(_file)
      } else {
        setIsOkToProceed(false)
        setAudioErrors((err) => [...err, _file?.name + ' type is not valid'])
      }
    })
  }

  const removeaudioFile = (file: any) => {
    const newFiles = [...audioFiles]
    newFiles.splice(audioFiles.indexOf(file), 1)
    setAudioFiles(newFiles)
    setAudioErrors([])
    setIsOkToProceed(false)
  }

  const updateFiles = (file: File) => {
    const _audioFiles = [...audioFiles, file]
    setAudioFiles(_audioFiles)
  }

  useEffect(() => {
    if (audioFiles.length === metadata.length) {
      setIsOkToProceed(true)
    }
  }, [audioFiles, metadata])

  const ref = createRef() as any

  const openDialog = () => {
    if (ref.current) {
      ref.current.open()
    }
  }

  return (
    <div>
      <div className='w-full p-4 mt-8 border-2 border-dashed border-black/10'>
        {metadata.map((item, i) => (
          <FileUpload
            item={item}
            i={i}
            key={i}
            updateFiles={updateFiles}
            removeFile={removeaudioFile}
          />
        ))}
      </div>

      {/* <Dropzone
        onDrop={(acceptedFiles) => validateaudioFile(acceptedFiles)}
        maxFiles={1}
        noClick
        noKeyboard
        ref={ref}
        accept={{
          'audio/*': ['.wav', '.mp3'],
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <section className='flex items-center justify-center w-full p-4'>
            <div
              {...getRootProps()}
              className='relative p-8 border-2 border-dashed border-black/10 !m-0 flex items-center justify-center flex-col w-full max-w-3xl rounded-lg gap-2'
            >
              <span className='text-xs text-gray-800'>
                All files should be in same order as the metadata file.
              </span>
              <input {...getInputProps()} />
              {audioFiles.length ? (
                <div className='relative flex flex-col items-start justify-start w-full gap-2'>
                  {audioFiles.map((file, i) => (
                    <div
                      className='flex items-center justify-between w-full'
                      key={i}
                    >
                      <ListMusic />
                      <span className='flex flex-1 ml-4 font-semibold'>
                        <span className='max-w-sm truncate'>{file.name}</span>
                      </span>
                      <Button
                        variant='destructive'
                        size='icon'
                        className='self-end'
                        onClick={(e) => {
                          removeaudioFile(file)
                        }}
                      >
                        <Trash2 size={16} />
                      </Button>
                    </div>
                  ))}
                </div>
              ) : (
                <Button variant='ghost' onClick={openDialog}>
                  Drop Audio files here, or click to select files
                </Button>
              )}
            </div>
          </section>
        )}
      </Dropzone> */}
      {audioErrors.length ? (
        <div className='flex flex-col items-center justify-center w-full max-w-3xl mx-auto'>
          <p className='mb-2 font-semibold text-red-600'>
            {t('audioUpload.invalidFiles')}
          </p>
          <div className='grid grid-cols-1 md:grid-cols-2'>
            {audioErrors.map((error, i) => (
              <span key={i} className='text-sm'>
                {error}
              </span>
            ))}
          </div>
        </div>
      ) : null}

      <div className='flex items-center justify-center w-full max-w-3xl mx-auto mt-4 gap-x-2'>
        {/* <Button variant='secondary' onClick={goBack}>
          Back
        </Button> */}
        <Button
          onClick={() => {
            updateData('audio', audioFiles)
          }}
          isDisabled={!isOkToProceed}
          variant='outline'
        >
          {t('audioUpload.nextButton')}
        </Button>
      </div>
    </div>
  )
}

export default AudioUpload
