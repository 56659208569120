export const getHeaders = (
  type?: 'marketing' | 'mastering' | 'neos'
): Headers | undefined => {
  const token =
    type === 'marketing'
      ? localStorage?.getItem('marketing_access_token')
      : type === 'mastering'
      ? localStorage?.getItem('mastering_access_token')
      : type === 'neos'
      ? localStorage?.getItem('neos_access_token')
      : localStorage?.getItem('access_token')
  if (!token && type && !['marketing', 'mastering', 'neos'].includes(type)) {
    localStorage.clear()
    return
  }
  const headers = new Headers()
  headers.append('Authorization', `Bearer ${token}`)
  headers.append('Accept', 'application/json')
  headers.append('Content-Type', 'application/json')

  return headers
}
