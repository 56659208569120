import { cn } from 'lib/utils'
import { useBulkSubmissions } from '../context/BulkSubmissionContext'
import MetadataUpload from './MetadataUpload'
import AudioUpload from './AudioUpload'
import ArtworkUpload from './ArtworkUpload'
import { CircleCheck } from 'lucide-react'
import Agreement from './Agreement'
import { useTranslation } from 'react-i18next'

const Inner = () => {
  const { step, totalSteps } = useBulkSubmissions()
  const { t } = useTranslation()

  const renderStep = () => {
    switch (step) {
      case 1:
        return <MetadataUpload />
      case 2:
        return <AudioUpload />
      case 3:
        return <ArtworkUpload />
      case 4:
        return <Agreement />

      default:
        return <Agreement />
    }
  }

  const renderStepTitle = () => {
    switch (step) {
      case 1:
        return t('innerComponent.uploadMetadata')
      case 2:
        return t('innerComponent.uploadAudioFiles')
      case 3:
        return t('innerComponent.uploadArtworks')
      case 4:
        return t('innerComponent.completeBulkSubmission')

      default:
        return t('innerComponent.uploadMetadata')
    }
  }

  return (
    <>
      <div className='mt-3 md:px-2'>
        <div
          className={cn(
            'flex flex-1 flex-col md:flex-row w-full items-center justify-between',
            step === 4 ? '!flex-row' : ''
          )}
        >
          <h2 className='w-full mr-4 text-lg font-semibold text-nowrap'>
            {renderStepTitle()}
          </h2>
          {step === 4 ? (
            <>
              <CircleCheck className='text-green-500' />
            </>
          ) : (
            <>
              <span className='text-sm font-semibold text-gray-600 whitespace-nowrap'>
                {t('words.step')} {step} {t('words.of')} {totalSteps}
              </span>
              {/* <Progress value={(step * 100) / totalSteps} /> */}
            </>
          )}
        </div>

        {renderStep()}
      </div>
    </>
  )
}

export default Inner
