// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { FieldWrapper } from 'components/shared/FormUtils'
import ImageCropper from 'components/shared/ImageCropper'

import { toast } from 'react-hot-toast'
import { useUI } from 'ctx/UIContext'

import { useCallbackPrompt } from 'hooks/useCallbackPrompt'
import CustomModal from 'components/shared/CustomModal'

import { genresList } from 'data/mock-data'

import FileInput from 'components/shared/FileInput'
import { filterObject } from 'lib/misc'
import { useState, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { useSubmissions, useUser } from 'store/RootStore'
import { OptionProps, components } from 'react-select'
import AsyncSelect from 'react-select/async'
import { Button } from 'components/partials/Button'
import PageHeader from 'components/shared/PageHeader'
import { Input, Label, SelectInput } from 'components/partials/Inputs'
import { cn, debounce, removeSpecialCharacters } from 'lib/utils'
import { uploadToS3 } from 'lib/aws-utils'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import { Link } from 'react-router-dom'
import { InfoIcon } from 'lucide-react'

type FormType = {
  releaseTitle: string
  genre: string
  label: string
  selected_type: string
  artistName: { value: string; identifier?: string }
  sub_genre: string
  status: string
  artwork: File | null
  additional_file: File | null
}

const defaultValues: FormType = {
  releaseTitle: '',
  genre: '',
  label: '',
  selected_type: '',
  artwork: null,
  artistName: { value: '', identifier: '' },
  sub_genre: '',
  status: 'draft',
  additional_file: null,
}

const AddSubmission = () => {
  const { t } = useTranslation()
  const schema = yup.object({
    label: yup.string().required(t('errors.labelRequired')),
    releaseTitle: yup.string().required(t('errors.releaseTitleRequired')),
    artistName: yup.object().shape({
      value: yup.string().required(t('errors.artistNameRequired')),
    }),
    genre: yup.string().required(t('errors.genreRequired')),
    sub_genre: yup.string().required(t('errors.subGenreRequired')),
    selected_type: yup.string().required(t('errors.typeRequired')),
    artwork: yup.mixed().required(t('errors.artworkRequired')),
  })

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setError,
    clearErrors,
    reset,
    control,
    getValues,
    watch,
  } = useForm<FormType>({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  })

  const navigate = useNavigate()
  const { setLoading } = useUI()
  const { createSubmission, submitMeta, getArtists } = useSubmissions()
  const { user } = useUser()
  const [selectedType, setSelectedType] = useState<string>('')
  const [isBlocking, setBlocking] = useState(false)
  const [isSubmitted, setSubmitted] = useState(false)

  const [isSubmitting, setSubmitting] = useState(false)
  const [fileValue, setFileValue] = useState<File | null>(null)

  const [progress, setProgress] = useState(0)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)

  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
    isBlocking && !isSubmitted
  )

  const uploadFiles = async (
    submissionId: string,
    userId: string,
    audioFile?: File,
    artworkFile?: File
  ) => {
    setLoading(true)
    try {
      if (artworkFile) {
        toast('Uploading artwork file', {
          icon: '📤',
        })
        await uploadToS3(artworkFile, userId, submissionId)
        toast.success('Successfully uploaded artwork file')
      }
      if (audioFile) {
        toast('Uploading audio file', {
          icon: '📤',
        })
        await uploadToS3(audioFile, userId, submissionId)
        toast.success('Successfully uploaded audio file')
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const onSubmit = async (data: FormType) => {
    if (!fileValue) {
      setError('artwork', { message: t('errors.artworkRequired') })
      return
    }

    if (!selectedFile) {
      setError('additional_file', { message: t('errors.audioFileRequired') })
      return
    }

    const artworkFile = new File(
      [fileValue],
      removeSpecialCharacters(fileValue.name),
      { type: fileValue.type }
    )
    const audioFile = new File(
      [selectedFile],
      removeSpecialCharacters(selectedFile.name),
      { type: selectedFile.type }
    )

    const submissionData: any = filterObject({
      releaseTitle: data.releaseTitle,
      artistName: data.artistName.value,
      identifier: data.artistName.identifier,
      label: data.label,
      genre: data.genre,
      sub_genre: data.sub_genre,
      selected_type: data.selected_type,
      sub_status: 'draft',
      artwork: artworkFile.name,
      additional_file: audioFile.name,
    })

    setLoading(true)
    setSubmitted(true)

    try {
      const createSubmissionRes = await createSubmission(submissionData)
      if (!createSubmissionRes) return

      const res = createSubmissionRes

      if (!('artwork_s3_url' in res) || !('additional_file_s3_url' in res)) return
      const updateFilesRes = await uploadFiles(
        res.submission_id,
        user.user_id,
        audioFile,
        artworkFile
      )
      toast('Submitting Meta Data')
      const submitMetaRes = await submitMeta({
        submission_id: res.submission_id,
        additional_file: res.additional_file_s3_url,
        artwork: res.artwork_s3_url,
        genre: data.genre,
        sub_genre: data.sub_genre,
      })
      toast.success('Meta Data Submitted')

      if (!submitMetaRes) return

      const type = data.selected_type.toLowerCase().split(' ').join('-')
      const nextUrl = `/submission/${type}/${res.submission_id}`

      toast.success(res.message)
      ReactGA.event({
        category: 'Submission',
        action: 'Create',
      })
      navigate(nextUrl)
    } catch (error) {
      console.log(error)
      setSubmitting(false)
      setLoading(false)
    }
  }

  const cancelSubmission = (e: any) => {
    e.preventDefault()
    navigate('/my-submissions')
  }

  useEffect(() => {
    if (isDirty) {
      if (isSubmitted) {
        setBlocking(false)
      } else {
        setBlocking(true)
      }
    }
    // if ((isDirty || isDirty_2 || isDirty3) && !isSubmitted) {
    //   setBlocking(true)
    // }
  }, [isDirty, isSubmitted])

  const loadArtists = async (inputValue: string) => {
    if (inputValue === '') return []

    return await new Promise<any[]>((resolve) => {
      getArtists(inputValue).then((res) => {
        if (res && 'artists' in res) {
          const _options = res.artists.items?.map((item: any) => ({
            label: item.name,
            value: item.name,
            profile_pic: item.images?.length ? item.images[0].url : '',
            identifier: item.uri,
          }))
          resolve(appendSpecialOption(_options, inputValue))
        }
      })
    })
  }

  const Option = (props: OptionProps<any>) => {
    return (
      <components.Option className='gap-2' {...props}>
        <div className='w-8 h-8 overflow-hidden rounded-full'>
          <img src={props.data.profile_pic} alt='' className='' />
        </div>
        {props.children}
      </components.Option>
    )
  }

  const appendSpecialOption = (filteredOptions: any[], value: string) => {
    const label = `Create "${value}"`
    return [
      { label, value, profile_pic: '/assets/plus.svg', identifier: '' },
      ...filteredOptions,
    ]
  }

  const type = watch('selected_type')

  const renderFileInputs = () => {
    switch (type.toLowerCase()) {
      case 'single':
      case 'album':
        return (
          <>
            <ImageCropper
              register={register}
              label={t('labels.artwork')}
              name='artwork'
              aspectW={1}
              aspectH={1}
              setError={setError}
              setValue={setFileValue}
              errors={errors}
              reset={reset}
              cls='relative w-full md:w-2/3'
              validationRules='1:1 - Minimum 3000 x 3000 px'
              required
              value={fileValue}
            />
            <div className='w-full md:w-1/2'>
              <FileInput
                register={register}
                setError={setError}
                label={t('labels.uploadAudio')}
                name='additional_file'
                helperText='FLAC / WAV Files only'
                clearErrors={clearErrors}
                errors={errors}
                accept={['.flac', '.wav']}
                setValue={setSelectedFile}
                fileValue={selectedFile}
                isLoading={false}
                progress={progress}
              />
            </div>
          </>
        )
      case 'music video':
        return (
          <>
            <ImageCropper
              register={register}
              label={t('labels.videoThumbnail')}
              name='artwork'
              aspectW={16}
              aspectH={9}
              setError={setError}
              setValue={setFileValue}
              errors={errors}
              reset={reset}
              cls='relative w-full md:w-2/3'
              validationRules='1:1 - Minimum 3000 x 3000 px'
              required
              value={fileValue}
            />
            <div className='w-full md:w-1/2'>
              <FileInput
                register={register}
                setError={setError}
                label={t('labels.uploadVideo')}
                name='additional_file'
                helperText='16:9 - mp4 / mov files only'
                errors={errors}
                clearErrors={clearErrors}
                accept={['.mp4', '.mov']}
                setValue={setSelectedFile}
                fileValue={selectedFile}
                isLoading={false}
                progress={progress}
              />
            </div>
          </>
        )

      default:
        return (
          <>
            <ImageCropper
              register={register}
              label={t('labels.artwork')}
              name='artwork'
              aspectW={1}
              aspectH={1}
              setError={setError}
              setValue={setFileValue}
              errors={errors}
              reset={reset}
              cls='relative w-full md:w-2/3'
              validationRules='1:1 - Minimum 3000 x 3000 px'
              required
              value={fileValue}
            />
            <div className='w-full md:w-1/2'>
              <FileInput
                register={register}
                setError={setError}
                label={t('labels.uploadAudio')}
                name='additional_file'
                helperText='FLAC / WAV Files only'
                errors={errors}
                accept={['.flac', '.wav']}
                clearErrors={clearErrors}
                setValue={setSelectedFile}
                fileValue={selectedFile}
                isLoading={false}
                progress={progress}
              />
            </div>
          </>
        )
    }
  }

  return (
    <>
      <CustomModal
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        isOpen={showPrompt}
        close={cancelNavigation}
        action={confirmNavigation}
        title={t('messages.leavePagePrompt.title')}
      >
        <p className='text-sm text-center text-gray-500'>
          {t('messages.leavePage')}
        </p>
      </CustomModal>

      <div className='page-wrapper '>
        <form
          className='flex flex-col items-center justify-center bg-white rounded-lg'
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <PageHeader
            title={t('buttons.newSubmission')}
            hasCreateSubmissionTrigger={false}
            additionalElements={
              <div className='relative flex flex-row items-center justify-center gap-1 w-max'>
                <Button type='submit' isLoading={isSubmitting}>
                  {isSubmitting
                    ? t('messages.creatingSubmission')
                    : t('buttons.createSubmission')}
                </Button>
                <Button variant='outline' onClick={cancelSubmission}>
                  {t('buttons.cancel')}
                </Button>
              </div>
            }
            className='bg-white'
          />

          <div className='w-full p-4 mt-5'>
            <div className='grid justify-between w-full grid-cols-1 gap-4 mb-10 md:grid-cols-2 lg:grid-cols-3'>
              <Input
                label={t('pageHeader.releaseTitle')}
                placeholder={t('pageHeader.releaseTitle')}
                name='releaseTitle'
                register={register}
                error={errors?.releaseTitle?.message as string}
              />
              <SelectInput
                control={control}
                options={[
                  { label: 'Single', value: 'Single' },
                  { label: 'Album', value: 'Album' },
                  // { label: 'Music Video', value: 'Music Video' },
                ]}
                label={t('labels.type')}
                placeholder={t('labels.selectType')}
                name='selected_type'
                error={errors?.selected_type?.message as string}
              />

              <FieldWrapper>
                <Label htmlFor='artistName'>
                  {t('labels.artistName')}{' '}
                  {errors['artistName'] ? (
                    <span className={cn('text-xs text-red-500 ml-1')}>
                      * {t('errors.artistNameRequired')}
                    </span>
                  ) : null}
                </Label>

                <Controller
                  control={control}
                  name='artistName'
                  rules={{ required: true }}
                  render={({ field: { onChange, value, ref } }) => {
                    return (
                      <AsyncSelect
                        className='input !p-0'
                        classNamePrefix='select'
                        value={value ? value : []}
                        onChange={onChange}
                        ref={ref}
                        isLoading={false}
                        isClearable
                        isSearchable
                        options={[]}
                        defaultOptions
                        noOptionsMessage={() => 'Type to search artists'}
                        cacheOptions
                        loadOptions={debounce(loadArtists, 500)}
                        // menuIsOpen
                        components={{
                          Option,
                        }}
                      />
                    )
                  }}
                />
              </FieldWrapper>

              <Input
                label={t('labels.label')}
                placeholder={t('labels.label')}
                name='label'
                register={register}
                error={errors?.label?.message as string}
              />

              <SelectInput
                control={control}
                options={
                  genresList &&
                  genresList.map((genre) => ({
                    label: genre.name,
                    value: genre.name,
                  }))
                }
                label={t('labels.genre')}
                placeholder={t('labels.selectGenre')}
                name='genre'
                error={errors?.genre?.message as string}
              />
              <SelectInput
                control={control}
                options={
                  genresList &&
                  genresList.map((genre) => ({
                    label: genre.name,
                    value: genre.name,
                  }))
                }
                label={t('labels.subGenre')}
                placeholder={t('labels.selectSubGenre')}
                name='sub_genre'
                error={errors?.sub_genre?.message as string}
              />

              <div className='flex items-center gap-2 p-4 px-6 rounded-md bg-primary-purple/5'>
                <InfoIcon className='size-5 text-primary-purple' />{' '}
                <div>
                  {t('labels.checkArtworkInfo')}{' '}
                  <Link
                    to={'/artwork-generator'}
                    className='font-semibold transition-all text-primary-purple hover:text-primary-purple/80'
                  >
                    {t('words.here')}
                  </Link>{' '}
                  {t('labels.checkArtworkInfo2')}
                </div>
              </div>

              <div className='relative flex flex-col items-start justify-center gap-1 col-span-full md:flex-row md:gap-2'>
                {renderFileInputs()}
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default AddSubmission
