import React, { useState, useEffect } from 'react'
import { useCampaign } from 'ctx/CampaignContext'
import { Card } from 'components/partials/Card'
import { useMarketing, useSpotify } from 'store/RootStore'
import toast from 'react-hot-toast'
import { CircleCheck, CircleX } from 'lucide-react'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/partials/Select'
import { PageLoading } from 'components/layout/LoadingScreen'
import { Button } from 'components/partials/Button'
import { useNavigate, useSearchParams } from 'react-router-dom'

const CampaignConnect: React.FC<{
  onValidityChange: (isValid: boolean) => void
}> = ({ onValidityChange }) => {
  const { config, setConfig } = useCampaign()
  const [searchParams] = useSearchParams()
  const { getPageId, getAdAccountId, getInstagramBusinessId, getInstagramUser } =
    useMarketing()
  const { handleAuthorize, handleCallback, getUserProfile, handleRefresh } =
    useSpotify()
  const [pages, setPages] = useState<any[] | null>(null)
  const [adAccounts, setAdAccounts] = useState<any[] | null>(null)
  const [facebookPage, setFacebookPage] = useState(config.facebookPage || null)
  const [adAccount, setAdAccount] = useState(config.adAccount || null)
  const [instagramUser, setInstagramUser] = useState(config.instagramUser || null)
  const [spotifyUser, setSpotifyUser] = useState(config.spotifyUser || null)
  const [instaLoading, setInstaLoading] = useState(false)

  useEffect(() => {
    const code = searchParams.get('code')
    const spotifyToken = localStorage.getItem('spotify_access_token')

    const handleSpotifyAuth = async () => {
      // Check if authorization code is present
      if (code) {
        try {
          // Handle the callback to exchange the code for tokens
          const response = await handleCallback(code)

          // Handle error in response
          if ('error' in response) {
            toast.error(response.error_description)
          } else {
            // Store access and refresh tokens in localStorage
            const { access_token: accessToken, refresh_token: refreshToken } =
              response
            localStorage.setItem('spotify_access_token', accessToken)
            localStorage.setItem('spotify_refresh_token', refreshToken)

            // Fetch the user profile with the new access token
            const userProfile = await getUserProfile(accessToken)

            // Handle error in user profile response
            if ('detail' in userProfile || 'error' in userProfile) {
              toast.error(
                userProfile.detail || 'Failed to retrieve Spotify user profile'
              )
            } else {
              setSpotifyUser(userProfile)
            }
          }
        } catch (error: any) {
          toast.error(`Spotify Authorization Failed: ${error.message}`)
        }
      }
      // If no authorization code but there's a stored token, fetch user profile
      else if (spotifyToken) {
        try {
          let userProfile = await getUserProfile(spotifyToken)

          // If the profile response contains an error (e.g., expired token)
          if ('detail' in userProfile) {
            try {
              // Attempt to refresh the token
              const refreshResponse = await handleRefresh()

              // Handle error in refresh token response
              if ('error' in refreshResponse) {
                toast.error(refreshResponse.error_description)
              } else {
                // Store the new access token and fetch the user profile again
                const refreshedToken = refreshResponse.access_token
                localStorage.setItem('spotify_access_token', refreshedToken)
                userProfile = await getUserProfile(refreshedToken)

                // Handle error in user profile response
                if ('detail' in userProfile) {
                  toast.error(
                    userProfile.detail || 'Failed to retrieve Spotify user profile'
                  )
                } else {
                  setSpotifyUser(userProfile)
                }
              }
            } catch (refreshError: any) {
              toast.error(`Failed to refresh Spotify token: ${refreshError.message}`)
            }
          } else {
            setSpotifyUser(userProfile)
          }
        } catch (error: any) {
          toast.error(`Failed to fetch Spotify user profile: ${error.message}`)
        }
      }
    }

    if (!spotifyUser?.id) {
      handleSpotifyAuth()
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const pageRes = await getPageId()
        if (pageRes.detail) {
          throw new Error(pageRes.detail)
        }
        setPages(pageRes.data)
      } catch (error: any) {
        toast.error(`Error fetching page data: ${error.message}`)
      }

      try {
        const adRes = await getAdAccountId()
        if (adRes.detail) {
          throw new Error(adRes.detail)
        }
        setAdAccounts(adRes.data)
      } catch (error: any) {
        toast.error(`Error fetching ad accounts data: ${error.message}`)
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    setConfig({
      ...config,
      facebookPage,
      adAccount,
      instagramUser,
      spotifyUser,
    })

    const isValid = !!(adAccount && (facebookPage || instagramUser))
    onValidityChange(isValid)
  }, [facebookPage, adAccount, instagramUser, spotifyUser, onValidityChange])

  const handlePageSelect = async (selectedPage: any) => {
    setFacebookPage(selectedPage)
    setInstaLoading(true)
    const instagramRes = await getInstagramBusinessId(selectedPage.id)

    if (instagramRes.instagram_business_account?.id) {
      const instagramUser = await getInstagramUser(
        instagramRes.instagram_business_account.id
      )
      setInstagramUser(instagramUser)
    } else {
      setInstagramUser(null)
    }
    setInstaLoading(false)
  }

  const handleSpotifyAuth = async () => {
    try {
      const url = await handleAuthorize()
      if ('detail' in url) {
        toast.error(url.detail)
        throw new Error(url.detail)
      } else {
        window.location.href = url.auth_url
      }
    } catch (error: any) {
      toast.error(`Spotify Authorization Failed: ${error.message}`)
    }
  }

  return (
    <div className='mt-6'>
      <Card className='p-4 mt-4 rounded-lg'>
        <div>
          <h2 className='mb-1 font-bold'>Connect Accounts</h2>
          <p className='max-w-lg text-sm md:text-base'>
            To create the most optimal audiences for your campaign, we'll need to
            plug-in with your Facebook & Instagram account.
          </p>
        </div>
        <div className='my-4 border-b' />
        <div className='flex flex-col gap-4 md:flex-row md:gap-10 md:justify-between md:items-center'>
          <div>
            <h2 className='mb-1 font-bold'>Advertising Account: </h2>
            <p className='max-w-lg text-sm md:text-base'>
              Run MusicDASH marketing using your Facebook Ad Account
            </p>
          </div>
          <div className='w-full md:max-w-xs'>
            {adAccounts && adAccounts.length > 0 ? (
              <Select
                onValueChange={(value) =>
                  setAdAccount(adAccounts?.find((acc) => acc.id === value) || null)
                }
                value={adAccount?.id || ''}
              >
                <SelectTrigger className='border rounded-lg focus:outline'>
                  <SelectValue placeholder={'Select Ad Account'} />
                </SelectTrigger>
                <SelectContent>
                  {adAccounts.map((opt, i) => (
                    <SelectItem key={i} value={opt.id}>
                      {opt.account_id}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            ) : (
              <PageLoading className='flex items-center justify-center h-full' />
            )}
          </div>
        </div>
        <div className='my-4 border-b' />
        <div className='flex flex-col gap-4 md:flex-row md:gap-10 md:justify-between md:items-center'>
          <div>
            <h2 className='mb-1 font-bold'>Pages: </h2>
            <p className='max-w-lg text-sm md:text-base'>
              Your ads will run through your linked pages, to improve targeting and
              grow followers
            </p>
          </div>
          <div className='w-full md:max-w-xs'>
            {pages && pages.length > 0 ? (
              <Select
                onValueChange={(value) =>
                  handlePageSelect(pages?.find((pg) => pg.id === value) || null)
                }
                value={config.facebookPage?.id || ''}
              >
                <SelectTrigger className='border rounded-lg focus:outline'>
                  <SelectValue placeholder={'Select Page'} />
                </SelectTrigger>
                <SelectContent>
                  {pages.map((opt, i) => (
                    <SelectItem key={i} value={opt.id}>
                      {opt.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            ) : (
              <PageLoading className='flex items-center justify-center h-full' />
            )}
          </div>
        </div>
        {/* <div className='my-4 border-b' />
        <div className='flex flex-col gap-4 md:flex-row md:gap-10 md:justify-between md:items-center'>
          <div>
            <h2 className='mb-1 font-bold'>Spotify Account: </h2>
            <p className='max-w-lg text-sm md:text-base'>
              You can boost spotify songs to improve targeting and grow followers
            </p>
          </div>
          <div className='flex justify-end w-full md:max-w-xs'>
            <Button disabled={!!spotifyUser?.id} onClick={handleSpotifyAuth}>
              {!spotifyUser?.id ? 'Connect to Spotify' : 'Connected'}
            </Button>
          </div>
        </div> */}
        <div className='my-4 border-b' />
        <h2 className='mb-4 font-bold'>Connected Accounts</h2>
        <div className='grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4'>
          <PageCard
            account='FB Ad Account'
            page={adAccount}
            image='/assets/facebook-logo.png'
            isConnected={!!adAccount?.id}
          />
          <PageCard
            account='Facebook Page'
            page={facebookPage}
            image='/assets/facebook-logo.png'
            isConnected={!!facebookPage?.name}
          />
          <PageCard
            account='Instagram Page'
            page={instagramUser}
            image='/assets/instagram-logo.png'
            isConnected={!!instagramUser?.id}
            isLoading={instaLoading}
          />
          {/* <PageCard
            account='Spotify Account'
            page={spotifyUser}
            image='/assets/spotify-logo.png'
            isConnected={!!spotifyUser?.id}
          /> */}
        </div>
      </Card>
    </div>
  )
}

export default CampaignConnect

const PageCard = ({
  account,
  page,
  image,
  isConnected,
  isLoading,
}: {
  account: string
  page: any
  image: string
  followers?: string
  isConnected: boolean
  isLoading?: boolean
}) => {
  return (
    <Card className='flex flex-col items-center w-full p-5 space-y-4 rounded-md'>
      <div className='relative flex items-center gap-2'>
        <img src={image} alt={account} className='rounded-sm size-10' />
        <h2 className='font-bold'>{account}</h2>
      </div>
      <div className='flex items-center gap-2'>
        {isConnected ? (
          <>
            <p>Connected</p>
            <CircleCheck className='text-white fill-green-500 size-5' />
          </>
        ) : isLoading ? (
          <PageLoading className='flex items-center justify-center h-full' />
        ) : (
          <>
            <p>Not Connected</p>
            <CircleX className='text-white fill-red-500 size-5' />
          </>
        )}
      </div>
      <p className='capitalize'>
        {page?.display_name || page?.name || page?.username || page?.id}
      </p>
    </Card>
  )
}
