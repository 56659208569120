// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import { v4 as uuid } from 'uuid'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { FieldWrapper, Error } from 'components/shared/FormUtils'
import { useForm } from 'react-hook-form'
// import Select from 'react-select'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import { useUI } from 'ctx/UIContext'
import axios from 'axios'

import { useCallbackPrompt } from 'hooks/useCallbackPrompt'
import CustomModal from 'components/shared/CustomModal'
import { GoogleIcon, LoadingIcon } from 'components/shared/Icons'
import { useGoogleLogin, googleLogout } from '@react-oauth/google'
import PageHeader from 'components/shared/PageHeader'
import { useTranslation } from 'react-i18next'

const schema = yup.object({
  artistName: yup.string().required('Artist Name is required'),
  channelName: yup.string().required('Channel name is required'),
  channelGenre: yup.string().required('Channel Genre is required'),
  googleAccount: yup.string().required('Google Account is required').email(),
})

const defaultValues = {
  artistName: '',
  // synopsis: '',
  // genre: '',
  // type: 'film',
  // portrait_3_4: '',
}

const allowedExts = ['jpeg', 'png', 'jpg']

const CreateYoutubeChannel = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    control,
    setError,
    getValues,
    watch,
    reset,
    setValue,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      channelName: '',
      artistName: '',
      googleAccount: '',
      channelGenre: '',
    },
  })

  const { t } = useTranslation()
  const navigate = useNavigate()
  const { setLoading } = useUI()
  const [account, setAccount] = useState<any>(null)
  const [isBlocking, setBlocking] = useState(false)
  const [isSubmitted, setSubmitted] = useState(false)
  const [metaData, setMetaData] = useState<any>([])
  const [isSubmitting, setSubmitting] = useState(false)
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
    isBlocking && !isSubmitted
  )

  const onSubmit = (data: any) => {
    const _data = {
      ...data,
      id: uuid(),
    }

    toast.success(t('toasts.channelCreated'))

    reset()
    setAccount(null)
  }

  const cancelSubmission = (e: any) => {
    e.preventDefault()
    navigate('/my-submissions')
  }

  useEffect(() => {
    if (isDirty && !isSubmitted) {
      setBlocking(true)
    }
  }, [isDirty, isSubmitted])

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      axios
        .get('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: {
            Authorization: `Bearer ${tokenResponse.access_token}`,
          },
        })
        .then((res) => {
          setValue('googleAccount', res.data.email)
          setAccount(res.data)
        })
    },
  })

  return (
    <>
      <CustomModal
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        isOpen={showPrompt}
        close={cancelNavigation}
        action={confirmNavigation}
        title='Leave page'
      >
        <p className='text-sm text-center text-gray-500'>
          {t('messages.leavePage')}
        </p>
      </CustomModal>

      <div className='page-wrapper'>
        <form
          className='flex flex-col items-center justify-center'
          onSubmit={handleSubmit(onSubmit)}
        >
          <PageHeader
            title={t('labels.createYTChannel')}
            hasCreateSubmissionTrigger={false}
            additionalElements={
              <div className='relative items-center justify-center hidden gap-1 w-max md:flex'>
                <button
                  type='submit'
                  className={[
                    'button primary flex items-center justify-center gap-1',
                    isSubmitting ? 'disabled' : '',
                  ].join(' ')}
                >
                  {isSubmitting ? 'creating channel...' : 'create channel'}
                  {isSubmitting ? (
                    <span className='inline-block text-xl animate-spin'>
                      <LoadingIcon />
                    </span>
                  ) : null}
                </button>
                <button className='button muted' onClick={cancelSubmission}>
                  {t('buttons.cancel')}
                </button>
              </div>
            }
          />

          <div className='w-full p-4 mt-5'>
            {account ? (
              <button
                className='button muted flex items-center justify-center gap-2 !rounded-full'
                type='button'
                onClick={() => {
                  googleLogout()
                  setAccount(null)
                }}
              >
                <span className='text-3xl'>
                  <GoogleIcon />
                </span>
                Logout: {account?.name}
              </button>
            ) : (
              <button
                className='button muted flex items-center justify-center gap-2 !rounded-full'
                onClick={() => login()}
                type='button'
              >
                <span className='text-3xl'>
                  <GoogleIcon />
                </span>
                Connect Google Account
              </button>
            )}
            <div className='grid justify-between w-full grid-cols-1 gap-4 mt-10 md:grid-cols-2 lg:grid-cols-3'>
              <FieldWrapper>
                <label htmlFor='channelName'>
                  Channel Name{' '}
                  {errors['channelName'] ? (
                    <Error>{errors?.channelName?.message?.toString()}</Error>
                  ) : null}
                </label>
                <input
                  className='input'
                  type='text'
                  placeholder='Channel Name'
                  {...register('channelName')}
                />
              </FieldWrapper>
              <FieldWrapper>
                <label htmlFor='channelGenre'>
                  Channel Genre{' '}
                  {errors['channelGenre'] ? (
                    <Error>{errors?.channelGenre?.message?.toString()}</Error>
                  ) : null}
                </label>
                <input
                  className='input'
                  type='text'
                  placeholder='Channel Genre'
                  {...register('channelGenre')}
                />
              </FieldWrapper>
              <FieldWrapper>
                <label htmlFor='artistName'>
                  Artist Name{' '}
                  {errors['artistName'] ? (
                    <Error>{errors?.artistName?.message?.toString()}</Error>
                  ) : null}
                </label>
                <input
                  className='input'
                  type='text'
                  placeholder='Artist Name'
                  {...register('artistName')}
                />
              </FieldWrapper>

              {/* <InputFile
                fieldName='googleAccount'
                labelName={'Google Account'}
                register={register}
                errorName={errors?.googleAccount?.message?.toString()}
                readOnly={true}
              /> */}
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default CreateYoutubeChannel
