import { useUI } from 'ctx/UIContext'
import { lsget } from 'lib/misc'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useArtwork } from 'store/RootStore'

export default function useArtworkTool(): {
  generateAccessToken: () => Promise<void>
  accessToken: string
} {
  const navigate = useNavigate()
  const { setLoading } = useUI()
  const { getAccessToken } = useArtwork()
  const { t } = useTranslation()

  const accessToken = lsget('neos_access_token')

  const generateAccessToken = async (): Promise<void> => {
    setLoading(true)

    try {
      const _token: any = await getAccessToken()

      if (!('access_token' in _token)) {
        throw new Error("Couldn't login")
      }

      localStorage.setItem('neos_access_token', _token.access_token)
    } catch (error: any) {
      if ('message' in error) {
        console.error(error)
        toast.error(t('artworkGenerator.unavailable'))
        navigate('/')
      }
    } finally {
      setLoading(false)
    }
  }

  return {
    generateAccessToken,
    accessToken,
  }
}

export {}
