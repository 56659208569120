import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import yupPassword from 'yup-password'

import AuthLayout from 'components/layout/AuthLayout'
import { FieldWrapper, Error } from 'components/shared/FormUtils'
import { useAuth } from 'ctx/AuthContext'
import { EyeClosedIcon, EyeOpenIcon } from 'components/shared/Icons'
import { listUsers } from 'lib/aws-config'
import { useTranslation } from 'react-i18next'
import { useUI } from 'ctx/UIContext'

const defaultValues = { username: '', password: '' }

yupPassword(yup)

const emailString = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i

const Login = () => {
  const auth = useAuth()
  const { t } = useTranslation()
  const { whiteLabelData } = useUI()
  const [isPasswordVisible, setPasswordVisible] = useState(false)

  const schema = yup.object().shape({
    username: yup.string().required(t('auth.confirm.username.required')),
    password: yup.string().required(t('auth.errors.passRequired')),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema),
  })

  const onSubmit = (data: any) => {
    auth?.login(data.username, data.password).then(() => {
      reset(defaultValues)
    })
  }

  // useEffect(() => {
  //   listUsers()
  // }, [])

  const togglePasswordVisible = () => setPasswordVisible(!isPasswordVisible)

  return (
    <AuthLayout>
      <form
        className='form justify-center !h-max !p-0'
        onSubmit={handleSubmit(onSubmit)}
      >
        <FieldWrapper>
          <label htmlFor='username'>
            {t('auth.confirm.username.title')}{' '}
            {errors['username'] ? (
              <Error>
                <span className='inline-block mt-0.5'>
                  {errors?.username?.message?.toString()}
                </span>
              </Error>
            ) : null}
          </label>
          <input
            className='input'
            type='text'
            placeholder={'Username'}
            {...register('username')}
          />
        </FieldWrapper>
        <FieldWrapper>
          <label htmlFor='password'>
            {t('auth.password.title')}{' '}
            {errors['password'] ? (
              <Error>
                <span className='inline-block mt-0.5'>
                  {errors.password.message?.toString()}
                </span>
              </Error>
            ) : null}
          </label>
          <div className='flex items-start justify-between w-full'>
            <input
              className='input'
              type={isPasswordVisible ? 'text' : 'password'}
              placeholder='Min 8 characters'
              {...register('password')}
            />
            <span
              className='inline-block pt-4 pl-2 text-sm text-primary-purple'
              aria-hidden
              onClick={togglePasswordVisible}
            >
              {isPasswordVisible ? <EyeOpenIcon /> : <EyeClosedIcon />}
            </span>
          </div>
        </FieldWrapper>
        <div className='relative flex items-center justify-between my-2'>
          <div className='flex items-center justify-start'>
            <input type='checkbox' name='' id='' className='!m-0' defaultChecked />
            <span className='ml-2 text-sm text-gray-600 whitespace-nowrap'>
              {t('authLayout.login.keepLogged')}
            </span>
          </div>
          <Link to='/forgot-password' className='text-sm underline'>
            {t('authLayout.forgotPassword.title')}
          </Link>
        </div>
        <button
          className={`mt-2 button ${
            !whiteLabelData.theme ? 'primary' : 'hover:opacity-80'
          }`}
          style={{
            backgroundColor: whiteLabelData?.theme?.[2] ?? '',
            color: whiteLabelData?.theme?.[1] ?? '',
          }}
          type='submit'
        >
          {t('authLayout.login.plain')}
        </button>
        <div className='relative flex items-center justify-start my-2'>
          <span className='flex items-center justify-start text-sm'>
            {t('authLayout.notRegisteredYet')}
          </span>
        </div>
        <button
          className={`button ${
            !whiteLabelData.theme ? 'primary' : 'hover:opacity-80'
          }`}
          style={{
            backgroundColor: whiteLabelData?.theme?.[0] ?? '',
            color: whiteLabelData?.theme?.[1] ?? '',
          }}
        >
          <Link to='/signup'>{t('authLayout.createAccount')}</Link>
        </button>
      </form>
    </AuthLayout>
  )
}

export default Login
