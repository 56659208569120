import useMarketingTool from 'hooks/useMarketingTool'
import { useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { getHeaders } from 'lib/apiHelpers'
import {
  API_URL,
  MARKETING_API_URL,
  MASTERING_API_URL,
  NEOS_API_URL,
} from 'lib/constants'
import { filterObject, lsget } from 'lib/misc'

const browser = typeof window !== 'undefined'

type Submission = any
type User = any

const submissionsAtom = atomWithStorage<Submission[]>('submissions', [])
const userAtom = atomWithStorage<User>('user', {})

const APIRequest = async (url: string, options: RequestInit) =>
  await fetch(`${url}`, options)
    .then((res) => res.json())
    .catch((err) => {
      if (err && err.message && err.message?.toLowerCase() === 'failed to fetch') {
        // localStorage.clear()
      }
    })

export function useSubmissions() {
  const [submissions, setSubmissions] = useAtom(submissionsAtom)

  const { user } = useUser()

  const createSubmission = async (data: any) => {
    const headers = getHeaders()

    if (!headers) return

    const requestOptions: RequestInit = {
      method: 'POST',
      headers,
      cache: 'no-cache',
    }

    const url = new URLSearchParams(data).toString()

    return await APIRequest(`${API_URL}submissions?${url}`, requestOptions)
  }

  const updateSubmission = async (data: any) => {
    const headers = getHeaders()

    if (!headers) return

    const requestOptions: RequestInit = {
      method: 'PUT',
      headers,
      redirect: 'follow',
      cache: 'no-cache',
    }

    const url = new URLSearchParams(data).toString()
    return await APIRequest(`${API_URL}update_submissions?${url}`, requestOptions)
  }

  const getSubmissions = async () => {
    const headers = getHeaders()

    if (!headers) return
    if (!user || !user.user_id) return

    const requestOptions: RequestInit = {
      method: 'GET',
      headers,
      redirect: 'follow',
      cache: 'no-cache',
    }

    const url = `${API_URL}get_submissions?user_id=${user.user_id}`

    return await APIRequest(url, requestOptions)
  }

  const getSubmission = async (sub_id: string) => {
    const headers = getHeaders()

    if (!headers) return

    // setLoading(true)
    const requestOptions: RequestInit = {
      method: 'GET',
      headers,
      redirect: 'follow',
      cache: 'no-cache',
    }

    return await APIRequest(
      `${API_URL}get_submissions_byid/${sub_id}`,
      requestOptions
    )
  }

  const updateSubmissions = async () => {
    getSubmissions()
  }

  const deleteSubmission = async (id: string) => {
    const headers = getHeaders()

    if (!headers) return

    const requestOptions: RequestInit = {
      method: 'DELETE',
      headers,
      redirect: 'follow',
      cache: 'no-cache',
    }

    return await APIRequest(
      `${API_URL}delete_submissions?submission_id=${id}`,
      requestOptions
    )
  }

  const getMetadata = async (id: string) => {
    const headers = getHeaders()

    if (!headers) return
    const requestOptions: RequestInit = {
      method: 'GET',
      headers,
      redirect: 'follow',
      cache: 'no-cache',
    }

    return await APIRequest(
      `${API_URL}get_meta_submissions?submission_id=${id}`,
      requestOptions
    )
  }

  const submitMeta = async (data: any) => {
    const headers = getHeaders()

    if (!headers) return

    const requestOptions: RequestInit = {
      method: 'POST',
      headers,
      // body: raw,
      redirect: 'follow',
      cache: 'no-cache',
    }

    const _data: any = filterObject({
      additional_file: data.additional_file,
      artwork: data.artwork,
      submission_id: data.submission_id,
      version: data.version,
      genre: data.genre,
      trackTitle: data.trackTitle,
      sub_genre: data.sub_genre,
      lyrics: data.lyrics,
      lyricsLanguage: data.lyricsLanguage,
      titleLanguage: data.titleLanguage,
      parentalAdvisory: data.parentalAdvisory,
      digitalReleaseDate: data.digitalReleaseDate,
      preview_start: data.preview_start,
      lyrics_writer: data.lyrics_writer,
      composer: data.composer,
      album: data.selected_album,
    })

    const url = new URLSearchParams(_data).toString()

    return await APIRequest(`${API_URL}meta_data_submissions?${url}`, requestOptions)
  }
  const updateMeta = async (data: any) => {
    const headers = getHeaders()

    if (!headers) return

    const requestOptions: RequestInit = {
      method: 'PUT',
      headers,
      // body: raw,
      redirect: 'follow',
      cache: 'no-cache',
    }

    const _data: any = filterObject({
      additional_file: data.additional_file,
      artwork: data.artwork,
      submission_id: data.submission_id,
      version: data.version,
      genre: data.genre,
      trackTitle: data.trackTitle,
      sub_genre: data.sub_genre,
      lyrics: data.lyrics,
      lyricsLanguage: data.lyricsLanguage,
      titleLanguage: data.titleLanguage,
      parentalAdvisory: data.parentalAdvisory,
      digitalReleaseDate: data.digitalReleaseDate,
      preview_start: data.preview_start,
      lyrics_writer: data.lyrics_writer,
      composer: data.composer,
      album: data.selected_album,
    })

    const url = new URLSearchParams(_data).toString()

    return await APIRequest(`${API_URL}update_metadata?${url}`, requestOptions)
  }

  const submitForReview = async (submission_id: any, status: string) => {
    const headers = getHeaders()

    const requestOptions: RequestInit = {
      method: 'PUT',
      headers,
      // body: JSON.stringify(),
      redirect: 'follow',
      cache: 'no-cache',
    }
    const url = new URLSearchParams({
      submission_id,
      sub_status: status,
    }).toString()

    return await APIRequest(`${API_URL}update_status?${url}`, requestOptions)
  }

  const validateSubmission = async (submission_id: string) => {
    const headers = getHeaders()

    const requestOptions: RequestInit = {
      method: 'POST',
      headers,
      redirect: 'follow',
      cache: 'no-cache',
    }

    return await APIRequest(
      `${API_URL}send_to_review?submission_id=${submission_id}`,
      requestOptions
    )
  }

  const getAlbums = async () => {
    const headers = getHeaders()

    if (!headers) return
    const requestOptions: RequestInit = {
      method: 'GET',
      headers,
      redirect: 'follow',
      cache: 'no-cache',
    }
    return await APIRequest(`${API_URL}get_album`, requestOptions)
  }

  const createAlbum = async (Album_name: string, label: string) => {
    const headers = getHeaders()

    if (!headers) return

    const requestOptions: RequestInit = {
      method: 'POST',
      headers,
      // body: raw,
      redirect: 'follow',
      cache: 'no-cache',
    }

    const _data: any = {
      Album_name,
      label,
    }

    const url = new URLSearchParams(_data).toString()

    // const url = `submission_id=${data.submission_id}&trackTitle=${data.trackTitle}&version=${data.version}&lyricsLanguage=${data.lyricsLanguage}&genre=${data.genre}&sub_genre=${data.sub_genre}&titleLanguage=${data.titleLanguage}&lyrics=${data.lyrics}&parentalAdvisory=${data.parentalAdvisory}&digitalReleaseDate=${data.digitalReleaseDate}&preview_start=${data.preview_start}&artwork=${data.artWork}&additional_file=${data.file}&lyrics_writer=${data.lyrics_writer}&composer=${data.composer}`

    // return await fetch(`${API_URL}create_album?${url}`, requestOptions)
    //   .then((res) => res.json())
    //   .catch((err) => {
    //     console.error(err)
    //     navigate('/my-submissions')
    //   })
    return await APIRequest(`${API_URL}create_album?${url}`, requestOptions)
  }

  const getDSPS = async (sub_id: string) => {
    const headers = getHeaders()

    if (!headers) return
    const requestOptions: RequestInit = {
      method: 'GET',
      headers,
      redirect: 'follow',
      cache: 'no-cache',
    }

    return await APIRequest(
      `${API_URL}get_dsp?submission_id=${sub_id}`,
      requestOptions
    )
  }

  const addDSPS = async (sub_id: string, dsp_ids: string, dsp_names: string) => {
    const headers = getHeaders()

    if (!headers) return

    const requestOptions: RequestInit = {
      method: 'POST',
      headers,
      redirect: 'follow',
      cache: 'no-cache',
    }

    const _data: any = {
      submission_id: sub_id,
      dsp_ids,
      dsp_names,
    }

    const url = new URLSearchParams(_data).toString()
    return await APIRequest(`${API_URL}add_dsp?${url}`, requestOptions)
  }

  const bulkSubmissions = async (data: string) => {
    const headers = getHeaders()

    if (!headers) return

    const requestOptions: RequestInit = {
      method: 'POST',
      headers,
      body: data,
      redirect: 'follow',
      cache: 'no-cache',
    }

    return await APIRequest(`${API_URL}v2/bulk_submission`, requestOptions)
  }

  const getArtists = async (name: string) => {
    // const headers = getHeaders()

    // if (!headers) return
    const requestOptions: RequestInit = {
      method: 'GET',
      // headers,
      redirect: 'follow',
      cache: 'no-cache',
    }
    return await APIRequest(
      `${API_URL}get_spotify_artist?track_artist=${name}`,
      requestOptions
    )
  }

  return {
    submissions,
    createSubmission,
    updateSubmission,
    getSubmissions,
    getSubmission,
    updateSubmissions,
    deleteSubmission,
    setSubmissions,
    getMetadata,
    submitMeta,
    updateMeta,
    submitForReview,
    getAlbums,
    createAlbum,
    bulkSubmissions,
    addDSPS,
    getDSPS,
    getArtists,
    validateSubmission,
  }
}

export function useUser() {
  const [user, setUser] = useAtom(userAtom)

  const getUser = async () => {
    const headers = getHeaders()
    if (!headers) return

    const requestOptions: RequestInit = {
      method: 'GET',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
    }

    return await APIRequest(`${API_URL}get_userid`, requestOptions)
  }

  const updateUser = async (data: any) => {
    const headers = getHeaders()

    if (!headers) return
    if (!user && !user.user_id) return

    const requestOptions: RequestInit = {
      method: 'PUT',
      headers,
      body: JSON.stringify(data),
      redirect: 'follow',
      cache: 'no-cache',
    }

    return await APIRequest(`${API_URL}update_user`, requestOptions)
  }

  const updateUserData = async (_user: {
    country?: string
    firstname?: string
    lastname?: string
    line1?: string
    line2?: string
    paypal?: string
    state?: string
    tel?: string
    user_email?: string
  }) => {
    setUser({ ...user, ..._user })
  }

  return {
    user,
    getUser,
    setUser,
    updateUser,
    updateUserData,
  }
}

export function useAnalytics() {
  const { user } = useUser()

  const getPaymentRequests = async (status: string) => {
    const headers = getHeaders()
    if (!headers) return

    const requestOptions: RequestInit = {
      method: 'GET',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
    }

    return await fetch(
      `${API_URL}users/payment-requests/uid/${user.user_id}/status/${status}`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const getCreditBalance = async () => {
    const headers = getHeaders()
    if (!headers) return

    const requestOptions: RequestInit = {
      method: 'GET',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
    }

    return await fetch(
      `${API_URL}users/dashboard/uid/${user.user_id}/balance`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const getPreviousCreditBalance = async (startDate: string, endDate: string) => {
    const headers = getHeaders()
    if (!headers) return

    const body = {
      user_id: user.user_id,
      start_date: startDate,
      end_date: endDate,
    }

    const requestOptions: RequestInit = {
      method: 'POST',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
      body: JSON.stringify(body),
    }

    return await fetch(`${API_URL}payments/balance/between/`, requestOptions)
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const getRoyaltyEarnings = async () => {
    const headers = getHeaders()
    if (!headers) return

    const requestOptions: RequestInit = {
      method: 'GET',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
    }

    return await fetch(
      `${API_URL}users/dashboard/uid/${user.user_id}/royality`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const getUserEarnings = async () => {
    const headers = getHeaders()
    if (!headers) return

    const requestOptions: RequestInit = {
      method: 'GET',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
    }

    return await fetch(
      `${API_URL}users/dashboard/uid/${user.user_id}/earnings`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const getSubmissionEarnings = async () => {
    // const submissionEarnings: any[] = []

    const headers = getHeaders()
    if (!headers) return

    const requestOptions: RequestInit = {
      method: 'GET',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
    }

    return await fetch(
      `${API_URL}users/dashboard/uid/${user.user_id}/submissions/earnings`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const requestDuePayments = async () => {
    const headers = getHeaders()
    if (!headers) return

    const requestOptions: RequestInit = {
      method: 'POST',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
    }

    return await fetch(
      `${API_URL}users/payment-requests/uid/${user.user_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  return {
    requestDuePayments,
    getCreditBalance,
    getPaymentRequests,
    getPreviousCreditBalance,
    getRoyaltyEarnings,
    getUserEarnings,
    getSubmissionEarnings,
  }
}

export function useMarketing() {
  const accessToken = lsget('fb_access_token')
  const apiVersion = 'v20.0'
  const FB_APP_ID = process.env.REACT_APP_FB_APP_ID as string
  const FB_APP_SECRET = process.env.REACT_APP_FB_APP_SECRET as string

  const getAccessToken = async (): Promise<void> => {
    const raw = new FormData()
    raw.append('grant_type', 'password')
    raw.append('client_id', FB_APP_ID)
    raw.append('client_secret', FB_APP_SECRET)
    raw.append('scope', '')
    raw.append('username', process.env.REACT_APP_MARKETINGAPI_USERNAME as string)
    raw.append('password', process.env.REACT_APP_MARKETINGAPI_PASSWORD as string)

    const requestOptions: RequestInit = {
      method: 'POST',
      body: raw,
      redirect: 'follow',
      cache: 'no-cache',
    }

    return await fetch(`${MARKETING_API_URL}token`, requestOptions)
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const getFBAccessToken = async (code: string, redirect_uri: string) => {
    const headers = getHeaders('marketing')
    if (!headers) return

    const requestOptions: RequestInit = {
      method: 'POST',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
    }

    return await fetch(
      `${MARKETING_API_URL}token/meta/access_token?code=${code}&redirect_uri=${redirect_uri}&api_version=${apiVersion}&client_secret=${FB_APP_SECRET}&client_id=${FB_APP_ID}`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const getLongLiveToken = async (access_token: string) => {
    const headers = getHeaders('marketing')
    if (!headers) return

    const requestOptions: RequestInit = {
      method: 'GET',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
    }

    return await fetch(
      `${MARKETING_API_URL}token/meta/long-live-token?fb_exchange_token=${access_token}&graph_api_version=${apiVersion}&client_secret=${FB_APP_SECRET}&client_id=${FB_APP_ID}`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const getPageId = async () => {
    const headers = getHeaders('marketing')
    if (!headers) return

    const requestOptions: RequestInit = {
      method: 'GET',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
    }

    return await fetch(
      `${MARKETING_API_URL}ids/page?u_access_token=${accessToken}`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const getBusinessId = async () => {
    const headers = getHeaders('marketing')
    if (!headers) return

    const requestOptions: RequestInit = {
      method: 'GET',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
    }

    return await fetch(
      `${MARKETING_API_URL}ids/business?u_access_token=${accessToken}`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const getAdAccountId = async () => {
    const headers = getHeaders('marketing')
    if (!headers) return

    const requestOptions: RequestInit = {
      method: 'GET',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
    }

    return await fetch(
      `${MARKETING_API_URL}ids/ad_account?u_access_token=${accessToken}`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const getInstagramBusinessId = async (pageId: string) => {
    const headers = getHeaders('marketing')
    if (!headers) return

    const requestOptions: RequestInit = {
      method: 'GET',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
    }

    return await fetch(
      `${MARKETING_API_URL}ids/instagram_business?u_access_token=${accessToken}&page_id=${pageId}`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const getPaymentOptions = async (pageId: string) => {
    const headers = getHeaders('marketing')
    if (!headers) return

    const requestOptions: RequestInit = {
      method: 'GET',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
    }

    return await fetch(
      `${MARKETING_API_URL}payment_options?u_access_token=${accessToken}&page_id=${pageId}`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const getFacebookPosts = async (pAccessToken: string, pageId: string) => {
    const headers = getHeaders('marketing')
    if (!headers) return

    const pageNum = 1
    const pageSize = 10

    const requestOptions: RequestInit = {
      method: 'GET',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
    }

    return await fetch(
      `${MARKETING_API_URL}get-feeds?p_access_token=${pAccessToken}&page_id=${pageId}&app_secret=${FB_APP_SECRET}&app_id=${FB_APP_ID}&page_num=${pageNum}&page_size=${pageSize}`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const getInstagramMedia = async (igUserId: string) => {
    const headers = getHeaders('marketing')
    if (!headers) return

    const pageNum = 1
    const pageSize = 10

    const requestOptions: RequestInit = {
      method: 'GET',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
    }

    return await fetch(
      `${MARKETING_API_URL}instagram/media-objects?u_access_token=${accessToken}&ig_user_id=${igUserId}&api_version=${apiVersion}&page_num=${pageNum}&page_size=${pageSize}`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const getInstagramUser = async (igUserId: string) => {
    const headers = getHeaders('marketing')
    if (!headers) return

    const requestOptions: RequestInit = {
      method: 'GET',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
    }

    return await fetch(
      `${MARKETING_API_URL}instagram/user-details?u_access_token=${accessToken}&ig_user_id=${igUserId}&api_version=${apiVersion}`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const getCampaignInfo = async (adComponent: string, adAccountId: string) => {
    const headers = getHeaders('marketing')
    if (!headers) return

    const requestOptions: RequestInit = {
      method: 'GET',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
    }

    return await fetch(
      `${MARKETING_API_URL}info/${adComponent}?u_access_token=${accessToken}&ad_account_id=${adAccountId}&app_secret=${FB_APP_SECRET}&app_id=${FB_APP_ID}`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const getCampaignInsights = async (adComponent: string, adAccountId: string) => {
    const headers = getHeaders('marketing')
    if (!headers) return

    const requestOptions: RequestInit = {
      method: 'GET',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
    }

    return await fetch(
      `${MARKETING_API_URL}insights/${adComponent}?u_access_token=${accessToken}&ad_account_id=${adAccountId}&app_secret=${FB_APP_SECRET}&app_id=${FB_APP_ID}`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const createAdcreative = async (data: {
    u_access_token: string
    ad_account_id: string
    name: string
    page_id: string
    description: string
    img_url: string
    link_url: string
    message: string
    headline: string
    types: string
    link_caption: string
  }) => {
    const headers = getHeaders('marketing')
    if (!headers) return

    const body = {
      ...data,
      app_secret: FB_APP_SECRET,
      app_id: FB_APP_ID,
    }

    const requestOptions: RequestInit = {
      method: 'POST',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
      body: JSON.stringify(body),
    }

    return await fetch(`${MARKETING_API_URL}create_ad_creative`, requestOptions)
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const createAdcreativePost = async (data: {
    u_access_token: string
    ad_account_id: string
    name: string
    page_post_id: string
  }) => {
    const headers = getHeaders('marketing')
    if (!headers) return

    const body = {
      ...data,
      app_secret: FB_APP_SECRET,
      app_id: FB_APP_ID,
    }

    const requestOptions: RequestInit = {
      method: 'POST',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
      body: JSON.stringify(body),
    }

    return await fetch(`${MARKETING_API_URL}create_ad_creative_post`, requestOptions)
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const createAdset = async (data: {
    u_access_token: string
    ad_account_id: string
    name: string
    campaign_id: string
    start_time: string
    end_time: string
    daily_budget: string
    bid_amount: string
    billing_event: string
    optimization_goal: string
    age_min: string
    age_max: string
    genders: number[]
    countries: string[]
    region: string
    f_positions?: string[]
    i_positions?: string[]
    publisher_platforms: string[]
    status: string
  }) => {
    const headers = getHeaders('marketing')
    if (!headers) return

    const body = {
      ...data,
      app_secret: FB_APP_SECRET,
      app_id: FB_APP_ID,
    }

    const requestOptions: RequestInit = {
      method: 'POST',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
      body: JSON.stringify(body),
    }

    return await fetch(`${MARKETING_API_URL}create_adsets`, requestOptions)
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const createAd = async (data: {
    u_access_token: string
    ad_account_id: string
    name: string
    adset_id: string
    creative_id: string
    status: string
  }) => {
    const headers = getHeaders('marketing')
    if (!headers) return

    const body = {
      ...data,
      app_secret: FB_APP_SECRET,
      app_id: FB_APP_ID,
    }

    const requestOptions: RequestInit = {
      method: 'POST',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
      body: JSON.stringify(body),
    }

    return await fetch(`${MARKETING_API_URL}create_ad`, requestOptions)
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const createCampaign = async (data: {
    u_access_token: string
    ad_account_id: string
    name: string
    objective: string
    status: string
    special_ad_categories: string
  }) => {
    const headers = getHeaders('marketing')
    if (!headers) return

    const body = {
      ...data,
      app_secret: FB_APP_SECRET,
      app_id: FB_APP_ID,
    }

    const requestOptions: RequestInit = {
      method: 'POST',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
      body: JSON.stringify(body),
    }

    return await fetch(`${MARKETING_API_URL}create_campaigns`, requestOptions)
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const deleteCampaign = async (adcomponent_id: string) => {
    const headers = getHeaders('marketing')
    if (!headers) return

    const body = {
      adcomponent_id,
      u_access_token: accessToken,
      app_secret: FB_APP_SECRET,
      app_id: FB_APP_ID,
      adcomponent: 'campaign',
    }

    const requestOptions: RequestInit = {
      method: 'DELETE',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
      body: JSON.stringify(body),
    }

    return await fetch(`${MARKETING_API_URL}delete_campaigns`, requestOptions)
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const getAlbumDetails = async (igMediaId: string) => {
    const headers = getHeaders('marketing')
    if (!headers) return

    const requestOptions: RequestInit = {
      method: 'GET',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
    }

    return await fetch(
      `${MARKETING_API_URL}instagram/album-details?u_access_token=${accessToken}&ig_user_id=${igMediaId}&api_version=${apiVersion}`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  return {
    getAccessToken,
    getFBAccessToken,
    getLongLiveToken,
    getPageId,
    getBusinessId,
    getAdAccountId,
    getInstagramUser,
    getInstagramBusinessId,
    getPaymentOptions,
    getCampaignInfo,
    getCampaignInsights,
    getFacebookPosts,
    getInstagramMedia,
    createAdcreative,
    createAdcreativePost,
    createAdset,
    createAd,
    getAlbumDetails,
    createCampaign,
    deleteCampaign,
  }
}

export function useSpotify() {
  const accessToken = lsget('spotify_access_token')
  const SPOTIFY_CLIENT_ID = process.env.REACT_APP_SPOTIFY_CLIENT_ID as string
  const SPOTIFY_CLIENT_SECRET = process.env.REACT_APP_SPOTIFY_CLIENT_SECRET as string
  const SPOTIFY_REFRESH_TOKEN = process.env.REACT_APP_SPOTIFY_REFRESH_TOKEN as string

  const mode = 'ads'
  const api_version = 'v2'
  const redirect_uri_live =
    'https://dev.d3gj116b7zwla0.amplifyapp.com/marketing-tool/campaign/create/'
  const redirect_uri_local = 'http://localhost:3000/marketing-tool/campaign/create/'

  const redirect_uri = redirect_uri_local
  const scope =
    'ugc-image-upload, user-read-playback-state, user-modify-playback-state, user-read-currently-playing, app-remote-control, streaming, playlist-read-private, playlist-read-collaborative, playlist-modify-private, playlist-modify-public, user-follow-modify, user-follow-read, user-read-playback-position, user-top-read, user-read-recently-played, user-library-modify, user-library-read, user-read-email, user-read-private'

  const getAccessToken = async () => {
    const headers = getHeaders('marketing')
    if (!headers) return

    const requestOptions: RequestInit = {
      method: 'GET',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
    }

    return await fetch(
      `${MARKETING_API_URL}token/spotify-token?client_id=${SPOTIFY_CLIENT_ID}&client_secret=${SPOTIFY_CLIENT_SECRET}&scope=${scope}`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const handleAuthorize = async () => {
    const headers = getHeaders('marketing')
    if (!headers) return

    const requestOptions: RequestInit = {
      method: 'GET',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
    }

    return await fetch(
      `${MARKETING_API_URL}token/spotify/authorize?client_id=${SPOTIFY_CLIENT_ID}&redirect_uri=${redirect_uri}&scope=${scope}`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const handleCallback = async (code: string) => {
    const headers = getHeaders('marketing')
    if (!headers) return

    const requestOptions: RequestInit = {
      method: 'POST',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
    }

    return await fetch(
      `${MARKETING_API_URL}token/spotify/secure/callback?redirect_uri=${redirect_uri}&code=${code}`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const handleRefresh = async () => {
    const headers = getHeaders('marketing')
    if (!headers) return

    const requestOptions: RequestInit = {
      method: 'POST',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
    }

    return await fetch(
      `${MARKETING_API_URL}token/spotify/secure/refresh-token?refresh_token=${SPOTIFY_REFRESH_TOKEN}`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const getAdAccounts = async () => {
    const headers = getHeaders('marketing')
    if (!headers) return

    const requestOptions: RequestInit = {
      method: 'GET',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
    }

    return await fetch(
      `${MARKETING_API_URL}spotify/ad_accounts?mode=${mode}&access_token=${accessToken}`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const getAdAccountInfo = async (ad_account_id: string) => {
    const headers = getHeaders('marketing')
    if (!headers) return

    const requestOptions: RequestInit = {
      method: 'GET',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
    }

    return await fetch(
      `${MARKETING_API_URL}spotify/ad_accounts/${ad_account_id}?mode=${mode}&access_token=${accessToken}`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const getCampaignIds = async (ad_account_id: string) => {
    const headers = getHeaders('marketing')
    if (!headers) return

    const requestOptions: RequestInit = {
      method: 'GET',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
    }

    return await fetch(
      `${MARKETING_API_URL}spotify/ad_accounts/${ad_account_id}/campaigns?mode=${mode}&access_token=${accessToken}`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const getCampaign = async (ad_account_id: string, campaigns_id: string) => {
    const headers = getHeaders('marketing')
    if (!headers) return

    const requestOptions: RequestInit = {
      method: 'GET',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
    }

    return await fetch(
      `${MARKETING_API_URL}spotify/ad_accounts/${ad_account_id}/campaigns/${campaigns_id}?mode=${mode}&access_token=${accessToken}`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const createCampaign = async (data: {
    ad_account_id: string
    name: string
    purchase_order: string
    objective: string
  }) => {
    const headers = getHeaders('marketing')
    if (!headers) return

    const body = {
      ...data,
      mode,
      access_token: accessToken,
      api_version,
    }

    const requestOptions: RequestInit = {
      method: 'POST',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
      body: JSON.stringify(body),
    }

    return await fetch(
      `${MARKETING_API_URL}spotify/ad_accounts/${data.ad_account_id}/campaigns`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const createAdsets = async (data: {
    ad_account_id: string
    campaign_id: string
    name: string
    category_id: string
    start_time: string
    end_time: string
    frequency_unit: string
    frequency_period: number
    max_impressions: number
    micro_amount: number
    b_type: string
    asset_format: string
    min_age: number
    max_age: number
    artist_ids: string[]
    country_code: string
    region_ids: string[]
    genders: string[]
    genre_ids: string[]
    platforms: string[]
    podcast_episode_topic_ids: string[]
    language: string
    playlist_ids: string[]
    promotion_goal: string
    tracking_event_type: string
    window_duration_ms: number
    bid_strategy: string
    bid_micro_amount: number
  }) => {
    const headers = getHeaders('marketing')
    if (!headers) return

    const body = {
      ...data,
      mode,
      access_token: accessToken,
      api_version,
    }

    const requestOptions: RequestInit = {
      method: 'POST',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
      body: JSON.stringify(body),
    }

    return await fetch(
      `${MARKETING_API_URL}spotify/ad_accounts/${data.ad_account_id}/ad_sets`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const createAds = async (data: {
    ad_account_id: string
    delivery: string
    ad_set_id: string
    language: string
    text: string
    clickthrough_url: string
    measurement_partner: string
    url: string
    companion_asset_id: string
    logo_asset_id: string
    asset_id: string
    canvas_asset_id: string
    name: string
    tagline: string
    advertiser_name: string
  }) => {
    const headers = getHeaders('marketing')
    if (!headers) return

    const body = {
      ...data,
      mode,
      access_token: accessToken,
      api_version,
    }

    const requestOptions: RequestInit = {
      method: 'POST',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
      body: JSON.stringify(body),
    }

    return await fetch(
      `${MARKETING_API_URL}spotify/ad_accounts/${data.ad_account_id}/ads`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const createAssets = async (data: {
    ad_account_id: string
    name: string
    asset_type: string
    asset_subtype: string
  }) => {
    const headers = getHeaders('marketing')
    if (!headers) return

    const body = {
      ...data,
      mode,
      access_token: accessToken,
      api_version,
    }

    const requestOptions: RequestInit = {
      method: 'POST',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
      body: JSON.stringify(body),
    }

    return await fetch(
      `${MARKETING_API_URL}spotify/ad_accounts/${data.ad_account_id}/assets`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const getUserProfile = async (access_token: string) => {
    const headers = getHeaders('marketing')
    if (!headers) return

    const requestOptions: RequestInit = {
      method: 'GET',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
    }

    return await fetch(
      `${MARKETING_API_URL}spotify/me?access_token=${access_token}`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const getUserPlaylists = async (
    spotify_id: string,
    limit?: string,
    offset?: string
  ) => {
    const headers = getHeaders('marketing')
    if (!headers) return

    const apiVersion = 'v1'

    const requestOptions: RequestInit = {
      method: 'GET',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
    }

    return await fetch(
      `${MARKETING_API_URL}spotify/user/${spotify_id}/playlists?access_token=${accessToken}&limit=${limit}&offset=${offset}&api_version=${apiVersion}`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const getUserTracks = async (
    spotify_id: string,
    limit?: string,
    offset?: string
  ) => {
    const headers = getHeaders('marketing')
    if (!headers) return

    const apiVersion = 'v1'

    const requestOptions: RequestInit = {
      method: 'GET',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
    }

    return await fetch(
      `${MARKETING_API_URL}spotify/me/tracks?access_token=${accessToken}&limit=${limit}&offset=${offset}&api_version=${apiVersion}`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  return {
    getAccessToken,
    handleAuthorize,
    handleCallback,
    handleRefresh,
    getAdAccounts,
    getAdAccountInfo,
    getCampaignIds,
    getCampaign,
    createCampaign,
    createAdsets,
    createAds,
    createAssets,
    getUserProfile,
    getUserPlaylists,
    getUserTracks,
  }
}

export function useMastering() {
  const getAccessToken = async (): Promise<void> => {
    const raw = new FormData()
    raw.append('grant_type', 'password')
    raw.append('scope', '')
    raw.append('username', process.env.REACT_APP_MASTERINGAPI_USERNAME as string)
    raw.append('password', process.env.REACT_APP_MASTERINGAPI_PASSWORD as string)

    const requestOptions: RequestInit = {
      method: 'POST',
      body: raw,
      redirect: 'follow',
      cache: 'no-cache',
    }

    return await fetch(`${MASTERING_API_URL}token`, requestOptions)
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const uploadFiles = async (urls: string[]) => {
    const headers = getHeaders('mastering')
    if (!headers) return

    const raw = `{
      "urls": [
        ${urls.map((url) => `"${decodeURIComponent(url)}"`).join(', ')}
      ]
    }`

    const requestOptions: RequestInit = {
      method: 'POST',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
      body: raw,
    }

    return await fetch(`${MASTERING_API_URL}upload/`, requestOptions)
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const mixFiles = async (urls: string[]) => {
    const headers = getHeaders('mastering')
    if (!headers) return

    const body = {}

    const requestOptions: RequestInit = {
      method: 'POST',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
      body: JSON.stringify(body),
    }

    return await fetch(`${MASTERING_API_URL}mix/`, requestOptions)
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const masterFiles = async (
    urls: string[],
    low_gain?: number,
    mid_gain?: number,
    high_gain?: number,
    width?: number
  ) => {
    const headers = getHeaders('mastering')
    if (!headers) return

    const body = {
      urls: urls.map((url) => decodeURIComponent(url)),
    }

    const requestOptions: RequestInit = {
      method: 'POST',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
      body: JSON.stringify(body),
    }

    return await fetch(
      `${MASTERING_API_URL}mastered-audio/?low_gain=${low_gain}&mid_gain=${mid_gain}&high_gain=${high_gain}&width=${width}`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  return {
    getAccessToken,
    uploadFiles,
    mixFiles,
    masterFiles,
  }
}

export function useArtwork() {
  const { user } = useUser()

  const getAccessToken = async (): Promise<void> => {
    const raw = new FormData()
    raw.append('grant_type', 'password')
    raw.append('scope', '')
    raw.append('client_id', 'string')
    raw.append('client_secret', 'string')
    raw.append('username', process.env.REACT_APP_NEOSAPI_USERNAME as string)
    raw.append('password', process.env.REACT_APP_NEOSAPI_PASSWORD as string)

    const requestOptions: RequestInit = {
      method: 'POST',
      body: raw,
      redirect: 'follow',
      cache: 'no-cache',
    }

    return await fetch(`${NEOS_API_URL}authentication`, requestOptions)
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const warmupWorkers = async () => {
    const headers = getHeaders('neos')

    if (!headers) return

    const body = {
      generator: 'image',
    }

    const requestOptions: RequestInit = {
      method: 'POST',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
      body: JSON.stringify(body),
    }

    return await fetch(`${NEOS_API_URL}ai/workers/warmup`, requestOptions)
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const generateArtwork = async (prompt: string) => {
    const headers = getHeaders('neos')

    if (!headers) return
    if (!user) return

    const body = {
      user: user.username,
      s3_bucket: 'ai-generator-media',
      prompt,
    }

    const requestOptions: RequestInit = {
      method: 'POST',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
      body: JSON.stringify(body),
    }

    return await fetch(`${NEOS_API_URL}ai/generate_art`, requestOptions)
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const getTaskHistory = async () => {
    const headers = getHeaders('neos')

    if (!headers) return
    if (!user) return

    const generator = 'image'
    const limit = 100
    const skip = 0

    const requestOptions: RequestInit = {
      method: 'GET',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
    }

    return await fetch(
      `${NEOS_API_URL}ai/task_history?generator=${generator}&user=${user.username}&skip=${skip}&limit=${limit}`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  const getTaskStatus = async (s3_url: string) => {
    const headers = getHeaders('neos')

    if (!headers) return
    if (!user) return

    const generator = 'image'

    const requestOptions: RequestInit = {
      method: 'GET',
      headers,
      cache: 'no-cache',
      redirect: 'follow',
    }

    return await fetch(
      `${NEOS_API_URL}ai/task_status?generator=${generator}&s3_url=${s3_url}`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((err) => console.log(err))
  }

  return {
    getAccessToken,
    warmupWorkers,
    generateArtwork,
    getTaskHistory,
    getTaskStatus,
  }
}
