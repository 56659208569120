import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PageHeader from 'components/shared/PageHeader'
import CampaignType from './CampaignType'
import CampaignCreative from './CampaignCreative'
import CampaignBudget from './CampaignBudget'
import CampaignReview from './CampaignReview'
import { CampaignProvider } from 'ctx/CampaignContext'
import { Button } from 'components/partials/Button'
import CampaignConnect from './CampaignConnect'
import { lsget } from 'lib/misc'

const CreateCampaign: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [step, setStep] = useState(0)
  const [isStepValid, setIsStepValid] = useState(false)

  useEffect(() => {
    const accessToken = lsget('fb_access_token')
    if (!accessToken) {
      navigate('/marketing-tool/login?returnUrl=/marketing-tool/campaign/create')
    }

    const marketingAccessToken = lsget('marketing_access_token')
    if (!marketingAccessToken) {
      navigate('/marketing-tool/')
    }
  }, [navigate])

  const nextStep = () => {
    if (step === 1) {
      setStep((prev) => prev + 1)
    } else if (isStepValid) {
      setStep((prev) => prev + 1)
      setIsStepValid(false) // Reset validity for the next step
    }
  }

  const prevStep = () => setStep((prev) => prev - 1)

  const renderStep = () => {
    switch (step) {
      case 0:
        return <CampaignConnect onValidityChange={setIsStepValid} />
      case 1:
        return <CampaignType nextStep={nextStep} />
      case 2:
        return <CampaignCreative onValidityChange={setIsStepValid} />
      case 3:
        return <CampaignBudget onValidityChange={setIsStepValid} />
      case 4:
        return <CampaignReview />
      default:
        return null
    }
  }

  return (
    <CampaignProvider>
      <div className='page-wrapper'>
        <PageHeader title='Create Campaign' isLoading={false} />
        {renderStep()}
        <div className='flex justify-end w-full gap-2 mt-4'>
          {step > 0 && (
            <Button variant='secondary' onClick={prevStep}>
              Back
            </Button>
          )}
          {step === 3 && (
            <Button onClick={nextStep} disabled={!isStepValid}>
              Review Campaign
            </Button>
          )}
          {(step === 0 || step < 3) && step !== 1 && (
            <Button onClick={nextStep} disabled={!isStepValid}>
              Continue
            </Button>
          )}
        </div>
      </div>
    </CampaignProvider>
  )
}

export default CreateCampaign
