import PageHeader from 'components/shared/PageHeader'
import { BulkSubmissionContextProvider } from './context/BulkSubmissionContext'
import Inner from './components/Inner'
import { useTranslation } from 'react-i18next'

const BulkSubmissions = () => {
  const { t } = useTranslation()
  return (
    <div className='page-wrapper'>
      <PageHeader
        title={t('sidebar.bulkSubmission')}
        hasCreateSubmissionTrigger={false}
      />
      <BulkSubmissionContextProvider>
        <Inner />
      </BulkSubmissionContextProvider>
    </div>
  )
}

export default BulkSubmissions
