import React, { useEffect, useState } from 'react'
import DatePicker from 'react-date-picker'
import 'react-date-picker/dist/DatePicker.css'
import 'react-calendar/dist/Calendar.css'
import { useCampaign } from 'ctx/CampaignContext'
import { Card } from 'components/partials/Card'
import { CircleAlert, CircleCheck, X } from 'lucide-react'
import { Badge } from 'components/partials/Badge'
import { useMarketing } from 'store/RootStore'
import { cn } from 'lib/utils'
import { countriesList } from 'lib/constants'
import { Button } from 'components/partials/Button'

type ValuePiece = Date | null

type Value = ValuePiece | [ValuePiece, ValuePiece]

const FacebookBudget: React.FC<{ onValidityChange: (isValid: boolean) => void }> = ({
  onValidityChange,
}) => {
  const { config, setConfig } = useCampaign()
  const { getPaymentOptions } = useMarketing()

  const [budget, setBudget] = useState<number | undefined>(config.budget || 1000)
  const [bidAmount, setBidAmount] = useState<number | undefined>(
    config.bidAmount || 10
  )
  const [startDate, setStartDate] = useState<Value>(config.startDate || null)
  const [endDate, setEndDate] = useState<Value>(config.endDate || null)
  const [selectedArtists, setSelectedArtists] = useState<string[]>(
    config.selectedArtists || []
  )
  // const [options, setOptions] = useState<string[]>(config.options || [])
  const [selectedAdditionalOption, setSelectedAdditionalOption] =
    useState<string>('')
  const [geoTargets, setGeoTargets] = useState<{ name: string; code: string }[]>(
    config.geoTargets || []
  )
  const [minAge, setMinAge] = useState<number | undefined>(config.minAge || 18)
  const [maxAge, setMaxAge] = useState<number | undefined>(config.maxAge || 40)

  useEffect(() => {
    const fetchPaymentOptions = async () => {
      if (config.page && config.page.id) {
        const paymentOptions = await getPaymentOptions(config.page.id)
        setConfig({
          ...config,
          paymentOption: paymentOptions,
        })
      }
    }
    fetchPaymentOptions()
  }, [config.page])

  useEffect(() => {
    const isValid =
      budget !== undefined &&
      bidAmount !== undefined &&
      startDate !== null &&
      endDate !== null &&
      minAge !== undefined &&
      maxAge !== undefined &&
      geoTargets.length > 0
    // options.length > 0

    setConfig({
      ...config,
      budget,
      bidAmount,
      startDate,
      endDate,
      // options,
      geoTargets,
      selectedArtists,
      minAge,
      maxAge,
    })
    onValidityChange(isValid)
  }, [
    budget,
    bidAmount,
    startDate,
    endDate,
    // options,
    geoTargets,
    selectedArtists,
    minAge,
    maxAge,
    setConfig,
    onValidityChange,
  ])

  // const handleSelectArtist = (artistName: string) => {
  //   const artist = artistOptions.find((a) => a.name === artistName)
  //   if (artist) {
  //     const newOptions = artist.options.filter((option) => !options.includes(option))
  //     setOptions((prev) => [...prev, ...newOptions])
  //     setSelectedArtists((prev) => [...prev, artistName])
  //   }
  // }

  // const handleRemoveOption = (option: string) => {
  //   setOptions((prev) => prev.filter((o) => o !== option))
  // }

  // const handleAddAdditionalOption = () => {
  //   if (selectedAdditionalOption && !options.includes(selectedAdditionalOption)) {
  //     setOptions((prev) => [...prev, selectedAdditionalOption])
  //   }
  // }

  const handleAddGeoTarget = (country: { name: string; code: string }) => {
    if (country.code === 'ALL') {
      setGeoTargets(countriesList.filter((country) => country.code !== 'ALL'))
    } else if (!geoTargets.some((target) => target.code === country.code)) {
      setGeoTargets((prev) => [...prev, country])
    }
  }

  const handleRemoveGeoTarget = (countryCode: string) => {
    setGeoTargets((prev) => prev.filter((gt) => gt.code !== countryCode))
  }

  return (
    <div>
      <Card className='p-4 mt-4 rounded-lg'>
        <div className='flex flex-col gap-4 md:flex-row md:gap-10 md:justify-between md:items-center'>
          <div>
            <h2 className='mb-1 font-bold'>Add Budget:</h2>
            <p className='max-w-lg text-sm md:text-base'>
              The budget is the amount of money you are willing to spend on your
              campaign. Enter the desired budget in the input field below.
            </p>
          </div>
          <div className='flex items-center w-full gap-1 md:justify-end md:max-w-xs'>
            <span>$ </span>
            <input
              type='number'
              placeholder='Budget'
              value={budget}
              className='max-w-[8rem]'
              onChange={(e) => setBudget(Number(e.target.value))}
              min={0}
            />
          </div>
        </div>
        <div className='flex flex-col gap-4 mt-6 md:flex-row md:gap-10 md:justify-between md:items-center'>
          <div>
            <h2 className='mb-1 font-bold'>Add Bid Amount:</h2>
            <p className='max-w-lg text-sm md:text-base'>
              The bid cap or target cost for this campaign. This helps bid on your
              behalf to meet your target on average and keep costs stable as you
              raise budget.
            </p>
          </div>
          <div className='flex items-center w-full gap-1 md:justify-end md:max-w-xs'>
            <span>$ </span>
            <input
              type='number'
              placeholder='Bid Amount'
              value={bidAmount}
              className='max-w-[8rem]'
              onChange={(e) => setBidAmount(Number(e.target.value))}
              min={0}
            />
          </div>
        </div>
        <div className='flex flex-col gap-4 mt-6 md:flex-row md:gap-10 md:justify-between'>
          <div>
            <h2 className='mb-1 font-bold'>Select Duration:</h2>
            <p className='max-w-lg text-sm md:text-base'>
              The duration is the amount of time you want your campaign to run. Enter
              the desired start date and end date.
            </p>
          </div>
          <div className='flex w-full max-w-md md:justify-end'>
            <div className='flex gap-4'>
              <div>
                <label className='block mb-1 font-bold'>Start Date:</label>
                <DatePicker
                  value={startDate}
                  onChange={setStartDate}
                  minDate={new Date()}
                  className='w-full p-2 border rounded'
                  format='dd/MM/yyyy'
                />
              </div>
              <div>
                <label className='block mb-1 font-bold'>End Date:</label>
                <DatePicker
                  value={endDate}
                  onChange={setEndDate}
                  minDate={new Date()}
                  className='w-full p-2 border rounded'
                  format='dd/MM/yyyy'
                />
              </div>
            </div>
          </div>
        </div>
      </Card>
      <Card
        className={cn(
          'flex flex-col p-4 mt-4 items-center justify-center w-full rounded-lg',
          config.paymentOption?.payment_option?.id ? 'bg-green-100' : 'bg-yellow-100'
        )}
      >
        <div className='flex flex-col items-center w-full gap-2 mb-2 font-bold md:justify-center'>
          {config.paymentOption?.payment_option?.id ? (
            <>
              <CircleCheck className='text-white fill-green-500 size-5' />
              <p>Payment Option Added</p>
            </>
          ) : (
            <>
              <CircleAlert className='text-white fill-yellow-500 size-5' />
              <p>No Payment Option Added</p>
            </>
          )}
        </div>
        <p className='text-sm md:text-base'>
          {config.paymentOption?.payment_option?.id
            ? 'The campaign will be active as soon as it starts.'
            : 'The campaign will be paused until a payment option is added.'}
        </p>
      </Card>
      {/* <Card className='p-4 mt-4 rounded-lg'>
    <div className='w-full p-2 bg-gray-100 rounded-lg'>
      <h2 className='mb-1 font-bold'>Audience Targeting</h2>
      <p className='max-w-lg text-sm md:text-base'>
        Refine the audiences you want to target with your marketing
      </p>
    </div>
    <h2 className='mt-4 mb-1 font-bold'>
      Search for Similar Artists to Target:
    </h2>
    <div className='flex flex-col gap-4 mt-6 md:flex-row md:gap-10 md:justify-between'>
      <p className='max-w-lg mb-2 text-sm md:text-base'>
        Enter artists that are in your genre or make music similar to yours, and
        we'll automatically look for them on Facebook.
      </p>
      <div className='flex w-full max-w-md md:justify-end'>
        <div className='w-full gap-4'>
          <select
            className='w-full p-2 border rounded focus:outline'
            onChange={(e) => handleSelectArtist(e.target.value)}
            defaultValue=''
          >
            <option value=''>Select an artist</option>
            {artistOptions.map((artist, index) => (
              <option key={index} value={artist.name}>
                {artist.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>

    <h2 className='mt-4 mb-1 font-bold'>Your ads will target these audiences</h2>
    <Card className='p-4 rounded-lg min-h-32'>
      <div className='flex flex-wrap gap-2'>
        {options.map((option, index) => (
          <Badge key={index} className='space-x-2 text-sm' variant='secondary'>
            <span className='capitalize'>{option}</span>
            <button
              onClick={() => handleRemoveOption(option)}
              className='text-red-500'
            >
              <X className='w-4 h-4' />
            </button>
          </Badge>
        ))}
      </div>
    </Card>
    <div className='mt-6 mb-2 '>
      <div className='flex flex-col items-center gap-4 mt-6 md:flex-row md:gap-10 md:justify-between'>
        <h2 className='font-bold'>Add more options:</h2>
        <div className='flex w-full md:justify-end'>
          <div className='flex w-full gap-4'>
            <select
              className='w-full p-2 border rounded'
              value={selectedAdditionalOption}
              onChange={(e) => setSelectedAdditionalOption(e.target.value)}
            >
              <option value=''>Select an additional option</option>
              {additionalOptions.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <button
              onClick={handleAddAdditionalOption}
              className='flex items-center gap-2 px-4 text-white bg-blue-500 rounded'
            >
              <Plus className='w-4 h-4' /> Add
            </button>
          </div>
        </div>
      </div>
    </div>
  </Card> */}
      <Card className='p-4 mt-4 rounded-lg'>
        <h2 className='mb-1 font-bold'>Geo-Targeting</h2>
        <p className='max-w-lg mb-2 text-sm md:text-base'>
          Enter the countries or regions you want to target.
        </p>
        <div className='flex gap-2'>
          <select
            className='p-2 border rounded focus:outline'
            onChange={(e) => handleAddGeoTarget(JSON.parse(e.target.value))}
            defaultValue=''
          >
            <option value=''>Select a country</option>
            {countriesList.map((country, index) => (
              <option key={index} value={JSON.stringify(country)}>
                {country.name}
              </option>
            ))}
          </select>
          <Button variant='secondary' onClick={() => setGeoTargets([])}>
            Clear All
          </Button>
        </div>
        <div className='flex flex-wrap gap-2 mt-4 overflow-y-auto max-h-96'>
          {geoTargets.map((target, index) => (
            <Badge key={index} className='space-x-2 text-sm' variant='secondary'>
              <span className='capitalize'>{target.name}</span>
              <button
                onClick={() => handleRemoveGeoTarget(target.code)}
                className='text-red-500'
              >
                <X className='w-4 h-4' />
              </button>
            </Badge>
          ))}
        </div>
      </Card>
      <Card className='p-4 mt-4 rounded-lg'>
        <div className='flex flex-col gap-4 md:flex-row md:gap-10 md:justify-between'>
          <div>
            <h2 className='mb-1 font-bold'>Select Age Range:</h2>
            <p className='max-w-lg text-sm md:text-base'>
              The age range allows users to select the minimum and maximum age for
              targeting their campaign.
            </p>
          </div>
          <div className='flex w-full max-w-md md:justify-end'>
            <div className='flex gap-4'>
              <div>
                <label className='block mb-1 font-bold'>Min Age:</label>
                <input
                  type='number'
                  value={minAge}
                  onChange={(e) => setMinAge(Number(e.target.value))}
                  className='w-full p-2 border rounded focus:outline'
                  min={0}
                  max={100}
                />
              </div>
              <div>
                <label className='block mb-1 font-bold'>Max Age:</label>
                <input
                  type='number'
                  value={maxAge}
                  onChange={(e) => setMaxAge(Number(e.target.value))}
                  className='w-full p-2 border rounded focus:outline'
                  min={0}
                  max={100}
                />
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default FacebookBudget
