import { ReactNode, Suspense, useEffect } from 'react'
import {
  Route,
  Routes,
  Outlet,
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast'

import { useUI } from 'ctx/UIContext'

import LoadingScreen from 'components/layout/LoadingScreen'

import Login from 'pages/Auth/Login'
import Signup from 'pages/Auth/Signup'
import ConfirmSignup from 'pages/Auth/ConfirmSignup'

import MainDashboard from 'pages/Dashboard'
import AddSubmission from 'pages/Dashboard/AddSubmission'
import EditSubmission from 'pages/Dashboard/EditSubmission'
import MySubmissions from 'pages/Dashboard/MySubmissions'
import AccountSettings from 'pages/Common/AccountSettings'

import 'react-tooltip/dist/react-tooltip.css'
import ForgotPassword from 'pages/Auth/ForgotPassword'
import ResetPassword from 'pages/Auth/ResetPassword'
import Analytics from 'pages/Dashboard/Analytics'
import CreateYoutubeChannel from 'pages/Dashboard/CreateYoutubeChannel'
import BulkSubmission from 'pages/Dashboard/BulkSubmission'
import { Trans } from 'react-i18next'
import useWhiteLabel from 'hooks/useWhiteLabel'
import { Helmet } from 'react-helmet'
import MarketingOverview from 'pages/Dashboard/MarketingTool/MarketingOverview'
import CreateCampaign from 'pages/Dashboard/MarketingTool/CreateCampaign'
import MarketingLogin from 'pages/Dashboard/MarketingTool/MarketingLogin'
import AudioMixing from 'pages/Dashboard/AudioMixing'
import MixingUpload from 'pages/Dashboard/AudioMixing/MixingUpload'
import ArtworkGenerator from 'pages/Dashboard/ArtworkGenerator'
import GenerateArtwork from 'pages/Dashboard/ArtworkGenerator/GenerateArtwork'
import { WHITELABEL_DATA } from 'lib/constants'
import PublicGuides from 'pages/Common/PublicGuides'
import PublicAbout from 'pages/Common/PublicAbout'
// import AddMetadata from 'pages/Dashboard/AddSubmission/AddMetadata'

function App() {
  const ui = useUI()
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { getWhiteLabelData } = useWhiteLabel()
  const { whiteLabelData, setWhiteLabelData, setLoading } = useUI()

  const findWhiteLabelData = (appName: string) =>
    WHITELABEL_DATA.find((data) => data.appname === appName)

  useEffect(() => {
    const coupon = searchParams.get('affiliate')
    if (coupon) {
      localStorage.setItem('affiliate', coupon)
    }
    if (location.pathname === '/') {
      if (whiteLabelData.app_name === 'default') {
        navigate('https://www.musicdash.com/')
      } else {
        navigate('/home')
      }
    }
  }, [searchParams, location, navigate])

  useEffect(() => {
    const fetchLabelData = async () => {
      try {
        setLoading(true)
        const res = await getWhiteLabelData()

        if (!res) {
          setWhiteLabelData({ app_name: 'default' })
          return
        }

        if ('detail' in res) {
          toast.error('Failed to retrieve whitelabel data!')
          setWhiteLabelData({ app_name: 'default' })
          return
        }

        setWhiteLabelData(res)
        const affiliateCode =
          res.coupon || findWhiteLabelData(res.app_name)?.code || ''

        if (affiliateCode) {
          localStorage.setItem('affiliate', affiliateCode)
        }
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false)
      }
    }

    fetchLabelData()
  }, [])

  return (
    <>
      {whiteLabelData.app_name !== 'default' &&
      whiteLabelData.app_name !== undefined ? (
        <Helmet>
          <meta charSet='utf-8' />
          <title>{`${
            findWhiteLabelData(whiteLabelData.app_name)?.title ||
            (whiteLabelData.app_name !== 'default'
              ? `${whiteLabelData.app_name} | Dashboard`
              : 'Music DASH | Dashboard')
          }`}</title>
          <meta
            name='description'
            content={`Our platform offers tools to help independent artists, labels, and distributors monetize their music and videos. Providing real-time analytics and earnings, ${whiteLabelData.app_name} allows creators to release their Singles, Albums and Videos worldwide 100% free to use.`}
          />
          <link rel='icon' href='/whitelabel/favicon.ico' data-react-helmet='true' />
          <meta name='viewport' content='width=device-width, initial-scale=1' />
          <meta name='theme-color' content='#000000' />
          <meta
            name='title'
            content={`${
              findWhiteLabelData(whiteLabelData.app_name)?.title ||
              (whiteLabelData.app_name !== 'default'
                ? `${whiteLabelData.app_name} | Dashboard`
                : 'Music DASH | Dashboard')
            }`}
          />
          <meta property='og:type' content='website' />
          <meta
            name='description'
            content={`Our platform offers tools to help independent artists, labels, and distributors monetize their music and videos. Providing real-time analytics and earnings, ${whiteLabelData.app_name} allows creators to release their Singles, Albums and Videos worldwide 100% free to use.`}
          />
          <meta
            property='og:url'
            content={`https://${whiteLabelData.app_name}.musicdash.com/`}
          />
          <meta
            property='og:title'
            content={`${
              findWhiteLabelData(whiteLabelData.app_name)?.title ||
              (whiteLabelData.app_name !== 'default'
                ? `${whiteLabelData.app_name} | Dashboard`
                : 'Music DASH | Dashboard')
            }`}
          />
          <meta
            property='og:description'
            content={`Our platform offers tools to help independent artists, labels, and distributors monetize their music and videos. Providing real-time analytics and earnings, ${whiteLabelData.app_name} allows creators to release their Singles, Albums and Videos worldwide 100% free to use.`}
          />

          <meta property='twitter:card' content='summary_large_image' />
          <meta
            property='twitter:url'
            content={`https://${whiteLabelData.app_name}.musicdash.com/`}
          />
          <meta
            property='twitter:title'
            content={`${
              findWhiteLabelData(whiteLabelData.app_name)?.title ||
              (whiteLabelData.app_name !== 'default'
                ? `${whiteLabelData.app_name} | Dashboard`
                : 'Music DASH | Dashboard')
            }`}
          />
          <meta
            property='twitter:description'
            content={`Our platform offers tools to help independent artists, labels, and distributors monetize their music and videos. Providing real-time analytics and earnings, ${whiteLabelData.app_name} allows creators to release their Singles, Albums and Videos worldwide 100% free to use.`}
          />

          <link
            rel='apple-touch-icon'
            href='/whitelabel/apple-touch-icon.png'
            data-react-helmet='true'
          />

          <link
            rel='manifest'
            href='/whitelabel/manifest.json'
            data-react-helmet='true'
          />
        </Helmet>
      ) : whiteLabelData.app_name === 'default' &&
        whiteLabelData.app_name !== undefined ? (
        <Helmet>
          <meta charSet='utf-8' />
          <link rel='icon' href='/favicon.ico' data-react-helmet='true' />
          <meta name='viewport' content='width=device-width, initial-scale=1' />
          <meta name='theme-color' content='#000000' />
          <meta name='title' content='Music DASH' />
          <meta property='og:type' content='website' />
          <meta
            name='description'
            content='Our platform offers tools to help independent artists, labels, and distributors monetize their music and videos. Providing real-time analytics and earnings, Music DASH allows creators to release their Singles, Albums and Videos worldwide 100% free to use.'
          />
          <meta property='og:url' content='https://www.musicdash.com/' />
          <meta property='og:title' content='Music DASH' />
          <meta
            property='og:description'
            content='Our platform offers tools to help independent artists, labels, and distributors monetize their music and videos. Providing real-time analytics and earnings, Music DASH allows creators to release their Singles, Albums and Videos worldwide 100% free to use.'
          />
          <meta property='twitter:card' content='summary_large_image' />
          <meta property='twitter:url' content='https://www.musicdash.com/' />
          <meta property='twitter:title' content='Music DASH' />
          <meta
            property='twitter:description'
            content='Our platform offers tools to help independent artists, labels, and distributors monetize their music and videos. Providing real-time analytics and earnings, Music DASH allows creators to release their Singles, Albums and Videos worldwide 100% free to use.'
          />

          <link
            rel='apple-touch-icon'
            href='/apple-touch-icon.png'
            data-react-helmet='true'
          />

          <link rel='manifest' href='/manifest.json' data-react-helmet='true' />

          <title>Music DASH | Dashboard</title>
        </Helmet>
      ) : null}

      <Trans>
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route
            path='/signup'
            element={
              <SuspensedView>
                <Signup />
              </SuspensedView>
            }
          />
          <Route
            path='/forgot-password'
            element={
              <SuspensedView>
                <ForgotPassword />
              </SuspensedView>
            }
          />
          <Route
            path='/reset-password'
            element={
              <SuspensedView>
                <ResetPassword />
              </SuspensedView>
            }
          />
          <Route
            path='/confirm-signup'
            element={
              <SuspensedView>
                <ConfirmSignup />
              </SuspensedView>
            }
          />
          <Route
            path='public/guides'
            element={
              <SuspensedView>
                <PublicGuides />
              </SuspensedView>
            }
          />
          <Route
            path='home'
            element={
              <SuspensedView>
                <PublicAbout />
              </SuspensedView>
            }
          />
          <Route path='/' element={<PrivateRoute />}>
            <Route
              path='/'
              element={
                <SuspensedView>
                  <MainDashboard />
                </SuspensedView>
              }
            >
              <Route
                path='add-submission'
                element={
                  <SuspensedView>
                    <AddSubmission />
                  </SuspensedView>
                }
              />
              <Route
                path='submission/:type/:id'
                element={
                  <SuspensedView>
                    <EditSubmission />
                  </SuspensedView>
                }
              />
              <Route
                path='my-submissions'
                element={
                  <SuspensedView>
                    <MySubmissions />
                  </SuspensedView>
                }
              />
              <Route
                path='earnings'
                element={
                  <SuspensedView>
                    <Analytics />
                  </SuspensedView>
                }
              />
              <Route
                path='audio-mixing'
                element={
                  <SuspensedView>
                    <AudioMixing />
                  </SuspensedView>
                }
              />
              <Route
                path='audio-mixing/upload'
                element={
                  <SuspensedView>
                    <MixingUpload />
                  </SuspensedView>
                }
              />
              <Route
                path='artwork-generator'
                element={
                  <SuspensedView>
                    <ArtworkGenerator />
                  </SuspensedView>
                }
              />
              <Route
                path='artwork-generator/generate'
                element={
                  <SuspensedView>
                    <GenerateArtwork />
                  </SuspensedView>
                }
              />
              <Route
                path='marketing-tool/login'
                element={
                  <SuspensedView>
                    <MarketingLogin />
                  </SuspensedView>
                }
              />
              <Route
                path='marketing-tool'
                element={
                  <SuspensedView>
                    <MarketingOverview />
                  </SuspensedView>
                }
              />
              <Route
                path='marketing-tool/campaign/create'
                element={
                  <SuspensedView>
                    <CreateCampaign />
                  </SuspensedView>
                }
              />
              <Route
                path='bulk-submission'
                element={
                  <SuspensedView>
                    <BulkSubmission />
                  </SuspensedView>
                }
              />
              <Route
                path='create-youtube-channel'
                element={
                  <SuspensedView>
                    <CreateYoutubeChannel />
                  </SuspensedView>
                }
              />

              <Route
                path='account-settings'
                element={
                  <SuspensedView>
                    <AccountSettings />
                  </SuspensedView>
                }
              />
            </Route>
          </Route>
        </Routes>
        <Toaster
          toastOptions={{
            duration: 6000,
            position: 'top-right',
          }}
        />
        {/* <GoogleAnalytics /> */}
        {ui.isLoading ? <LoadingScreen isLoading /> : null}
      </Trans>
    </>
  )
}
function PrivateRoute() {
  const auth = localStorage.getItem('access_token')
  return auth ? <Outlet /> : <Navigate to='/login' />
}

const SuspensedView = ({ children }: { children: ReactNode }) => {
  return <Suspense fallback={<LoadingScreen isLoading />}>{children}</Suspense>
}
export default App
