import { default as MarqueePlugin } from 'react-fast-marquee'

type Props = {
  items: {
    content: string
    img: string
  }[]
  direction?: 'left' | 'right' | 'up' | 'down'
  speed?: number
}
const Marquee = ({ items, direction = 'left', speed = 20 }: Props) => {
  return (
    <MarqueePlugin gradient direction={direction} speed={speed} autoFill>
      {items.map((item, i) => (
        <div
          key={i}
          className='flex items-center justify-center w-full p-3 mx-4 border rounded-lg bg-gray-50 border-neutral-500 md:mx-8'
        >
          <img src={item.img} alt={item.content} className='h-6 md:h-8' />
        </div>
      ))}
    </MarqueePlugin>
  )
}

export default Marquee
