import React, {
  FC,
  useCallback,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react'
import {
  FieldErrors,
  FieldValues,
  UseFormRegister,
  UseFormSetError,
  UseFormClearErrors,
} from 'react-hook-form'
import { CheckIcon, InfoIcon, LoadingIcon } from './Icons'
import Tooltip from './Tooltip'
import { parseBlob } from 'music-metadata'

interface IFileInput {
  register: UseFormRegister<FieldValues | any>
  label: string
  name: string
  setError: UseFormSetError<FieldValues | any>
  clearErrors: UseFormClearErrors<FieldValues | any>
  setValue?: any | Dispatch<SetStateAction<any>>
  errors?: FieldErrors<FieldValues | any>
  helperText?: string
  required?: boolean
  accept?: string[]
  value?: any
  fileValue?: any
  isLoading?: boolean
  progress?: number
}

const FileInput: FC<IFileInput> = ({
  register,
  label,
  name,
  setError,
  clearErrors,
  setValue,
  errors,
  helperText,
  required = false,
  accept,
  value = '',
  fileValue = '',
  isLoading = true,
  progress = 0,
}) => {
  const { onChange, ref } = register(name, {
    required: required ? 'This is required' : false,
  })

  const validFormats = [
    { sampleRate: 192000, bitDepth: [32, 24] },
    { sampleRate: 176400, bitDepth: [32, 24] },
    { sampleRate: 96000, bitDepth: [32, 24] },
    { sampleRate: 88200, bitDepth: [32, 24] },
    { sampleRate: 48000, bitDepth: [32, 24, 16] },
    { sampleRate: 44100, bitDepth: [32, 24, 16] },
  ]

  const validFormatsString = `
  Audio files must be FLAC or WAV and in one of the below specifications:
  HD Audio 192 kHz 32 bits
  HD Audio 192 kHz 24 bits
  HD Audio 176.4 kHz 32 bits
  HD Audio 176.4 kHz 24 bits
  HD Audio 96 kHz 32 bits
  HD Audio 96 kHz 24 bits
  HD Audio 88.2 kHz 32 bits
  HD Audio 88.2 kHz 24 bits
  HD Audio 48 kHz 32 bits
  HD Audio 48 kHz 24 bits
  HD Audio 48 kHz 16 bits
  HD Audio 44.1 kHz 32 bits
  HD Audio 44.1 kHz 24 bits
  Audio 44.1 kHz 16 bits
`

  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [_fileValue, setFileValue] = useState('')

  const validateAudioFile = async (file: File) => {
    const metadata = await parseBlob(file)

    const sampleRate = metadata.format.sampleRate
    const bitDepth = metadata.format.bitsPerSample

    if (!sampleRate || !bitDepth) {
      return { isValid: false, message: 'Sample rate or bit depth is missing.' }
    }

    const isValid = validFormats.some(
      (format) =>
        format.sampleRate === sampleRate && format.bitDepth.includes(bitDepth)
    )

    if (!isValid) {
      return {
        isValid: false,
        message: `Invalid format: ${sampleRate} Hz and ${bitDepth}-bit. Refer to the valid formats above`,
      }
    }

    return { isValid: true }
  }

  const onFileChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0]

      if (!file) {
        return
      }
      const fileName = file.name

      setSelectedFile(file)
      setFileValue(fileName)
      setValue(file)
      onChange(event)
      // clearErrors(name)
      // const file = event.target.files?.[0]
      // if (!file) {
      //   return
      // }

      // const fileName = file.name
      // const fileExtension = fileName.split('.').pop()?.toLowerCase()
      // const validExtensions = ['flac', 'wav']

      // // File type validation
      // if (!validExtensions.includes(fileExtension || '')) {
      //   setError(name, {
      //     type: 'custom',
      //     message: 'Invalid file type. Only FLAC or WAV files are allowed.',
      //   })
      //   return
      // }

      // const { isValid, message } = await validateAudioFile(file)
      // if (isValid) {
      //   setSelectedFile(file)
      //   setFileValue(fileName)
      //   setValue(file)
      //   onChange(event)
      // } else {
      //   setError(name, {
      //     type: 'custom',
      //     message: message,
      //   })
      // }
    },
    [accept, setError, setValue, onChange, name]
  )

  useEffect(() => {
    setFileValue(fileValue?.name)
  }, [fileValue])

  return (
    <div
      className={[
        'relative w-full border rounded-lg border-secondary-purple/20 flex flex-col items-center justify-center cursor-pointer bg-gray-50  hover:bg-gray-100',
        errors && errors[name] ? 'error' : '',
      ].join(' ')}
    >
      <label
        className='relative flex flex-col items-center justify-start w-full p-4 text-sm font-medium text-gray-900 truncate cursor-pointer'
        htmlFor={name}
      >
        <span className='flex items-center justify-center w-full'>
          <span className='text-end text-xs max-w-[300px] md:max-w-[100px] truncate'>
            {_fileValue ? (
              <Tooltip content={_fileValue}>
                <span className=''>{_fileValue}</span>
              </Tooltip>
            ) : (
              label
            )}
          </span>
          {_fileValue ? (
            <span className='ml-2 text-green-500 text-start'>
              <CheckIcon />
            </span>
          ) : null}
        </span>
        <p className='px-2 mt-1 text-xs text-gray-400' id='file_input_help'>
          {helperText}
        </p>
        <Tooltip
          content={
            <div style={{ whiteSpace: 'pre-wrap' }}>{validFormatsString.trim()}</div>
          }
        >
          <p
            className='inline-flex items-center gap-1 px-2 mt-1 text-xs text-gray-400'
            id='file_input_help'
          >
            Supported Audio Formats <InfoIcon />
          </p>
        </Tooltip>
        {errors && errors[name] ? (
          <p className='mt-2 text-xs text-red-500' id='file_input_help'>
            {errors[name]?.message?.toString()}
          </p>
        ) : null}
      </label>
      <input
        className='hidden'
        id={name}
        type='file'
        ref={ref}
        onChange={onFileChange}
        accept={accept?.join(',')}
      />
      <Tooltip content={helperText || 'deliverables'}>
        <div className='absolute top-0 left-0 flex items-center justify-center w-5 h-5 m-1 text-base bg-white border rounded-full border-secondary-purple text-secondary-purple'>
          <InfoIcon />
        </div>
      </Tooltip>
      {isLoading ? (
        <div className='absolute inset-0 bg-transparent z-top pointer-events-none overflow-hidden !rounded-lg flex items-center justify-center'>
          <div className='absolute inset-0 bg-white opacity-40'></div>
          <span className='text-2xl text-primary-purple animate-spin'>
            <LoadingIcon />
          </span>
        </div>
      ) : null}
      {progress !== (0 && 100) ? (
        <div className='relative flex items-center justify-center w-full gap-2'>
          <div className='relative w-full h-1 overflow-hidden rounded-full bg-primary-purple/30'>
            <span
              className='absolute inline-block h-1 rounded-full bg-secondary-purple'
              style={{ width: progress + '%' }}
            ></span>
          </div>
          <span className='text-xs'>{progress + '%'}</span>
        </div>
      ) : null}
    </div>
  )
}

export default FileInput
