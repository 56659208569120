import { Check, ClipboardCheck, Hourglass, SquarePen, X } from 'lucide-react'

const StatusInfo = ({ status }: { status: string }) => {
  const lowerStatus = status.toLowerCase()

  return (
    <div>
      {lowerStatus === 'draft' ? (
        <InfoItem
          color='bg-gray-100'
          icon={<SquarePen />}
          info='Draft: Please complete the missing info and send for distribution!'
        />
      ) : lowerStatus === 'review' || lowerStatus === 'pending' ? (
        <InfoItem
          color='bg-yellow-100'
          icon={<Hourglass />}
          info='In Review: Your submission is under review by the admins!'
        />
      ) : lowerStatus === 'in distribution' || lowerStatus === 'in disribution' ? (
        <InfoItem
          color='bg-blue-100'
          icon={<ClipboardCheck />}
          info='In Distribution: Awaiting final approval!'
        />
      ) : lowerStatus === 'delivered' || lowerStatus === 'completed' ? (
        <InfoItem
          color='bg-green-100'
          icon={<Check />}
          info='Delivered: Your submission has been delivered successfully!'
        />
      ) : lowerStatus === 'rejected' ? (
        <InfoItem
          color='bg-red-100'
          icon={<X />}
          info='Rejected: Your submission has been rejected. Please resubmit the form!'
        />
      ) : null}
    </div>
  )
}

export default StatusInfo

const InfoItem = ({
  color,
  icon,
  info,
}: {
  color: string
  icon: JSX.Element
  info: string
}) => {
  return (
    <div className={`flex w-max p-5 justify-center items-center gap-4 ${color}`}>
      <div>{icon}</div>
      <p className='font-semibold'>{info}</p>
    </div>
  )
}
