import { cn } from 'lib/utils'
import { FC, ReactNode } from 'react'

type Option = {
  value: string
  label: string
  key?: string
  disabled?: boolean
}

interface FormUtilsProps {
  children: ReactNode
  cls?: string
}

interface IDefaultItemRendererProps {
  checked: boolean
  option: Option
  disabled?: boolean
  onClick: () => void
}

const FieldWrapper: FC<FormUtilsProps> = ({ children, cls, ...fieldProps }) => (
  <div
    className={cn(
      'relative flex flex-col items-start justify-center mb-1 md:mb-0',
      cls
    )}
    {...fieldProps}
  >
    {children}
  </div>
)

const Error: FC<FormUtilsProps> = ({ children, cls }) => (
  <span
    className={['relative w-full mt-0.5 text-xs text-red-500 text-end', cls].join(
      ' '
    )}
  >
    *{children}
  </span>
)

const ItemRenderer = ({
  checked,
  option,
  onClick,
  disabled,
}: IDefaultItemRendererProps) => (
  <div className={`item-renderer ${disabled ? 'disabled' : ''}`}>
    <input
      type='checkbox'
      onChange={onClick}
      checked={checked}
      tabIndex={-1}
      disabled={disabled}
    />
    <span className='text-black'>{option.label}</span>
  </div>
)
const ItemRendererAlt = ({
  checked,
  option,
  disabled,
}: IDefaultItemRendererProps) => (
  <div className={`item-renderer ${disabled ? 'disabled' : ''}`}>
    <span className='text-black'>{option.label}</span>
  </div>
)

export { FieldWrapper, Error, ItemRenderer, ItemRendererAlt }
