import { useState, useEffect, useRef, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Country, State, City } from 'country-state-city'

import { FieldWrapper, Error } from 'components/shared/FormUtils'
import { useUser } from 'store/RootStore'
import { EyeClosedIcon, EyeOpenIcon } from 'components/shared/Icons'
import CustomModal from 'components/shared/CustomModal'
import { useAuth } from 'ctx/AuthContext'
import { Input, SelectInput } from 'components/partials/Inputs'
import { Button } from 'components/partials/Button'
import { useTranslation } from 'react-i18next'
import { useUI } from 'ctx/UIContext'

const emailString = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i

type AccountSettingsFormType = {
  firstname: string
  lastname: string
  user_email: string
  paypal: string
  line1: string
  line2: string
  state: string
  country: string
  tel: string
}

const defaultValues: AccountSettingsFormType = {
  firstname: 'Suvin',
  lastname: 'Chandula',
  user_email: 'suvinjavax@gmail.com',
  paypal: '',
  line1: '',
  line2: '',
  state: '',
  country: '',
  tel: '',
}

const AccountSettings = () => {
  const { t } = useTranslation()
  const { whiteLabelData } = useUI()
  const schema = yup.object().shape({
    email: yup.string().matches(emailString, { message: t('errors.invalidEmail') }),
    paypal: yup.string().matches(emailString, { message: t('errors.invalidEmail') }),
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    getValues,
    watch,
  } = useForm<AccountSettingsFormType>({
    mode: 'all',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues,
  })
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    reset: reset2,
    getValues: getValues2,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
  })

  const country_watcher = watch('country')

  const [selectedCountry, setSelectedCountry] = useState<any>({})
  const [countries, setCountries] = useState<any[]>([])
  const [states, setStates] = useState<any[]>([])
  const [cities, setCities] = useState<any[]>([])

  const [isLoading, setLoading] = useState(false)

  const paypalValue = useRef('')

  const { updateUser, updateUserData } = useUser()
  const auth = useAuth()
  const [isPasswordVisible, setPasswordVisible] = useState(false)
  const [isPaypalChangeWarningOpen, setPaypalChangeWarningOpen] = useState(false)

  const togglePasswordVisible = () => setPasswordVisible(!isPasswordVisible)
  const _user = localStorage.getItem('user')

  const [user, setUser] = useState<any>({})

  useEffect(() => {
    if (!_user) return
    setUser(JSON.parse(_user))
  }, [_user])

  const onSubmit = (data: AccountSettingsFormType) => {
    if (data.paypal.toLowerCase() !== paypalValue.current.toLowerCase()) {
      setPaypalChangeWarningOpen(true)
    } else {
      handleUpdateUser()
    }
  }

  const onPasswordFormSubmit = (data: any) => {
    auth?.changePassword(data.oldPass, data.newPass)
  }

  const handleUpdateUser = async () => {
    const data = getValues()
    setPaypalChangeWarningOpen(false)
    setLoading(true)

    await updateUser(data)
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        updateUserData(data)
        setLoading(false)
      })
  }

  const getAllCountries = () => Country.getAllCountries()

  const handleCountryChange = useCallback(
    (_selectedCountryName: any) => {
      const _selectedCountry = getAllCountries().find(
        (c) => c.name?.toLowerCase() === _selectedCountryName?.toLowerCase()
      )
      if (!_selectedCountry) return
      setSelectedCountry(_selectedCountry)
      const statesOfCountry = State.getStatesOfCountry(_selectedCountry.isoCode)
      setStates(statesOfCountry)

      if (!statesOfCountry.length) {
        const citiesOfcountry = City.getCitiesOfCountry(selectedCountry.isoCode)
        if (citiesOfcountry) {
          setCities(citiesOfcountry)
        }
      } else {
        const citiesOfState = City.getCitiesOfState(
          selectedCountry.isoCode,
          statesOfCountry[0].isoCode
        )

        setCities(citiesOfState)
      }
    },
    [selectedCountry]
  )

  useEffect(() => {
    setCountries(getAllCountries())
  }, [])

  useEffect(() => {
    handleCountryChange(country_watcher)
  }, [country_watcher])

  useEffect(() => {
    if (!user) return
    reset({
      firstname: user?.firstname,
      lastname: user?.lastname,
      user_email: user?.user_email,
      paypal: user?.paypal,
      line1: user.line1,
      line2: user.line2,
      state: user.state,
      country: user.country,
      tel: user.tel,
    })
    if (user.paypal && user.paypal !== '') {
      paypalValue.current = user?.paypal
    }
  }, [user])

  return (
    <>
      <CustomModal
        isOpen={isPaypalChangeWarningOpen}
        close={() => setPaypalChangeWarningOpen(false)}
        action={() => handleUpdateUser()}
        title={t('labels.paypalEmailChange')}
      >
        <p className='text-sm text-center text-gray-500'>
          {t('messages.changePaypalEmail')}
        </p>
      </CustomModal>
      <div className='relative flex flex-col mt-10'>
        <div className='max-w-2xl mx-auto mb-4 text-center'>
          <h2 className='text-3xl font-bold tracking-tight text-gray-900 capitalize sm:text-4xl'>
            {t('words.hi')}, {user?.firstname}!
          </h2>
        </div>
        <form
          className='form md:!w-2/3 mx-auto justify-center gap-2 bg-white/90 !p-8'
          onSubmit={handleSubmit(onSubmit)}
        >
          <p className='mt-2 text-lg leading-8 text-gray-600'>
            {t('messages.updateSettingsPrompt')}
          </p>

          <div className='relative flex flex-col items-center justify-between w-full gap-2 mt-8 md:flex-row'>
            <Input
              label={t('authLayout.info.firstName')}
              placeholder={t('authLayout.info.firstName')}
              name='firstname'
              register={register}
              wrapperClassName='flex-1'
              error={errors?.firstname?.message as string}
              isDisabled={isLoading}
            />
            <Input
              label={t('authLayout.info.lastName')}
              placeholder={t('authLayout.info.lastName')}
              name='lastname'
              register={register}
              wrapperClassName='flex-1'
              error={errors?.lastname?.message as string}
              isDisabled={isLoading}
            />
          </div>

          <Input
            label={t('authLayout.info.email')}
            placeholder={t('authLayout.info.email')}
            name='user_email'
            type='email'
            register={register}
            error={errors?.user_email?.message as string}
            isDisabled
          />
          <Input
            label={t('authLayout.info.paypalEmail')}
            placeholder={t('authLayout.info.paypalEmail')}
            name='paypal'
            type='email'
            register={register}
            error={errors?.paypal?.message as string}
            isDisabled={isLoading}
          />
          <div className='relative flex flex-col items-center justify-between w-full gap-2 md:flex-row'>
            <Input
              label={t('authLayout.info.addrLine1')}
              placeholder={t('authLayout.info.addrLine1')}
              name='line1'
              register={register}
              error={errors?.line1?.message as string}
              isDisabled={isLoading}
            />
            <Input
              label={t('authLayout.info.addrLine2')}
              placeholder={t('authLayout.info.addrLine2')}
              name='line2'
              register={register}
              error={errors?.line2?.message as string}
              isDisabled={isLoading}
            />
          </div>
          <SelectInput
            control={control}
            options={countries.map((country) => ({
              label: country.name,
              value: country.name,
            }))}
            label={t('authLayout.info.country')}
            placeholder={t('authLayout.info.country')}
            name='country'
            error={errors?.country?.message as string}
            isDisabled={isLoading}
          />
          <SelectInput
            control={control}
            options={states.map((state) => ({
              label: state.name,
              value: state.name,
            }))}
            label={t('authLayout.info.state')}
            placeholder={t('authLayout.info.state')}
            name='state'
            error={errors?.state?.message as string}
            isDisabled={isLoading}
          />

          {/* <FieldWrapper>
            <label htmlFor='paypal'>
              paypal email{' '}
              {errors['paypal'] ? (
                <Error>
                  <span className='inline-block mt-0.5'>
                    {' '}
                    {errors.paypal.message?.toString()}
                  </span>
                </Error>
              ) : null}
            </label>
            <input
              className='input'
              type='text'
              placeholder='paypal@email.com'
              {...register('paypal', {
                required: false,
                onChange: (e) => {
                  if (!isPaypalChanged.current) {
                    isPaypalChanged.current = true
                  }
                },
               
              })}
            />
          </FieldWrapper> */}

          <Button type='submit' className='mt-4' isLoading={isLoading}>
            {isLoading ? t('buttons.saving') : t('buttons.save')}
          </Button>
        </form>

        <form
          className='form md:!w-2/3 mx-auto justify-center gap-2 bg-white/90 !p-8 mt-8'
          onSubmit={handleSubmit2(onPasswordFormSubmit)}
        >
          <p className='mt-2 text-lg leading-8 text-gray-600'>
            {t('messages.updatePassword')}
          </p>
          <div className='relative flex flex-col items-center justify-between w-full gap-2 mt-8'>
            <FieldWrapper cls='flex-1 w-full'>
              <label htmlFor='oldPass'>
                {t('auth.password.oldPassword')}{' '}
                {errors2['oldPass'] ? (
                  <Error>
                    <span className='inline-block mt-0.5'>
                      {' '}
                      {errors2.oldPass.message?.toString()}
                    </span>
                  </Error>
                ) : null}
              </label>
              <div className='flex items-start justify-between w-full'>
                <input
                  className='input'
                  type={isPasswordVisible ? 'text' : 'password'}
                  placeholder={t('auth.password.oldPassword')}
                  {...register2('oldPass', { required: 'Old Password is Required' })}
                />
                <span
                  className='inline-block pt-4 pl-2 text-sm text-primary-purple'
                  aria-hidden
                  onClick={togglePasswordVisible}
                >
                  {isPasswordVisible ? <EyeOpenIcon /> : <EyeClosedIcon />}
                </span>
              </div>
            </FieldWrapper>
            <FieldWrapper cls='flex-1 w-full'>
              <label htmlFor='newPass'>
                {t('auth.password.newPassword')}{' '}
                {errors2['newPass'] ? (
                  <Error>
                    <span className='inline-block mt-0.5'>
                      {' '}
                      {errors2.newPass.message?.toString()}
                    </span>
                  </Error>
                ) : null}
              </label>
              <div className='flex items-start justify-between w-full'>
                <input
                  className='input'
                  type={isPasswordVisible ? 'text' : 'password'}
                  placeholder={t('auth.password.newPassword')}
                  {...register2('newPass', { required: 'New Password is Required' })}
                />
                <span
                  className='inline-block pt-4 pl-2 text-sm text-primary-purple'
                  aria-hidden
                  onClick={togglePasswordVisible}
                >
                  {isPasswordVisible ? <EyeOpenIcon /> : <EyeClosedIcon />}
                </span>
              </div>
            </FieldWrapper>
          </div>

          <button
            className='mt-2 button primary w-max'
            type='submit'
            style={{
              backgroundColor: whiteLabelData.theme
                ? 'var(--whitelabel-neutral)'
                : '',
            }}
          >
            {t('auth.password.updatePassword')}
          </button>
        </form>
      </div>
    </>
  )
}

export default AccountSettings
