import { DashIcon, MenuIcon } from 'components/shared/Icons'
import { classNames } from 'lib/misc'

import HeaderMenu from './HeaderMenu'
import { useNavigate } from 'react-router'
import { useUI } from 'ctx/UIContext'
import { useEffect, useState } from 'react'
// import Notifications from './Notifications'

const Header = () => {
  const { setSidebarOpen, whiteLabelData } = useUI()

  const navigate = useNavigate()

  const [themeColors, setThemeColors] = useState([])

  useEffect(() => {
    if (
      whiteLabelData.app_name !== 'default' &&
      whiteLabelData.app_name !== undefined &&
      whiteLabelData.theme
    ) {
      setThemeColors(whiteLabelData.theme)
    }
  }, [whiteLabelData])

  const generateBorderGradient = (themeColors: string[]) => {
    return `linear-gradient(to right, ${themeColors.join(', ')})`
  }

  return (
    <header
      className='fixed md:sticky top-0 bg-white w-full rounded-none px-4 py-4 z-[99] min-h-[var(--header-height)]'
      style={{ position: 'relative' }}
    >
      <div className='relative z-10 flex justify-between'>
        <div
          className={classNames(
            'relative flex items-center justify-center gap-1 md:gap-2'
            // isExpanded ? 'left-80' : 'left-20'
          )}
        >
          <button
            type='button'
            className='relative block p-2 text-lg text-white rounded-full md:hidden bg-primary-purple w-max'
            onClick={() => setSidebarOpen(true)}
          >
            <MenuIcon />
          </button>
          <button
            type='button'
            className='relative block p-2 text-lg text-white rounded-full md:hidden bg-primary-purple w-max'
            onClick={() => navigate('/my-submissions')}
          >
            <DashIcon />
          </button>
        </div>
        <div
          className={classNames('relative flex items-center justify-center gap-4')}
        >
          {/* <Notifications /> */}
          <a
            href={
              whiteLabelData.app_name === 'default'
                ? 'https://www.musicdash.com/guides'
                : '/public/guides'
            }
            target={whiteLabelData.app_name === 'default' ? '_blank' : ''}
            rel={whiteLabelData.app_name === 'default' ? 'noreferrer noopener' : ''}
            className='font-semibold transition-all'
          >
            <button className='px-4 py-1 text-white rounded-full bg-primary-purple'>
              Guides
            </button>
          </a>
          <HeaderMenu />
        </div>
      </div>
    </header>
  )
}

export default Header
