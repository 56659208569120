import { Button } from 'components/partials/Button'
import { Card } from 'components/partials/Card'
import PageHeader from 'components/shared/PageHeader'
import { lsget } from 'lib/misc'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import CampaignInfo from './CampaignInfo'
import { useMarketing } from 'store/RootStore'
import { FB_REDIRECT_URL } from 'lib/constants'
import toast from 'react-hot-toast'

const MarketingOverview = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const { getFBAccessToken } = useMarketing()

  useEffect(() => {
    const fetchToken = async () => {
      const fbCode = searchParams.get('code')
      const error = searchParams.get('error')
      const errorReason = searchParams.get('error_reason')
      const errorDescription = searchParams.get('error_description')
      const fAccessToken = lsget('fb_access_token')
      const mAccessToken = lsget('marketing_access_token')
      if (fbCode) {
        const accessToken = await getFBAccessToken(fbCode, FB_REDIRECT_URL)
        if ('details' in accessToken) {
          toast.error('Failed to get access token')
          return
        } else {
          localStorage.setItem('fb_access_token', accessToken.access_token)
          navigate('/marketing-tool')
        }
      } else if (error && errorReason && errorDescription) {
        toast.error(`Could not login: ${errorDescription}`)
      } else if (!fAccessToken && !mAccessToken) {
        navigate('/marketing-tool/login')
      }
    }

    fetchToken()
  }, [])

  return (
    <div className='page-wrapper'>
      <PageHeader title={t('sidebar.marketingTool')} isLoading={false} />
      <Card className='w-full p-5 mt-6 rounded-md'>
        <h1 className='mb-2 text-lg font-bold'>Create Campaign</h1>
        <p>Put your fanbase growth with MusicDASH's automated marketing tools</p>
        <div className='flex items-center gap-2 mt-4'>
          <a href='/marketing-tool/campaign/create'>
            <Button>Start Campaign</Button>
          </a>
        </div>
      </Card>
      <CampaignInfo />
    </div>
  )
}

export default MarketingOverview
