import {
  DeleteObjectCommand,
  ObjectCannedACL,
  PutObjectCommand,
} from '@aws-sdk/client-s3'
import { s3Client } from './aws-config'
import toast from 'react-hot-toast'
import { Upload } from '@aws-sdk/lib-storage'

export const uploadToS3 = async (
  file: File,
  userId: string,
  submissionId: string
) => {
  const fileName = file.name
  const key = `submissions/${userId}/${submissionId}/${fileName}`

  const uploadParams = {
    Bucket: 'musicdash',
    Key: key,
    Body: file,
    ACL: 'public-read' as ObjectCannedACL,
  }

  try {
    const parallelUploads3 = new Upload({
      client: s3Client,
      params: uploadParams,
      leavePartsOnError: false,
    })

    parallelUploads3.on('httpUploadProgress', (progress) => {
      console.log(progress)
    })

    const data = await parallelUploads3.done()
    return data
  } catch (err) {
    console.log('Error', err)
    toast.error("Couldn't upload the file")
  }
}

export const deleteFromS3 = async (
  fileName: string,
  userId: string,
  submissionId: string
) => {
  const key = `submissions/${userId}/${submissionId}/${fileName}`
  const deleteParams = {
    Bucket: 'musicdash',
    Key: key,
    ACL: 'public-read',
  }
  try {
    const data = await s3Client.send(new DeleteObjectCommand(deleteParams))
    return data // For unit tests.
  } catch (err) {
    console.log('Error', err)
    toast.error("Couldn't delete the file")
  }
}

export function toBase64(u8: any) {
  const CHUNK_SIZE = 0x8000 //arbitrary number
  let index = 0
  const length = u8.length
  let result = ''
  let slice
  while (index < length) {
    slice = u8.subarray(index, Math.min(index + CHUNK_SIZE, length))
    result += String.fromCharCode.apply(null, slice)
    index += CHUNK_SIZE
  }
  return btoa(result)
}
