import { Button } from 'components/partials/Button'
import React from 'react'

interface FBLoginProps {
  clientId: string
  redirectUri: string
  scope: string[]
  configId: string
  responseType?: 'token' | 'code'
  display?: 'popup' | 'window' | 'page'
}

const FBLogin = ({
  clientId,
  redirectUri,
  scope,
  configId,
  responseType = 'code',
  display = 'page',
}: FBLoginProps) => {
  const generateFBLoginUrl = () => {
    const url =
      `https://facebook.com/v20.0/dialog/oauth?` +
      `client_id=${clientId}&` +
      `display=${display}&` +
      `redirect_uri=${encodeURIComponent(redirectUri)}&` +
      `response_type=${responseType}&` +
      `state=${decodeURIComponent(
        JSON.stringify({
          permissions: scope,
        })
      )}&` +
      `config_id=${configId}&` +
      `scope=${scope}`
    return url
  }

  const handleClick = () => {
    const url = generateFBLoginUrl()
    // Redirect the user to the Facebook login URL in the same window
    window.location.href = url
  }

  return <Button onClick={handleClick}>Get Started</Button>
}

export default FBLogin
