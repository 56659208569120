import { classNames, formatDate } from 'lib/misc'
import React, { FC } from 'react'
import { useUser } from 'store/RootStore'

const PageFooter = () => {
  return (
    <div
      className={classNames(
        // 'row center text-xs text-center mt-64',
        'absolute bottom-0 left-0 py-2 text-center w-full text-[0.6rem]'
      )}
    >
      © {new Date().getFullYear()} Music DASH INC. All Rights Reserved. All Rights
      Reserved..
    </div>
  )
}

interface IPDFRenderer {
  data?: any
  signature: string
  userData?: any
}

const PDFRenderer: FC<IPDFRenderer> = ({ data, signature, userData }) => {
  const { user } = useUser()

  const sub = data[0]

  const _user = userData ? userData : user
  const name = _user.firstname + ' ' + _user.lastname

  return (
    <>
      <div className='pdf-inner'>
        <div className='relative flex flex-col items-center justify-start w-full pt-8'>
          <img
            src='/assets/nfhits-logo-white-bg.png'
            alt='header-img'
            className='relative h-auto w-full max-w-[150px] object-cover object-center'
          />
          <h4 className='mt-4 font-bold'>
            AUDIO LICENSING DISTRIBUTION & CMS AGREEMENT
          </h4>
        </div>
        <div className='relative flex flex-col items-start justify-start w-full gap-2 mt-8'>
          <p>
            The following shall constitute the distribution agreement (“Agreement”)
            as of <strong>{formatDate(new Date())}</strong> (the “Effective Date”),
            by and you <strong>{`${name}`}</strong> (hereinafter referred to as
            “Licensor/Artist”, “you” and “your”){' '}
            <strong>
              ({sub.artistName} - {sub.releaseTitle})
            </strong>{' '}
            with address{' '}
            <strong>{`${_user.line1 || ''} ${_user.line2 || ''} ${
              _user.country || ''
            } ${_user.state || ''}`}</strong>{' '}
            and NFHITS , Inc., with an address at 401 E Las Olas Blvd. (Suite 1400),
            Ft. Lauderdale Florida 33301 (“NFHITS”) (hereinafter referred to as
            “Distributor”, “we” “us” and “our”) regarding the use of our digital
            distribution services and the distribution-related elements of our
            Service.
          </p>

          <p className=''>
            <strong>RIGHTS GRANTED: </strong> The Licensor hereby grants to the
            Licensee for the Term a non-exclusive but unlimited, and unrestricted
            license to use, exploit, store, copy, and distribute your Content (as
            hereinafter defined), throughout the universe, to any consumer and B2B
            digital audio stores, distributors, and other third-party services (e.g.,
            synchronization with visual images) available through the Service
            (“Third-Party audio Services”) in all formats and mediums, whether now
            known or hereafter discovered. Provided, however, you allow Distributor
            to, distribute any individual master sound recording which is part of the
            Content as a stand-alone “single/album/EP/full music catalogue/multiple
            singles/multiple albums/multiple EPs/music videos” on any Third-Party
            audio Service but only in connection with album compilation records.{' '}
            <br />
            To the extent necessary for Distributor to facilitate the distribution to
            Third-Party audio Services authorized by you, you hereby grant to
            Distributor the right and authorization to distribute, license, sell, AI
            Machine learning license/synch/ video games/ micro synch Youtube. Tiktok,
            Instagram and much more, stream, transmit, synchronize, perform, promote,
            and otherwise exploit such Content and any and all rights in and to the
            sound recording(s) and the underlying composition(s) embodied in such
            Content, throughout the world, in any and all media or formats now known
            or hereafter devised, including without limitation by: permanent digital
            downloads; temporary digital downloads; interactive streaming;
            non-interactive streaming; interactive radio; and non-interactive radio
            (DMCA compliant, compulsory license); semi-interactive radio (voluntary
            license); the servicing of any and all “existing” and “to be” developed
            digital and mobile delivery methods. <br /> This contract serves to
            authorize the channels to join and access the Distributor's Content
            Management System (CMS). By entering into this agreement, the channels
            agree to grant the Distributor the necessary exclusive rights and
            permissions to manage and distribute their content through the
            Distributor's CMS. The Distributor shall have the authority to upload,
            promote, and monetize the content provided by the channels within their
            designated sections of the CMS. Both parties agree to comply with the
            terms and conditions outlined in this contract and work collaboratively
            to enhance the visibility and reach of the content across the
            Distributor's platforms. You grant NF Hits, Inc. permission to license
            your channel management rights to its partners, including but not limited
            to NF Hits, Inc./AdRev and other third parties. <br /> You authorize
            Distributor to sub-license any rights granted by you under this
            Distribution Agreement to any Third-Party audio Services, as required to
            provide the service hereunder. Distributor does not guarantee placement
            of the Content with any Third-Party audio Services and reserves the right
            to remove any Content from its service and reject distribution of any
            content at its discretion.
          </p>
        </div>
        <PageFooter />
      </div>
      <div className='pdf-inner'>
        <div className='relative flex flex-col items-start justify-start w-full gap-2 mt-8'>
          <p className=''>
            With respect to the foregoing, Licensor hereby represents and warrants
            that Licensor is the sole and non-exclusive copyright owner of the
            Content. Licensor assumes all responsibility for securing all of the
            Intellectual Property Rights and copyrights necessary for the
            distribution of the Content in accordance with this Agreement and
            indemnifies the Distributor and its affiliates and Distributors from any
            liability concerning intellectual property, content copyrights, and
            clearances, including, but not limited to audio and clearances and
            publishing rights, if any, by any third party.
          </p>
          <p className=''>
            In the event that Licensor designates Distributor as its publisher in
            connection with the audio compositions embodied in any Content (e.g., by
            way of email or other written notice to Distributor), then such rights
            and obligations shall be subject to Exhibit A attached hereto and made a
            part hereof.
          </p>
          <p className=''>
            For the avoidance of doubt, in the event Licensor does not elect to have
            Distributor act as a publisher pursuant to Exhibit A, it is understood
            and agreed that, although streaming platforms in North America (excluding
            Canada) account for and pay publishing royalties together with the
            streaming and download payments, for the territories of Canada and the
            rest of the world, such publishing money shall be retained by such online
            digital platforms, and the publisher shall be responsible for filing a
            claim to collect such monies.
          </p>
          <p className=''>
            <strong>
              <span className='mr-8'>1.</span>TERM:
            </strong>{' '}
            The Term of this Agreement shall commence with an initial term beginning
            on the Effective Date and shall continue for five (5) years. Thereafter,
            Distributor shall have the irrevocable option to extend this Agreement
            for an additional five (5) years.And another additional 5 Years , and it
            shall continue to renew automatically without consent . It is understood
            and agreed that Distributor shall be permitted during the Term to enter
            into licensing agreements which extend past the term of this Agreement
            and such licensing agreements shall remain in effect for their respective
            stated duration nothingwithstanding the Term hereof.. Notwithstanding
            anything to the contrary contained herein, Licensor shall be entitled (i)
            to enter into any agreement with any other party to use, exploit and
            distribute the Content on a non-exclusive basis and (ii).
          </p>
          <p className=''>
            <strong>
              <span className='mr-8'>2.</span>LICENSE FEE/ ROYALTIES:
            </strong>{' '}
            In exchange of grant of these license rights, Distributor shall pay to
            Licensor Seventy percent (70%) of Net Revenue generated for Content
            provided under this Agreement in a prompt manner after deduction of the
            operator shares and the applicable withholding taxes (if any). License
            Fee/Royalty percentages for all third party royalty payments shall be the
            sole responsibility of Licensor. These include master licensing fees,
            publishing license and moral right fees, if applicable, and any other
            fees, which are applicable to use the Content. Licensor shall be solely
            responsible for (a) all record royalties due to artists, producers and
            other persons who performed in the making of the Content and other
            royalty participants, (b) all royalties due to any party as a result of
            samples included in the Content (c) all payments that may be required
            under collective bargaining agreements applicable to you and your
            affiliates, and (d) all audio publishing licenses and royalties. For
            digital download sales in the United States, your payment typically
            includes the mechanical royalty on the underlying composition. In the
            case of the iTunes Match service, your payment includes a publishing
            portion that covers both the mechanical and public performance royalties.
            If you do not own or control the underlying composition(s) in your sound
            recording(s), it is your obligation to pay these publishing royalties to
            the person or entity that does.
          </p>
          <p className=''>
            <strong>
              <span className='mr-8'>3.</span> ACCOUNTING/REPORTING:
            </strong>{' '}
            Distributor shall provide quarterly accounting statements to Licensor
            within forty-five (45) calendar days from end of each calendar quarter
            (except that statements may be rendered on an annual basis if any
            accounting shall reflect earnings of less than one hundred dollars). All
            statements rendered shall be binding on Licensor and not subject to any
            objection for any reason unless specific written objection, stating the
            basis thereof, is received by Distributor within one (1) year of the date
            such statement should have been rendered, in which event such statements
            shall be binding in all respects except those specifically objected to as
            aforesaid. Neither party shall have any right to commence any legal
            action concerning the foregoing unless specific written objection has
            been made within the timeframes specified above.
          </p>
        </div>
        {/* <PageFooter /> */}
      </div>
      <div className='pdf-inner'>
        <div className='relative flex flex-col items-start justify-start w-full gap-2 mt-8'>
          <p className=''>
            <strong>
              <span className='mr-8'>4.</span>REPRESENTATIONS AND WARRANTIES:
            </strong>
            Licensor represents and warrants that it has the full authority to act on
            behalf of any and all owners of any right, title and interest in and to
            the Content. Each party represents and warrants that it has full
            authority to enter into this Agreement and to fully perform its
            obligations hereunder and has obtained all necessary third-party
            consents, licenses and permissions necessary to enter into and fully
            perform its obligations herein. In furtherance of the foregoing, Licensor
            agrees to execute any and all additional documentation as maybe be
            necessary or expedient for Distributor to use and exploit all of the
            rights granted to Distributor hereunder, and in the event Licensor fails
            to timely execute such documentation Licensor hereby grants Distributor a
            power of attorney to execute such document for and on behalf of
            Distributor, and such power is hereby coupled with an interest. Each
            party represents and warrants that it owns or controls the necessary
            rights in order to make the grant of rights, licenses and permissions
            herein, and that the exercise of such rights, licenses and permissions by
            the other party hereto shall not violate or infringe the rights of any
            third party. Each party represents and warrants that it shall not act in
            any manner which conflicts or interferes with any existing commitment or
            obligation of such party, and that no agreement previously entered into
            by such party will interfere with such party's performance of its
            obligations under this Agreement. Each party represents and warrants that
            it shall perform in compliance with any applicable laws, rules and
            regulations of any governmental authority.
          </p>
          <p className=''>
            <strong>
              <span className='mr-8'>5.</span>DEFINITIONS:
            </strong>
            “Content” shall mean any and all audio and audio/visual recordings
            delivered by Licensor to Distributor hereunder including any and all
            Intellectual Property Rights arising out of or related thereto.
            “Confidential Information” means any data or information disclosed
            hereunder (whether written, oral or graphical) that relates to the
            disclosing party's products, financial information, technology, research,
            development, customers or business activities, and which is confidential
            or proprietary to or a trade secret of the disclosing party, provided
            that either the information is marked or identified as confidential at
            the time of disclosure, or that it is reasonably apparent to the
            recipient that the information is confidential. “Effective Date” shall
            mean the date upon which Licensor signs the Agreement. “Force Majeure”
            shall mean any earthquake, volcanic eruption, act of war, sabotage,
            strike(s) as are not limited to the establishments of the Parties, acts
            of terrorism, invasion, revolution, event of military or usurped power,
            civil war, riot, civil commotion or disorder, acts of Government,
            sanctions imposed by the authority, natural calamity or any other event
            mutually agreed by the party as being beyond the reasonable control of
            the defaulting Party. “Intellectual Property Rights” shall mean all
            vested, contingent and future intellectual property rights including but
            not limited to goodwill, reputation, rights in confidential information,
            copyright, trademarks, logos, service marks, devices, plans, models,
            diagrams, specifications, source and object code materials, data and
            processes, design rights, patents, know how, trade secrets, inventions,
            get up, database rights (whether registered or unregistered) and any
            products or registrations for the protection of these rights. “Net
            Revenue” shall mean the revenue received by Distributor from Customers
            earned from the Content less all amounts paid or payable by Distributor
            to third parties relating to the Content including, without limitation,
            expenses, commissions, royalties, percentage sharing, discounts, refunds,
            rebates, charge backs, taxes, duties, customs, and all other fees paid to
            carriers and others for payment processing, carriage, platform licensing
            and/or related services. “Content” shall mean any and all Intellectual
            Property Rights arising out of or related to the sound recordings and the
            audioal compositions embodied therein of Licensor including, but not
            limited to, the sound recording/master recording, the underlying audioal
            composition, the Artists’ names, likenesses, album art, lyrics,
            trademarks, logos, etc.
          </p>
        </div>
      </div>
      <div className='pdf-inner'>
        <div className='relative flex flex-col items-start justify-start w-full gap-2 mt-8'>
          <p className=''>
            <strong>
              <span className='mr-8'>6.</span>CONFIDENTIALITY:
            </strong>{' '}
            Neither party may disclose the terms of this Agreement to any third party
            without the other party’s prior written consent. Notwithstanding the
            foregoing, each party may disclose the Confidential Information (i) to
            the extent required by a court of competent jurisdiction or other
            governmental authority or otherwise as required by law; provided, however
            that the party required to so disclose the Confidential Information of
            the other party shall use commercially reasonable efforts to minimize
            such disclosure and shall provide written notice of such disclosure and
            consult with and assist the other party in obtaining a protective order
            prior to such disclosure or (ii) on a “need-to-know” basis under an
            obligation of confidentiality to its legal counsel, accountants, banks
            and other financing sources and their advisors.
          </p>
          <p className=''>
            <strong>
              <span className='mr-8'>7.</span>INDEMNIFICATION:
            </strong>{' '}
            Each Party shall defend, indemnify and hold harmless the other party from
            and against any and all third party claims, damages, liabilities, costs
            and expenses, including reasonable legal fees and expenses, arising out
            of or relating to the breach of any representation or warranty on its
            part under this Agreement. In consideration of Distributor’s entering
            into this Agreement, you covenant and agree, in respect to the Content
            not to bring, assert, pursue, maintain, join in or directly and/or
            indirectly support, assist, fund, lend resources to, or otherwise
            participate in any litigation, involving or asserting any claim based
            upon or alleging any form of copyright infringement arising from
            Distributor’s or any Third Party audio Service’s exploitation of the
            rights licensed by you to us herein through the operation of our service,
            and in accordance with this Agreement, that you have, had or may have
            against Amuse prior to the Effective Date or during the Term. In
            addition, where you have authorized Distributor to distribute the Content
            to and on YouTube, you covenant not to sue any user of the YouTube
            service who synchronizes any Content in a video uploaded to the YouTube
            website, to the extent your claim is based on the alleged infringement of
            rights granted by you to Distributor herein.
          </p>
          <p className=''>
            <strong>
              <span className='mr-8'>8.</span>WAIVER:
            </strong>{' '}
            The failure or delay of either party to enforce or to exercise, at any
            time or for any period of time, any term of or any right, power or
            privilege arising pursuant to this Agreement does not constitute and
            shall not be construed as a waiver of such term or right and shall in no
            way affect a party’s right later to enforce or exercise it nor shall any
            single or partial exercise of any remedy, right, power or privilege
            preclude any further exercise of the same or the exercise of any other
            remedy, right, power or privilege.
          </p>
          <p className=''>
            <strong>
              <span className='mr-8'>9.</span> SEVERABILITY:
            </strong>
            : If any term or provision of this Agreement is determined to be illegal,
            unenforceable, or invalid in whole or in part for any reason, such
            illegal, unenforceable, or invalid provisions or part thereof shall be
            stricken from this Agreement, and such provision shall not affect the
            legality, enforceability, or validity of the remainder of this Agreement.
            If any provision or part thereof of this Agreement is stricken in
            accordance with the provisions of this section, then this stricken
            provision shall be replaced, to the extent possible, with a legal,
            enforceable, and valid provision that is as similar in tenor and purpose
            to the stricken provision as is legally possible.
          </p>
          <p className=''>
            <strong>
              <span className='mr-8'>10.</span> SURVIVAL:
            </strong>
            Except as otherwise specifically provided herein termination of this
            Agreement for any reason shall not release any Party hereto in respect of
            any act or omission prior to such termination, nor shall any such
            termination hereof affect in any way the survival of any right, duty or
            obligation of any such Party hereto which is expressly stated elsewhere
            in this Agreement or by necessary implication should operate to survive
            termination hereof including, without limitation the provisions of
            conditions relating to confidentiality, intellectual property rights,
            provisions relating to warranties, liquidated damages.
          </p>
        </div>
      </div>
      <div className='pdf-inner'>
        <div className='relative flex flex-col items-start justify-start w-full gap-2 mt-8'>
          <p className=''>
            <strong>
              <span className='mr-8'>11.</span>GOVERNING LAW:
            </strong>
            This agreement will be governed by and construed in accordance with the
            laws of the State of New York applicable to contracts made and performed
            entirely in the State of New York. Any dispute arising out of the
            interpretation and/or the implementation of this Agreement shall be
            finally settled by arbitration in the State of New York, in accordance
            with the laws and customs of the State of New York, and its leading
            arbitration associations. No waiver of any provision of or default under
            this agreement nor any failure to exercise any party's rights hereunder
            shall prejudice the rights of either party thereafter, nor shall it be a
            precedent for the future. The parties hereto expressly agree that, at all
            times, only the arbitrators located within the State and city of New York
            shall have jurisdiction to hear and determine any and all matters arising
            out of or related to this Agreement, and the parties consent to such
            jurisdiction and venue and consent to the granting of such legal and
            equitable relief as is deemed appropriate only by such arbitrators (and
            the court(s) located within such jurisdiction, as applicable).
          </p>

          <p className=''>
            <strong>
              <span className='mr-8'>12.</span>FORCE MAJEURE:
            </strong>{' '}
            Neither Party shall be liable to the other if, and to the extent, that
            the undertaking or performance or delay in undertaking or performance of
            any of its activities, duties, obligations or functions under this
            Agreement is prevented, restricted, delayed or interfered with, due to
            circumstances beyond the Party's reasonable control, including Force
            Majeure.
          </p>
          <p className=''>
            <strong>
              <span className='mr-8'>13.</span>NOTICE:
            </strong>
            All notices under the agreement shall be in writing and will be given by
            certified mail with return receipt or by an international courier, at the
            respective addresses shown above or such other address as either party
            shall designate. Only notices given specifically in accordance with the
            foregoing shall be deemed effective.
          </p>
          <p className=''>
            <strong>
              <span className='mr-8'>14.</span>ENTIRE AGREEMENT:
            </strong>
            This Agreement supersedes all prior agreements, arrangements and
            understandings; whether oral or written, between both parties in relation
            to the matter dealt with herein and constitutes the entire agreement and
            understanding between both parties. In the event that any provision of
            this Agreement or part thereof shall be held void, illegal or
            unenforceable, it shall be rendered invalid to that extent but shall in
            no way affect or prejudice the remaining provision or other provisions of
            this Agreement. This Agreement shall be binding upon the parties hereto
            and their respective successors and assigns. The Distributor shall have
            the right to assign all rights, title, benefits and obligations of this
            Agreement to any third party
          </p>
          <p className=''>
            <strong>
              <span className='mr-8'>15.</span>
            </strong>
            15. Each party hereto has had access to legal counsel of its choice, been
            advised of the opportunity to avail itself of same, and has done so or
            knowingly and willingly waived such right.
          </p>
          <p className='mt-4'>
            <strong>
              <span className='mr-8'></span>
            </strong>
            If these terms conform to your understanding, this shall constitute an
            agreement between us when executed by you below.
          </p>
        </div>
        <div className='relative grid w-full grid-cols-3 mt-72 place-items-center'>
          <div className='relative flex flex-col items-center w-full'>
            <img src='/assets/owner_sign.png' alt='owner sign' />
            <hr className='w-full mt-3 text-black' />
            <span className=''>By: NF Hits, Inc.</span>
          </div>
          <div />
          <div className='relative flex flex-col items-center w-full'>
            {signature ? (
              <img src={signature} alt='account sign' />
            ) : (
              <strong>{name}</strong>
            )}
            <hr className='w-full mt-3 text-black' />
            <span className=''>By: {name}</span>
          </div>
        </div>
        <PageFooter />
      </div>
    </>
  )
}

export default PDFRenderer
