export const formStepTransition = {
  y: { type: 'tween', duration: 0.3, ease: 'easeOut' },
  opacity: { duration: 0.2, type: 'tween', ease: 'easeOut' },
}

export const formStepVariants = {
  enter: (direction: number) => {
    return {
      y: direction > 0 ? -250 : 250,
      // opacity: 0,
    }
  },
  center: {
    y: 0,
    opacity: 1,
    zIndex: 1,
  },
  exit: (direction: number) => {
    return {
      y: direction < 0 ? -250 : 250,
      // opacity: 0,
      zIndex: 0,
    }
  },
}

export const tabTransition = { ease: 'easeOut', duration: 0.3 }
export const tabStepVariants = {
  enter: (direction: number) => {
    return {
      y: direction > 0 ? -25 : 25,
      opacity: 0,
    }
  },
  center: {
    y: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      y: direction < 0 ? 25 : -25,
      opacity: 0,
    }
  },
}
