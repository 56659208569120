import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'jotai'
import './i18n'

import 'lib/aws-config'
import App from 'App'

import 'styles/index.scss'
import LoadingScreen from 'components/layout/LoadingScreen'

import { MainContextProvider } from 'ctx/MainContext'
import { AuthContextProvider } from 'ctx/AuthContext'
import { UIContextProvider } from 'ctx/UIContext'
import { GoogleOAuthProvider } from '@react-oauth/google'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <Provider>
      <BrowserRouter>
        <Suspense fallback={<LoadingScreen isLoading />}>
          <UIContextProvider>
            <AuthContextProvider>
              <MainContextProvider>
                <GoogleOAuthProvider clientId='1093981604147-tdpbi8ukhrqr2dhqlhjomalcl0ou022u.apps.googleusercontent.com'>
                  <App />
                </GoogleOAuthProvider>
              </MainContextProvider>
            </AuthContextProvider>
          </UIContextProvider>
        </Suspense>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
)
