import PageHeader from 'components/shared/PageHeader'
import { useUser } from 'store/RootStore'
import useEarnings from 'hooks/useEarnings'
import { useTranslation } from 'react-i18next'
import BalanceBreakdown from 'components/shared/Analytics/BalanceBreakdown'
import SubmissionEarningsTable from 'components/shared/Analytics/SubmissionEarningsTable'
import EarningsChart from 'components/shared/Analytics/EarningsChart'

const Analytics = () => {
  const { user } = useUser()
  const { t } = useTranslation()

  const {
    creditBalance,
    royaltyEarnings,
    userEarnings,
    submissionEarnings,
    updateDuePayments,
    pendingRequests,
  } = useEarnings(user.user_id)

  return (
    <div className='page-wrapper'>
      <PageHeader title={t('analytics.title')} hasCreateSubmissionTrigger={false} />
      <div className='w-full h-full pt-16 pb-16 overflow-y-auto md:pt-8'>
        <div className='relative flex flex-col-reverse items-start justify-start w-full gap-2 lg:flex-row h-max'>
          <div className='flex flex-col w-full gap-4'>
            <div className=''>
              <EarningsChart data={userEarnings} />
            </div>
            <div className='h-full'>
              {submissionEarnings.length > 0 && (
                <SubmissionEarningsTable data={submissionEarnings} />
              )}
            </div>
          </div>
          <div className='w-px h-full bg-primary-purple/5' />
          <div className='relative h-full w-full lg:max-w-[500px]'>
            <BalanceBreakdown
              creditBalance={creditBalance}
              royaltyEarnings={royaltyEarnings}
              pendingRequests={pendingRequests}
              updateDuePayments={updateDuePayments}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Analytics
