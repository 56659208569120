import { FieldWrapper, Error } from 'components/shared/FormUtils'
import { genresList, lyricsLanguage } from 'data/mock-data'
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { Controller, useForm } from 'react-hook-form'
import TimeField from 'react-advanced-timefield'
import PageHeader from 'components/shared/PageHeader'
import { useNavigate } from 'react-router'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useSubmissions } from 'store/RootStore'
import { useUI } from 'ctx/UIContext'
import { toast } from 'react-hot-toast'
import { filterObject, formatDate } from 'lib/misc'
import { Dialog } from '@headlessui/react'
import { Button } from 'components/partials/Button'
import { Input, SelectInput } from 'components/partials/Inputs'
import { useTranslation } from 'react-i18next'
import { parse } from 'date-fns'

const MetadataForm: FC<{
  submission: any
  hasMetadata: boolean
  type: string
  getSub: () => void
}> = ({ submission, hasMetadata, type = 'single', getSub }) => {
  const { t } = useTranslation()
  const schema = yup.object().shape({
    trackTitle: yup.string().required(t('errors.trackTitleRequired')),
    lyrics: yup.string().required(t('errors.lyricsRequired')),
    lyrics_writer: yup.string().required(t('errors.lyricsWriterRequired')),
    composer: yup.string().required(t('errors.composerRequired')),
    lyricsLanguage: yup.string().required(t('errors.lyricsLanguageRequired')),
    titleLanguage: yup.string().required(t('errors.titleLanguageRequired')),
    parentalAdvisory: yup.string().required(t('errors.parentalAdvisoryRequired')),
    digitalReleaseDate: yup
      .date()
      .transform((value, originalValue, context) => {
        if (context.isType(value)) return value
        return parse(originalValue, 'dd/MM/yyyy', new Date())
      })
      .required(t('errors.digitalReleaseDateRequired')),
    genre: yup.string().required(t('errors.genreRequired')),
    sub_genre: yup.string().required(t('errors.subGenreRequired')),
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    control,
    getValues,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  })

  const navigate = useNavigate()

  const { submitMeta, updateMeta, getAlbums, createAlbum } = useSubmissions()
  const { setLoading } = useUI()

  const [time, setTime] = useState('00:00:00')

  const [albums, setAlbums] = useState<any[]>([])
  const [isCreateAlbumOpen, setCreateAlbumOpen] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)

  useEffect(() => {
    reset({
      ...submission,
      trackTitle: submission.releaseTitle,
      selected_album: submission.album,
    })

    if (!submission.preview_start || submission.preview_start === 'None') {
      setTime('00:00:00')
      // setTime(submission.preview_start)
    } else {
      setTime(
        submission.preview_start
          .split(':')
          .map((val: string) => (val.length < 2 ? '0' + val : val))
          .join(':')
      )
    }
  }, [JSON.stringify(submission), albums])

  const onSubmit = async (data: any) => {
    const tempdata = {
      ...data,
      version:
        data.version === undefined || data.version === '' || data.version === null
          ? '1.0'
          : data.version,
    }
    const _data = filterObject({
      ...tempdata,
      artwork: submission.artWork,
      additional_file: submission.file,
      digitalReleaseDate: formatDate(data.digitalReleaseDate),
    })

    if (hasMetadata) {
      setSubmitting(true)
      await updateMeta(_data)
        .then((res) => {
          if ('detail' in res) {
            toast.error(res.detail)
          } else {
            toast.success(res.message)
          }
        })
        .catch((err) => {
          console.log(err)
          toast.error(t('errors.anerror'))
        })
        .finally(() => {
          setSubmitting(false)
          getSub()
          // navigate('/my-submissions')
        })
    } else {
      setSubmitting(true)
      await submitMeta(_data)
        .then((res) => {
          if ('detail' in res) {
            toast.error(res.detail)
          } else {
            toast.success(res.message)
          }
        })
        .catch((err) => {
          console.log(err)
          toast.error(t('errors.anerror'))
        })
        .finally(() => {
          setSubmitting(false)
          getSub()
          // navigate('/my-submissions')
        })
    }
  }

  const onCancel = (e: any) => {
    e.preventDefault()
    navigate('/my-submissions')
  }

  const loadAlbums = useCallback(async () => {
    await getAlbums().then((res) => {
      setAlbums(res)
    })
  }, [])

  // useEffect(() => {
  //   loadAlbums()
  // }, [])

  const openCreateAlbum = async (e: any) => {
    e.preventDefault()
    setCreateAlbumOpen(true)
  }

  const handleCreateAlbum = async (data: any) => {
    setLoading(true)
    await createAlbum(data.Album_name, data.label)
      .then((res) => {
        if (res && 'message' in res) {
          toast.success(res.message)
        }
      })
      .finally(() => {
        setCreateAlbumOpen(false)
        loadAlbums()
        setLoading(false)
      })
  }

  // useAutoSave(() => {
  //   const values = getValues()
  //   onSubmit(values)
  // }, 35 * 1000)

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='flex flex-col items-center justify-center bg-white rounded-lg shadow-sm'
      >
        <PageHeader
          title={
            submission?.releaseTitle
              ? `${submission.releaseTitle} - ${type}`
              : `Untitled - ${type}`
          }
          hasCreateSubmissionTrigger={false}
          additionalElements={
            <div className='flex items-center justify-end gap-1'>
              <Button type='button' onClick={onCancel} variant='destructive'>
                {t('buttons.cancel')}
              </Button>
              <Button
                type='submit'
                variant='outline'
                disabled={isSubmitting}
                isLoading={isSubmitting}
              >
                {isSubmitting ? t('buttons.saving') : t('buttons.saveChanges')}
              </Button>
            </div>
          }
          className='bg-white'
        />
        <div className='w-full mt-5'>
          <div className='grid items-start justify-between w-full grid-cols-1 gap-4 p-5 mb-4 rounded-lg md:grid-cols-2 lg:grid-cols-3'>
            <Input
              label={t('labels.trackTitle')}
              placeholder={t('labels.trackTitle')}
              name='trackTitle'
              register={register}
              error={errors?.trackTitle?.message as string}
            />
            <Input
              label={t('labels.version')}
              placeholder={t('labels.version')}
              name='version'
              register={register}
              error={errors?.version?.message as string}
            />
            <SelectInput
              control={control}
              options={
                genresList &&
                genresList.map((genre) => ({
                  label: genre.name,
                  value: genre.name,
                }))
              }
              label={t('labels.genre')}
              placeholder={t('labels.selectGenre')}
              name='genre'
              value={watch('genre')}
              error={errors?.genre?.message as string}
            />
            <SelectInput
              control={control}
              options={
                genresList &&
                genresList.map((genre) => ({
                  label: genre.name,
                  value: genre.name,
                }))
              }
              label={t('labels.subGenre')}
              placeholder={t('labels.selectSubGenre')}
              name='sub_genre'
              error={errors?.sub_genre?.message as string}
            />
            <SelectInput
              control={control}
              options={
                lyricsLanguage &&
                lyricsLanguage.map((lang) => ({
                  label: lang.name,
                  value: lang.name,
                }))
              }
              label={t('labels.lyricsLanguage')}
              placeholder={t('labels.selectLyricsLanguage')}
              name='lyricsLanguage'
              error={errors?.lyricsLanguage?.message as string}
            />
            <SelectInput
              control={control}
              options={
                lyricsLanguage &&
                lyricsLanguage.map((lang) => ({
                  label: lang.name,
                  value: lang.name,
                }))
              }
              label={t('labels.titleLanguage')}
              placeholder={t('labels.selectTitleLanguage')}
              name='titleLanguage'
              error={errors?.titleLanguage?.message as string}
            />
            <SelectInput
              control={control}
              options={['YES', 'NO', 'CLEAN'].map((val) => ({
                label: val,
                value: val,
              }))}
              label={t('labels.parentalAdvisory')}
              placeholder={t('labels.selectParentalAdvisory')}
              name='parentalAdvisory'
              error={errors?.parentalAdvisory?.message as string}
            />
            <FieldWrapper>
              <label htmlFor='digitalReleaseDate'>
                {t('labels.digitalReleaseDate')}
                {errors['digitalReleaseDate'] ? (
                  <Error>
                    <span className='inline-block mt-0.5'>
                      {errors?.digitalReleaseDate?.message?.toString()}
                    </span>
                  </Error>
                ) : null}
              </label>
              <input
                className='input'
                type='date'
                defaultValue={formatDate(new Date())}
                {...register('digitalReleaseDate')}
              />
            </FieldWrapper>
            <FieldWrapper>
              <label htmlFor={'preview_start'} className='flex flex-col'>
                {t('labels.previewStart')}
                {errors['preview_start'] ? (
                  <Error>
                    <span className='inline-block mt-0.5'>
                      {errors?.preview_start?.message?.toString()}
                    </span>
                  </Error>
                ) : null}
              </label>
              <Controller
                name='preview_start'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TimeField
                    value={time}
                    showSeconds
                    onChange={(e: any, v: any) => {
                      setTime(v)
                      field.onChange(v)
                    }}
                    style={{
                      width: '100%',
                      padding: '0.5rem',
                      color: 'inherit',
                    }}
                  />
                )}
              />
            </FieldWrapper>

            <Input
              label={t('labels.lyricsWriter')}
              placeholder={t('labels.lyricsWriter')}
              name='lyrics_writer'
              register={register}
              error={errors?.lyrics_writer?.message as string}
            />
            <Input
              label={t('labels.composer')}
              placeholder={t('labels.composer')}
              name='composer'
              register={register}
              error={errors?.composer?.message as string}
            />

            {type?.toLowerCase() === 'album' ? (
              <>
                <SelectInput
                  control={control}
                  options={albums.map((album) => ({
                    label: album.Album_name,
                    value: album.Album_name,
                  }))}
                  label={t('labels.album')}
                  placeholder={t('labels.selectAlbum')}
                  name='selected_album'
                  error={errors?.selected_album?.message as string}
                  additionalComponents={
                    <button
                      className='font-semibold button muted small'
                      onClick={openCreateAlbum}
                    >
                      + create album
                    </button>
                  }
                />
              </>
            ) : null}
            <FieldWrapper cls='relative col-span-full'>
              <label htmlFor='lyrics'>
                {t('labels.lyrics')}{' '}
                {errors['lyrics'] ? (
                  <Error>
                    <span className='inline-block mt-0.5'>
                      {errors.lyrics.message?.toString()}
                    </span>
                  </Error>
                ) : null}
              </label>

              <textarea
                className='input flex-1 !mb-0'
                cols={30}
                rows={5}
                placeholder={t('labels.lyrics')}
                {...register('lyrics')}
              ></textarea>
            </FieldWrapper>
          </div>
        </div>
      </form>
      <NewAlbumForm
        isOpen={isCreateAlbumOpen}
        setOpen={setCreateAlbumOpen}
        afterSubmit={handleCreateAlbum}
      />
    </>
  )
}

// new album form

type AlbumForm = {
  Album_name: string
  label: string
}

const defaultAlbumFormValues: AlbumForm = {
  Album_name: '',
  label: '',
}

const albumFormSchema = yup.object().shape({
  Album_name: yup.string().required('Album Name is required'),
  label: yup.string().required('Album Label is required'),
})

const NewAlbumForm: FC<{
  isOpen: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  afterSubmit: (data: AlbumForm) => void
}> = ({ isOpen, setOpen, afterSubmit }) => {
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<AlbumForm>({
    defaultValues: defaultAlbumFormValues,
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(albumFormSchema),
  })

  const handleAddNewAlbum = (data: any) => {
    afterSubmit(data)
    reset({
      Album_name: '',
      label: '',
    })
  }

  return isOpen ? (
    <Dialog
      open={true}
      onClose={() => setOpen(false)}
      as='div'
      className='relative z-top'
    >
      <div className='fixed inset-0 bg-black/40' aria-hidden='true' />
      <div className='fixed inset-0 flex items-center justify-center'>
        <Dialog.Panel
          as='form'
          onSubmit={handleSubmit(handleAddNewAlbum)}
          className='w-full max-w-md p-6 mx-2 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl'
        >
          <Dialog.Title
            as='h3'
            className='mb-4 text-lg font-semibold leading-6 text-center text-gray-900 capitalize'
          >
            {t('labels.newAlbum')}
          </Dialog.Title>
          {/* <Dialog.Description>{subtitle}</Dialog.Description> */}
          <div className='mt-2 p-2 max-h-[50vh] overflow-y-auto'>
            <Input
              label={t('labels.albumName')}
              placeholder={t('labels.albumName')}
              name='Album_name'
              register={register}
              error={errors?.Album_name?.message as string}
              isRequired
            />
            <Input
              label={t('labels.label')}
              placeholder={t('labels.label')}
              name='label'
              register={register}
              error={errors?.label?.message as string}
              isRequired
              wrapperClassName='mt-3'
            />
          </div>
          <div className='relative flex items-center justify-center w-full gap-1 mt-4'>
            <Button
              type='submit'
              // onClick={handleSubmit(handleAddNewContact)}
            >
              {t('labels.createNewAlbum')}
            </Button>
            <Button
              variant='secondary'
              onClick={(e) => {
                e.preventDefault()
                setOpen(false)
              }}
            >
              {t('buttons.cancel')}
            </Button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  ) : null
}

export default MetadataForm
