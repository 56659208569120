import React, { useEffect, useRef, useState } from 'react'
import { useCampaign } from 'ctx/CampaignContext'
import { Card } from 'components/partials/Card'
import { ctaOptions, ctaOptionsSpotify } from 'lib/constants'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/partials/Select'
import { useMarketing, useSpotify } from 'store/RootStore'
import toast from 'react-hot-toast'
import { PageLoading } from 'components/layout/LoadingScreen'
import * as yup from 'yup'
import PostCard from './PostCard'
import { useNavigate } from 'react-router-dom'
import SongCard from './SongCard'
import { Button } from 'components/partials/Button'
import Carousel from 'components/partials/Carousel'

const FacebookCreative: React.FC<{
  onValidityChange: (isValid: boolean) => void
}> = ({ onValidityChange }) => {
  const { config, setConfig } = useCampaign()
  const [createPost, setCreatePost] = useState(false)
  const { getFacebookPosts, getInstagramMedia } = useMarketing()
  const [adName, setAdName] = useState(config.adName || '')
  const [adDescription, setAdDescription] = useState(config.adDescription || '')
  const [ctaText, setCtaText] = useState(config.cta)
  const [message, setMessage] = useState(config.message || '')
  const [linkUrl, setLinkUrl] = useState(config.linkUrl || '')
  const [headline, setHeadline] = useState(config.headline || '')
  const [posts, setPosts] = useState([])
  const [moving, setMoving] = useState(false)
  const [selectedPost, setSelectedPost] = useState<any | null>(config.post)
  const [file, setFile] = useState<File | null>(config.file || null)
  const [filePreview, setFilePreview] = useState<string | null>(
    config.filePreview || null
  )
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [selectedSongType, setSelectedSongType] = useState<'track' | 'playlist'>(
    config.selectedSongType || 'track'
  )
  const [tracks, setTracks] = useState<any[]>([])
  const [playlists, setPlaylists] = useState<any[]>([])
  const [selectedTrack, setSelectedTrack] = useState<any | null>(
    config.selectedTrack || null
  )
  const { getUserPlaylists, getUserTracks } = useSpotify()
  const navigate = useNavigate()

  const [errors, setErrors] = useState<{ [key: string]: string }>({})

  const urlSchema = yup.string().url('Must be a valid URL')

  useEffect(() => {
    if (config.type.id === 'boost_facebook_engagement') {
      setCreatePost(false)
    } else if (config.type.id === 'create_facebook_campaign') {
      setCreatePost(true)
    }
  }, [])

  useEffect(() => {
    const fetchSpotifyData = async () => {
      try {
        const fetchedPlaylists = await getUserPlaylists(
          config.spotifyUser.id,
          '50',
          '0'
        )
        const fetchedTracks = await getUserTracks(config.spotifyUser.id, '50', '0')

        if ('detail' in fetchedPlaylists || 'detail' in fetchedTracks) {
          toast.error('Error fetching Spotify data. Please try again later!')
          navigate('/marketing-tool')
          return
        }

        setPlaylists(fetchedPlaylists.items)
        setTracks(fetchedTracks.items)
      } catch (error: any) {
        toast.error(`Error fetching Spotify data: ${error.message}`)
      }
    }

    if (config.type.id === 'boost_spotify_engagement') {
      fetchSpotifyData()
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (
          config.type.id === 'boost_facebook_engagement' ||
          config.type.id === 'boost_spotify_engagement'
        ) {
          const postsRes = await getFacebookPosts(
            config.facebookPage?.access_token,
            config.facebookPage.id
          )
          if (postsRes.detail) {
            throw new Error(postsRes.detail)
          }
          setPosts(postsRes?.data)
        } else if (config.type.id === 'boost_instagram_engagement') {
          const postsRes = await getInstagramMedia(config.instagramUser?.id)
          if (postsRes.detail) {
            throw new Error(postsRes.detail)
          }
          setPosts(postsRes?.data)
        }
      } catch (error: any) {
        toast.error(`Error fetching data: ${error.message}`)
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    const validateUrls = async () => {
      try {
        await urlSchema.validate(linkUrl)
        setErrors({})
        onValidityChange(true)
      } catch (error: any) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [error.path]: error.message,
        }))
        onValidityChange(false)
      }
    }

    setConfig({
      ...config,
      adName,
      adDescription,
      cta: ctaText,
      post: selectedPost,
      message,
      linkUrl,
      headline,
      file,
      filePreview,
      selectedTrack,
      selectedSongType,
      postCreated: createPost,
    })

    if (
      config.adName &&
      config.adDescription &&
      config.cta &&
      config.message &&
      config.selectedSongType &&
      (createPost ? config.headline && config.file && config.linkUrl : config.post)
    ) {
      validateUrls()
    } else {
      onValidityChange(false)
    }
  }, [
    adName,
    adDescription,
    ctaText,
    selectedPost,
    message,
    linkUrl,
    headline,
    file,
    filePreview,
    selectedSongType,
    selectedTrack,
    createPost,
    setConfig,
    onValidityChange,
  ])

  const handlePostClick = (post: any) => {
    setSelectedPost(selectedPost?.id === post.id ? null : post)
    setCreatePost(false)
  }

  useEffect(() => {
    if (selectedPost) {
      setMessage(selectedPost.caption)
    }
  }, [selectedPost])

  useEffect(() => {
    if (selectedTrack) {
      setLinkUrl(selectedTrack.external_urls.spotify)
    }
  }, [selectedTrack])

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0] || null
    if (selectedFile) {
      setFile(selectedFile)
      setFilePreview(URL.createObjectURL(selectedFile))
    } else {
      setFile(null)
      setFilePreview(null)
    }
  }

  const handleRemoveFile = () => {
    setFile(null)
    setFilePreview(null)
    setConfig({
      ...config,
      file: null,
      filePreview: null,
    })
  }

  const handleUploadClick = () => {
    fileInputRef.current?.click()
  }

  return (
    <div>
      <Card className='p-4 rounded-lg'>
        <div className='flex flex-col gap-4'>
          <div>
            <h2 className='mb-1 font-bold'>Ad Name:</h2>
            <input
              type='text'
              id='adName'
              className='w-full border rounded-lg focus:outline'
              value={adName}
              onChange={(e) => setAdName(e.target.value)}
            />
          </div>
          <div>
            <h2 className='mb-1 font-bold'>Ad Description:</h2>
            <textarea
              id='adDescription'
              className='w-full border rounded-lg focus:outline'
              value={adDescription}
              rows={3}
              onChange={(e) => setAdDescription(e.target.value)}
            />
          </div>
        </div>
      </Card>
      {config.type.id === 'boost_spotify_engagement' && (
        <Card className='p-4 mt-4 rounded-lg'>
          <div className='flex flex-col gap-4 md:flex-row md:gap-10 md:justify-between md:items-center'>
            <div>
              <h2 className='mb-1 font-bold'>Select Playlist or Track</h2>
              <p className='max-w-lg mb-4 text-sm md:text-base'>
                The CTA link will be the link to song selected.
              </p>
            </div>
            <div className='w-full md:max-w-xs'>
              <Select
                onValueChange={(value) =>
                  setSelectedSongType(value as 'track' | 'playlist')
                }
                defaultValue={selectedSongType}
              >
                <SelectTrigger className='border rounded-lg focus:outline'>
                  <SelectValue placeholder='Select Type' />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value='track'>Track</SelectItem>
                  <SelectItem value='playlist'>Playlist</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
          {tracks && playlists ? (
            <>
              {(tracks.length > 0 && selectedSongType === 'track') ||
              (playlists.length > 0 && selectedSongType === 'playlist') ? (
                <Carousel
                  perView={1.5}
                  sm={2.5}
                  md={2.5}
                  lg={4}
                  xl={5.6}
                  xl2={6}
                  spacing={5}
                  arrows
                  dots
                >
                  {(selectedSongType === 'track' ? tracks : playlists).map(
                    (song: any) => (
                      <SongCard
                        key={song.id}
                        song={song}
                        type={selectedSongType}
                        active={song.id === selectedTrack?.id}
                        onClick={() => setSelectedTrack(song)}
                      />
                    )
                  )}
                </Carousel>
              ) : (
                <p className='flex items-center justify-center w-full h-32 capitalize'>
                  No {selectedSongType}s Found.
                </p>
              )}
            </>
          ) : (
            <PageLoading className='flex items-center justify-center w-full h-32' />
          )}
        </Card>
      )}
      <Card className='p-4 mt-4 rounded-lg'>
        <div className='flex flex-col gap-4 mb-6 md:flex-row md:gap-10 md:justify-between md:items-center'>
          <div>
            <h2 className='mb-1 font-bold'>
              {createPost ? 'Create Post:' : 'Select Post:'}
            </h2>
            <p className='max-w-lg mb-1 text-sm md:text-base'>
              {createPost
                ? "Create a new post; the created post's details will be used for the boosted post."
                : "Select an existing post; the selected post's details will be used for the boosted post."}
            </p>
          </div>
          {/* <Button
            onClick={() => {
              setCreatePost(createPost ? false : true)
              setSelectedPost(null)
            }}
          >
            {createPost ? 'Select Existing Post' : 'Create New Post'}
          </Button> */}
        </div>
        {!createPost && (
          <>
            {posts.length > 0 ? (
              <Carousel
                perView={1.5}
                sm={2.5}
                md={2.5}
                lg={4}
                xl={5.6}
                xl2={6}
                spacing={5}
                arrows
                dots
                // responsive={postsCarouselConfig}
                // beforeChange={() => setMoving(true)}
                // afterChange={() => setMoving(false)}
              >
                {posts.map((post: any) => (
                  <PostCard
                    key={post.id}
                    post={post}
                    active={post.id === selectedPost?.id}
                    onClick={() => handlePostClick(post)}
                  />
                ))}
              </Carousel>
            ) : (
              <PageLoading className='flex items-center justify-center w-full h-32' />
            )}
          </>
        )}
      </Card>
      <Card
        className={`p-4 mt-4 space-y-6 rounded-lg ${
          createPost ? 'block' : 'hidden'
        }`}
      >
        {config.type.id !== 'boost_spotify_engagement' && (
          <div className='flex flex-col gap-4 md:flex-row md:gap-10 md:justify-between md:items-center'>
            <div>
              <h2 className='mb-1 font-bold'>Link URL: </h2>
              <p className='max-w-lg mb-1 text-sm md:text-base'>
                Provide the URL where users will be directed when they click on the
                link in your ad. The URL will also be diplayed as the caption.
              </p>
              <p className='max-w-lg text-sm italic text-gray-500 md:text-base'>
                Include Full URL. Example: https://www.musicdash.com/
              </p>
            </div>
            <div className='w-full md:max-w-md'>
              <input
                type='text'
                id='linkUrl'
                className='w-full border rounded-lg focus:outline'
                value={linkUrl}
                onChange={(e) => setLinkUrl(e.target.value)}
              />
              {errors.linkUrl && (
                <p className='text-sm text-red-600'>{errors.linkUrl}</p>
              )}
            </div>
          </div>
        )}

        <div className='flex flex-col gap-4 md:flex-row md:gap-10 md:justify-between md:items-center'>
          <div>
            <h2 className='mb-1 font-bold'>Headline:</h2>
            <p className='max-w-lg text-sm md:text-base'>
              Write a compelling headline for your ad. This should grab the attention
              of your audience and encourage them to engage with your content.
            </p>
          </div>
          <div className='w-full md:max-w-md'>
            <input
              type='text'
              id='headline'
              className='w-full border rounded-lg focus:outline'
              value={headline}
              onChange={(e) => setHeadline(e.target.value)}
            />
          </div>
        </div>
      </Card>
      <Card className={`p-4 mt-4 rounded-lg ${createPost ? 'block' : 'hidden'}`}>
        <div className='flex flex-col gap-4 md:flex-row md:gap-10 md:justify-between'>
          <div>
            <h2 className='mb-1 font-bold'>Upload Image/Video: </h2>
            <p className='max-w-lg text-sm md:text-base'>
              Upload the image or video you want to use for your ad. This media will
              be displayed prominently and should be visually appealing.
            </p>
          </div>
          <div className='w-full md:max-w-xs'>
            {filePreview ? (
              <div className='flex gap-2'>
                <img
                  src={filePreview}
                  alt='Preview'
                  className='aspect-square max-w-32'
                />
                <button
                  type='button'
                  className='mt-2 text-red-600'
                  onClick={handleRemoveFile}
                >
                  Remove File
                </button>
              </div>
            ) : (
              <div
                className='flex items-center justify-center w-full h-32 border-2 border-dashed rounded-lg cursor-pointer'
                onClick={handleUploadClick}
              >
                <span className='text-center text-gray-500'>
                  {file ? 'Change File' : 'Upload Image/Video'}
                </span>
                <input
                  type='file'
                  accept='image/*,video/*'
                  ref={fileInputRef}
                  className='hidden'
                  onChange={handleFileChange}
                />
              </div>
            )}
          </div>
        </div>
      </Card>
      <Card className={`p-4 mt-4 rounded-lg`}>
        <div className='flex flex-col gap-4 md:flex-row md:gap-10 md:justify-between md:items-center'>
          <div>
            <h2 className='mb-1 font-bold'>Call-to-Action: </h2>
            <p className='max-w-lg text-sm md:text-base'>
              Choose the action you want users to take after seeing your ad. This
              will be displayed as the swipe-up-call-to-action on your ad.
            </p>
          </div>
          <div className='w-full md:max-w-xs'>
            <Select
              onValueChange={(value) => {
                setCtaText(
                  (config.type.id === 'boost_spotify_engagement'
                    ? ctaOptionsSpotify
                    : ctaOptions
                  ).find((o) => o.value === value) || ''
                )
              }}
              defaultValue={config.cta?.value}
            >
              <SelectTrigger className='border rounded-lg focus:outline'>
                <SelectValue placeholder={'Select CTA Text'} />
              </SelectTrigger>
              <SelectContent>
                {(config.type.id === 'boost_spotify_engagement'
                  ? ctaOptionsSpotify
                  : ctaOptions
                ).map((opt: any, i: any) => (
                  <SelectItem key={i} value={opt.value}>
                    {opt.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>
        <div className='flex flex-col gap-4 mt-6 md:flex-row md:gap-10 md:justify-between'>
          <div>
            <h2 className='mb-1 font-bold'>Message: </h2>
            <p className='max-w-lg text-sm md:text-base'>
              Enter the main body text for your ad. This should convey your message
              clearly and effectively to your target audience.
            </p>
          </div>
          <div className='w-full max-w-xl'>
            <textarea
              id='message'
              className='border rounded-lg focus:outline'
              value={message}
              rows={6}
              onChange={(e) => {
                setMessage(e.target.value)
              }}
            />
          </div>
        </div>
      </Card>
    </div>
  )
}

export default FacebookCreative
