import { WarningIconAlt } from 'components/shared/Icons'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface ISubmissionEarningsTable {
  data: any
}

const SubmissionEarningsTable = ({ data }: ISubmissionEarningsTable) => {
  const { t } = useTranslation()

  return (
    <div className='card h-full overflow-hidden !p-0'>
      <div className='relative h-full overflow-y-auto'>
        <table className='relative w-full h-full'>
          <thead className='sticky top-0 z-[5] text-xs text-gray-700 block w-full uppercase bg-gray-50'>
            <tr className='flex justify-between'>
              <th scope='col' className='p-4 text-start'>
                {t('submission.submissionId')}
              </th>
              <th scope='col' className='py-3 pr-24 truncate text-start'>
                {t('submission.earning')}
              </th>
            </tr>
          </thead>
          <tbody className='relative block overflow-y-auto text-sm text-gray-600 max-h-64'>
            {data && data.length > 0 ? (
              data.map((item: any, i: number) => <TableRow item={item} key={i} />)
            ) : (
              <tr className='block w-full'>
                <td className='flex items-center justify-center h-64 px-6 py-4'>
                  <div className='flex flex-col items-center justify-center gap-2 px-4 text-center'>
                    <span className='text-amber-500'>
                      <WarningIconAlt />
                    </span>
                    <span className='font-bold'>
                      {t('submission.noSubmissionData')}
                    </span>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

const TableRow: FC<{ item: any }> = ({ item }) => {
  return (
    <tr className='relative w-full p-2 bg-white border-b hover:bg-gray-50 h-max'>
      <td className='w-full px-6 py-3'>{`${item.release_title}`}</td>
      <td className='w-full py-3 pr-24'>{`$${item.earning.toFixed(2)}`}</td>
    </tr>
  )
}

export default SubmissionEarningsTable
