import PageLayout from 'components/layout/PageLayout'
import { Outlet } from 'react-router-dom'

const MainDashboard = () => {
  return (
    <PageLayout>
      <Outlet />
    </PageLayout>
  )
}

export default MainDashboard
