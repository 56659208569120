import { FC } from 'react'
import { Link } from 'react-router-dom'
import { LoadingIcon, PlusIcon } from './Icons'
import { Button } from 'components/partials/Button'
import { Plus } from 'lucide-react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

interface IPageHeader {
  title: string | JSX.Element
  isLoading?: boolean
  hasCreateSubmissionTrigger?: boolean
  additionalElements?: JSX.Element
  className?: string
}

const PageHeader: FC<IPageHeader> = ({
  title,
  isLoading = false,
  hasCreateSubmissionTrigger = false,
  additionalElements,
  className,
}) => {
  const { t } = useTranslation()
  return (
    <div
      className={clsx(
        'sticky left-0 z-20 top-0 py-2 w-full px-4 flex flex-col md:flex-row flex-nowrap items-center justify-between border-b bg-gray-50',
        className
      )}
    >
      <div className='flex items-center justify-start gap-2'>
        <h2 className='text-base font-black md:text-xl w-max font-title'>{title}</h2>
        {isLoading ? (
          <span className='relative inline-block animate-spin text-primary-purple'>
            <LoadingIcon />
          </span>
        ) : null}
      </div>

      {hasCreateSubmissionTrigger ? (
        <Link to='/add-submission'>
          <Button>
            <Plus size={14} /> {t('buttons.createSubmission')}
          </Button>
        </Link>
      ) : null}
      {additionalElements}
    </div>
  )
}

export default PageHeader
