import { FC } from 'react'

import { LoadingIcon } from 'components/shared/Icons'
import { LoaderCircle } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useUI } from 'ctx/UIContext'
import { cn } from 'lib/utils'

type LoadingScreenProps = {
  isLoading?: boolean
}

const LoadingScreen: FC<LoadingScreenProps> = ({ isLoading = true }) => {
  const { t } = useTranslation()
  return (
    <>
      {isLoading ? (
        <div>
          <div className='fixed inset-0 bg-white z-[1000]'></div>
          <div
            className='flex flex-col items-center justify-center backdrop'
            style={{ zIndex: 9999 }}
          >
            <span className='text-5xl text-black animate-spin'>
              <LoaderCircle size={24} />
            </span>
            <span className='mt-8 text-sm text-black'>
              {t('loadingScreen.loading')}
            </span>
          </div>
        </div>
      ) : null}
    </>
  )
}

const PageLoading = ({ className }: { className?: string }) => {
  const { whiteLabelData } = useUI()

  return (
    <div
      className={cn(
        'relative w-full h-[var(--content-height)] flex items-center justify-center',
        className
      )}
    >
      <span
        className='relative inline-block text-4xl animate-spin text-primary-purple'
        style={{
          color: whiteLabelData.theme ? 'var(--whitelabel-neutral)' : '',
        }}
      >
        <LoadingIcon />
      </span>
    </div>
  )
}

export default LoadingScreen
export { PageLoading }
