import { Auth } from 'aws-amplify'
import AWS from 'aws-sdk'

import { S3Client } from '@aws-sdk/client-s3'
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers'
import { ScanInput, ScanOutput } from 'aws-sdk/clients/dynamodb'
import {
  CognitoIdentityProviderClient,
  ListUsersCommand,
  ListUsersCommandInput,
} from '@aws-sdk/client-cognito-identity-provider'

const identityPoolId = process.env.REACT_APP_IDENTITY_POOL_ID as string

Auth.configure({
  region: process.env.REACT_APP_REGION,
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
  mandatorySignIn: true,
  authenticationFlowType: 'USER_PASSWORD_AUTH',
})
AWS.config.region = process.env.REACT_APP_REGION as string // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: identityPoolId,
})
export const ddb = new AWS.DynamoDB.DocumentClient()

export const TABLE_NAME = 'music-dash-applications-dev'

const s3Client = new S3Client({
  region: process.env.REACT_APP_REGION as string,
  credentials: fromCognitoIdentityPool({
    identityPoolId,
    clientConfig: {
      region: process.env.REACT_APP_REGION as string,
    },
  }),
})

export const scanTable = async () => {
  const params: ScanInput = {
    TableName: TABLE_NAME,
  }

  const scanResults: ScanOutput[] = []
  let items
  do {
    items = await ddb.scan(params).promise()
    items.Items?.forEach((item) => scanResults.push(item))
    params.ExclusiveStartKey = items.LastEvaluatedKey
  } while (typeof items.LastEvaluatedKey != 'undefined')

  return scanResults
}

export { s3Client }

export async function listUsers() {
  const result: any[] = []
  let lastPaginationToken: string

  const credentials = fromCognitoIdentityPool({
    identityPoolId,
    clientConfig: {
      region: process.env.REACT_APP_REGION as string,
    },
  })

  const client = new CognitoIdentityProviderClient({
    region: process.env.REACT_APP_REGION as string,
    credentials,
  })
  const params: ListUsersCommandInput = {
    UserPoolId: process.env.REACT_APP_USER_POOL_ID as string,
    AttributesToGet: ['email', 'custom:firstname', 'custom:lastname'],
  }
  const command = new ListUsersCommand(params)

  do {
    const { Users, PaginationToken } = await client.send(command)
    lastPaginationToken = PaginationToken as any
    result.push(...(Users as any))
    params.PaginationToken = lastPaginationToken
  } while (typeof lastPaginationToken !== 'undefined')

  const _users = result.map((user) => ({
    // attr: user.Attributes,
    name: '',
    given_name: '',
    family_name: '',
    middle_name: '',
    nickname: '',
    preferred_username: '',
    profile: '',
    picture: '',
    website: '',
    email: user.Attributes ? user.Attributes[2].Value : '',
    email_verified: true,
    gender: '',
    birthdate: '',
    zoneinfo: '',
    locale: '',
    phone_number: '',
    phone_number_verified: false,
    address: '',
    updated_at: '',
    'custom:firstname': user.Attributes ? user.Attributes[0].Value : '',
    'custom:lastname': user.Attributes ? user.Attributes[1].Value : '',
    'cognito:mfa_enabled': false,
    'cognito:username': user.Username,
  }))
  // const res = await client.send(command)
  // if (!res.Users) return

  // const _emails = res.Users.map((user) =>
  //   user.Attributes ? user.Attributes[0]?.Value : null
  // )

  // return _emails
}

export async function listUserEmails() {
  const result: any[] = []
  let lastPaginationToken: string

  const credentials = fromCognitoIdentityPool({
    identityPoolId,
    clientConfig: {
      region: process.env.REACT_APP_REGION as string,
    },
  })

  const client = new CognitoIdentityProviderClient({
    region: process.env.REACT_APP_REGION as string,
    credentials,
  })

  const params: ListUsersCommandInput = {
    UserPoolId: process.env.REACT_APP_USER_POOL_ID as string,
    AttributesToGet: ['email'],
  }

  const command = new ListUsersCommand(params)

  do {
    const { Users, PaginationToken } = await client.send(command)
    lastPaginationToken = PaginationToken as any
    result.push(...(Users as any))
    params.PaginationToken = lastPaginationToken
  } while (typeof lastPaginationToken !== 'undefined')

  const _users = result.map((user) => ({
    email: user.Attributes ? user.Attributes[0].Value : '',
    username: user.Username,
  }))

  return _users
}
