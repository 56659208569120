import { Button } from 'components/partials/Button'
import Signature from 'components/shared/Signature'
import React, { useRef, useState } from 'react'
import { useBulkSubmissions } from '../context/BulkSubmissionContext'
import PDFRenderer from 'components/shared/PDFRenderer.tsx'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import htmltopdf from 'html2pdf.js'
import { useUser } from 'store/RootStore'
import Tooltip from 'components/shared/Tooltip'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'components/partials/Dialog'
import { useTranslation } from 'react-i18next'

const Agreement = () => {
  const [sign, setSign] = useState('')

  const { user } = useUser()
  const { t } = useTranslation()
  const { updateData, metadata } = useBulkSubmissions()

  const pdfRef = useRef<HTMLDivElement>(null)

  const renderPDF = async () => {
    const fileName = `${user.firstname + '_' + user.lastname}_agreement.pdf`
    const opt = {
      margin: 1,
      fileName,
      image: { type: 'jpeg', quality: 0.98 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    }

    await htmltopdf()
      .from(pdfRef.current)
      .set(opt)
      .toPdf()
      .output('blob')
      .then((data: Blob) => {
        const file = new File([data], fileName)
        updateData('agreement', [file])
        // setTimeout(() => {
        // }, 100)
      })
    // .save()
  }

  const updateSignature = (sign: string) => {
    setSign(sign)
  }

  return (
    <>
      <div>
        <Signature updateSignature={updateSignature} />
        <div className='mt-4 text-sm text-black/75'>
          {t('agreement.confirm')}
          <Dialog>
            <DialogTrigger>
              <Tooltip content={t('agreement.view')}>
                <div
                  aria-hidden
                  //   onClick={() => openAgreement()}
                  className='inline font-semibold text-black underline cursor-pointer whitespace-nowrap'
                >
                  {t('agreement.confirm2')}
                </div>
              </Tooltip>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogDescription>
                  <div className='h-full pdf-wrapper'>
                    <PDFRenderer data={metadata} signature={sign} />
                  </div>
                </DialogDescription>
              </DialogHeader>
            </DialogContent>
          </Dialog>
        </div>
        <div className='flex items-center justify-center w-full max-w-3xl mx-auto mt-2'>
          <Button
            onClick={() => {
              renderPDF()
            }}
            //   isDisabled={!isOkToProceed}
          >
            {t('buttons.createSubmission')}
          </Button>
        </div>
      </div>
      <div className='hidden'>
        <div className='pdf-wrapper' ref={pdfRef}>
          <PDFRenderer data={metadata} signature={sign} />
        </div>
      </div>
    </>
  )
}

export default Agreement
