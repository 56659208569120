import { useUI } from 'ctx/UIContext'
import { uploadToS3 } from 'lib/aws-utils'
import { ReactNode, createContext, useContext, useEffect, useState } from 'react'
import { useSubmissions, useUser } from 'store/RootStore'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { v4 as uuid } from 'uuid'

const TOTAL_STEPS = 4
const DEFAULT_STEP = 1

type BulkSubmissionContextProps = {
  metadata: any[]
  audioFiles: File[]
  artworkFiles: File[]
  updateData: (key: BulkSubmissionTypes, data: any[] | File[]) => void
  goBack: () => void
  step: number
  totalSteps: number
}

type BulkSubmissionTypes = 'metadata' | 'audio' | 'artwork' | 'agreement'

const BulkSubmissionContext = createContext<BulkSubmissionContextProps>({
  metadata: [],
  audioFiles: [],
  artworkFiles: [],
  updateData: (key: BulkSubmissionTypes, data: any[] | File[]) => {
    0
  },
  step: DEFAULT_STEP,
  totalSteps: TOTAL_STEPS,
  goBack: () => {
    0
  },
})

type BulkSubmissionContextProviderProps = {
  children: ReactNode
}

export const BulkSubmissionContextProvider = ({
  children,
}: BulkSubmissionContextProviderProps) => {
  const totalSteps = TOTAL_STEPS

  const { user } = useUser()
  const { setLoading } = useUI()
  const { bulkSubmissions } = useSubmissions()

  const [step, setStep] = useState(DEFAULT_STEP)

  const [metadata, setMetadata] = useState<any[]>([])
  const [audioFiles, setAudioFiles] = useState<File[]>([])
  const [artworkFiles, setArtworkFiles] = useState<File[]>([])
  const [agreement, setAgreement] = useState<File>()

  const updateData = (key: BulkSubmissionTypes, data: any[] | File[]) => {
    switch (key) {
      case 'metadata':
        setMetadata(data)
        setStep(2)
        break
      case 'audio':
        setAudioFiles(data)
        setStep(3)
        break
      case 'artwork':
        setArtworkFiles(data)
        setStep(4)
        break
      case 'agreement':
        setAgreement(data[0])

        break
    }
  }

  useEffect(() => {
    if (agreement) {
      setTimeout(() => {
        submitData()
      }, 100)
    }
  }, [agreement])

  const uploadFiles = async (
    file: File | null,
    submissionId: string,
    userId: string
  ) => {
    try {
      if (file) {
        await uploadToS3(file, userId, submissionId)
      }
    } catch (error) {
      console.log(error)
    }
  }

  function escapeString(str: string) {
    const _str = str.replace(/\\/g, '\\\\').replace(/"/g, '\\"')
    return `"${_str}"`
  }

  // Function to build the string
  function buildString(dataArray: any[]) {
    let raw = '[\r\n'

    dataArray.forEach((data, index) => {
      raw += '    {\r\n'
      const keys = Object.keys(data)
      keys.forEach((key, i) => {
        raw += `      "${key}": ${data[key] ? escapeString(data[key]) : null}`
        if (i < keys.length - 1) {
          raw += ','
        }
        raw += '\r\n'
      })
      raw += '    }'
      if (index < dataArray.length - 1) {
        raw += ','
      }
      raw += '\r\n'
    })

    raw += '    ]'

    return raw
  }

  const triggerBulkSub = async (data: any) => {
    const raw = buildString(data)
    await bulkSubmissions(raw)
      .then((res) => {
        if ('detail' in res) {
          throw res
        }
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
  }

  const submitData = async () => {
    const submission_id = uuid()
    const user_id = user.user_id

    setLoading(true)

    const finalData: any[] = []

    metadata.forEach((item, i) => {
      const audioFileName = audioFiles[i].name
      const artworkFileName = artworkFiles[i].name
      const agreementFileName = agreement?.name

      const audioURL = `https://musicdash.s3.amazonaws.com/submissions/${user_id}/${submission_id}/${audioFileName}`
      const artworkURL = `https://musicdash.s3.amazonaws.com/submissions/${user_id}/${submission_id}/${artworkFileName}`
      const agreementURL = `https://musicdash.s3.amazonaws.com/submissions/${user_id}/${submission_id}/${agreementFileName}`

      const _item = {
        submission_id: submission_id,
        release_title: item['Release Title'] || null,
        artist_name: item['Artist Name'] || null,
        identifier: item['identifier'] || null,
        label: item['Label Name'] || null,
        genre: item['Main Genre'] || null,
        sub_genre: item['Sub-genre'] || null,
        selected_type: item['Submission Type'] || null,
        track_title: item['Release Title'] || null,
        status: 'draft',
        version: item['Version (Optional)'] || null,
        lyrics_language: item['Lyrics Language'] || null,
        title_language: item['Title Language'] || null,
        lyrics: item['Full Lyrics'] || null,
        parental_advisory: item['Parental Advisory'] || null,
        digital_release_date: item['Release Date'] || null,
        preview_start: item['Preview Start'] || null,
        file: audioURL,
        art_work: artworkURL,
        composer: item['Composer'] || null,
        lyrics_writer: item['Lyrics Writer'] || null,
        album: item['Album'] || null,
        product_id: null,
        agreement: agreementURL,
      }

      finalData.push(_item)
    })

    try {
      await triggerBulkSub(finalData)
      for (const file of audioFiles) {
        await uploadFiles(file, submission_id, user_id)
      }
      for (const file of artworkFiles) {
        await uploadFiles(file, submission_id, user_id)
      }
      if (agreement) {
        await uploadFiles(agreement, submission_id, user_id)
      }
      setMetadata([])
      setAudioFiles([])
      setArtworkFiles([])
      setAgreement(undefined)
      setStep(1)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const goBack = () => {
    setStep((v) => v - 1)
  }

  return (
    <BulkSubmissionContext.Provider
      value={{
        metadata,
        audioFiles,
        artworkFiles,
        updateData,
        goBack,
        step,
        totalSteps,
      }}
    >
      {children}
    </BulkSubmissionContext.Provider>
  )
}

export const useBulkSubmissions = () => useContext(BulkSubmissionContext)
