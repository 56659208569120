import { Button } from 'components/partials/Button'
import { Label } from 'components/partials/Inputs'
import { Error } from 'components/shared/FormUtils'
import { cn } from 'lib/utils'
import { Trash2 } from 'lucide-react'
import React, { useState } from 'react'

type FileUploadProps = {
  item: any
  i: number
  type?: 'audio' | 'image'
  allowedTypes?: 'audio/*' | 'image/*'
  updateFiles: (file: File) => void
  removeFile: (file: File) => void
}

const FileUpload = ({
  item,
  i,
  updateFiles,
  type = 'audio',
  allowedTypes = 'audio/*',
  removeFile,
}: FileUploadProps) => {
  const [file, setFile] = useState<File | undefined>()
  const [error, setError] = useState('')

  const label =
    type === 'audio' ? `${item['Filename']}` : `${item['Artwork Filename']}`

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0]

    if (selectedFile) {
      setFile(selectedFile)
      if (selectedFile.name.toLowerCase() === label.toLowerCase()) {
        updateFiles(selectedFile)
        setError('')
      } else {
        setError('wrong file')
      }
    }
  }

  const name = type === 'audio' ? `${label}-input` : `${label}-input`

  return (
    <div className='flex flex-1 items-center justify-start gap-2'>
      <Label htmlFor={name} className='flex items-center gap-2'>
        {i + 1}.{label}
        <div className='flex flex-col items-start justify-start'>
          <input
            type='file'
            className={cn('input')}
            role='presentation'
            autoComplete='off'
            accept={allowedTypes}
            name={name}
            onChange={handleFileChange}
          />
          {error !== '' ? <Error>{error}</Error> : null}
        </div>
        {/* <Button variant='ghost'>Add file here</Button> */}
      </Label>
      {/* <Button
        variant='destructive'
        size='icon'
        className=''
        onClick={(e) => {
          if (file) {
            removeFile(file)
            setFile(undefined)
          }
        }}
      >
        <Trash2 size={16} />
      </Button> */}
    </div>
  )
}

export default FileUpload
