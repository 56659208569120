import React, { useState, ChangeEvent } from 'react'
import PageHeader from 'components/shared/PageHeader'
import useAudioMixing from 'hooks/useAudioMixing'
import { Button } from 'components/partials/Button'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import { Card } from 'components/partials/Card'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/partials/Tabs'

const MAX_FILES = 3
const MAX_FILE_SIZE = 20 * 1024 * 1024 // 20MB

const MixingUpload: React.FC = () => {
  const { mixAudio, masterAudio } = useAudioMixing()
  const [files, setFiles] = useState<File[]>([])
  const [error, setError] = useState<string>('')
  const [outputUrl, setOutputUrl] = useState<string | null>(null)
  const [activeTab, setActiveTab] = useState<'mixing' | 'mastering'>('mixing')
  const [selectedPreset, setSelectedPreset] = useState<string | null>('Preset 1')
  const { t } = useTranslation()

  const handleMixing = async () => {
    if (files.length === 0) {
      setError(t('audioMixing.oneFile'))
      return
    }

    const mixResponse: any = await mixAudio(files, 'audio-mixing')

    if (mixResponse) {
      setOutputUrl(mixResponse.s3_url)
      ReactGA.event({
        category: 'Audio Mixing',
        action: 'Mix',
      })
      setError('')
    } else {
      setError(t('audioMixing.oneFile'))
    }
  }

  const handleMastering = async () => {
    if (files.length === 0) {
      setError(t('audioMixing.oneFile'))
      return
    }

    const preset = presetOptions.find((p) => p.name === selectedPreset)
    const masterResponse: any = await masterAudio(
      files,
      'audio-mastering',
      preset?.low_gain,
      preset?.mid_gain,
      preset?.high_gain,
      preset?.width
    )

    if (masterResponse) {
      setOutputUrl(masterResponse.s3_url)
      ReactGA.event({
        category: 'Audio Mastering',
        action: 'Master',
      })
      setError('')
    } else {
      setError(t('audioMixing.oneFile'))
    }
  }

  const presetOptions = [
    {
      name: 'Balanced Warmth',
      low_gain: 0.67,
      mid_gain: 0.15,
      high_gain: 0.33,
      width: 0.8,
    },
    {
      name: 'Vocal Clarity',
      low_gain: 0.25,
      mid_gain: 0.5,
      high_gain: 0.75,
      width: 0.9,
    },
    {
      name: 'Punchy Bass',
      low_gain: 0.67,
      mid_gain: 0.5,
      high_gain: 0.17,
      width: 0.7,
    },
    {
      name: 'Bright and Wide',
      low_gain: 0.25,
      mid_gain: 0.15,
      high_gain: 0.83,
      width: 1.5,
    },
    {
      name: 'Club Boost',
      low_gain: 0.67,
      mid_gain: 0.75,
      high_gain: 0.67,
      width: 1.2,
    },
    {
      name: 'Smooth Mono',
      low_gain: 0.5,
      mid_gain: 0.6,
      high_gain: 0.17,
      width: 0.0,
    },
  ]

  return (
    <div className='page-wrapper'>
      <PageHeader title={t('sidebar.audioMixing')} isLoading={false} />
      <div className='flex flex-col w-full gap-6 mt-6 lg:flex-row'>
        <div className='w-full max-w-[1000px] mx-auto'>
          <Tabs
            value={activeTab}
            onValueChange={(value) => setActiveTab(value as 'mixing' | 'mastering')}
          >
            <TabsList>
              <Card className='px-5 py-2'>
                <TabsTrigger value='mixing'>Audio Mixing</TabsTrigger>
                <TabsTrigger value='mastering'>Audio Mastering</TabsTrigger>
              </Card>
            </TabsList>

            <TabsContent value='mixing'>
              <div className='py-5'>
                <div>
                  <p className='max-w-lg'>{t('audioMixing.description')}</p>
                </div>
                <UploadSection
                  files={files}
                  setFiles={setFiles}
                  error={error}
                  setError={setError}
                />
                {error && <p className='text-red-500'>{error}</p>}
                <Button onClick={handleMixing} className='w-full max-w-[10rem] mt-6'>
                  {t('audioMixing.mix')}
                </Button>
              </div>
            </TabsContent>

            <TabsContent value='mastering'>
              <div className='py-5'>
                <div>
                  <p className='max-w-lg'>{t('audioMastering.description')}</p>
                  <UploadSection
                    files={files}
                    setFiles={setFiles}
                    error={error}
                    setError={setError}
                  />
                  {error && <p className='text-red-500'>{error}</p>}
                  <label htmlFor='preset-selector' className='block mt-6 mb-2'>
                    Select Preset
                  </label>
                  <div id='preset-selector' className='grid grid-cols-3 gap-2'>
                    {presetOptions.map((preset) => (
                      <div key={preset.name}>
                        <input
                          type='radio'
                          id={preset.name}
                          name='preset'
                          value={preset.name}
                          checked={selectedPreset === preset.name}
                          onChange={() => setSelectedPreset(preset.name)}
                          className='hidden'
                        />
                        <label
                          htmlFor={preset.name}
                          className={`inline-flex items-center w-full lg:text-base font-semibold justify-center h-20  text-center transition-colors border-2 border-gray-300 rounded-md cursor-pointer hover:border-blue-500 ${
                            selectedPreset === preset.name
                              ? 'border-blue-500 bg-blue-50'
                              : ''
                          }`}
                        >
                          {preset.name}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <Button
                  onClick={handleMastering}
                  className='w-full max-w-[10rem] mt-6'
                >
                  {t('audioMastering.master')}
                </Button>
              </div>
            </TabsContent>
          </Tabs>

          {outputUrl && (
            <Card className='w-full p-5 mt-6 space-y-6'>
              <p className='text-lg font-bold'>{t('audioMixing.mixedAudio')}</p>
              <div className='flex flex-col items-center justify-between w-full gap-6 lg:flex-row'>
                <audio controls className='w-full'>
                  <source src={outputUrl} type='audio/mpeg' />
                  Your browser does not support the audio element.
                </audio>
                <a href={outputUrl} download>
                  <Button className='w-full max-w-[10rem]'>Download Audio</Button>
                </a>
              </div>
            </Card>
          )}
        </div>
      </div>
    </div>
  )
}

export default MixingUpload

const UploadSection = ({
  files,
  setFiles,
  error,
  setError,
}: {
  files: File[]
  setFiles: React.Dispatch<React.SetStateAction<File[]>>
  error: string
  setError: React.Dispatch<React.SetStateAction<string>>
}) => {
  const { t } = useTranslation()

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(e.target.files || [])
    const totalFiles = selectedFiles.length + files.length

    if (totalFiles > MAX_FILES) {
      setError(
        `${t('audioMixing.maxFiles')} ${MAX_FILES} ${t('audioMixing.files')}.`
      )
      return
    }

    const validFiles = selectedFiles.filter((file) => {
      if (file.size > MAX_FILE_SIZE) {
        setError(t('audioMixing.files'))
        return false
      }
      return true
    })

    if (validFiles.length) {
      setFiles((prevFiles) => [...prevFiles, ...validFiles])
      setError('')
    }
  }

  const handleDelete = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index))
  }

  const formatFileSize = (size: number) => `${(size / 1024 / 1024).toFixed(2)} MB`

  return (
    <div className='flex flex-col w-full gap-6 mx-auto my-6'>
      <div className='flex items-center justify-center w-full'>
        <label
          htmlFor='dropzone-file'
          className='flex flex-col items-center justify-center w-full h-48 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100'
        >
          <p className='mb-2 text-sm text-gray-500'>
            <span className='font-semibold'>{t('audioMixing.clickUpload')}</span>
          </p>
          <p className='text-xs text-gray-500'>WAV, MP3, or AVI (MAX. 20MB)</p>
          <input
            id='dropzone-file'
            type='file'
            accept='audio/*'
            multiple
            onChange={handleFileChange}
            className='hidden'
          />
        </label>
      </div>
      {files.length > 0 && (
        <ul className='w-full space-y-4'>
          {files.map((file, index) => (
            <li
              key={index}
              className='flex flex-col justify-between p-2 bg-gray-100 border rounded'
            >
              <div className='flex items-center gap-2'>
                <span>{file.name}</span>
                <span className='ml-2 text-gray-500'>
                  ({formatFileSize(file.size)})
                </span>
                <button
                  onClick={() => handleDelete(index)}
                  className='px-2 py-1 ml-auto text-xs text-red-500 bg-transparent border border-red-500 rounded-full hover:bg-red-500 hover:text-white'
                >
                  {t('audioMixing.remove')}
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
