import React from 'react'
import { useCampaign } from 'ctx/CampaignContext'
import FacebookCreative from './components/FacebookCreative'
import SpotifyCreative from './components/SpotifyCreative'

const CampaignCreative: React.FC<{
  onValidityChange: (isValid: boolean) => void
}> = ({ onValidityChange }) => {
  const { config } = useCampaign()

  return (
    <div className='mt-6'>
      {(config.type.id === 'boost_spotify_engagement' ||
        config.type.id === 'boost_instagram_engagement' ||
        config.type.id === 'boost_facebook_engagement' ||
        config.type.id === 'create_facebook_campaign') && (
        <FacebookCreative onValidityChange={onValidityChange} />
      )}
      {/* {config.type.id === 'boost_spotify_engagement' && (
        <SpotifyCreative onValidityChange={onValidityChange} />
      )} */}
    </div>
  )
}

export default CampaignCreative
