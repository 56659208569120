import * as React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from 'lib/utils'
import { useUI } from 'ctx/UIContext'

const badgeVariants = cva(
  'inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
  {
    variants: {
      variant: {
        default:
          'border-transparent bg-primary-purple text-white hover:bg-primary-purple/80',
        secondary:
          'border-transparent bg-secondary-purple/10 text-primary-purple hover:bg-secondary-purple/20',
        destructive:
          'border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80',
        outline: 'text-foreground',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  const { whiteLabelData } = useUI()

  const overrideBackgroundColor =
    (!variant || variant === 'default') && whiteLabelData.theme
      ? { backgroundColor: 'var(--whitelabel-neutral)' }
      : {}

  const overrideTextColor =
    variant === 'secondary' && whiteLabelData.theme
      ? { color: 'var(--whitelabel-neutral)' }
      : {}

  return (
    <div
      className={cn(badgeVariants({ variant }), className)}
      {...props}
      style={{
        ...props.style,
        ...overrideBackgroundColor,
        ...overrideTextColor,
      }}
    />
  )
}

export { Badge, badgeVariants }
