import { Button } from 'components/partials/Button'
import { Card } from 'components/partials/Card'
import Tooltip from 'components/shared/Tooltip'
import { cn } from 'lib/utils'
import { SquareArrowOutUpRight } from 'lucide-react'

interface PostCardProps {
  onClick?: () => void
  active?: boolean
  post: any
}

const PostCard = ({ onClick, active, post }: PostCardProps) => {
  return (
    <Card
      className={cn(
        'w-[12rem] rounded-lg p-1',
        active && 'border-primary-purple/60'
      )}
    >
      <div className='w-full rounded-lg aspect-square'>
        {post.media_type === 'VIDEO' ? (
          <video
            poster={post.media_url}
            src={post.media_url}
            className='object-contain w-full h-full rounded-lg'
          />
        ) : (
          <img
            src={post.full_picture || post.media_url}
            alt={post.message}
            className='object-contain w-full h-full rounded-lg'
          />
        )}
      </div>
      <div className='flex items-center gap-1 pt-2'>
        <Button
          variant={active ? 'default' : 'secondary'}
          className='flex-1 transition-all rounded-md'
          onClick={onClick}
        >
          {active ? 'Selected' : 'Select Post'}
        </Button>
        <Tooltip content='View Post'>
          <a
            href={(post.actions && post.actions[0]?.link) || post.media_url}
            target='_blank'
            rel='noreferrer'
          >
            <Button variant='secondary' size='icon' className='rounded-md'>
              <SquareArrowOutUpRight />
            </Button>
          </a>
        </Tooltip>
      </div>
    </Card>
  )
}

export default PostCard
