import { changeLanguage } from 'i18next'
import { useEffect, useState } from 'react'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './Select'
import { getI18n } from 'react-i18next'
import { languagesSelect } from 'lib/constants'

const LanguageSelector = () => {
  const [language, setLanguage] = useState<{
    language: string
    translation: string
    code: string
  }>({
    language: 'english',
    translation: 'English',
    code: 'en',
  })

  useEffect(() => {
    const lang = getI18n().language

    const language = languagesSelect.find((item) => item.code === lang)
    if (language) {
      setLanguage(language)
    }
  }, [])

  const handleLanguageSelect = (value: string) => {
    changeLanguage(value)
    window.location.reload()
  }
  return (
    <Select onValueChange={handleLanguageSelect}>
      <SelectTrigger>
        <SelectValue
          placeholder={`${language?.translation} ${language?.language}`}
        />
      </SelectTrigger>
      <SelectContent className='z-[1000000]'>
        {languagesSelect.map((opt, i) => (
          <SelectItem key={i} value={opt.code} className='capitalize'>
            <strong>{opt.translation}</strong> {opt.language}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}

export default LanguageSelector
