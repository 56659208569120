import GuidesTabs2 from 'components/layout/GuidesTabs2'
import LoadingScreen from 'components/layout/LoadingScreen'
import { useUI } from 'ctx/UIContext'

const PublicGuides = () => {
  const { whiteLabelData } = useUI()
  return (
    <>
      {whiteLabelData.app_name && whiteLabelData !== undefined ? (
        <>
          <div className='fixed top-0 w-full bg-white shadow-md z-top'>
            <div className='container flex items-center justify-between p-2 mx-auto '>
              <a
                href='/'
                className={`h-10 p-2 md:h-14 ${
                  whiteLabelData?.wlabel === 'wintribemedia'
                    ? ''
                    : 'bg-primary-purple'
                }`}
                style={{
                  backgroundColor:
                    whiteLabelData?.wlabel === 'wintribemedia'
                      ? ''
                      : whiteLabelData?.theme?.[2] ?? '',
                }}
              >
                <img
                  src={whiteLabelData.logo ?? '/assets/logo-white.png'}
                  alt=''
                  className='object-contain h-full'
                />
              </a>
              <div className='space-x-4'>
                <a
                  href='/'
                  className={`font-semibold py-2 transition-all button hover:opacity-90 ${
                    !whiteLabelData?.theme?.[2] ? ' bg-gray-800 text-white' : ''
                  }`}
                  style={{
                    color: whiteLabelData?.theme?.[1] ?? '',
                    backgroundColor: whiteLabelData?.theme?.[2] ?? '',
                  }}
                >
                  Home
                </a>
                <a
                  href='/my-submissions'
                  className={`font-semibold py-2 transition-all button hover:opacity-90 ${
                    !whiteLabelData?.theme?.[2] ? ' bg-gray-800 text-white' : ''
                  }`}
                  style={{
                    color: whiteLabelData?.theme?.[1] ?? '',
                    backgroundColor: whiteLabelData?.theme?.[2] ?? '',
                  }}
                >
                  Dashboard
                </a>
              </div>
            </div>
          </div>
          <div className='relative grid px-4 pt-32 mx-auto md:px-0 md:container place-content-center'>
            <h1 className='mb-2 text-5xl font-bold tracking-tighter text-center md:text-7xl font-title'>
              Get Started
            </h1>
            <p className='relative text-center mb-4 md:mb-8 max-w-lg px-0.5 md:px-0 text-sm md:text-lg leading-5 md:leading-7 text-gray-700'>
              Unlock the full potential of your content with our easy-to-follow
              guides.
            </p>
          </div>
          <div className='container py-4 pb-32 mx-auto'>
            <GuidesTabs2 />
          </div>
        </>
      ) : (
        <LoadingScreen isLoading={true} />
      )}
    </>
  )
}

export default PublicGuides
