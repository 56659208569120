/* eslint-disable react/prop-types */
import { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useSubmissions } from 'store/RootStore'
import { Dialog } from '@headlessui/react'
import { filterObject, modifyDsps } from 'lib/misc'

import EditSubmissionData from './editSubForms'
import { toast } from 'react-hot-toast'
import { useUI } from 'ctx/UIContext'
import MetadataForm from './metadataForms'
import CustomModal from 'components/shared/CustomModal'
import { FieldWrapper, ItemRenderer } from 'components/shared/FormUtils'
import { MultiSelect } from 'react-multi-select-component'
import { dsps } from 'lib/constants'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/partials/Tabs'
import { Button } from 'components/partials/Button'
import { Info, LoaderCircle } from 'lucide-react'
import AgreementForm from './agreementForms'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import SubmissionInfo from './submissionInfo'
import StatusInfo from './submissionInfo/StatusInfo'

const AddMetadata = () => {
  const params = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const {
    getSubmission,
    getMetadata,
    validateSubmission,
    submitForReview,
    getDSPS,
    addDSPS,
  } = useSubmissions()
  const { setLoading } = useUI()

  const [submission, setSubmission] = useState<any>({})
  const [rawSubmission, setRawSubmission] = useState<any>({})
  const [hasMetadata, setHasMetadata] = useState(false)

  const [isSubLoading, setSubLoading] = useState(false)

  const [isDspSelectorOpen, setDspSelectorOpen] = useState(false)
  const [isDspConfirmationOpen, setDspConfirmationOpen] = useState(false)
  const [selectedDSPs, setSelectedDSPs] = useState<any[]>([])

  const type = useMemo(() => params.type, [params])
  const id = useMemo(() => params.id, [params])

  const getSubData = async (isFirst = false) => {
    let merged = {}
    if (isFirst) {
      setSubLoading(true)
    }
    await getSubmission(id).then((res) => {
      if (res && res[0]) {
        const sub = res[0]
        merged = {
          ...merged,
          artWork: sub.artWork,
          artistName: sub.artistName,
          identifier: sub.identifier,
          file: sub.file,
          label: sub.label,
          releaseTitle: sub.releaseTitle,
          selected_type: sub.selected_type,
          status: sub.status,
          submission_id: sub.submission_id,
          user_id: sub.user_id,
          agreement: sub.agreement,
        }
      }
    })
    await getMetadata(id).then((res) => {
      if (res && res[0]) {
        const sub = res[0]
        setHasMetadata(true)
        merged = {
          ...merged,
          sub_genre: sub.sub_genre,

          genre: sub.genre,
          album: sub.album,
          composer: sub.composer,
          digitalReleaseDate: sub.digitalReleaseDate,
          lyrics: sub.lyrics,
          lyricsLanguage: sub.lyricsLanguage,
          lyrics_writer: sub.lyrics_writer,
          parentalAdvisory: sub.parentalAdvisory,
          preview_start: sub.preview_start,

          titleLanguage: sub.titleLanguage,
          trackTitle: sub.trackTitle,
          version: sub.version,
        }
      }
    })

    await getDSPS(id).then((res) => {
      if (res && res.length) {
        const dsps = res.map((item: any) => ({
          value: item.dsp_id,
          label: item.dsp_name,
        }))
        merged = {
          ...merged,
          dsps,
        }
      } else {
        merged = {
          ...merged,
          dsps: [],
        }
      }
    })

    const _merged = filterObject(merged)
    setRawSubmission(merged)
    setSubmission(_merged)
    setSubLoading(false)
  }

  useEffect(() => {
    getSubData(true)
  }, [id])

  const hasEmptyValues = useMemo(() => {
    const _rawSub = { ...rawSubmission }
    _rawSub.selected_type === 'Single' || _rawSub.selected_type === 'Music Video'
      ? delete _rawSub.album
      : delete _rawSub.label

    delete _rawSub.dsps
    delete _rawSub.identifier
    const values = Object.values(_rawSub).filter((val: any) => !val)
    return values.length > 0
  }, [rawSubmission, submission])

  const submit = async (hasDsps: boolean) => {
    if (!hasDsps) {
      setDspSelectorOpen(true)
    } else {
      if (!submission || !submission.submission_id) return
      setLoading(true)
      await validateSubmission(submission.submission_id)
        // await submitForReview(submission.submission_id, 'pending')
        .then((res) => {
          if (res?.detail) {
            toast.error(`Couldn't submit: ${res.detail.error}`)
            // toast.error(`Couldn't submit: ${res.detail}`)
          } else {
            toast.success('Submitted for review!')
            navigate('/my-submissions')
            ReactGA.event({
              category: 'Submission',
              action: 'Submit',
            })
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const SubWrapper: FC<{ isLoading: boolean; children: ReactNode }> = ({
    isLoading,
    children,
  }) => {
    return (
      <div className='relative w-full h-full'>
        {isLoading ? (
          <div className='absolute inset-0 z-[100] bg-white/95 flex items-center justify-center'>
            <span className='text-2xl text-primary-purple animate-spin'>
              <LoaderCircle />
            </span>
          </div>
        ) : null}
        <div className='page-wrapper !px-0'>{children}</div>
      </div>
    )
  }

  const handleAddDsps = async () => {
    const _dspIds = selectedDSPs?.map((dsp: any) => dsp.value).join(',')
    const _dspNames = selectedDSPs?.map((dsp: any) => dsp.label).join(',')

    setRawSubmission((val: any) => ({ ...val, dsps: selectedDSPs }))

    await addDSPS(submission.submission_id as string, _dspIds, _dspNames)
      .then((res) => {
        submit(true)
      })
      .finally(() => {
        setDspSelectorOpen(false)
        setDspConfirmationOpen(false)
        setSelectedDSPs([])
      })
  }

  const handleDSPconfirmation = () => {
    setDspConfirmationOpen(true)
  }

  const tabNames = useMemo(
    () =>
      rawSubmission.agreement
        ? [
            t('submission.addMetadata'),
            t('submission.updateSubmission'),
            t('submission.updateAgreement'),
          ]
        : [
            t('submission.addMetadata'),
            t('submission.updateSubmission'),
            t('submission.updateAgreement'),
          ],
    [rawSubmission]
  )

  return (
    <>
      <div>
        {submission?.status &&
          (submission?.status?.toLowerCase() === 'draft' ||
            submission?.status?.toLowerCase() === 'rejected') && (
            <>
              <CustomModal
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                isOpen={isDspConfirmationOpen}
                close={() => setDspConfirmationOpen(false)}
                action={() => handleAddDsps()}
                title={t('labels.confirmDsps')}
              >
                <p className='text-sm text-center text-gray-500'>
                  {t('messages.confirmDsps')}
                </p>
              </CustomModal>
              <Tabs defaultValue={t('submission.addMetadata')}>
                <TabsList className='sticky top-0 z-10 flex flex-col items-center justify-between gap-2 px-4 py-2 bg-white border-b md:flex-row page-wrapper'>
                  <div className='flex items-center justify-start gap-1 p-1 rounded-full bg-secondary-purple/10'>
                    {tabNames.map((tab: string, i: number) => (
                      <TabsTrigger value={tab} key={i}>
                        {tab}
                      </TabsTrigger>
                    ))}
                  </div>
                  <div className='flex items-center justify-end gap-2'>
                    {!hasEmptyValues ? (
                      <Button
                        type='button'
                        onClick={() => {
                          submit(rawSubmission.dsps.length > 0)
                        }}
                        disabled={isSubLoading}
                      >
                        {t('buttons.submitReview')}
                      </Button>
                    ) : (
                      <>
                        {/* <Tooltip content='Fill all data to unlock'>
                  <span className='text-secondary-purple'>
                    <Info />
                  </span>
                </Tooltip> */}
                        <Button type='button' variant='outline' disabled>
                          {t('buttons.submitReview')}
                        </Button>
                      </>
                    )}
                  </div>
                </TabsList>
                {hasEmptyValues && (
                  <div className='container flex items-center justify-center gap-4 p-5 m-5 mx-auto bg-yellow-100'>
                    <span className='text-yellow-500'>
                      <Info />
                    </span>
                    <p>{t('messages.fillAllData')}</p>
                  </div>
                )}
                {submission?.status?.toLowerCase() === 'rejected' && (
                  <StatusInfo status={submission?.status} />
                )}
                <TabsContent value={t('submission.addMetadata')}>
                  <SubWrapper isLoading={isSubLoading}>
                    <MetadataForm
                      submission={submission}
                      hasMetadata={hasMetadata}
                      type={type}
                      getSub={getSubData}
                    />
                  </SubWrapper>
                </TabsContent>
                <TabsContent value={t('submission.updateSubmission')}>
                  <SubWrapper isLoading={isSubLoading}>
                    <EditSubmissionData
                      submission={submission}
                      getSub={getSubData}
                    />
                  </SubWrapper>
                </TabsContent>
                <TabsContent value={t('submission.updateAgreement')}>
                  <SubWrapper isLoading={isSubLoading}>
                    <AgreementForm submission={submission} getSub={getSubData} />
                  </SubWrapper>
                </TabsContent>
              </Tabs>
              {isDspSelectorOpen ? (
                <Dialog
                  open={true}
                  onClose={() => setDspSelectorOpen(false)}
                  className='relative z-[9998]'
                  as='div'
                >
                  <div className='fixed inset-0 bg-black/80' aria-hidden='true' />
                  <div className='fixed inset-0 flex items-center justify-center'>
                    <Dialog.Panel className='w-full max-w-md p-6 mx-2 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl'>
                      <Dialog.Title
                        as='h3'
                        className='mb-4 text-lg font-semibold leading-6 text-center text-gray-900 capitalize'
                      >
                        {t('labels.selectDsps')}
                      </Dialog.Title>
                      <div className='p-4 w-full min-h-[300px]'>
                        <FieldWrapper>
                          <MultiSelect
                            options={modifyDsps(dsps)}
                            value={selectedDSPs ? selectedDSPs : []}
                            onChange={(e: any) => {
                              setSelectedDSPs(e)
                            }}
                            labelledBy={t('labels.selectDsps')}
                            className={['input', ''].join(' ')}
                            disableSearch
                            ItemRenderer={ItemRenderer}
                            hasSelectAll={true}
                            // isOpen
                            isLoading={false}
                          />
                        </FieldWrapper>
                      </div>
                      <div className='flex items-center justify-center w-full gap-2'>
                        <button
                          className='button muted'
                          onClick={(e) => {
                            e.preventDefault()
                            setDspSelectorOpen(false)
                            setSelectedDSPs([])
                          }}
                        >
                          {t('buttons.close')}
                        </button>
                        <button
                          className='button primary'
                          onClick={(e) => {
                            e.preventDefault()
                            handleDSPconfirmation()
                          }}
                        >
                          {t('buttons.save')}
                        </button>
                      </div>
                    </Dialog.Panel>
                  </div>
                </Dialog>
              ) : null}
            </>
          )}
        {submission?.status &&
          (submission?.status?.toLowerCase() !== 'draft' ||
            submission?.status?.toLowerCase() !== 'rejected') && (
            <div className='page-wrapper !px-0 space-y-6'>
              <SubmissionInfo submission={submission} />
              {/* <Analytics submission={submission} />
            <Earnings submission={submission} /> */}
            </div>
          )}
      </div>
    </>
  )
}

export default AddMetadata
