import Marquee from 'components/partials/Marquee'
import { useUI } from 'ctx/UIContext'
import React from 'react'

const dspList1 = [
  {
    content: 'Joox',
    img: '/assets/dsps/05JOOX_icon_vertical_black.png',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/Amazon.png',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/ang_wide_color.png',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/applemusic0.png',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/boomplay-logo2019.jpeg',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/Colored_Full_Black@2x-small.png',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/gn_wide_color.png',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/gp_wide_color.png',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/JioSaavn-Lockup-Navy-Transparent.png',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/mn_wide_color.png',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/napster-logo-bw.jpeg',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/NetEase-Cloud-Music-Digital-Stores-Page.png',
  },
]
const dspList2 = [
  {
    content: 'Joox',
    img: '/assets/dsps/Pandora_Wordmark_Black-1.png',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/Peloton-Standard-Lockup-RGB.png',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/resso_logo_stores.png',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/SnapLogo.jpeg',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/Spotify_logo_black-2000x601.png',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/STORE-PAGEMT-ListenOn-Badge-3.png',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/sz_wide_color.png',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/tencent-logo.png',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/Tiktok-logo1-1.png',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/tp_wide_color.png',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/wi_wide_color.png',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/YouTube.png',
  },
  {
    content: 'Joox',
    img: '/assets/dsps/浅色背景.png',
  },
]

const AboutContent = () => {
  const { whiteLabelData } = useUI()
  const features = [
    {
      title: 'Social Media Intergration (Coming Soon)',
      description:
        'Input your social media handles, get automatically tagged in related posts, and share release snippets across our platform and your social channels.',
    },
    {
      title: 'Affiliate Program',
      description:
        'Allowing users to earn rewards for referring new artists or users. Provides unique referral links, tracks activities, and offers incentives like discounts or monetary rewards.',
    },
    {
      title: `${
        whiteLabelData?.wlabel === 'everybodymusic'
          ? 'AI Artist Evaluation Tool (Coming Soon)'
          : 'Free AI Artist Evaluation Tool (Coming Soon)'
      }`,
      description:
        'An artist evaluation tool to gauge potential success based on streaming, social engagement, and industry recognition metrics.',
    },
    {
      title: 'Automated Release Alerts',
      description:
        'Automatically notifies artists via email upon release of albums or singles, providing links to all platforms hosting their music.',
    },
    {
      title: `${
        whiteLabelData?.wlabel === 'everybodymusic'
          ? 'AI Mastering Tool'
          : 'Free AI Mastering Tool'
      }`,
      description:
        'Empower artists with tools for mastering music and audio tracks, offering presets and manual adjustments for top-tier sound quality.',
    },
    {
      title: `${
        whiteLabelData?.wlabel === 'everybodymusic'
          ? 'AI-Powered Marketing Tool (Coming Soon)'
          : 'Free AI Marketing Tool (Coming Soon)'
      }`,
      description:
        'Supports artists with music promotion, including strategies, social media scheduling, and detailed campaign analytics for effective outreach.',
    },
    {
      title: `${
        whiteLabelData?.wlabel === 'everybodymusic'
          ? 'AI Audio Mix-down tool'
          : 'Free AI Audio Mix-down tool'
      }`,
      description:
        'Elevate your sound with AI-driven audio mixing. A professional-grade, balanced mix. Perfect for artists seeking precision and efficiency in mastering tracks.',
    },
    {
      title: `${
        whiteLabelData?.wlabel === 'everybodymusic'
          ? 'AI Artwork Generator Tool'
          : 'Free AI Artwork Generator Tool'
      }`,
      description:
        'Create stunning, professional artwork with AI-powered generation and customizable templates, designed to fit your unique vision.',
    },
  ]
  return (
    <div>
      <div className='relative grid pt-14 md:pt-28 md:px-0'>
        <h4 className='z-10 max-w-5xl mx-auto space-y-4 text-xl font-semibold md:text-center font-title md:text-2xl lg:text-3xl'>
          Empowering artists, labels, and distributors to monetize their music and
          videos independently. It offers real-time analytics and earnings insights,
          enabling global releases of singles, albums, and videos at zero cost to the
          creators.
        </h4>
        {/* <div className="absolute inset-0 -z-10 h-full w-full items-center px-5 py-24 [background:radial-gradient(125%_125%_at_50%_10%,#000_40%,#63e_100%)]"></div> */}
        <div className='bg-[#fcd2d2] absolute top-[-6rem] -z-10 right-[11rem] h-[37.25rem] w-[31.25rem] rounded-full blur-[10rem] sm:w-[68.75rem] opacity-60'></div>
        <div className='bg-[#dbd7ff] absolute top-[-1rem] -z-10 left-[-35rem] h-[37.25rem] w-[50rem] rounded-full blur-[10rem] sm:w-[68.75rem] md:left-[-33rem] lg:left-[-28rem] xl:left-[-15rem] 2xl:left-[-5rem] opacity-60'></div>
      </div>
      <div className='relative grid py-14 md:py-28 md:px-0 place-content-center'>
        <h4 className='z-10 max-w-5xl space-y-4 text-xl font-semibold text-center font-title md:text-4xl lg:text-5xl'>
          <span className='text-primary-purple'>
            {whiteLabelData.app_name !== 'default'
              ? whiteLabelData.app_name
              : 'MusicDASH'}
          </span>{' '}
          allows independent artists, labels, and distributors to release their
          Singles, Albums and Videos
          <span className='text-primary-purple'> worldwide 100% free</span> to use.
        </h4>
        {/* <div className="absolute inset-0 -z-10 h-full w-full items-center px-5 py-24 [background:radial-gradient(125%_125%_at_50%_10%,#000_40%,#63e_100%)]"></div> */}
        <div className='bg-[#fcd2d2] absolute top-[-6rem] -z-10 right-[11rem] h-[37.25rem] w-[31.25rem] rounded-full blur-[10rem] sm:w-[68.75rem] opacity-60'></div>
        <div className='bg-[#dbd7ff] absolute top-[-1rem] -z-10 left-[-35rem] h-[37.25rem] w-[50rem] rounded-full blur-[10rem] sm:w-[68.75rem] md:left-[-33rem] lg:left-[-28rem] xl:left-[-15rem] 2xl:left-[-5rem] opacity-60'></div>
      </div>
      <div className='my-16'>
        <h1 className='max-w-5xl mx-auto mb-10 text-3xl font-bold tracking-tight text-center text-black md:text-5xl lg:text-7xl lg:leading-tight font-title'>
          Unlimited <span className='text-primary-purple'>100% Free</span>{' '}
          Distribution
        </h1>
        <div className='space-y-6'>
          <Marquee items={dspList1} />
          <Marquee items={dspList2} direction='right' />
        </div>
        <p className='relative font-medium text-center mt-10 mb-4 mx-auto md:mb-8 max-w-lg px-0.5 md:px-0 text-sm md:text-xl leading-5 md:leading-7 text-gray-700'>
          Release your music on Spotify, Apple Music, TikTok, Instagram, Soundcloud,
          Tidal, and all other major stores and streaming platforms.
        </p>
      </div>
      <div className='my-16 md:my-32'>
        <div className='grid w-full grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4 md:gap-10 md:px-10'>
          <h1 className='max-w-5xl mb-4 text-3xl font-bold tracking-tight text-black md:mb-10 lg:text-7xl md:text-5xl lg:leading-tighter font-title md:col-span-2'>
            {whiteLabelData?.wlabel === 'everybodymusic' ? (
              <>
                <span className='text-primary-purple'>AI Powered Tools</span> to
                Enhance Your Creativity
              </>
            ) : (
              <>
                <span className='text-primary-purple'>Powerful Tools</span> to
                Enhance Your Creativity
              </>
            )}
          </h1>
          {features.map((feature, i) => (
            <FeatureCard
              key={i}
              title={feature.title}
              description={feature.description}
            />
          ))}
        </div>
      </div>
      <div className='relative flex flex-col'>
        <h1 className='max-w-5xl mx-auto mb-10 text-3xl font-bold tracking-tight text-center text-black lg:text-7xl md:text-5xl lg:leading-tight font-title'>
          Start growing with{' '}
          <span className='text-primary-purple'>
            {' '}
            {whiteLabelData.app_name !== 'default'
              ? whiteLabelData.app_name
              : 'MusicDASH'}
          </span>{' '}
          Today!
        </h1>
        <div className='flex flex-col items-center justify-center gap-4 mt-2 md:mt-10 md:flex-row'>
          <a href='/login'>
            <button
              className={`button md:!text-xl md:!py-3 md:!px-6 ${
                !whiteLabelData.theme ? 'primary' : 'hover:opacity-80'
              }`}
              style={{
                backgroundColor: whiteLabelData?.theme?.[2] ?? '',
                color: whiteLabelData?.theme?.[1] ?? '',
              }}
            >
              Get Started for Free
            </button>
          </a>
        </div>
        <div className='bg-[#fcd2d2] absolute top-[-6rem] -z-10 right-[11rem] h-[37.25rem] w-[31.25rem] rounded-full blur-[10rem] sm:w-[68.75rem] opacity-60'></div>
        <div className='bg-[#dbd7ff] absolute top-[-1rem] -z-10 left-[-35rem] h-[37.25rem] w-[50rem] rounded-full blur-[10rem] sm:w-[68.75rem] md:left-[-33rem] lg:left-[-28rem] xl:left-[-15rem] 2xl:left-[-5rem] opacity-60'></div>
      </div>
    </div>
  )
}

export default AboutContent

const FeatureCard = ({
  title,
  description,
}: {
  title: string
  description: string
}) => {
  return (
    <div className='col-span-1 p-5 text-white bg-primary-purple grad-bg rounded-3xl md:min-h-72 pt-7'>
      <h3 className='mb-6 text-lg font-semibold md:text-2xl'>{title}</h3>
      <p className='text-sm md:text-base'>{description}</p>
    </div>
  )
}
