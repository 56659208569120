import { Button } from 'components/partials/Button'
import { Download, FileSpreadsheet, Trash2 } from 'lucide-react'
import { createRef, useState } from 'react'
import Dropzone from 'react-dropzone'
import * as XLSX from 'xlsx'
import { useBulkSubmissions } from '../context/BulkSubmissionContext'
import { convertExcelTimeToReadable, formatDateFromDays } from 'lib/utils'
import { useTranslation } from 'react-i18next'

const allowedMetadataHeaders = [
  'Release Title',
  'Artist Name',
  'Label Name',
  'Main Genre',
  'Sub-genre',
  'Submission Type',
  'Track Title',
  'Version (Optional)',
  'Lyrics Language',
  'Title Language',
  'Full Lyrics',
  'Parental Advisory',
  'Release Date',
  'Preview Start',
  'Filename',
  'Artwork Filename',
  'Composer',
  'Lyrics Writer',
  'Album',
]

const MetadataUpload = () => {
  const { updateData } = useBulkSubmissions()
  const { t } = useTranslation()

  const csvRules = [
    'Altered templates (added or removed columns or changed column titles) may not be recognized.',
  ]
  const [csvErrors, setCSVErrors] = useState<any[]>([])
  const [csvFiles, setCSVFiles] = useState<any[]>([])
  const [csvData, setCSVData] = useState<any>([])
  const [isCSVLoading, setCSVLoading] = useState(false)
  const [isOkToProceed, setIsOkToProceed] = useState(false)

  const validateCSVFile = (file: any) => {
    setCSVLoading(true)
    const reader = new FileReader()

    reader.onload = (e: any) => {
      /* Parse data */
      const bstr = e.target.result
      const wb = XLSX.read(bstr, { type: 'binary' })
      /* Get first worksheet */
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      /* Convert array of arrays */
      const data: any[] = XLSX.utils
        .sheet_to_json(ws, { header: 0, defval: '' })
        .sort((a: any, b: any) => {
          return Object.keys(b).length - Object.keys(a).length
        })
        .map((item: any) => {
          return {
            ...item,
            'Preview Start': convertExcelTimeToReadable(item['Preview Start']),
            'Release Date': formatDateFromDays(item['Release Date']),
          }
        })
      /* Update state */

      const headers = Object.keys(data[0])
      const checkHeadersAltered = allowedMetadataHeaders.filter(
        (element) => !headers.includes(element)
      )

      if (checkHeadersAltered.length) {
        setCSVErrors(
          checkHeadersAltered.map((err: string) => '"' + err + '" is missing')
        )
      } else {
        setIsOkToProceed(true)
        setCSVData(data)
        setCSVErrors([])
      }
      setCSVLoading(false)
    }

    reader.readAsBinaryString(file[0])
    setCSVFiles(file)
  }

  const removeCSVFile = (file: any) => {
    const newFiles = [...csvFiles]
    newFiles.splice(csvFiles.indexOf(file), 1)
    setCSVFiles(newFiles)
    setCSVErrors([])
    setIsOkToProceed(false)
  }

  const ref = createRef() as any

  const openDialog = () => {
    if (ref.current) {
      ref.current.open()
    }
  }

  return (
    <div>
      <Dropzone
        onDrop={(acceptedFiles) => validateCSVFile(acceptedFiles)}
        maxFiles={1}
        noClick
        noKeyboard
        ref={ref}
        accept={{
          'application/vnd': ['.xlsx', '.xls'],
          'text/csv': ['.csv'],
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <section className='flex items-center justify-center w-full p-4'>
            <div
              {...getRootProps()}
              className='relative p-8 border-2 border-dashed border-black/10 !m-0 flex items-center justify-center flex-col w-full md:max-w-3xl rounded-lg gap-2'
            >
              <input {...getInputProps()} />
              {csvFiles.length ? (
                <div className='relative flex items-center justify-center w-full gap-2'>
                  <span className='flex items-center justify-center gap-2 font-semibold'>
                    <FileSpreadsheet size={16} />
                    {csvFiles[0].name}
                  </span>
                  <Button
                    variant='destructive'
                    size='icon'
                    onClick={(e) => {
                      removeCSVFile(csvFiles[0])
                    }}
                  >
                    <Trash2 />
                  </Button>
                </div>
              ) : (
                <Button variant='secondary' onClick={openDialog}>
                  {t('metadataUpload.dropFileHere')}
                </Button>
              )}
              <span className='flex gap-1 mt-1 text-sm'>
                {t('metadataUpload.downloadTemplate')}{' '}
                <span className='flex items-center gap-1 font-bold uppercase text-primary-purple'>
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href='https://musicdash.s3.amazonaws.com/MUSICDASH_BULK_TEMPLATE_2023.xlsx'
                    download
                  >
                    {t('words.here')}
                  </a>
                  <Download />
                </span>
              </span>
              <span className='text-xs text-gray-800'>
                {t('metadataUpload.alteredTemplatesWarning')}
              </span>
            </div>
          </section>
        )}
      </Dropzone>
      {csvErrors.length ? (
        <div className='flex flex-col items-center justify-center w-full max-w-3xl mx-auto'>
          <p className='mb-2 font-semibold text-red-600'>
            {t('metadataUpload.missingDataError')}
          </p>
          <div className='grid grid-cols-1 md:grid-cols-2'>
            {csvErrors.map((error, i) => (
              <span key={i} className='text-sm'>
                {error}
              </span>
            ))}
          </div>
        </div>
      ) : null}

      <div className='flex items-center justify-center w-full max-w-3xl mx-auto'>
        <Button
          onClick={() => {
            updateData('metadata', csvData)
          }}
          isDisabled={!isOkToProceed}
          variant='outline'
        >
          {t('metadataUpload.nextButton')}
        </Button>
      </div>
    </div>
  )
}

export default MetadataUpload
