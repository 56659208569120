import React, { useEffect, useState } from 'react'
import PageHeader from 'components/shared/PageHeader'
import { useArtwork } from 'store/RootStore'
import { Button } from 'components/partials/Button'
import LoadingScreen from 'components/layout/LoadingScreen'
import moment from 'moment'
import { Download, Expand } from 'lucide-react'
import { Card } from 'components/partials/Card'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'

const GenerateArtwork: React.FC = () => {
  const { generateArtwork, getTaskStatus, getTaskHistory, warmupWorkers } =
    useArtwork()
  const { t } = useTranslation()
  const [prompt, setPrompt] = useState('')
  const [image, setImage] = useState<string | null>(null)
  const [loadingStatus, setLoadingStatus] = useState<string | null>(null)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [currentImage, setCurrentImage] = useState<string | null>(null)
  const [history, setHistory] = useState<any[]>([])

  useEffect(() => {
    const warmup = async () => warmupWorkers()

    warmup()
  }, [])

  useEffect(() => {
    const fetchTaskHistory = async () => {
      const historyResponse = await getTaskHistory()
      setHistory(historyResponse)
    }

    fetchTaskHistory()
  }, [image])

  const checkTaskStatus = async (s3Url: string) => {
    setLoadingStatus(t('artworkGenerator.imageGenerated'))

    const interval = setInterval(async () => {
      const statusResponse = await getTaskStatus(s3Url)
      if (statusResponse.task_status === 'completed') {
        setLoadingStatus(t('artworkGenerator.imageUpload'))
        setImage(s3Url)
        clearInterval(interval)
      } else if (statusResponse.task_status === 'failed') {
        setLoadingStatus(null)
        clearInterval(interval)
        alert(t('artworkGenerator.failed'))
      }
    }, 30000)
  }

  const handleGenerateArtwork = async () => {
    setImage(null)
    setLoadingStatus(t('artworkGenerator.generating'))
    const imageResponse = await generateArtwork(prompt)
    if ('detail' in imageResponse) {
      setLoadingStatus(null)
      return alert(imageResponse.detail)
    } else {
      checkTaskStatus(imageResponse.s3_url)
      ReactGA.event({
        category: 'Artwork Generator',
        action: 'Generate',
      })
    }
  }

  const handleViewImage = (imageUrl: string) => {
    setCurrentImage(imageUrl)
    setIsFullScreen(true)
  }

  return (
    <div className='page-wrapper'>
      <PageHeader title={t('sidebar.artworkGenerator')} isLoading={false} />
      <div className='flex flex-col w-full h-full mt-10 font-medium'>
        <div className='w-full'>
          <p className='mb-2 font-medium'>{t('artworkGenerator.enterPrompt')}</p>
          <div className='flex flex-col gap-4 lg:gap-2 lg:flex-row'>
            <input
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              placeholder={t('artworkGenerator.enterPromptPlaceholder')}
              className='p-2 border border-gray-300 rounded'
            />
            <Button onClick={handleGenerateArtwork}>
              {t('artworkGenerator.generateArtwork')}
            </Button>
          </div>
          {loadingStatus && !image && <LoadingStatus status={loadingStatus} />}
          {/* {image && (
              <>
                <p>Generated Image</p>
                <img
                  src={image}
                  alt='Generated artwork'
                  className='object-cover w-full h-auto cursor-pointer'
                  onClick={() => handleViewImage(image)}
                />
              </>
            )} */}
        </div>

        <div className='mt-10'>
          <h2 className='text-xl font-semibold'>
            {t('artworkGenerator.generatedArtworks')}
          </h2>
          {Array.isArray(history) ? (
            history.length > 0 ? (
              <div className='grid grid-cols-1 gap-6 mt-4 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4'>
                {history.map((task, index) => (
                  <ArtworkCard
                    key={index}
                    task={task}
                    handleViewImage={handleViewImage}
                  />
                ))}
              </div>
            ) : (
              <p className='mt-4'>{t('artworkGenerator.noArtworks')}</p>
            )
          ) : (
            <LoadingScreen isLoading />
          )}
        </div>
      </div>
      {isFullScreen && currentImage && (
        <FullScreenImageModal
          imageUrl={currentImage}
          onClose={() => setIsFullScreen(false)}
        />
      )}
    </div>
  )
}

export default GenerateArtwork

const LoadingStatus: React.FC<{ status: string }> = ({ status }) => {
  return (
    <div className='flex items-center mt-4 text-blue-600'>
      <LoadingSpinner />
      <p className='ml-4 animate-pulse'>{status}</p>
    </div>
  )
}

const LoadingSpinner: React.FC = () => {
  return (
    <div className='border-4 border-blue-600 border-solid rounded-full size-5 border-t-transparent animate-spin'></div>
  )
}

interface FullScreenImageModalProps {
  imageUrl: string
  onClose: () => void
}

const FullScreenImageModal: React.FC<FullScreenImageModalProps> = ({
  imageUrl,
  onClose,
}) => {
  return (
    <div
      onClick={onClose}
      className='fixed h-screen w-full inset-0 z-[10000000] flex items-center justify-center bg-black bg-opacity-75'
    >
      <button
        onClick={onClose}
        className='absolute text-5xl text-white top-4 right-4'
      >
        &times;
      </button>
      <img
        src={imageUrl}
        alt='Full screen artwork'
        className='max-w-full max-h-full aspect-square'
      />
    </div>
  )
}

const ArtworkCard: React.FC<{
  task: any
  handleViewImage: (url: string) => void
}> = ({ task, handleViewImage }) => {
  const { t } = useTranslation()

  return (
    <Card className='relative p-2'>
      <img
        src={task.s3_url}
        alt={`${t('artworkGenerator.artworkPrompt')} ${task.prompt}`}
        className='object-cover w-full mb-4 rounded-lg aspect-square'
      />
      <div className='absolute flex gap-1 top-5 right-5'>
        <div className='p-2 transition-all bg-white rounded-full cursor-pointer hover:bg-blue-100 '>
          <a href={task.s3_url} download>
            <Download className='size-4' />
          </a>
        </div>
        <div
          className='p-2 transition-all bg-white rounded-full cursor-pointer hover:bg-blue-100'
          onClick={() => handleViewImage(task.s3_url)}
        >
          <Expand className=' size-4' />
        </div>
      </div>

      <p>
        <strong>{t('words.prompt')}:</strong> {task.prompt}
      </p>
      <p className='capitalize'>
        <strong>{t('words.status')}:</strong> {task.status}
      </p>
      <p>
        <strong>{t('words.creationTime')}:</strong>{' '}
        {moment(task.creation_time).format('MMMM Do YYYY, h:mm a')}
      </p>
      <p>
        <strong>{t('words.completedTime')}:</strong>{' '}
        {moment(task.completion_time).format('MMMM Do YYYY, h:mm a')}
      </p>
    </Card>
  )
}
