import { useNavigate, useSearchParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import AuthLayout from 'components/layout/AuthLayout'
import { FieldWrapper, Error } from 'components/shared/FormUtils'
import { useEffect } from 'react'
import axios from 'axios'
import { toast } from 'react-hot-toast'
import { useUI } from 'ctx/UIContext'
import { API_URL } from 'lib/constants'
import { useTranslation } from 'react-i18next'

const ConfirmSignup = () => {
  const ui = useUI()
  const { t } = useTranslation()
  const { whiteLabelData } = useUI()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: 'all', reValidateMode: 'onChange' })

  const onSubmit = async (data: any) => {
    // auth?.confirmSignup(data.username, data.code).finally(() => {
    //   reset(defaultValues)
    // })
    ui.setLoading(true)
    await axios
      .post(`${API_URL}confirm`, {
        username: data.username,
        confirmation_code: data.code,
      })
      .then(() => {
        toast.success(t('toasts.emailConfirmed'), {
          position: 'top-center',
        })
        navigate('/login')
      })
      .finally(() => {
        ui.setLoading(false)
      })
  }
  const resendEmail = async () => {
    ui.setLoading(true)
    await axios
      .post(`${API_URL}resend`, {
        username: searchParams.get('user'),
      })
      .then((res) => {
        toast.success(res.data.message, {
          position: 'top-center',
        })
      })
      .finally(() => {
        ui.setLoading(false)
      })
  }
  useEffect(() => {
    if (searchParams && searchParams.get('user')) {
      reset({ username: searchParams.get('user') })
    }
  }, [searchParams])

  return (
    <AuthLayout>
      <form
        className='form justify-center !h-max !p-0'
        onSubmit={handleSubmit(onSubmit)}
      >
        <FieldWrapper cls='hidden'>
          <label htmlFor='username'>
            {t('auth.confirm.username.title')}{' '}
            {errors['username'] ? (
              <Error>
                <span className='inline-block mt-0.5'>
                  {t('auth.confirm.username.required')}
                </span>
              </Error>
            ) : null}
          </label>
          <input
            className='input'
            type='text'
            placeholder='Username'
            disabled
            {...register('username', {
              required: {
                value: true,
                message: 'Username is required',
              },
            })}
          />
        </FieldWrapper>
        <FieldWrapper>
          <label htmlFor='code'>
            {t('auth.confirm.code.plain')}{' '}
            {errors['code'] ? (
              <Error>
                <span className='inline-block mt-0.5'>
                  {t('auth.confirm.code.required')}
                </span>
              </Error>
            ) : null}
          </label>
          <input
            className='input'
            type='code'
            placeholder='Enter the code here'
            {...register('code', { required: true })}
          />
        </FieldWrapper>

        <button
          type='submit'
          className={`mt-2 button ${
            !whiteLabelData.theme ? 'primary' : 'hover:opacity-80'
          }`}
          style={{
            backgroundColor: whiteLabelData?.theme?.[2] ?? '',
            color: whiteLabelData?.theme?.[1] ?? '',
          }}
        >
          {t('auth.confirm.plain')}
        </button>
      </form>
      <span className='text-sm'>
        {t('authLayout.confirmSignup.didntReceive')}
        <span className='ml-1 underline' onClick={resendEmail}>
          {t('auth.confirm.resend.title')}
        </span>
      </span>
    </AuthLayout>
  )
}

export default ConfirmSignup
