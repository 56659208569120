import { useCampaign } from 'ctx/CampaignContext'
import FacebookBudget from './components/FacebookBudget'

const CampaignBudget = ({
  onValidityChange,
}: {
  onValidityChange: (isValid: boolean) => void
}) => {
  const { config } = useCampaign()

  return (
    <div className='mt-6'>
      {(config.type.id === 'boost_spotify_engagement' ||
        config.type.id === 'boost_instagram_engagement' ||
        config.type.id === 'boost_facebook_engagement') && (
        <FacebookBudget onValidityChange={onValidityChange} />
      )}
      {/* {config.type.id === 'boost_spotify_engagement' && (
        <SpotifyBudget onValidityChange={onValidityChange} />
      )} */}
    </div>
  )
}

export default CampaignBudget
