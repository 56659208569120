import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import yupPassword from 'yup-password'

import AuthLayout from 'components/layout/AuthLayout'
import { FieldWrapper, Error } from 'components/shared/FormUtils'
import { useAuth } from 'ctx/AuthContext'
import { EyeOpenIcon, EyeClosedIcon } from 'components/shared/Icons'
import { useTranslation } from 'react-i18next'
import { Handshake } from 'lucide-react'
import { useUI } from 'ctx/UIContext'

const defaultValues = {
  firstName: '',
  lastName: '',
  username: '',
  email: '',
  password: '',
  cPassword: '',
  terms: false,
}

yupPassword(yup)

const emailString = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i
const checkSpaces = /^[a-zA-Z0-9@]+$/

const Signup = () => {
  const auth = useAuth()
  const { t } = useTranslation()
  const { whiteLabelData } = useUI()
  const [isPasswordVisible, setPasswordVisible] = useState(false)

  const [coupon, setCoupon] = useState<string | null>(null)

  useEffect(() => {
    const coupon = localStorage.getItem('affiliate')
    if (coupon) {
      setCoupon(coupon)
    }
  }, [])

  const schema = yup.object().shape({
    username: yup
      .string()
      .matches(checkSpaces, t('auth.errors.whiteSpace'))
      .required(t('auth.confirm.username.required')),
    firstName: yup
      .string()
      .matches(checkSpaces, t('auth.errors.whiteSpace'))
      .required(t('auth.errors.firstName')),
    lastName: yup.string().trim().required(t('auth.errors.lastName')),
    email: yup
      .string()
      .trim()
      .required(t('auth.errors.email'))
      .matches(emailString, { message: t('auth.errors.emailInvalid') }),
    password: yup
      .string()
      .trim()
      .required(t('auth.confirm.password.required'))
      .minLowercase(1, t('auth.errors.passLowerCase'))
      .minUppercase(1, t('auth.errors.passUpperCase'))
      .minNumbers(1, t('auth.errors.passNumber'))
      .minSymbols(1, t('auth.errors.passSpecialChar'))
      .min(8, t('auth.errors.passLength')),
    cPassword: yup
      .string()
      .trim()
      .required(t('auth.errors.confirmPassRequired'))
      .minLowercase(1, t('auth.errors.passLowerCase'))
      .minUppercase(1, t('auth.errors.passUpperCase'))
      .minNumbers(1, t('auth.errors.passNumber'))
      .minSymbols(1, t('auth.errors.passSpecialChar'))
      .min(8, t('auth.errors.passLength'))
      .oneOf([yup.ref('password')], t('auth.errors.confirmPassMatch')),
    terms: yup.bool().oneOf([true], t('auth.errors.acceptTerms')),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema),
  })

  const onSubmit = async (data: any) => {
    auth
      ?.signUp(
        data.username,
        data.password,
        data.firstName,
        data.lastName,
        data.email,
        coupon
      )
      .then(() => {
        reset(defaultValues)
      })
  }

  const togglePasswordVisible = () => setPasswordVisible(!isPasswordVisible)

  return (
    <AuthLayout>
      {whiteLabelData.app_name === 'default' && coupon && (
        <div className='flex items-center gap-2 p-2 px-4 font-semibold text-center bg-blue-100 border border-blue-300 rounded-md'>
          <Handshake className='w-5 h-5 text-blue-500' /> Affiliate Coupon:{' '}
          <span className='font-normal'>{coupon}</span>
        </div>
      )}
      <form
        className='form justify-center !h-max !p-0'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='relative flex flex-col items-center justify-between w-full gap-2 md:flex-row'>
          <FieldWrapper cls='flex-1 w-full'>
            <label htmlFor='firstname'>
              {t('authLayout.info.firstName')}{' '}
              {errors['firstName'] ? (
                <Error>
                  <span className='inline-block mt-0.5'>
                    {' '}
                    {errors.firstName?.message?.toString()}
                  </span>
                </Error>
              ) : null}
            </label>
            <input
              className='input'
              type='text'
              placeholder='First name'
              {...register('firstName')}
            />
          </FieldWrapper>
          <FieldWrapper cls='flex-1 w-full'>
            <label htmlFor='lastName'>
              {t('authLayout.info.lastName')}{' '}
              {errors['lastName'] ? (
                <Error>
                  <span className='inline-block mt-0.5'>
                    {' '}
                    {errors.lastName?.message?.toString()}
                  </span>
                </Error>
              ) : null}
            </label>
            <input
              className='input'
              type='text'
              placeholder='Last name'
              {...register('lastName')}
            />
          </FieldWrapper>
        </div>
        <FieldWrapper cls='flex-1 w-full'>
          <label htmlFor='username'>
            {t('auth.confirm.username.title')}{' '}
            {errors['username'] ? (
              <Error>
                <span className='inline-block mt-0.5'>
                  {' '}
                  {errors.username?.message?.toString()}
                </span>
              </Error>
            ) : null}
          </label>
          <input
            className='input'
            type='text'
            placeholder='Username'
            {...register('username')}
          />
        </FieldWrapper>
        <FieldWrapper>
          <label htmlFor='email'>
            {t('authLayout.info.email')}{' '}
            {errors['email'] ? (
              <Error>
                <span className='inline-block mt-0.5'>
                  {' '}
                  {errors.email?.message?.toString()}
                </span>
              </Error>
            ) : null}
          </label>
          <input
            className='input'
            type='text'
            placeholder='your@email.com'
            {...register('email')}
          />
        </FieldWrapper>
        <FieldWrapper>
          <label htmlFor='password'>
            {t('auth.password.title')}{' '}
            {errors['password'] ? (
              <Error>
                <span className='inline-block mt-0.5'>
                  {' '}
                  {errors.password?.message?.toString()}
                </span>
              </Error>
            ) : null}
          </label>
          <div className='flex items-start justify-between w-full'>
            <input
              className='input'
              type={isPasswordVisible ? 'text' : 'password'}
              placeholder='Min 8 characters'
              {...register('password')}
            />
            <span
              className='inline-block pt-4 pl-2 text-sm text-primary-purple'
              aria-hidden
              onClick={togglePasswordVisible}
            >
              {isPasswordVisible ? <EyeOpenIcon /> : <EyeClosedIcon />}
            </span>
          </div>
        </FieldWrapper>
        <FieldWrapper>
          <label htmlFor='cPassword'>
            {t('auth.password.confirmPassword')}{' '}
            {errors['cPassword'] ? (
              <Error>
                <span className='inline-block mt-0.5'>
                  {' '}
                  {errors.cPassword?.message?.toString()}
                </span>
              </Error>
            ) : null}
          </label>
          <div className='flex items-start justify-between w-full'>
            <input
              className='input'
              type={isPasswordVisible ? 'text' : 'password'}
              placeholder='Min 8 characters'
              {...register('cPassword')}
            />
            <span
              className='inline-block pt-4 pl-2 text-sm text-primary-purple'
              aria-hidden
              onClick={togglePasswordVisible}
            >
              {isPasswordVisible ? <EyeOpenIcon /> : <EyeClosedIcon />}
            </span>
          </div>
        </FieldWrapper>
        <div className='relative flex flex-col items-start justify-start'>
          <div className='flex items-center justify-start'>
            <input type='checkbox' {...register('terms')} className='!m-0 w-max' />
            <span className='ml-2 text-sm text-gray-600 md:whitespace-nowrap'>
              {t('auth.signup.confirmSignup')}
            </span>
          </div>
          {errors['terms'] ? (
            <Error cls='!text-start'>
              <span className='inline-block mt-0.5'>
                {errors.terms?.message?.toString()}
              </span>
            </Error>
          ) : null}
        </div>
        <button
          className={`mt-3 button ${
            !whiteLabelData.theme ? 'primary' : 'hover:opacity-80'
          }`}
          style={{
            backgroundColor: whiteLabelData?.theme?.[2] ?? '',
            color: whiteLabelData?.theme?.[1] ?? '',
          }}
          type='submit'
        >
          {t('authLayout.signup.plain')}
        </button>
        <div className='relative flex items-center justify-start mt-2'>
          <span className='flex items-center justify-start text-sm'>
            {t('authLayout.signup.existing')}
          </span>
          <Link to='/login'>
            <span className='ml-1 text-sm underline'>{t('auth.login.title')}</span>
          </Link>
        </div>
      </form>
    </AuthLayout>
  )
}

export default Signup
