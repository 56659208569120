import { API_URL } from 'lib/constants'

export default function useWhiteLabel(): {
  getWhiteLabelData: () => Promise<any | null>
} {
  const getWhiteLabelData = async () => {
    try {
      const domain = getDomain(window.location.origin)
      if (!domain || domain === 'localhost' || domain === 'musicdash.com')
        return null

      const endpoint = `${API_URL}public/white-label/dom/${domain}`
      const response = await fetch(endpoint, {
        method: 'GET',
        cache: 'no-cache',
        redirect: 'follow',
      })

      if (response.ok) {
        const data = await response.json()
        return data.detail ? null : data
      }
      console.error(`Failed to fetch data: ${response.statusText}`)
      return null
    } catch (err) {
      console.error('Error fetching white-label data:', err)
      return null
    }
  }

  const getDomain = (url: string): string | null => {
    const match = url.match(
      /https?:\/\/(?:[a-zA-Z0-9-]+\.):*?([a-zA-Z0-9-]+\.[a-zA-Z0-9-]+\.[a-zA-Z]{2,}|[a-zA-Z0-9-]+\.[a-zA-Z]{2,})\/?/i
    )
    return match ? match[1] : null
  }

  return {
    getWhiteLabelData,
  }
}
