/* eslint-disable no-bitwise */
export const formatDate = (date: Date) => {
  const d = new Date(date)
  return [
    d.getFullYear(),
    `0${d.getMonth() + 1}`.slice(-2),
    `0${d.getDate()}`.slice(-2),
  ].join('-')
}
const lsSupport = () => {
  // eslint-disable-next-line dot-notation
  return 'localStorage' in window && window['localStorage'] !== null
}

export const lsset = (key: string | number, val: string, exp?: number) => {
  if (lsSupport()) {
    // eslint-disable-next-line no-param-reassign
    if (!exp) exp = 600 * 500 // = 10 minutes Default
    localStorage[key] = JSON.stringify({
      val,
      exp: ~~(new Date().getTime() / 1000) + exp,
    })
  }
}

export const lsget = (key: string | number) => {
  let json
  if (lsSupport()) {
    const str = localStorage[key]
    if (typeof str !== 'undefined' && str.length) {
      try {
        // is json or not
        json = JSON.parse(str)
      } catch (e) {
        // if variable not set via lsset func
        // json.exp=false;// will return null
        return str // will return original variable
      }
      if (json.exp) {
        // variable setted via lsset func
        if (~~(new Date().getTime() / 1000) > json.exp) {
          // expired
          delete localStorage[key]
        } else {
          return json.val
        }
      }
    }
  }
  return null
}

export const classNames = (...classes: string[]) => classes.filter(Boolean).join(' ')

export const getNumberWithOrdinal = (n: number) => {
  const s = ['th', 'st', 'nd', 'rd'],
    v = n % 100
  return n + (s[(v - 20) % 10] || s[v] || s[0])
}
export const generateRandomNumberInRange = (min = 100000, max = 999999) =>
  Math.floor(Math.random() * (max - min)) + min

export const filterObject = (obj: any) =>
  Object.fromEntries(
    Object.entries(obj).filter(([_, v]) => v && v !== '' && v !== 'undefined')
  )
export const formatCash = (n: number) => {
  if (n < 1e3) return n.toFixed(2)
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K'
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M'
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B'
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T'
}

export const getQuarter = (date?: Date) => {
  const _date = date || new Date()
  return Math.floor(_date.getMonth() / 3 + 1)
}

export const modifyDsps = (dsps: any[]) =>
  dsps.map((dsp: any) => ({ label: dsp.dsp_name, value: dsp.dsp_id }))
