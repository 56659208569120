import React, { MutableRefObject, useRef } from 'react'
import SignatureCanvas from 'react-signature-canvas'

import Tooltip from './Tooltip'
import { Button } from 'components/partials/Button'
import { RotateCcw } from 'lucide-react'
import clsx from 'clsx'

const Signature = ({
  updateSignature,
  clearSignature,
  className,
}: {
  updateSignature: (sign: string) => void
  clearSignature?: () => void
  className?: string
}) => {
  const canvasRef = useRef(null) as MutableRefObject<any>
  const wrapperRef = useRef<HTMLDivElement>(null)

  const formatIntoPng = () => {
    if (canvasRef.current) {
      const dataURL = canvasRef.current.toDataURL('image/png')
      updateSignature(dataURL)
      return dataURL
    }
  }

  const clearSign = (e: any) => {
    e.preventDefault()
    if (canvasRef.current) {
      canvasRef.current.clear()
      clearSignature && clearSignature()
    }
  }

  const w = wrapperRef.current?.offsetWidth

  return (
    <div className={clsx('relative', className)} ref={wrapperRef}>
      <SignatureCanvas
        ref={canvasRef}
        penColor='black'
        onEnd={() => {
          formatIntoPng()
        }}
        canvasProps={{
          height: 300,
          width: w,
          className: 'relative w-auto border-b border-b-secondary-purple/20',
        }}
      />
      <Button
        variant='outline'
        size='icon'
        className='absolute top-0 right-0 m-4'
        onClick={clearSign}
      >
        <RotateCcw size={14} />
      </Button>
    </div>
  )
}

export default Signature
